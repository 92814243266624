import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"

import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { csvDownloadData } from "constants/common"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import Loader from "common/Loader"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const EmailSubscribeTable = props => {
  const [orders, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/email-subscribe?$sort[created_at]=-1&remove_limit=true`
      )

      if (response) {
        setData(response?.data)
        setLoading(false)
      }
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
  }

  useEffect(async () => {
    masterData()
  }, [])

  const selectRow = {
    mode: "checkbox",
  }

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search

  const EmailSubscriptionColumns = toggleModal => [
    {
      dataField: "email_id",
      text: "Email",
      sort: true,
    },

    {
      dataField: "updated_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  let downloadableArr = []

  let arr
  if (orders) {
    let csvTableHeaders = EmailSubscriptionColumns()
    var str = JSON.stringify(orders)
    arr = JSON.parse(str)
    downloadableArr = csvDownloadData(csvTableHeaders, arr)
  }

  const toggle = () => {
    setModal(!modal)
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.SUBSCRIPTIONS_DATA_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="email_id"
                      data={orders}
                      columns={EmailSubscriptionColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row justify-content-between">
                            <div className="col">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </div>

                            <div className="col-auto">
                              {accessControlData?.permissions
                                ?.SUBSCRIPTIONS_DATA_DOWNLOAD ? (
                                <button
                                  type="button"
                                  className="btn btn-primary btn-rounded"
                                  onClick={() =>
                                    JSONToCSVConvertor(
                                      downloadableArr,
                                      "Email-Subscriptions",
                                      true
                                    )
                                  }
                                >
                                  <i className="mdi mdi-arrow-down-bold-circle me-1" />{" "}
                                  {
                                    accessControlData?.permissions
                                      ?.SUBSCRIPTIONS_DATA_DOWNLOAD
                                  }
                                </button>
                              ) : null}
                            </div>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                columns={EmailSubscriptionColumns(toggle)}
                                data={orders}
                                pagination={paginationFactory(pageOptions)}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

EmailSubscribeTable.propTypes = {
  orders: PropTypes.array,
}

export default withRouter(EmailSubscribeTable)
