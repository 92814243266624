import React from "react"
import { axiosInstance } from "ConfigAxioxinstance"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { Card, CardBody, Modal, ModalBody, ModalHeader } from "reactstrap"
import RemarkText from "./RemarkText"

const RemarkCard = ({ investmentId }) => {
  const [remarkModal, setRemarkModal] = useState(false)
  const [remarks, setRemarks] = useState([])

  useEffect(() => {
    FetchRemarksHandler()
  }, [])

  const FetchRemarksHandler = async () => {
    try {
      const response = await axiosInstance.get(
        `deal-remarks?$sort[created_at]=-1&investmentId=${investmentId}`
      )
      const remarks = response.data?.data || response.data || []
      if (remarks.length) {
        setRemarks(
          remarks.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          )
        )
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  const toggleRemarkModal = () => {
    setRemarkModal(!remarkModal)
  }

  return remarks?.length ? (
    <Card className="h-100" style={{ maxHeight: "38vh" }}>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center">
          <h5>Remarks</h5>
          <Link
            onClick={toggleRemarkModal}
            className="d-flex justify-content-center align-items-center"
          >
            View All
          </Link>
        </div>
        <hr className="mt-2 mb-3" />
        <div style={{ maxHeight: "25vh", overflowY: "scroll" }}>
          {remarks &&
            remarks.map(item => (
              <RemarkText
                key={item.id}
                createdAt={item?.created_at}
                remark={item?.remark}
                reportType={item.report_type}
                username={item?.createdBy?.user_name}
                truncateLength={50}
              />
            ))}
        </div>
      </CardBody>
      <Modal isOpen={remarkModal} toggle={toggleRemarkModal} size="lg">
        <ModalHeader toggle={toggleRemarkModal} tag="h4">
          Remarks
        </ModalHeader>
        <ModalBody>
          <div style={{ maxHeight: "70rem", overflowY: "auto" }}>
            {remarks &&
              remarks.map(item => (
                <RemarkText
                  key={item.id}
                  createdAt={item?.created_at}
                  remark={item?.remark}
                  reportType={item.report_type}
                  username={item?.createdBy?.user_name}
                  truncateLength={100}
                />
              ))}
          </div>
        </ModalBody>
      </Modal>
    </Card>
  ) : (
    <></>
  )
}

RemarkCard.propTypes = {
  investmentId: PropTypes.string,
}

export default RemarkCard
