import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { handleValidDate, humanize } from "constants/common"

const RemarkText = ({
  remark,
  username,
  createdAt,
  reportType,
  truncateLength,
}) => {
  const [showMore, setShowMore] = useState(false)

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text
    return text.slice(0, maxLength) + "..."
  }

  return (
    <div className="d-flex flex-column pb-3 border-bottom mb-2">
      <div className="d-flex justify-content-start align-items-center">
        <i className="mdi mdi-account-outline fs-3"></i>
        <p className="mb-0 fs-5 ms-1">{username || "Admin"}</p>
      </div>
      <p className="mb-0">
        {showMore ? remark : truncateText(remark, truncateLength)}
      </p>
      {remark.length > truncateLength && (
        <>
          {showMore ? (
            <Link className="mt-1" onClick={() => setShowMore(false)}>
              Read Less
            </Link>
          ) : (
            <Link className="mt-1" onClick={() => setShowMore(true)}>
              Read More
            </Link>
          )}
        </>
      )}
      <div className="d-flex justify-content-between align-items-center text-secondary mt-1">
        <div className="d-flex justify-content-start align-items-center">
          <i className="mdi mdi-file-document fs-5"></i>
          <p className="mb-0 fs-6 ms-1">{humanize(reportType)}</p>
        </div>
        <p className="mb-0 fs-6 ms-1">{handleValidDate(createdAt)}</p>
      </div>
    </div>
  )
}

RemarkText.propTypes = {
  remark: PropTypes.string,
  username: PropTypes.string,
  createdAt: PropTypes.string,
  reportType: PropTypes.string,
  truncateLength: PropTypes.number,
}

export default RemarkText
