import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import "react-quill/dist/quill.snow.css"
import "../SponserOtherProjects/styles.css"
import { sortingByAlphabet } from "../../constants/sort"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { AccessId } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import { investmentData, Pagination } from "constants/common"

import Breadcrumbs from "components/Common/Breadcrumb"

import Loader from "common/Loader"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const UnderlyingAsset = () => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [state, setState] = useState({ value: null })
  const [detail, setDetail] = useState({ value: null })
  const [loading, setLoading] = useState(false)

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/underlying-asset?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setData(response?.data?.data)
        setLimit(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  const selectRow = {
    mode: "checkbox",
  }

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  //pagination customization
  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search

  const UnderLyingAssetColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessControlData?.permissions?.UNDERLYING_ASSET_EDIT ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {accessControlData?.permissions?.UNDERLYING_ASSET_EDIT}
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
            {accessControlData?.permissions?.UNDERLYING_ASSET_DELETE ? (
              <Link
                to="#"
                className="text-danger"
                onClick={() => handleDeleteOrder(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {accessControlData?.permissions?.UNDERLYING_ASSET_DELETE}
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },

    {
      dataField: "investment.project_name",
      text: "Project Name",
      sort: true,
    },
    {
      dataField: "text",
      text: "Location Name",
      sort: true,
    },
    {
      dataField: "disp_order",
      text: "Disp Order",
      sort: true,
    },
    {
      dataField: "lat",
      text: "Latitude",
      sort: true,
    },
    {
      dataField: "lng",
      text: "Longitude",
      sort: true,
    },

    {
      dataField: "updated_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  const handleDocList = id => {
    handleDoc(id)
  }

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const toLowerCase1 = str => {
    return str.toLowerCase()
  }

  const handleOrderClick = arg => {
    const order = arg
    setState({ value: null })
    setDetail({ value: null })
    setOrderList({
      id: order.id,
      project_name: order.investmentId,
      disp_order: order.disp_order,
      lat: order.lat,
      lng: order.lng,
      text: order.text,
      created_at: order.created_at,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDeleteOrder = async order => {
    setLoading(true)
    var r = confirm(`Are you sure want to delete Location ${order?.text || ""}`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `underlying-asset/${order.id}`
        )
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    setLoading(false)
  }
  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEdit) {
      const updateOrder = {
        investmentId: values.project_name,
        disp_order: values.disp_order || orderList.disp_order,
        lat: values.lat || orderList.lat,
        lng: values.lng || orderList.lng,
        text: values.text,
        created_at: values.created_at,
      }

      // update order

      try {
        const response = await axiosInstance.patch(
          `underlying-asset/${orderList.id}`,
          updateOrder
        )
        if (response) {
          setState({ value: null })
          setDetail({ value: null })
          masterData()
          toast.success("Successfully Edited")
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      const newOrder = {
        investmentId: values["project_name"],
        disp_order: values.disp_order,
        lat: values["lat"],
        lng: values["lng"],
        text: values["text"],
        created_at: values["created_at"],
      }
      // save new order

      try {
        const response = await axiosInstance.post(`underlying-asset`, newOrder)
        if (response) {
          setState({ value: null })
          setDetail({ value: null })
          masterData()
          toast.success(" Item Successfully Added")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    toggle()
    setLoading(false)
  }

  const handleOrderClicks = () => {
    setState({ value: null })
    setDetail({ value: null })
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const [sponser, setSponser] = useState([])

  useEffect(async () => {
    setLoading(true)
    try {
      const response = await investmentData()
      if (response) {
        setSponser(response?.data?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }, [])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.UNDERLYING_ASSET_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={UnderLyingAssetColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              {accessControlData?.permissions
                                ?.UNDERLYING_ASSET_ADD && (
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleOrderClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    {
                                      accessControlData?.permissions
                                        ?.UNDERLYING_ASSET_ADD
                                    }
                                  </Button>
                                </div>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                columns={UnderLyingAssetColumns(toggle)}
                                data={orders}
                                pagination={paginationFactory(pageOptions)}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit
                                    ? "Edit Underlying Asset"
                                    : "Add Underlying Asset"}
                                </ModalHeader>
                                <ModalBody>
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <AvField
                                            name="project_name"
                                            label="Project Name"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid Project Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.project_name || ""}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {sortingByAlphabet(sponser).map(
                                              (item, index) => (
                                                <option
                                                  key={item.project_name}
                                                  value={item.id}
                                                >
                                                  {item?.project_name}
                                                </option>
                                              )
                                            )}
                                          </AvField>
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="text"
                                            label="Location Name"
                                            type="text"
                                            errorMessage="Invalid Location Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.text || ""}
                                          />
                                        </div>

                                        <div className="mb-3">
                                          <AvField
                                            name="disp_order"
                                            label="Disp Order"
                                            type="number"
                                            errorMessage="Invalid Disp Order"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.disp_order || ""}
                                          />
                                        </div>

                                        <div className="mb-3">
                                          <AvField
                                            name="lat"
                                            label="Latitude"
                                            type="number"
                                            errorMessage="Invalid Latitude"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.lat || ""}
                                          />
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="lng"
                                            label="Longitude"
                                            type="number"
                                            errorMessage="Invalid Longitude"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.lng || ""}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

UnderlyingAsset.propTypes = {
  orders: PropTypes.array,
}

export default withRouter(UnderlyingAsset)
