import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Input,
  TabPane,
  TabContent,
  NavItem,
  NavLink,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { campaignStatusList } from "constants/ConstantFields"
import { humanize } from "constants/common"
import classnames from "classnames"
import SelectUsers from "./Components/SelectUsers"
import ChooseTemplate from "./Components/ChooseTemplate"
import SendTestEmails from "./Components/SendTestEmails"
import ScheduleEmails from "./Components/ScheduleEmails"
import CreateCampaign from "./Components/CreateCampaign"
import ReactSelect from "constants/ReactSelect"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const MarketingEmails = () => {
  // Loader
  const [loading, setLoading] = useState(false)

  // Pagination Logic
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])
  const [campaignStatusFilter, setCampaignStatusFilter] = useState("")

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "limit":
        setLimit(Number(e.target.value))
        break
      case "campaignStatus":
        setCampaignStatusFilter(e)
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(() => {
    masterData()
  }, [limit, skip, campaignStatusFilter])

  // Fetch Table Data Logic
  const [tableData, setTableData] = useState([])

  const masterData = async (campaignId = null) => {
    setLoading(true)

    let filterLink = `marketing-emails?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1`
    if (campaignStatusFilter) {
      filterLink += `&status=${campaignStatusFilter?.id}`
    }
    try {
      const tableResponse = await axiosInstance.get(filterLink)

      if (tableResponse) {
        const tableData = tableResponse?.data?.data || tableResponse?.data
        setTableData(tableData)
        setTotal(tableResponse?.data?.total)
        let pages = Math.ceil(
          (tableResponse.data?.total || tableResponse?.total) /
            (tableResponse.data?.limit || tableResponse?.limit)
        )
        setTotalPage(pages)
        if (campaignId) {
          setSelectedCampaign(
            tableData.find(el => el.campaign_id === campaignId)
          )
        }
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ]

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM YYYY hh:mm a")
    return date1
  }

  const marketingEmailStatus = [
    { status: "Created", colorClassNames: "secondary" },
    { status: "Test_Emails_Sent", colorClassNames: "primary" },
    { status: "Test_Emails_Verified", colorClassNames: "primary" },
    { status: "Sent", colorClassNames: "success" },
    { status: "Scheduled", colorClassNames: "success" },
    { status: "Suspended", colorClassNames: "danger" },
  ]

  const allowEditOrDeleteStatus = [
    "Created",
    "Test_Emails_Sent",
    "Test_Emails_Verified",
    // "Scheduled",
  ]

  const handleDeleteOrder = async order => {
    setLoading(true)
    var r = confirm(
      `Are you sure want to delete this campaign permanently? You won't be able to restore it again.`
    )
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `marketing-emails/` + order.id
        )
        if (response) {
          masterData()
          toast.success("Successfully Deleted!")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    setLoading(false)
  }

  const handleActionClick = row => {
    setLoading(true)
    if (row?.status === "Created" || row?.status === "Test_Emails_Sent") {
      setSendEmailModal(4)
    } else {
      setSendEmailModal(5)
    }
    setSelectedCampaign(row)
    setCampaignName(row.campaign_name)
    setCampaignId(row.campaign_id)
    setModal(true)
    setLoading(false)
  }

  const MarketingEmailColumns = (download = false) => {
    const cols = []
    if (accessControlData?.permissions?.EMAILS_DELETE) {
      cols.push({
        dataField: "delete",
        isDummyField: true,
        text: "Delete",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <>
            <div className="d-flex gap-3">
              {accessControlData?.permissions?.EMAILS_DELETE &&
              allowEditOrDeleteStatus?.includes(row?.status) ? (
                <Link
                  className="text-danger"
                  onClick={() => handleDeleteOrder(row)}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {accessControlData?.permissions?.EMAILS_DELETE}
                  </UncontrolledTooltip>
                </Link>
              ) : (
                "NA"
              )}
            </div>
          </>
        ),
      })
    }
    cols.push(
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          (accessControlData?.permissions?.EMAILS_CREATE_CAMPAIGN ||
            accessControlData?.permissions?.EMAILS_TEST ||
            accessControlData?.permissions?.EMAILS_VERIFY ||
            accessControlData?.permissions?.EMAILS_SCHEDULE ||
            accessControlData?.permissions?.EMAILS_SUSPEND) &&
          allowEditOrDeleteStatus?.includes(row?.status) ? (
            <Link to="#" className="text-success">
              <Button
                type="button"
                color={
                  row?.status === "Test_Emails_Verified"
                    ? "success"
                    : row?.status === "Scheduled"
                    ? "danger"
                    : "primary"
                }
                outline
                className="btn-md btn-rounded"
                onClick={() => handleActionClick(row)}
              >
                {row?.status === "Created"
                  ? // accessControlData?.permissions?.EMAILS_TEST
                    "Test"
                  : ""}
                {row?.status === "Test_Emails_Sent"
                  ? // accessControlData?.permissions?.EMAILS_VERIFY
                    "Verify"
                  : ""}
                {row?.status === "Test_Emails_Verified"
                  ? // accessControlData?.permissions?.EMAILS_SCHEDULE
                    "Schedule"
                  : ""}
                {row?.status === "Scheduled"
                  ? // accessControlData?.permissions?.EMAILS_SUSPEND
                    "Suspend"
                  : ""}
              </Button>
            </Link>
          ) : (
            "NA"
          ),
      },
      {
        dataField: "campaign_name",
        text: "Campaign Name",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          row.campaign_name + " (#" + row.campaign_id + ")",
      },
      { dataField: "recipients", text: "Recipients" },
      {
        dataField: "status",
        text: "Campaign Status",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <span
            className={`badge badge-pill font-size-12 badge-soft-${
              marketingEmailStatus?.find(el => el?.status === cellContent)
                .colorClassNames
            } text-capitalize`}
          >
            {humanize(cellContent)}
          </span>
        ),
      },
      {
        dataField: "scheduled_at",
        text: "Scheduled At",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          row?.scheduled_at ? handleValidDate(row.scheduled_at) : "",
      },
      {
        dataField: "triggered_at",
        text: "Triggered At",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          row?.triggered_at ? handleValidDate(row.triggered_at) : "",
      },
      {
        dataField: "template_name",
        text: "Template Name",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          row.template_name + " (#" + row.template_id + ")",
      },
      { dataField: "triggeredBy.user_name", text: "Sent By" },
      { dataField: "verifiedBy.user_name", text: "Verified By" },
      { dataField: "createdBy.user_name", text: "Created By" },
      {
        dataField: "created_at",
        text: "Created At",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          row?.created_at ? handleValidDate(row.created_at) : "",
      },
      { dataField: "email_subject", text: "Email Subject" },
      {
        dataField: "contact_list_name",
        text: "Contact List",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => row.contact_list_name,
      },
      { dataField: "triggeredBy.email", text: "Triggered By (Email)" },
      { dataField: "verifiedBy.email", text: "Verified By (Email)" },
      { dataField: "createdBy.email", text: "Created By (Email)" },
      { dataField: "sender_name", text: "Campaign Sender" },
      { dataField: "sender_email", text: "Campaign Sender (Email)" }
    )
    return cols
  }
  // Modal Logic
  const [modal, setModal] = useState(false)
  const [sendEmailModal, setSendEmailModal] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])

  const toggle = () => {
    setModal(!modal)
  }

  const handleCreate = () => {
    setSendEmailModal(1)
    setSelectedEmailTemplate(null)
    setMarketingData(null)
    setExcludedUsers([])
    setEmailCsvData([])
    setUserData([])
    setCampaignName("")
    setSelectedSender()
    setCampaignId()
    setSelectedCampaign()
    toggle()
  }

  function toggleModalStep(tab) {
    if (sendEmailModal !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 5) {
        setSendEmailModal(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }
  const nextModalStep = () => {
    toggleModalStep(sendEmailModal + 1)
  }

  // Common Logic
  const [marketingData, setMarketingData] = useState(null)
  const [excludedUsers, setExcludedUsers] = useState([])
  const [emailCsvData, setEmailCsvData] = useState([])
  const [userData, setUserData] = useState([])
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(null)
  const [campaignName, setCampaignName] = useState("")
  const [selectedSender, setSelectedSender] = useState()
  const [campaignId, setCampaignId] = useState()
  const [selectedCampaign, setSelectedCampaign] = useState()

  const paramsList = [
    "FIRSTNAME",
    "RM_NAME",
    "RM_EMAIL",
    "RM_PHONE",
    "DISTRIBUTOR_NAME",
    "DISTRIBUTOR_EMAIL",
    "DISTRIBUTOR_PHONE",
  ]
  const [validParams, setValidParams] = useState(paramsList)

  const handleCreateCampaign = async (e, values) => {
    try {
      setLoading(true)
      const campaignObj = {
        data: [...userData],
        template_id: selectedEmailTemplate?.id,
        template_name: selectedEmailTemplate?.name,
        email_subject: selectedEmailTemplate?.subject,
        userGroup: marketingData?.userGroup,
        campaignName: campaignName,
        sender: selectedSender,
      }
      const response = await axiosInstance.post(
        "create-marketing-email-campaign",
        campaignObj
      )
      if (response) {
        toast.success("Campaign created successfully!")
        setCampaignId(response?.data?.output?.campaignResponse?.id)
        nextModalStep()
      }
      masterData(response?.data?.output?.campaignResponse?.id)
    } catch (error) {
      toast.error(error)
    }
    setLoading(false)
  }

  const handleTestEmails = async emails => {
    if (emails?.length && selectedCampaign?.id) {
      try {
        setLoading(true)
        const response = await axiosInstance.patch(
          "marketing-email-service/" + selectedCampaign?.id,
          {
            campaignId: campaignId,
            emailTo: emails,
            type: "test",
          }
        )
        if (response) {
          toast.success(response.data.message)
          masterData(campaignId)
        }
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    }
  }

  const handleVerifyTest = async () => {
    try {
      setLoading(true)
      const localUser = JSON.parse(localStorage.getItem("userInfo"))
      const response = await axiosInstance.patch(
        "marketing-emails/" + selectedCampaign.id,
        { status: "Test_Emails_Verified", verifiedById: localUser?.id }
      )
      if (response) {
        toast.success("Campaign marked as Verified!")
        nextModalStep()
        masterData(selectedCampaign.campaign_id)
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.EMAILS_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between">
                  <Col md={2}>
                    <select
                      className="form-select w-75"
                      value={limit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <div className="col-auto">
                    {accessControlData?.permissions?.EMAILS_CREATE_CAMPAIGN && (
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-2 me-2"
                        onClick={handleCreate}
                      >
                        <i className="mdi mdi-telegram me-1" />
                        {accessControlData?.permissions?.EMAILS_CREATE_CAMPAIGN}
                      </Button>
                    )}
                  </div>
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={tableData}
                      columns={MarketingEmailColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row justify-content-start">
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Campaign Status</label>
                                <ReactSelect
                                  users={campaignStatusList}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "campaignStatus")
                                  }
                                  optionLabelKeys={["statusText"]}
                                  isClearable={true}
                                  multiOptionLabel={false}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                columns={MarketingEmailColumns()}
                                data={tableData}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal
                                isOpen={modal}
                                toggle={toggle}
                                className={"modal-xl h-100 text-muted p-1"}
                                contentClassName={
                                  sendEmailModal === 2
                                    ? selectedEmailTemplate?.id
                                      ? "custom-modal-height"
                                      : "h-50"
                                    : ""
                                }
                                backdrop="static"
                                keyboard={false}
                              >
                                <ModalHeader toggle={toggle} tag="h4">
                                  Create Marketing Campaign
                                </ModalHeader>
                                <ModalBody>
                                  {/* <AvForm
                                  onValidSubmit={handleValidSubmit}
                                  className="h-100 d-flex flex-column justify-content-between gap-2"
                                > */}
                                  <div className="vertical-wizard wizard clearfix vertical">
                                    <div className="steps clearfix">
                                      <ul>
                                        <NavItem
                                          className={classnames({
                                            current: sendEmailModal === 1,
                                          })}
                                        >
                                          <NavLink
                                            className={
                                              classnames({
                                                active: sendEmailModal === 1,
                                              }) + "pe-none"
                                            }
                                            disabled={
                                              !(passedSteps || []).includes(1)
                                            }
                                          >
                                            <span className="number">1.</span>{" "}
                                            Select Users
                                          </NavLink>
                                        </NavItem>
                                        <NavItem
                                          className={classnames({
                                            current: sendEmailModal === 2,
                                          })}
                                        >
                                          <NavLink
                                            className={
                                              classnames({
                                                active: sendEmailModal === 2,
                                              }) + "pe-none"
                                            }
                                            disabled={
                                              !(passedSteps || []).includes(2)
                                            }
                                          >
                                            <span className="number">2.</span>{" "}
                                            <span>Choose Template</span>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem
                                          className={classnames({
                                            current: sendEmailModal === 3,
                                          })}
                                        >
                                          <NavLink
                                            className={
                                              classnames({
                                                active: sendEmailModal === 3,
                                              }) + "pe-none"
                                            }
                                            disabled={
                                              !(passedSteps || []).includes(3)
                                            }
                                          >
                                            <span className="number">3.</span>{" "}
                                            Create Campaign
                                          </NavLink>
                                        </NavItem>
                                        <NavItem
                                          className={classnames({
                                            current: sendEmailModal === 4,
                                          })}
                                        >
                                          <NavLink
                                            className={
                                              classnames({
                                                active: sendEmailModal === 4,
                                              }) + "pe-none"
                                            }
                                            disabled={
                                              !(passedSteps || []).includes(4)
                                            }
                                          >
                                            <span className="number">4.</span>{" "}
                                            Send Test Emails
                                          </NavLink>
                                        </NavItem>
                                        <NavItem
                                          className={classnames({
                                            current: sendEmailModal === 5,
                                          })}
                                        >
                                          <NavLink
                                            className={
                                              classnames({
                                                active: sendEmailModal === 5,
                                              }) + "pe-none"
                                            }
                                            disabled={
                                              !(passedSteps || []).includes(5)
                                            }
                                          >
                                            <span className="number">5.</span>{" "}
                                            Schedule Campaign
                                          </NavLink>
                                        </NavItem>
                                      </ul>
                                    </div>

                                    <div className="content clearfix">
                                      <TabContent
                                        activeTab={sendEmailModal}
                                        className="body"
                                      >
                                        <TabPane tabId={1}>
                                          {sendEmailModal === 1 ? (
                                            <SelectUsers
                                              loading={loading}
                                              setLoading={setLoading}
                                              marketingData={marketingData}
                                              setMarketingData={
                                                setMarketingData
                                              }
                                              excludedUsers={excludedUsers}
                                              setExcludedUsers={
                                                setExcludedUsers
                                              }
                                              emailCsvData={emailCsvData}
                                              setEmailCsvData={setEmailCsvData}
                                              userData={userData}
                                              setUserData={setUserData}
                                              accessControlData={
                                                accessControlData
                                              }
                                              nextModalStep={nextModalStep}
                                              paramsList={paramsList}
                                              validParams={validParams}
                                              setValidParams={setValidParams}
                                            />
                                          ) : null}
                                        </TabPane>
                                        <TabPane tabId={2}>
                                          {sendEmailModal === 2 ? (
                                            <ChooseTemplate
                                              loading={loading}
                                              setLoading={setLoading}
                                              nextModalStep={nextModalStep}
                                              validParams={validParams}
                                              selectedEmailTemplate={
                                                selectedEmailTemplate
                                              }
                                              setSelectedEmailTemplate={
                                                setSelectedEmailTemplate
                                              }
                                            />
                                          ) : null}
                                        </TabPane>
                                        <TabPane tabId={3}>
                                          {sendEmailModal === 3 ? (
                                            <CreateCampaign
                                              loading={loading}
                                              setLoading={setLoading}
                                              marketingData={marketingData}
                                              userData={userData}
                                              setUserData={setUserData}
                                              nextModalStep={nextModalStep}
                                              selectedEmailTemplate={
                                                selectedEmailTemplate
                                              }
                                              setSelectedEmailTemplate={
                                                setSelectedEmailTemplate
                                              }
                                              campaignName={campaignName}
                                              setCampaignName={setCampaignName}
                                              selectedSender={selectedSender}
                                              setSelectedSender={
                                                setSelectedSender
                                              }
                                              handleCreateCampaign={
                                                handleCreateCampaign
                                              }
                                            />
                                          ) : null}
                                        </TabPane>
                                        <TabPane tabId={4}>
                                          {sendEmailModal === 4 ? (
                                            <SendTestEmails
                                              handleTestEmails={
                                                handleTestEmails
                                              }
                                              selectedCampaign={
                                                selectedCampaign
                                              }
                                              handleVerifyTest={
                                                handleVerifyTest
                                              }
                                            />
                                          ) : null}
                                        </TabPane>
                                        <TabPane tabId={5}>
                                          {sendEmailModal === 5 ? (
                                            <ScheduleEmails
                                              setLoading={setLoading}
                                              selectedCampaign={
                                                selectedCampaign
                                              }
                                              masterData={masterData}
                                              setModal={setModal}
                                            />
                                          ) : null}
                                        </TabPane>
                                      </TabContent>
                                    </div>
                                    {/* <div className="actions clearfix d-flex justify-content-end mt-3">
                                      <div className="text-start me-3">
                                        <button
                                          type="button"
                                          className="btn btn-success save-user"
                                          disabled={
                                            loading || sendEmailModal === 1
                                              ? true
                                              : false
                                          }
                                          onClick={prevModalStep}
                                        >
                                          PREVIOUS
                                        </button>
                                      </div>
                                    </div> */}
                                  </div>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}
                    `}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(MarketingEmails)
