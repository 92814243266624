import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import getBase64 from "../../base64"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "components/Common/Breadcrumb"

import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"

import {
  benPosReportTypes,
  interestCalculationReports,
} from "constants/ConstantFields"
import { investmentData, csvDownloadData } from "constants/common"
import paginationFactory from "react-bootstrap-table2-paginator"
import { useHistory, useLocation, Link } from "react-router-dom"
import { permissionChecker } from "common/PermissionChecker"
import BenposProjectCard from "components/Common/BenposProjectCard"
import HeaderWithBackBtn from "components/Common/HeaderWithBackBtn"
import AddRemarkBtn from "components/Remark/AddRemarkBtn"

const BenPosMaster = () => {
  const [orders, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [file, setFile] = useState(null)
  const [calculationFile, setCalculationFile] = useState(null)
  const [base64, setBase64] = useState("")
  const [upload, setUpload] = useState(false)
  const [approvalModalData, setApprovalModalData] = useState([])

  const [investments, setInvestments] = useState([])
  const [selectedProject, setSelectedProject] = useState(null)

  const [benPosDate, setBenPosDate] = useState()
  const [sourceDetails, setSourceDetails] = useState("")
  const [receivedOnDate, setReceivedOnDate] = useState()

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [userFilter, setUserFilter] = useState("")
  const [total, setTotal] = useState(0)

  const [investmentFilter, setInvestmentFilter] = useState({})
  const [benPosFilter, setBenPosFilter] = useState()

  const [reportType, setReportType] = useState("")
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const [interestCalculationFilter, setInterestCalculationFilter] = useState("")
  const localUser = JSON.parse(localStorage.getItem("userInfo"))

  const typeFromUrl = queryParams.get("type")
  const invIdFromUrl = queryParams.get("investmentId")
  const benposDateFromUrl = queryParams.get("benpos-date")
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null })
  const [showInterestTable, setShowInterestTable] = useState(false)

  const [breadcrumbItem, setBreadcrumbItem] = useState(benPosReportTypes[0])

  const [accessControlData, setAccessControlData] = useState([])
  const [trancheWiseSubTotal, setTrancheWiseSubTotal] = useState([])

  const [approveModal, setApproveModal] = useState(false)
  const [selectedAction, setSelectedAction] = useState("")
  const [actionReason, setActionReason] = useState("")
  const [consent, setConsent] = useState(false)
  const [myPolicy, setMyPolicy] = useState(null)

  const [
    calculatedInterestAmountsPerTranche,
    setCalculatedInterestAmountsPerTranche,
  ] = useState({})

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  useEffect(() => {
    setUserFilter("")
    setShowInterestTable(false)
    setSkip(0)
    setLimit(10)
    setCurrentPage(1)
    setTotalPage(0)
    setInterestCalculationFilter(null)
    setDateRange({ startDate: null, endDate: null })
    if (typeFromUrl && invIdFromUrl && benposDateFromUrl) {
      setReportType(typeFromUrl)
      setInvestmentFilter({ id: invIdFromUrl })
      setBenPosFilter(benposDateFromUrl)
      setBreadcrumbItem(
        benPosReportTypes.find(item => item.type == typeFromUrl)
      )
    } else {
      setReportType("")
      setInvestmentFilter("")
      setBenPosFilter("")
      setBreadcrumbItem(benPosReportTypes[0])
    }
  }, [location])

  const masterData = async () => {
    const url =
      benPosReportTypes.find(item => item.type == typeFromUrl)?.endpoint ||
      "benpos-master"

    const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
      investmentFilter?.id || invIdFromUrl
        ? `&investmentId=${investmentFilter.id || invIdFromUrl}`
        : ""
    }${
      benPosFilter || benposDateFromUrl
        ? `&benpos_date=${benPosFilter || benposDateFromUrl}`
        : ""
    }${
      dateRange.startDate && dateRange.endDate
        ? `&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`
        : ""
    }`

    if (
      typeFromUrl == "interest" &&
      !(dateRange.startDate && dateRange.endDate)
    ) {
      return
    }

    if (interestCalculationFilter?.id === "tranche-wise-per-bond") {
      const TranchWiseBondsUrl = `${url}/tranche-wise-per-bond?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`
      const TrancheWiseUserUrl = `${url}?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`
      try {
        setLoading(true)
        const respTranchWiseBonds = await axiosInstance.get(TranchWiseBondsUrl)
        const respTrancheWiseUser = await axiosInstance.get(TrancheWiseUserUrl)
        const bondWiseData = respTranchWiseBonds.data.data
        const userWiseData = respTrancheWiseUser.data.data
        const combineData = bondWiseData.map(item => {
          if (
            userWiseData.find(
              el => el.investmentLotId === item.id && el.isSubtotal
            )
          ) {
            return {
              ...item,
              total_payable_interest: userWiseData.find(
                el => el.investmentLotId === item.id && el.isSubtotal
              ).gross_interest,
            }
          } else {
            return { ...item, total_payable_interest: 0 }
          }
        })
        if (respTranchWiseBonds) {
          setData([])
          setData(combineData)

          setTotal(respTranchWiseBonds.data.total)
          let pages = Math.ceil(
            (respTranchWiseBonds.data?.total || respTranchWiseBonds.total) /
              (respTranchWiseBonds.data?.limit || respTranchWiseBonds.limit)
          )
          setTrancheWiseSubTotal(
            respTranchWiseBonds.data.data.filter(item => item.isSubtotal)
          )
          setTotalPage(pages)
        }
      } catch (error) {
        toast.error(error?.message)
      } finally {
        setLoading(false)
      }
    } else {
      const getUrl = `${url}${
        interestCalculationFilter?.endpoint
          ? "/" + interestCalculationFilter.endpoint
          : ""
      }?${
        typeFromUrl == "validation" ? "" : `$limit=${limit}&$skip=${skip}&`
      }$sort[created_at]=-1${filterUrl}`
      try {
        setLoading(true)
        const response = await axiosInstance.get(getUrl)
        if (response) {
          setData([])
          setData(response?.data?.data || response?.data)

          setTotal(response.data.total)
          let pages = Math.ceil(
            (response.data?.total || response.total) /
              (response.data?.limit || response.limit)
          )
          setTrancheWiseSubTotal(
            response.data.data.filter(item => item.isSubtotal)
          )
          setTotalPage(pages)
        }
      } catch (error) {
        toast.error(error?.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const GetTrancheWiseInterestPerUserData = async () => {
    const url = `
benpos-interest-calculation-report?$limit=10&$skip=0&$sort[created_at]=-1`
    const filterUrl = `${
      investmentFilter?.id || invIdFromUrl
        ? `&investmentId=${investmentFilter.id || invIdFromUrl}`
        : ""
    }${
      benPosFilter || benposDateFromUrl
        ? `&benpos_date=${benPosFilter || benposDateFromUrl}`
        : ""
    }${
      dateRange.startDate && dateRange.endDate
        ? `&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`
        : ""
    }`
    try {
      setLoading(true)
      const response = await axiosInstance.get(url + filterUrl)
      if (response) {
        setApprovalModalData(response.data.data.filter(item => item.isSubtotal))
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const getFilters = async () => {
    try {
      const investment = await investmentData("Approved", "Closed", "Exited")
      if (investment) {
        setInvestments(investment?.data?.data)
      } else {
        toast.error("Something went wrong")
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    getFilters()
  }, [])

  useEffect(async () => {
    masterData()
  }, [limit, skip, investmentFilter, benPosFilter, reportType])

  useEffect(() => {
    if (dateRange?.startDate && dateRange?.endDate) {
      masterData()
    }
  }, [interestCalculationFilter])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "projectType":
        setInvestmentFilter(e)
        setUserFilter("")
        setBenPosFilter("")
        break
      case "interestType":
        setInterestCalculationFilter(e)
        break
      case "benPosDate":
        setBenPosFilter(e.target.value)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM YYYY")
    return date1
  }

  const handleRowButtonClick = async (item, type) => {
    history.push({
      search: `?type=${type}&investmentId=${item.investmentId}&benpos-date=${item.benpos_date}`,
    })
  }

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }

  const benPosColumns = toggleModal => {
    let columns = [
      {
        dataField: "",
        text: "BenPos",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div>
            <Link
              to={`/benpos-master?type=uploaded&investmentId=${row.investmentId}&benpos-date=${row.benpos_date}`}
              className="text-primary"
            >
              View
            </Link>
          </div>
        ),
      },

      {
        dataField: "project_name",
        text: "Project Name",
        sort: true,
      },
      {
        dataField: "benpos_date",
        text: "BenPos Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          row.benpos_date ? handleValidDate(row.benpos_date) : "",
      },
      // {
      //   dataField: "email",
      //   text: "Created By (Email)",
      //   sort: true,
      // },
      {
        dataField: "Action",
        isDummyField: true,
        text: "Interest and Repayment Reports",
        // eslint-disable-next-line react/display-name
        headerFormatter: () => (
          <span className="d-flex w-50">Interest Processing Dashboard</span>
        ),
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          accessControlData?.permissions?.BENPOS_INTEREST_REPAYMENT_REPORTS ? (
            <div className="d-flex justify-content-center w-75">
              <Link
                to={`/interest-and-repayment-dashboard/${row.investmentId}/${row.benpos_date}`}
              >
                <svg viewBox="0 0 24 24" width="26px" fill={"#556ee6"}>
                  <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                </svg>
              </Link>
            </div>
          ) : (
            "NA"
          ),
      },
      {
        dataField: "user_name",
        text: "Created By",
        sort: true,
      },
      {
        dataField: "created_at",
        text: "Created At",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: cellContent => handleValidDate(cellContent),
      },
      {
        dataField: "source_details",
        text: "Source Details",
        sort: true,
      },
      {
        dataField: "received_on",
        text: "Received On",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: cellContent =>
          cellContent ? handleValidDate(cellContent) : <></>,
      },
    ]

    if (reportType == "validation") {
      columns = [
        {
          dataField: "project_name",
          text: "Project Name",
          sort: true,
        },
        {
          dataField: "benpos_date",
          text: "BenPos Date",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => handleValidDate(row.benpos_date),
        },
        {
          dataField: "category",
          text: "Category",
          sort: true,
        },
        {
          dataField: "investor_name",
          text: "Investor Name (as per BenPos)",
          sort: true,
        },
        { dataField: "pan_number", text: "PAN (as per BenPos)", sort: true },
        {
          dataField: "debentures",
          text: "Lot Size (as per BenPos)",
          sort: true,
        },
        {
          dataField: "bank_name",
          text: "Bank Name (as per BenPos)",
          sort: true,
        },
        {
          dataField: "bank_account_number",
          text: "Account Number (as per BenPos)",
          sort: true,
        },
        {
          dataField: "bank_ifsc",
          text: "IFSC (as per BenPos)",
          sort: true,
        },
        { dataField: "demat_id", text: "Demat Id (as per BenPos)", sort: true },
        {
          dataField: "name_on_pan",
          text: "Investor Name (as per System)",
          sort: true,
        },
        { dataField: "lot_size", text: "Lot Size (as per System)", sort: true },
        {
          dataField: "user_account_number",
          text: "Account Number (as per System)",
          sort: true,
        },
        {
          dataField: "user_ifsc_code",
          text: "IFSC (as per System)",
          sort: true,
        },
        {
          dataField: "user_demat_id",
          text: "Demat Id (as per System)",
          sort: true,
        },
        {
          dataField: "nil_tds_applicable",
          text: "NIL TDS applicable?",
          sort: true,
        },
        { dataField: "user_exists", text: "Pan Found?", sort: true },
        { dataField: "name_matched", text: "Name Matched?", sort: true },
        {
          dataField: "investment_exists",
          text: "Investment found?",
          sort: true,
        },
        {
          dataField: "debenture_matched",
          text: "Debentures Matched?",
          sort: true,
        },
        {
          dataField: "bank_account_matched",
          text: "Bank Account Matched?",
          sort: true,
        },
        {
          dataField: "ifsc_matched",
          text: "IFSC Matched?",
          sort: true,
        },
        {
          dataField: "demat_matched",
          text: "Demat Matched?",
          sort: true,
        },
        // { dataField: "userInvestmentId", text: "", sort: true },
      ]
    }

    if (reportType == "interest") {
      columns = [
        // { dataField: "project_name", text: "Project Name" },
        // { dataField: "benpos_date", text: "BenPos Date" },
      ]
      if (interestCalculationFilter?.id === "tranche-wise-per-bond") {
        columns.push(
          { dataField: "investment_lot_name", text: "Tranche" },
          {
            dataField: "disbursement_date",
            text: "Disbursement Date",
            formatter: (cellContent, row) =>
              handleValidDate(row.disbursement_date),
          },
          { dataField: "lot_size", text: "Lot Size" },
          {
            dataField: "start_date",
            text: "Start Date",
            formatter: (cellContent, row) => handleValidDate(row.start_date),
          },
          {
            dataField: "end_date",
            text: "End Date",
            formatter: (cellContent, row) => handleValidDate(row.end_date),
          },
          {
            dataField: "interest",
            text: "Gross Interest (per bond)",
            formatter: (cellContent, row) =>
              row.interest ? handleAmount(row.interest) : 0,
          },
          {
            dataField: "total_interest",
            text: "Total Interest",
            formatter: (cellContent, row) =>
              row.total_interest ? handleAmount(row.total_interest) : 0,
          },
          {
            dataField: "total_payable_interest",
            text: "Total Payable Interest",
            formatter: (cellContent, row) =>
              row.total_payable_interest
                ? handleAmount(row?.total_payable_interest)
                : 0,
          },
          {
            dataField: "difference",
            text: "Difference",
            formatter: (cellContent, row) => {
              let difference = row.total_interest - row.total_payable_interest
              if (difference < 0) {
                return `(${handleAmount(Math.abs(difference))})`
              } else {
                return handleAmount(difference)
              }
            },
          }
        )
      } else if (interestCalculationFilter?.id === "trade-wise") {
        columns.push(
          { dataField: "investment_lot_name", text: "Tranche" },
          { dataField: "user_name", text: "Investor Name" },
          { dataField: "pan_number", text: "Pan Number" },
          { dataField: "lot_size", text: "Debentures" },
          {
            dataField: "gross_interest",
            text: "Gross Interest Due",
            formatter: (cellContent, row) => handleAmount(row.gross_interest),
          },
          {
            dataField: "net_interest",
            text: "Net Interest",
            formatter: (cellContent, row) => handleAmount(row.net_interest),
          },
          {
            dataField: "tds",
            text: "TDS",
            formatter: (cellContent, row) => handleAmount(row.tds),
          },
          {
            dataField: "gross_interest_per_bond",
            text: "Gross Interest Per Bond",
            formatter: (cellContent, row) =>
              handleAmount(
                Math.round((row.gross_interest / row.lot_size) * 10) / 10
              ),
          }
        )
      } else {
        if (interestCalculationFilter?.id === "tranche-wise") {
          columns.push({
            dataField: "investment_lot_name",
            text: "Tranche",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
              if (row?.isSubtotal) {
                return <strong>{cellContent}</strong>
              }
              return cellContent
            },
          })
        }
        columns.push(
          {
            dataField: "investor_name",
            text: "Investor Name",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
              if (row?.isSubtotal) {
                return <strong>{cellContent}</strong>
              }
              return cellContent
            },
          },
          { dataField: "pan_number", text: "Pan Number" },
          { dataField: "demat_id", text: "Demat Id" },
          {
            dataField: "lot_size",
            text: "Debentures", // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
              if (row?.isSubtotal) {
                return <strong>{cellContent}</strong>
              }
              return cellContent
            },
          },
          {
            dataField: "gross_interest",
            text: "Gross Interest Due",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
              if (row?.isSubtotal) {
                return <strong>{handleAmount(cellContent)}</strong>
              }
              return handleAmount(cellContent)
            },
          },
          {
            dataField: "net_interest",
            text: "Net Interest",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
              if (row?.isSubtotal) {
                return <strong>{handleAmount(cellContent)}</strong>
              }
              return handleAmount(cellContent)
            },
          },
          {
            dataField: "tds",
            text: "TDS",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
              if (row?.isSubtotal) {
                return <strong>{handleAmount(cellContent)}</strong>
              }
              return handleAmount(cellContent)
            },
          },
          {
            dataField: "gross_interest_per_bond",
            text: "Gross Interest Per Bond",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
              if (row?.isSubtotal) {
                return (
                  <strong>
                    {handleAmount(
                      Math.round((row.gross_interest / row.lot_size) * 10) / 10
                    )}
                  </strong>
                )
              }
              return handleAmount(
                Math.round((row.gross_interest / row.lot_size) * 10) / 10
              )
            },
          },
          { dataField: "bank_name", text: "Bank Name" },
          { dataField: "bank_account_number", text: "Account Number" },
          { dataField: "bank_ifsc", text: "IFSC Code" },
          { dataField: "user_ifsc", text: "IFSC Code (As per system)" },
          {
            dataField: "user_account_number",
            text: "Account Number (As per system)",
          }
        )
      }
    }

    if (reportType == "uploaded") {
      columns = [
        {
          dataField: "investment.project_name",
          text: "Project Name",
          sort: true,
        },
        {
          dataField: "benpos_date",
          text: "BenPos Date",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) =>
            row.benpos_date ? handleValidDate(row.benpos_date) : "",
        },
        {
          dataField: "investor_name",
          text: "Investor Name",
          sort: true,
        },
        {
          dataField: "pan_number",
          text: "PAN Number",
          sort: true,
        },
        {
          dataField: "debentures",
          text: "Debentures",
          sort: true,
        },
        {
          dataField: "bank_name",
          text: "Bank Name",
          sort: true,
        },
        {
          dataField: "bank_account_number",
          text: "Bank Account Number",
          sort: true,
        },
        {
          dataField: "bank_ifsc",
          text: "Bank IFSC",
          sort: true,
        },
        {
          dataField: "demat_id",
          text: "Demat Id",
          sort: true,
        },
      ]
    }
    return columns
  }

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  const toggle = () => {
    setModal(!modal)
  }

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (upload) {
      const dataToUpload = {
        benpos: base64.replace("data:text/csv;base64,", ""),
        investmentId: selectedProject?.id,
        benpos_date: benPosDate,
        source_details: sourceDetails,
        received_on: receivedOnDate,
      }
      if (base64) {
        try {
          const uploadResponse = await axiosInstance.post(
            "/upload-base-64?type=benpos",
            dataToUpload
          )
          if (uploadResponse) {
            masterData()
            toast.success("Sucessfully Uploaded")
            setUpload(false)
            setBase64("")
          }
        } catch (error) {
          toast.error(error.message)
        }
      } else {
        toast.error("Please select valid file")
        setLoading(false)
        return false
      }
      toggle()
    }
    setLoading(false)
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const downloadData = async fileName => {
    if (
      reportType == "" ||
      reportType == "uploaded" ||
      reportType == "interest"
    ) {
      if (interestCalculationFilter?.id == "tranche-wise-per-bond") {
        const csvTableHeaders = benPosColumns()
        const str = JSON.stringify(orders)
        const arr = JSON.parse(str)

        arr.forEach(el => {
          el.difference = handleAmount(
            el.total_interest - el.total_payable_interest
          )
          ;(el.interest = handleAmount(el.interest)),
            (el.total_interest = handleAmount(el.total_interest)),
            (el.total_payable_interest = handleAmount(
              el.total_payable_interest
            ))
        })
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(
          downladableArr,
          "Tranche-wise Interest per bond",
          true
        )
        return
      }

      const filterUrl = `${
        userFilter.length >= 3 ? `&name=${userFilter}` : ``
      }${investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""}${
        benPosFilter ? `&benpos_date=${benPosFilter}` : ""
      }${
        dateRange.startDate && dateRange.endDate
          ? `&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`
          : ""
      }`

      let url = "benpos-master"
      if (reportType == "uploaded") {
        url = "benpos"
        fileName = "Benpos"
      }
      if (reportType == "interest") {
        url = "benpos-interest-calculation-report/tranche-wise-per-bond"
        fileName = "Tranche-wise Interest per bond"
      }
      const getUrl = `${url}?$sort[created_at]=-1${filterUrl}`
      try {
        setLoading(true)
        const res = await axiosInstance.get(getUrl)
        if (res) {
          const data = res.data?.data || res.data
          const csvTableHeaders = benPosColumns()
          const str = JSON.stringify(data)
          const arr = JSON.parse(str)
          const downladableArr = csvDownloadData(csvTableHeaders, arr)
          JSONToCSVConvertor(downladableArr, fileName, true)
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        toast.error(error?.message)
      }
    } else {
      downloadExcel()
    }
  }

  const downloadExcel = async () => {
    try {
      setLoading(true)
      const filterUrl = `${
        userFilter.length >= 3 ? `&name=${userFilter}` : ``
      }${investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""}${
        benPosFilter ? `&benpos_date=${benPosFilter}` : ""
      }${
        dateRange.startDate && dateRange.endDate
          ? `&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`
          : ""
      }`

      let downloadType = "benpos-report"
      if (reportType == "interest") {
        if (interestCalculationFilter.id === "tranche-wise") {
          downloadType = "benpos-interest-calculation-report"
        } else if (interestCalculationFilter.id === "trade-wise") {
          downloadType = "trade-wise-interest"
        } else if ((interestCalculationFilter.id = "investor-wise-interest")) {
          downloadType = "investor-wise-interest"
        }
      }

      const downloadUrl = `/download-excel?type=${downloadType}&$sort[created_at]=-1${filterUrl}`

      const response = await axiosInstance.get(downloadUrl, {
        responseType: "blob",
      })

      const contentDispositionHeader = response.headers["content-disposition"]
      let match
      if (contentDispositionHeader) {
        match = contentDispositionHeader.split("filename=")
      }

      const filename = match ? match[1] : "download.xlsx"
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const downloadLink = document.createElement("a")
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = filename
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const handleUplaodExcel = e => {
    setUpload(true)
    setSelectedProject(null)
    toggle()
  }
  const onSelectFile = e => {
    setFile(e.target.files[0])
  }

  useEffect(async () => {
    if (file) {
      setLoading(true)
      new Promise((resolve, reject) => {
        getBase64(file, data => resolve(data))
      }).then(result => {
        setBase64(result)
        setLoading(false)
      })
    }
  }, [file])

  const handleProjectName = inv => {
    const currentInv = investments.find(val => val.id == inv?.id)
    setSelectedProject(currentInv)
  }

  const handleCsvDownload = (json, projectName) => {
    JSONToCSVConvertor(json, projectName, true)
  }

  const sampleCsv = [
    {
      investor_name: "Test User",
      pan_number: "ABCDE0123F",
      demat_id: "1234567890123456",
      debentures: 10,
      bank_name: "HDFC",
      bank_account_number: "12345678901",
      bank_ifsc: "ABCD0012345",
    },
  ]

  const { SearchBar } = Search

  const toggleApproveModal = () => {
    setApproveModal(!approveModal)
    if (interestCalculationFilter?.id !== "tranche-wise") {
      GetTrancheWiseInterestPerUserData()
    } else {
      setApprovalModalData(trancheWiseSubTotal)
    }
  }
  const handleApprove = () => {
    setCalculatedInterestAmountsPerTranche({})
    setSelectedAction("APPROVE")
    setActionReason("")
    setConsent(false)
    toggleApproveModal()
  }
  const handleReject = () => {
    setCalculatedInterestAmountsPerTranche({})

    setSelectedAction("REJECT")
    setActionReason("")
    setConsent(false)
    toggleApproveModal()
  }

  const handleReportAction = async (e, values) => {
    try {
      setLoading(true)
      const payload = {
        modifiedById: localUser.id,
        investmentId: invIdFromUrl,
        benpos_date: benposDateFromUrl,
        status: selectedAction == "APPROVE" ? "APPROVED" : "REJECTED",
        report_type: "INTEREST_CALCULATION",
        comment: actionReason,
      }
      if (selectedAction == "APPROVE") {
        const jsonb_data = {
          calculation_file: "",
          calculated_values: [],
        }
        for (const id of Object.keys(calculatedInterestAmountsPerTranche)) {
          const item = calculatedInterestAmountsPerTranche[id]
          if (item.gross_interest) {
            jsonb_data.calculated_values.push({
              investmentLotId: id,
              gross_interest: Number(item.gross_interest),
            })
          }
        }

        if (approvalModalData.length != jsonb_data.calculated_values.length) {
          toast.error("All Fields are required")
          return
        }

        if (!calculationFile) {
          toast.error("Select your calculation file")
          return
        }
        const formData = new FormData()
        formData.append("file", calculationFile)
        formData.append("investmentId", invIdFromUrl)
        const response = await axiosInstance.post(
          `/file-upload/?type=interest_calculation`,
          formData
        )
        if (response) {
          jsonb_data.calculation_file = response.data[0].name
        }

        payload["data"] = jsonb_data
      }
      const res = await axiosInstance.post(`/interest-approval`, payload)
      if (res) {
        await getApprovalPolicy()
        toast.success(
          `${
            selectedAction == "APPROVE" ? "Approved" : "Rejected"
          } Successfully`
        )
        toggleApproveModal()
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const getApprovalPolicy = async () => {
    try {
      const response = await axiosInstance.get(
        `/interest-approval-policy?investmentId=${invIdFromUrl}&report_type=INTEREST_CALCULATION`
      )
      if (response) {
        const interestApprovalData = await axiosInstance.get(
          `/interest-approval?investmentId=${invIdFromUrl}&report_type=INTEREST_CALCULATION`
        )

        const approverData = response?.data?.data || response?.data

        const interestData = interestApprovalData?.data?.data || response?.data

        const combinedData = approverData.map(approver => {
          const matchingApproval = interestData?.find(
            approval =>
              approval.report_type === approver.report_type &&
              approval.modifiedById === approver.userId
          )

          return {
            ...approver,
            approvalStatus: matchingApproval?.status || "PENDING",
          }
        })
        setMyPolicy(combinedData.find(item => item.userId == localUser.id))
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    invIdFromUrl && benposDateFromUrl && getApprovalPolicy()
  }, [invIdFromUrl, benposDateFromUrl])

  const handleCalculatedFileUpload = e => {
    setCalculationFile(e.target.files[0])
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>

        <Container fluid>
          {reportType === "" ? (
            <Breadcrumbs
              breadcrumbItem={breadcrumbItem.name}
              breadcrumbRight={breadcrumbItem.route}
            />
          ) : (
            <>
              <HeaderWithBackBtn title={breadcrumbItem.name} />
              <hr />
            </>
          )}

          <Row>
            <Col xs="12">
              <Row className="mb-2 row justify-content-end">
                <div className="col-auto">
                  <div className="row">
                    {!reportType?.length ? (
                      <div className="col-auto pe-0">
                        {accessControlData?.permissions?.BENPOS_ADD_UPDATE ? (
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded mb-2 me-2"
                            onClick={handleUplaodExcel}
                          >
                            <i className="mdi mdi-arrow-up-bold-circle"></i>{" "}
                            {accessControlData?.permissions?.BENPOS_ADD_UPDATE}
                          </Button>
                        ) : null}
                        {accessControlData?.permissions?.BENPOS_SAMPLE_CSV ? (
                          <Button
                            type="button"
                            color="primary"
                            className="btn-rounded mb-2 me-2"
                            onClick={() =>
                              handleCsvDownload(
                                sampleCsv,
                                "Sample BenPos",
                                true
                              )
                            }
                          >
                            <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                            {accessControlData?.permissions?.BENPOS_SAMPLE_CSV}
                          </Button>
                        ) : null}
                        {reportType !== "interest" &&
                          accessControlData?.permissions?.BENPOS_DOWNLOAD && (
                            <Button
                              type="button"
                              color="primary"
                              className="btn-rounded mb-2"
                              onClick={() => downloadData("BenPos Master")}
                              disabled={
                                reportType === "interest" &&
                                (!dateRange?.startDate ||
                                  !dateRange?.endDate ||
                                  !interestCalculationFilter?.id)
                              }
                            >
                              <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                              {accessControlData?.permissions?.BENPOS_DOWNLOAD}
                            </Button>
                          )}
                      </div>
                    ) : null}
                    {(reportType === "validation" ||
                      reportType === "uploaded") &&
                      accessControlData?.permissions?.BENPOS_DOWNLOAD && (
                        <div className="col-auto me-2">
                          <AddRemarkBtn
                            benpos_date={benposDateFromUrl}
                            investmentId={invIdFromUrl}
                            report_type={
                              (reportType === "validation" &&
                                "VALIDATION_REPORT") ||
                              (reportType === "uploaded" &&
                                "UPLOADED_BENPOS_FILE")
                            }
                          />
                          <Button
                            type="button"
                            color="primary"
                            className="btn-rounded mb-2 ms-2"
                            onClick={() => downloadData("BenPos Master")}
                            disabled={
                              reportType === "interest" &&
                              (!dateRange?.startDate ||
                                !dateRange?.endDate ||
                                !interestCalculationFilter?.id)
                            }
                          >
                            <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                            {accessControlData?.permissions?.BENPOS_DOWNLOAD}
                          </Button>
                        </div>
                      )}
                  </div>
                </div>
              </Row>
              {reportType === "interest" && (
                <>
                  <BenposProjectCard
                    investmentId={invIdFromUrl}
                    benpos_date={benposDateFromUrl}
                    onLoadComplete={setSelectedProject}
                  />
                  {/* <PoliciesCard policies={policies} /> */}
                </>
              )}
              <Row className="mb-2 d-flex justify-content-end align-items-center">
                {reportType === "interest" && (
                  <div className="d-flex justify-content-end mb-1">
                    {myPolicy?.id ? (
                      <>
                        {myPolicy?.approvalStatus === "APPROVED" ? (
                          <div className="mb-2 me-2 border px-3 border-success rounded-pill d-flex justify-content-center align-items-center text-success">
                            Approved By You
                          </div>
                        ) : (
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded mb-2 me-2"
                            onClick={handleApprove}
                            disabled={orders.length == 0}
                          >
                            <i className="mdi mdi-check-circle"></i> Approve
                          </Button>
                        )}
                        {myPolicy?.approvalStatus === "REJECTED" ? (
                          <div className="mb-2 me-2 border px-3 border-danger rounded-pill d-flex justify-content-center align-items-center text-danger">
                            Rejected By You
                          </div>
                        ) : (
                          <Button
                            type="button"
                            color="danger"
                            className="btn-rounded mb-2 me-2"
                            onClick={handleReject}
                            disabled={orders.length == 0}
                          >
                            <i className="mdi mdi-close-circle"></i> Reject
                          </Button>
                        )}
                      </>
                    ) : null}
                    <div>
                      <AddRemarkBtn
                        benpos_date={benposDateFromUrl}
                        investmentId={invIdFromUrl}
                        report_type={"INTEREST_CALCULATION"}
                      />

                      {accessControlData?.permissions?.BENPOS_DOWNLOAD && (
                        <Button
                          type="button"
                          color="primary"
                          className="btn-rounded mb-2 mx-2"
                          onClick={() => downloadData("BenPos Master")}
                          disabled={
                            reportType === "interest" &&
                            (!dateRange?.startDate ||
                              !dateRange?.endDate ||
                              !interestCalculationFilter?.id)
                          }
                        >
                          <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                          {accessControlData?.permissions?.BENPOS_DOWNLOAD}
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </Row>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={benPosColumns(toggle)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        {reportType !== "interest" ? (
                          <>
                            {reportType !== "validation" ? (
                              <Row className="justify-content-between">
                                <Col md={6}>
                                  <Row>
                                    <Col md={6}>
                                      <div className="position-relative mb-3">
                                        <label>User</label>
                                        <input
                                          onChange={e =>
                                            handleFilterChange(e, "user")
                                          }
                                          id="search-bar-0"
                                          type="text"
                                          className="form-control rounded custom-input-height"
                                          placeholder={`Search by Name`}
                                          value={userFilter || ""}
                                        />
                                      </div>
                                    </Col>
                                    {!reportType ? (
                                      <Col md={6}>
                                        <div className="mb-3">
                                          <label>Project Name</label>
                                          <ReactSelect
                                            users={investments}
                                            setSelectedOption={e =>
                                              handleFilterChange(
                                                e,
                                                "projectType"
                                              )
                                            }
                                            multiOptionLabel={true}
                                            optionLabelKeys={["project_name"]}
                                            isClearable={true}
                                          />
                                        </div>
                                      </Col>
                                    ) : null}
                                  </Row>
                                </Col>
                                <Col
                                  md={2}
                                  className="row justify-content-center align-items-end pb-3"
                                >
                                  {reportType !== "interest" ||
                                  reportType !== "validation" ? (
                                    <select
                                      className="form-select w-75"
                                      value={limit}
                                      onChange={e =>
                                        handleFilterChange(e, "limit")
                                      }
                                    >
                                      {[10, 30, 50, 100].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                          Show {pageSize}
                                        </option>
                                      ))}
                                    </select>
                                  ) : null}
                                </Col>
                              </Row>
                            ) : (
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <Col xl="12">
                                <BootstrapTable
                                  keyField="id"
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  // selectRow={selectRow}
                                  columns={benPosColumns(toggle)}
                                  data={orders}
                                  wrapperClasses={"table-responsive mb-4"}
                                  pagination={
                                    reportType == "validation"
                                      ? paginationFactory(pageOptions)
                                      : null
                                  }
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <>
                            <Row
                              className={
                                dateRange.startDate &&
                                dateRange.endDate &&
                                showInterestTable
                                  ? ""
                                  : "mb-5"
                              }
                            >
                              <Row>
                                <Col md={3}>
                                  <div className="mb-4">
                                    <label>Report Type</label>
                                    <ReactSelect
                                      users={interestCalculationReports}
                                      setSelectedOption={e =>
                                        handleFilterChange(e, "interestType")
                                      }
                                      selectedOption={
                                        interestCalculationFilter || ""
                                      }
                                      multiOptionLabel={true}
                                      optionLabelKeys={["name"]}
                                    />
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-4">
                                    <Label>Start Date</Label>
                                    <Input
                                      name="date"
                                      type="date"
                                      value={dateRange.startDate}
                                      onChange={e => {
                                        setDateRange({
                                          ...dateRange,
                                          startDate: e.target.value,
                                        })
                                      }}
                                      className="custom-input-height"
                                    />
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-4">
                                    <Label>End Date</Label>
                                    <Input
                                      name="date"
                                      type="date"
                                      value={dateRange.endDate}
                                      onChange={e => {
                                        setDateRange({
                                          ...dateRange,
                                          endDate: e.target.value,
                                        })
                                      }}
                                      className="custom-input-height"
                                    />
                                  </div>
                                </Col>
                                <Col md={3} className="align-self-center">
                                  <div>
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => {
                                        setShowInterestTable(true)
                                        masterData()
                                      }}
                                      disabled={
                                        !interestCalculationFilter?.id ||
                                        !dateRange?.startDate ||
                                        !dateRange?.endDate
                                      }
                                    >
                                      Calculate
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Row>
                            {dateRange.startDate &&
                            dateRange.endDate &&
                            showInterestTable ? (
                              <>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <BootstrapTable
                                      keyField="id"
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      // selectRow={selectRow}
                                      columns={benPosColumns(toggle)}
                                      pagination={
                                        reportType == "interest"
                                          ? paginationFactory(pageOptions)
                                          : null
                                      }
                                      data={orders}
                                      wrapperClasses={"table-responsive mb-4"}
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"table-light"}
                                      {...toolkitProps.baseProps}
                                    />
                                  </Col>
                                </Row>
                              </>
                            ) : null}
                          </>
                        )}

                        {reportType !== "interest" &&
                        reportType !== "validation" ? (
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}`}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ) : null}
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Add/Update BenPos
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleValidOrderSubmit}>
                <Row form>
                  <Col className="col-12">
                    <div className="mb-3">
                      <label>Project Name</label>
                      <ReactSelect
                        users={investments}
                        setSelectedOption={handleProjectName}
                        multiOptionLabel={true}
                        optionLabelKeys={["project_name", "location"]}
                        selectedOption={selectedProject}
                      />
                    </div>
                  </Col>
                  <Col className="col-12">
                    <div className="mb-3">
                      <AvField
                        name="benpos_date"
                        label="BenPos Date"
                        type="date"
                        errorMessage="Invalid Value"
                        onChange={e => setBenPosDate(e.target.value)}
                        validate={{
                          required: { value: true },
                        }}
                        value={benPosDate}
                      ></AvField>
                    </div>
                  </Col>
                  <Col className="col-12">
                    <div className="mt-2 mb-3">
                      <Label htmlFor="formFile" className="form-label">
                        Upload BenPos
                      </Label>
                      <Input
                        className="form-control"
                        type="file"
                        accept=".csv"
                        id="formFile"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={onSelectFile}
                      />
                    </div>
                  </Col>
                  <Col className="col-12">
                    <div className="mb-3">
                      <AvField
                        name="source_details"
                        label="Source Details"
                        type="textarea"
                        errorMessage="Invalid Value"
                        onChange={e => setSourceDetails(e.target.value)}
                        validate={{
                          required: { value: true },
                        }}
                        value={sourceDetails}
                        placeholder="Ex: Received from RTA on Email..."
                      ></AvField>
                    </div>
                  </Col>
                  <Col className="col-12">
                    <div className="mb-3">
                      <AvField
                        name="received_on"
                        label="Received On"
                        type="datetime-local"
                        errorMessage="Invalid Value"
                        onChange={e => setReceivedOnDate(e.target.value)}
                        validate={{
                          required: { value: true },
                        }}
                        value={receivedOnDate}
                      ></AvField>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
          {/* Approve Modal */}
          <Modal
            isOpen={approveModal}
            toggle={toggleApproveModal}
            size={selectedAction == "APPROVE" ? "lg" : "md"}
          >
            <ModalHeader toggle={toggleApproveModal} tag="h4">
              {selectedAction == "APPROVE" ? "Approve" : "Reject"} Tranche Wise
              Interest (per user)
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={handleReportAction}>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <div>Project Name</div>
                      <p
                        className="fw-medium font-size-15"
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        {selectedProject?.project_name || "NA"}
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <div>Benpos Date</div>
                      <p
                        className="fw-medium font-size-15"
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        {handleValidDate(benPosDate) || "NA"}
                      </p>
                    </div>
                  </Col>
                  {selectedAction == "APPROVE" ? (
                    <div className="">
                      <div className="mb-3">Tranche Wise Summary</div>
                      <BootstrapTable
                        keyField="id"
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        columns={[
                          {
                            text: "Tranche",
                            dataField: "investment_lot_name",
                          },
                          {
                            text: "Debentures",
                            dataField: "lot_size",
                            // eslint-disable-next-line react/display-name
                            formatter: (cellContent, row) => {
                              return handleAmount(cellContent)
                            },
                          },
                          {
                            dataField: "gross_interest",
                            text: "Gross Interest Due",
                            // eslint-disable-next-line react/display-name
                            formatter: (cellContent, row) => {
                              return handleAmount(cellContent)
                            },
                          },
                          {
                            dataField: "",
                            text: "Your Gross Interest Amount",
                            // eslint-disable-next-line react/display-name
                            formatter: (cellContent, row) => {
                              return (
                                <AvField
                                  name={"tranche_" + row.investmentLotId}
                                  type="number"
                                  value={
                                    calculatedInterestAmountsPerTranche[
                                      row.investmentLotId
                                    ]?.gross_interest
                                  }
                                  onChange={e => {
                                    setCalculatedInterestAmountsPerTranche(
                                      prev => ({
                                        ...prev,
                                        [row.investmentLotId]: {
                                          ...prev[row.investmentLotId],
                                          gross_interest: e.target.value,
                                        },
                                      })
                                    )
                                  }}
                                ></AvField>
                              )
                            },
                          },
                          {
                            text: "TDS",
                            dataField: "tds",
                            // eslint-disable-next-line react/display-name
                            formatter: (cellContent, row) => {
                              return handleAmount(cellContent)
                            },
                          },
                          {
                            text: "Net Interest",
                            dataField: "net_interest",
                            // eslint-disable-next-line react/display-name
                            formatter: (cellContent, row) => {
                              return handleAmount(cellContent)
                            },
                          },
                        ]}
                        data={approvalModalData}
                        wrapperClasses={"table-responsive mb-2"}
                        classes={"table align-middle table-nowrap table-check"}
                        headerWrapperClasses={"table-light"}
                        bootstrap4
                        search
                      />
                      <div>
                        <Label htmlFor="formFile" className="form-label">
                          Upload Your Calculation File
                        </Label>
                        <Input
                          className="form-control"
                          type="file"
                          id="formFile"
                          // accept=".pdf"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={handleCalculatedFileUpload}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="mt-3">
                    <AvField
                      name="description"
                      label={selectedAction == "APPROVE" ? "Comment" : "Reason"}
                      type="textarea"
                      errorMessage="Invalid value"
                      validate={{
                        required: { value: true },
                      }}
                      value={actionReason || ""}
                      onChange={e => setActionReason(e.target.value)}
                      rows={3}
                    ></AvField>
                  </div>
                  <div className="mt-4">
                    <AvField
                      name={"consent"}
                      label={
                        <span className={consent ? "" : "text-muted"}>
                          I have checked the interest calculation.
                        </span>
                      }
                      type="checkbox"
                      checked={consent}
                      value={consent}
                      onChange={e => setConsent(e.target.checked)}
                    ></AvField>
                  </div>
                  <Col>
                    <div className="text-end gap-4 mt-2">
                      <button
                        type="submit"
                        className={`btn w-100 ${
                          selectedAction == "APPROVE"
                            ? "btn-success"
                            : "btn-danger"
                        } save-user`}
                        disabled={
                          selectedAction == "APPROVE" ? !consent : !consent
                        }
                      >
                        {selectedAction == "APPROVE" ? "Approve" : "Reject"}
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BenPosMaster
