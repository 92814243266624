import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { SidebarOptions } from "components/VerticalLayout/SidebarOptions"
const options = [
  {
    id: 1,
    name: "Investment Summary",
    links: [
      {
        id: 1,
        name: "Completed Investments",
        route: "/reports/completed-investments",
      },
      {
        id: 2,
        name: "Investor Count Summary",
        route: "/reports/investor-count-summary",
      },
    ],
  },
  {
    id: 2,
    name: "Transactions",
    links: [
      {
        id: 1,
        name: "Investment Transactions",
        route: "/reports/investment-transactions",
      },
      {
        id: 2,
        name: "Interest & Repayments",
        route: "/reports/interest-and-repayments",
      },
      {
        id: 3,
        name: "Payout Summary",
        route: "/reports/payout-summary",
      },
    ],
  },
  {
    id: 3,
    name: "Documents",
    links: [
      {
        id: 1,
        name: "Deal Updates",
        route: "/reports/documents/deal-updates",
      },
    ],
  },
]

const Reports = () => {
  const [allowedOptions, setAllowedOptions] = useState(options)

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    const section = SidebarOptions.find(item => item.key == "REPORTS")

    if (userInfo.permissions === "Admin") {
      setAllowedOptions(options)
    } else {
      const permissions = userInfo.role?.permissions

      const permissionUniquePageIds = new Set(permissions.map(p => p.pageId))
      const allowedPages = section.pages.filter(page =>
        permissionUniquePageIds.has(page.id)
      )
      const allowedUrls = new Set(allowedPages.map(page => page.path))
      const filteredOptions = []
      for (const option of options) {
        const allowedLinks = option.links.filter(link =>
          allowedUrls.has(link.route)
        )
        if (allowedLinks.length) {
          filteredOptions.push({
            ...option,
            links: allowedLinks,
          })
        }
      }

      setAllowedOptions(filteredOptions)
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Reports" />
          <Row>
            {allowedOptions.map(option => (
              <>
                {option.links.length ? (
                  <Col key={option.id} xl={3} md={6}>
                    <div className="card jobs-categories h-100">
                      <div className="card-body">
                        <h4 className="card-title mb-3">{option.name}</h4>
                        {option.links.map(item => (
                          <Link
                            key={item.id}
                            to={item.route}
                            className="px-2 py-2 rounded d-block"
                          >
                            <i className="mdi mdi-chevron-right"></i>
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </Col>
                ) : null}
              </>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Reports)
