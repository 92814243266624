import { SidebarOptions } from "components/VerticalLayout/SidebarOptions"

export const permissionChecker = path => {
  let currentPath = path ? path : window.location.pathname

  const currentPage = SidebarOptions.find(item =>
    item.pages.some(pageItem => pageItem.path === currentPath)
  )?.pages.find(pageItem => pageItem.path === currentPath)

  if (!currentPage) {
    return {
      permissions: {},
      pageName: "",
    }
  }

  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  if (userInfo.permissions === "Admin") {
    const permissions = {}

    currentPage.actions.forEach(action => {
      permissions[action.key] = action.displayName
    })
    return {
      permissions,
      pageName: currentPage.displayName,
    }
  }

  const allowedActionForCurrentPage = new Set(
    userInfo?.role?.permissions
      .filter(item => item.pageId == currentPage.id)
      .map(item => item.actionId)
  )

  const permissions = {}

  currentPage.actions.forEach(action => {
    if (allowedActionForCurrentPage.has(action.id)) {
      permissions[action.key] = action.displayName
    }
  })

  return {
    permissions,
    pageName: currentPage.displayName,
  }
}
