import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { SidebarOptions } from "./SidebarOptions"
import { permissionChecker } from "common/PermissionChecker"

const SidebarContent = props => {
  const ref = useRef()
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i]?.pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem?.pathname !== "/") {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item?.classList.add("active")
    const parent = item?.parentElement
    const parent2El = parent?.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  const filterSidebarOptions = () => {
    const SidebarOptionsCopy = JSON.parse(JSON.stringify(SidebarOptions))
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    if (userInfo.permissions === "Admin") {
      return SidebarOptionsCopy.filter(
        section =>
          section.id != "DISTRIBUTOR_PORTAL" && section.id != "SUPPORT_PORTAL"
      )
    }
    const permissions = userInfo.role?.permissions
    if (!permissions) {
      window.location.href = "/logout"
    }
    const permissionUniquePageIds = new Set(permissions.map(p => p.pageId))

    const filteredOptions = []
    for (const section of SidebarOptionsCopy) {
      const pages = section.pages.filter(
        page =>
          permissionUniquePageIds.has(page.id) && page?.isVisible !== false
      )

      if (pages.length > 0) {
        section["pages"] = pages
        filteredOptions.push(section)
      }
    }
    return filteredOptions
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")}</li>
            {filterSidebarOptions().map(option => (
              <>
                {option.expandable ? (
                  <li key={option.key}>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-list-ul"></i>
                      <span>{props.t(option.displayName)}</span>
                    </Link>
                    {option.pages && (
                      <ul className="sub-menu" aria-expanded="false">
                        {option.pages.map(subPage => {
                          if (subPage?.isVisible !== false) {
                            return (
                              <li key={subPage.key}>
                                <Link to={subPage.path}>
                                  {props.t(subPage.displayName)}
                                </Link>
                              </li>
                            )
                          }
                        })}
                      </ul>
                    )}
                  </li>
                ) : (
                  <React.Fragment key={option.key}>
                    {option.path ? (
                      <li>
                        <Link to={option.path}>
                          <i className="bx bx-list-ul"></i>
                          {props.t(option.displayName)}
                        </Link>
                      </li>
                    ) : (
                      <>
                        {option.pages.map(subPage => (
                          <li key={subPage.key}>
                            <Link to={subPage.path}>
                              <i className="bx bx-list-ul"></i>
                              {props.t(subPage.displayName)}
                            </Link>
                          </li>
                        ))}
                      </>
                    )}
                  </React.Fragment>
                )}
              </>
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
