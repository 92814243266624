import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import { csvDownloadData } from "constants/common"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const VpaReports = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [userFilter, setUserFilter] = useState("")
  const [statusFilter, setStatusFilter] = useState("")
  const [total, setTotal] = useState(0)
  const [lastUpdated, setLastUpdated] = useState(null)
  const [modal, setModal] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    statusFilter ? `&status=${statusFilter}` : ``
  }`
  const getUrl = `vpa-reports?$limit=${limit}&$skip=${skip}&$sort[updated_at]=-1${filterUrl}`

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(getUrl)

      if (response) {
        setTotal(response.data.total)
        setData(response.data?.data)
        if (response.data.data.length) {
          setLastUpdated(handleValidDate(response.data.data[0].updated_at))
        }
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const VpaReportColumns = () => [
    {
      dataField: "user_name",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "customer_id",
      text: "VPA User Id",
      sort: true,
    },
    {
      dataField: "va_id",
      text: "VPA Account Id",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "receiver_name",
      text: "VPA Name",
      sort: true,
    },
    {
      dataField: "receiver_account_number",
      text: "VPA Account No.",
      sort: true,
    },
    {
      dataField: "receiver_ifsc",
      text: "VPA IFSC",
      sort: true,
    },
    {
      dataField: "amount_paid",
      text: "Amount Paid",
      sort: true,
      formatter: (cellContent, row) => handleAmount(row.amount_paid),
    },
    {
      dataField: "amount_expected",
      text: "Amount Expected",
      sort: true,
      formatter: (cellContent, row) =>
        row.amount_expected ? handleAmount(row.amount_expected) : null,
    },
    {
      dataField: "created_at",
      text: "Created At",
      sort: true,
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
    {
      dataField: "closed_by",
      text: "Closed by",
      sort: true,
    },
    {
      dataField: "closed_at",
      text: "Closed At",
      sort: true,
      formatter: (cellContent, row) =>
        row.closed_at ? handleValidDate(row.closed_at) : null,
    },
  ]
  const handleFilterChange = (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "status":
        setStatusFilter(e?.id || "")
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const toggle = () => {
    setModal(!modal)
  }

  useEffect(() => {
    if (!modal) {
      setIsConfirm(false)
    }
  }, [modal])

  const vpaStatusFilter = [
    { statusText: "Active", id: "active" },
    { statusText: "Closed", id: "closed" },
  ]

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(async () => {
    masterData()
  }, [limit, skip, statusFilter])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const downloadData = async fileName => {
    try {
      setLoading(true)
      const url = `vpa-reports?$sort[created_at]=-1${filterUrl}`
      const res = await axiosInstance.get(url)
      if (res) {
        const data = res.data?.data
        const csvTableHeaders = VpaReportColumns()
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        arr.forEach(item => {
          item["amount_paid"] = handleAmount(item.amount_paid)
          item["created_at"] = handleValidDate(item.created_at)
          if (item.amount_expected) {
            item["amount_expected"] = handleAmount(item.amount_expected)
          }
          if (item.closed_at) {
            item["closed_at"] = handleValidDate(item.closed_at)
          }
        })
        const downloadableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downloadableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    const amountInRupees = amount / 100
    return dollarIndianLocale.format(amountInRupees)
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM YYYY hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const refreshReport = async () => {
    setIsConfirm(true)
    try {
      const res = await axiosInstance.post("vpa-reports", {})
      if (res) {
        setSkip(0)
        setCurrentPage(1)
        setLimit(10)
        toast.success(res.data.message)
        masterData()
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setModal(false)
    }
  }
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.VPA_REPORTS_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between">
                  <Col md={2}>
                    <select
                      className="form-select w-75"
                      value={limit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <div className="col-auto d-flex align-items-center">
                    <div className="col-auto mx-2">
                      <span style={{ fontWeight: "500" }}>Last Updated:</span>{" "}
                      {lastUpdated || "NA"}
                    </div>
                    <div className="col-auto">
                      {accessControlData?.permissions?.VPA_REPORTS_REFRESH && (
                        <Button
                          type="button"
                          color="primary"
                          className="btn-rounded  mb-2 me-2"
                          onClick={toggle}
                          style={{ marginRight: "3px" }}
                        >
                          <i className="mdi mdi-refresh"></i>{" "}
                          {accessControlData?.permissions?.VPA_REPORTS_REFRESH}
                        </Button>
                      )}
                      {accessControlData?.permissions?.VPA_REPORTS_DOWNLOAD && (
                        <Button
                          type="button"
                          color="primary"
                          className="btn-rounded  mb-2 me-2"
                          onClick={() => downloadData("VPA Report")}
                          style={{ marginRight: "3px" }}
                        >
                          <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                          {accessControlData?.permissions?.VPA_REPORTS_DOWNLOAD}
                        </Button>
                      )}
                    </div>
                  </div>
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={data}
                      columns={VpaReportColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row justify-content-start">
                            <Col md={3}>
                              <div className="position-relative">
                                <label>User</label>
                                <input
                                  onChange={e => handleFilterChange(e, "user")}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control rounded custom-input-height"
                                  placeholder={`Search by User Name, Email or Phone `}
                                  value={userFilter || ""}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>VPA Status</label>
                                <ReactSelect
                                  users={vpaStatusFilter}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "status")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["statusText"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                columns={VpaReportColumns}
                                data={data}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                            </Col>
                            <Modal
                              isOpen={modal}
                              toggle={toggle}
                              backdrop="static"
                            >
                              <ModalHeader toggle={toggle} tag="h4">
                                {!isConfirm
                                  ? "Update VPA Report"
                                  : "Updating..."}
                              </ModalHeader>
                              <ModalBody>
                                {!isConfirm ? (
                                  <Row>
                                    <Col>
                                      <div className="p-2">
                                        <h5>
                                          Are you sure you want Generate a new
                                          VPA report?
                                        </h5>
                                      </div>
                                    </Col>
                                  </Row>
                                ) : (
                                  <div>
                                    <p>
                                      The report is currently in the process of
                                      being prepared. The data will be updated
                                      once it is ready.
                                    </p>
                                    <div className="w-100 d-flex justify-content-end">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => setModal(false)}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </ModalBody>
                              {!isConfirm ? (
                                <ModalFooter>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="button"
                                          className="btn btn-danger me-2"
                                          onClick={() => setModal(false)}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-success save-user"
                                          onClick={refreshReport}
                                        >
                                          Confirm
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </ModalFooter>
                              ) : (
                                <></>
                              )}
                            </Modal>
                          </Row>
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}`}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

VpaReports.propTypes = {}

export default withRouter(VpaReports)
