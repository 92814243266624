import { picUrl } from "constants/common"
import moment from "moment"
import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Row } from "reactstrap"

const ProjectDetailCard = ({
  imageUrl,
  projectName,
  issuerName,
  cityLabel,
  city,
  isinLabel,
  isinLinkText,
  isin,
  faceValueLabel,
  faceValue,
  bondsLabel,
  bonds,
  dateLabel,
  date,
  issueSizeLabel,
  issueSize,
  annualReturnLabel,
  annualReturn,
  distributorNameLabel,
  distributorName,
  exInterestDateLabel,
  exInterestDate,
  interestPayoutDateLabel,
  interestPayoutDate,
  recordDateLabel,
  recordDate,
  isinBelow = false,
}) => {
  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM YYYY")
    return date1
  }

  return (
    <Card style={{ borderRadius: "0.5rem", overflow: "hidden" }}>
      <CardBody className="p-0 h-100">
        <Row className="align-items-center h-100 ms-0">
          {imageUrl ? (
            <Col lg={3} className="ps-0">
              <div className="d-flex">
                <div className="flex-grow-1 align-self-center">
                  <img
                    style={{
                      width: "100%",
                    }}
                    src={picUrl + imageUrl}
                    alt="display_pic_url"
                  />
                </div>
              </div>
            </Col>
          ) : null}
          <Col lg={3} className="d-flex">
            <div className="d-flex">
              <div className="flex-grow-1 align-self-center">
                <div className="text-muted">
                  <h2 className="mb-1">{projectName || "NA"}</h2>
                  <p className="mb-1">{issuerName || "NA"}</p>
                  {isinBelow && (
                    <a
                      target="_blank"
                      href={`https://nsdl.co.in/master_search_detail_res.php?isin=${isin}`}
                      rel="noreferrer"
                    >
                      {isin}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col style={{ height: "8.5rem" }}>
            <Row className="h-100 align-items-center">
              {cityLabel && city ? (
                <Col lg={4}>
                  <div>
                    <p className="text-muted text-truncate mb-2">{cityLabel}</p>
                    <h5 className="mb-0">{city || "NA"}</h5>
                  </div>
                </Col>
              ) : null}
              {!isinBelow && isinLabel && isinLinkText && isin ? (
                <Col lg={4}>
                  <div>
                    <p className="text-muted text-truncate mb-2">
                      {isinLabel}
                      <a
                        target="_blank"
                        href={`https://nsdl.co.in/master_search_detail_res.php?isin=${isin}`}
                        rel="noreferrer"
                      >
                        {isinLinkText}
                      </a>
                    </p>
                    <h5 className="mb-0">{isin || "0"}</h5>
                  </div>
                </Col>
              ) : null}
              {faceValueLabel && faceValue ? (
                <Col lg={4}>
                  <div>
                    <p className="text-muted text-truncate mb-2">
                      {faceValueLabel}
                    </p>
                    <h5 className="mb-0">
                      ₹ {faceValue?.toLocaleString("en-IN")}
                    </h5>
                  </div>
                </Col>
              ) : null}
              {bondsLabel && bonds ? (
                <Col lg={4}>
                  <div>
                    <p className="text-muted text-truncate mb-2">
                      {bondsLabel}
                    </p>
                    <h5 className="mb-0">{bonds || "0"}</h5>
                  </div>
                </Col>
              ) : null}
              {dateLabel && date ? (
                <Col lg={4}>
                  <div>
                    <p className="text-muted text-truncate mb-2">{dateLabel}</p>
                    <h5 className="mb-0">{handleValidDate(date) || "NA"}</h5>
                  </div>
                </Col>
              ) : null}
              {annualReturnLabel && annualReturn ? (
                <Col lg={4}>
                  <div>
                    <p className="text-muted text-truncate mb-2">
                      {annualReturnLabel}
                    </p>
                    <h5 className="mb-0">{annualReturn} %</h5>
                  </div>
                </Col>
              ) : null}
              {issueSizeLabel && issueSize ? (
                <Col lg={4}>
                  <div>
                    <p className="text-muted text-truncate mb-2">
                      {issueSizeLabel}
                    </p>
                    <h5 className="mb-0">
                      ₹ {issueSize?.toLocaleString("en-IN")}
                    </h5>
                  </div>
                </Col>
              ) : null}
              {exInterestDateLabel && exInterestDate ? (
                <Col lg={4}>
                  <div>
                    <p className="text-muted text-truncate mb-2">
                      {exInterestDateLabel}
                    </p>
                    <h5 className="mb-0">
                      {handleValidDate(exInterestDate) || "NA"}
                    </h5>
                  </div>
                </Col>
              ) : null}
              {recordDateLabel ? (
                <Col lg={4}>
                  <div>
                    <p className="text-muted text-truncate mb-2">
                      {recordDateLabel}
                    </p>
                    <h5 className="mb-0">
                      {recordDate ? handleValidDate(recordDate) : "NA"}
                    </h5>
                  </div>
                </Col>
              ) : null}
              {interestPayoutDateLabel && interestPayoutDate ? (
                <Col lg={4}>
                  <div>
                    <p className="text-muted text-truncate mb-2">
                      {interestPayoutDateLabel}
                    </p>
                    <h5 className="mb-0">
                      {handleValidDate(interestPayoutDate) || "NA"}
                    </h5>
                  </div>
                </Col>
              ) : null}
              {distributorNameLabel && distributorName ? (
                <Col lg={4}>
                  <div>
                    <p className="text-muted text-truncate mb-2">
                      {distributorNameLabel}
                    </p>
                    <h5 className="mb-0">{distributorName}</h5>
                  </div>
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default ProjectDetailCard

ProjectDetailCard.propTypes = {
  imageUrl: PropTypes.string,
  projectName: PropTypes.string.isRequired,
  issuerName: PropTypes.string,
  cityLabel: PropTypes.string,
  city: PropTypes.string,
  isinLabel: PropTypes.string,
  isinLinkText: PropTypes.string,
  isin: PropTypes.string,
  faceValueLabel: PropTypes.string,
  faceValue: PropTypes.number,
  bondsLabel: PropTypes.string,
  bonds: PropTypes.number,
  dateLabel: PropTypes.string,
  date: PropTypes.date,
  issueSizeLabel: PropTypes.string,
  issueSize: PropTypes.number,
  annualReturnLabel: PropTypes.string,
  annualReturn: PropTypes.number,
  distributorNameLabel: PropTypes.string,
  distributorName: PropTypes.string,
  exInterestDateLabel: PropTypes.string,
  exInterestDate: PropTypes.date,
  interestPayoutDateLabel: PropTypes.string,
  interestPayoutDate: PropTypes.date,
  recordDateLabel: PropTypes.string,
  recordDate: PropTypes.date,
  isinBelow: PropTypes.bool,
}
