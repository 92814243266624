import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { axiosInstance } from "ConfigAxioxinstance"
//redux
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import { toast } from "react-toastify"
import { logoutUser } from "../../store/actions"
import { useDispatch } from "react-redux"
import { humanize } from "constants/common"

const UserProfile = props => {
  //View Detail states
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [roleName, setRoleName] = useState("")
  const [department, setDepartment] = useState("")
  const [selfRefCode, setSelfRefCode] = useState("")
  const [relManager, setRelManager] = useState({})
  const [waTnc, setWaTnc] = useState(false)
  //Change password form state
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const dispatch = useDispatch()

  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  useEffect(() => {
    setName(localUser.user_name)
    setEmail(localUser.email)
    setPhone(localUser.phone)
    setRoleName(localUser.role?.role_name)
    setDepartment(localUser.role?.department)
    setSelfRefCode(localUser.self_ref_code)
    setRelManager(localUser.relManager)
    setWaTnc(localUser.whtsup_tnc)
  }, [])

  // useEffect(() => {
  //   dispatch(logoutUser(props.history))
  // }, [dispatch])

  const handleValidSubmit = async (event, values) => {
    if (password !== confirmPassword) {
      toast.error("Password do not match!!")
      return
    }
    try {
      const res = await axiosInstance.post(`change-password`, {
        userId: localUser.id,
        password: password,
      })
      if (res) {
        toast.success("Successfully Updated")
        setPassword("")
        setConfirmPassword("")
        dispatch(logoutUser(props.history))
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  const handleOnChange = e => {
    const { name, value } = e.target
    switch (name) {
      case "password":
        setPassword(value)
        break
      case "confirmPassword":
        setConfirmPassword(value)
        break
      default:
        break
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb breadcrumbItem="Profile" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1 align-self-center">
                      <div>
                        <div style={{ fontSize: "14px", fontWeight: 600 }}>
                          User Name:
                        </div>
                        <p className="mb-1" style={{ fontSize: "16px" }}>
                          {name || "NA"}
                        </p>
                      </div>
                      <div>
                        <div
                          className="mt-4"
                          style={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          Email:
                        </div>
                        <p className="mb-1" style={{ fontSize: "16px" }}>
                          {email || "NA"}
                        </p>
                      </div>
                      <div>
                        <div
                          className="mt-4"
                          style={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          Phone:
                        </div>
                        <p className="mb-1" style={{ fontSize: "16px" }}>
                          {phone || "NA"}
                        </p>
                      </div>
                      <div>
                        <div
                          className="mt-4"
                          style={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          Self Referral Code:
                        </div>
                        <p className="mb-1" style={{ fontSize: "16px" }}>
                          {selfRefCode || "NA"}
                        </p>
                      </div>
                      <div>
                        <div
                          className="mt-4"
                          style={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          WhatsApp Notification:
                        </div>
                        <p className="mb-1" style={{ fontSize: "16px" }}>
                          {waTnc ? "True" : "False"}
                        </p>
                      </div>
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div>
                        <div style={{ fontSize: "14px", fontWeight: 600 }}>
                          Role Name:
                        </div>
                        <p className="mb-1" style={{ fontSize: "16px" }}>
                          {roleName || "NA"}
                        </p>
                      </div>
                      <div>
                        <div
                          className="mt-4"
                          style={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          Department:
                        </div>
                        <p className="mb-1" style={{ fontSize: "16px" }}>
                          {humanize(department) || "NA"}
                        </p>
                      </div>
                      <div>
                        <div
                          className="mt-4"
                          style={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          Relationship Manager Name:
                        </div>
                        <p className="mb-1" style={{ fontSize: "16px" }}>
                          {relManager?.user_name || "NA"}
                        </p>
                      </div>
                      <div>
                        <div
                          className="mt-4"
                          style={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          Relationship Manager Email:
                        </div>
                        <p className="mb-1" style={{ fontSize: "16px" }}>
                          {relManager?.email || "NA"}
                        </p>
                      </div>
                      <div>
                        <div
                          className="mt-4"
                          style={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          Relationship Manager Phone:
                        </div>
                        <p className="mb-1" style={{ fontSize: "16px" }}>
                          {relManager?.phone || "NA"}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change Password</h4>

          <Card>
            <CardBody>
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                <div className="form-group col-md-4">
                  <div className="mb-3">
                    <AvField
                      name="password"
                      label="Password*"
                      value={password}
                      className="form-control"
                      errorMessage="Enter Valid Input"
                      placeholder="Enter New Password"
                      type="password"
                      autoComplete="off"
                      onChange={handleOnChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="confirmPassword"
                      label="Confirm Password*"
                      value={confirmPassword}
                      className="form-control"
                      errorMessage="Enter Valid Input"
                      placeholder="Re-enter New Password"
                      type="text"
                      autoComplete="off"
                      onChange={handleOnChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <p>*Password should be 8-16 characters long.</p>
                  </div>
                </div>
                <Button type="submit" color="success">
                  Change Password
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserProfile.propTypes = {
  history: PropTypes.object,
}

export default withRouter(UserProfile)
