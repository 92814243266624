export const StatusForUserInvestment = [
  "Initiated",
  "Commitment",
  "Awaiting_Token_Manual_Transfer",
  "Token_Paid",
  "EOI_Signed",
  "Awaiting_Manual_Transfer",
  "Partially_Paid",
  "Declined",
  // "Completed",
  "Initiate_Balance_Payment",
]

export const StatusObjForUserInvestment = [
  {
    statusText: "Initiated",
    id: "Initiated",
    colorClassName: "secondary",
  },
  {
    statusText: "Commitment",
    id: "Commitment",
    colorClassName: "secondary",
  },
  {
    statusText: "Awaiting Token Manual Transfer",
    id: "Awaiting_Token_Manual_Transfer",
    colorClassName: "warning",
  },
  {
    statusText: "Token Paid",
    id: "Token_Paid",
    colorClassName: "success",
  },
  {
    statusText: "EOI Signed",
    id: "EOI_Signed",
    colorClassName: "secondary",
  },
  {
    statusText: "Awaiting Manual Transfer",
    id: "Awaiting_Manual_Transfer",
    colorClassName: "warning",
  },
  {
    statusText: "Partially Paid",
    id: "Partially_Paid",
    colorClassName: "success",
  },
  {
    statusText: "Declined",
    id: "Declined",
    colorClassName: "danger",
  },
  {
    statusText: "Completed",
    id: "Completed",
    colorClassName: "success",
  },
  {
    statusText: "Initiate Balance Payment",
    id: "Initiate_Balance_Payment",
    colorClassName: "secondary",
  },
]

export const StatusObjForDistributorReport = [
  {
    statusText: "Upfront Fee",
    id: "upfront",
  },
  {
    statusText: "Trail Fee",
    id: "trail",
  },
]

export const benPosReportTypes = [
  {
    name: "Benpos Master",
    type: "",
    route: "Benpos Master",
    endpoint: "benpos-master",
  },
  {
    name: "Validation Report",
    type: "validation",
    route: "Benpos Master / Validation Report",
    endpoint: "benpos-report",
  },
  {
    name: "Interest Calculation",
    type: "interest",
    route: "Benpos Master / Interest Calculation",
    endpoint: "benpos-interest-calculation-report",
  },
  {
    name: "Uploaded Benpos",
    type: "uploaded",
    route: "Benpos Master / Uploaded Benpos",
    endpoint: "benpos",
  },
]

export const interestCalculationReports = [
  {
    id: "tranche-wise-per-bond",
    name: "Tranche-wise Interest (per bond)",
    endpoint: "tranche-wise-per-bond",
  },
  {
    id: "trade-wise",
    name: "Trade-wise Interest (per topup)",
    endpoint: "trade-wise",
  },
  {
    id: "tranche-wise",
    name: "Tranche-wise Interest (per user)",
    endpoint: "",
  },
  {
    id: "investor-wise",
    name: "Investor-wise Interest",
    endpoint: "investor-wise",
  },
]

export const exceptionReportFilters = [
  {
    id: "common-benpos-bank-details",
    name: "Bank Details Mismatch (COMMON & BENPOS)",
    endpoint:
      "exception?category=COMMON&category=BENPOS&bank_details_exception=true",
  },
  // {
  //   id: "common-missing-benpos-ifsc",
  //   name: "COMMON and BENPOS: Missing BenPos IFSC",
  //   endpoint: "exception?category=COMMON&category=BENPOS&ifsc=null",
  // },
  // {
  //   id: "common-bank-account-same-ifsc-mismatch",
  //   name: "COMMON: Bank Account is Same but IFSC Mismatch",
  //   endpoint:
  //     "exception?category=COMMON&ifsc_matched=false&bank_account_matched=true",
  // },
  {
    id: "common-debenture-mismatch",
    name: "Debenture Mismatch (COMMON)",
    endpoint: "exception?category=COMMON&debenture_matched=false",
  },
  // {
  //   id: "common-debenture-over-25-ifsc-bank-mismatch",
  //   name: "COMMON: Debentures > 25 with IFSC/Bank Account Mismatch",
  //   endpoint:
  //     "exception?category=COMMON&debenture[$gt]=25&ifsc_matched=false&bank_account_matched=false",
  // },
  {
    id: "common-nil-tds",
    name: "NIL TDS Applicable (COMMON)",
    endpoint: "exception?category=COMMON&nil_tds=true",
  },
  {
    id: "system-entries",
    name: "All Entries (SYSTEM)",
    endpoint: "exception?category=SYSTEM",
  },
  {
    id: "benpos-entries",
    name: "All Entries (BENPOS)",
    endpoint: "exception?category=BENPOS",
  },
]

export const pdfTemplates = [
  { statusText: "Securities Transfer Form", id: "STF" },
]
export const distributorInvoiceStatus = [
  { statusText: "GENERATED", id: "GENERATED", colorClassName: "secondary" },
  // { statusText: "PENDING", id: "PENDING" },
  { statusText: "SUBMITTED", id: "SUBMIT", colorClassName: "warning" },
  { statusText: "APPROVED", id: "APPROVED", colorClassName: "success" },
  { statusText: "RE SUBMIT", id: "RE_SUBMIT", colorClassName: "danger" },
  { statusText: "PAID", id: "PAID", colorClassName: "success" },
]

export const distributorInvoiceReportStatus = [
  {
    statusText: "NOT GENERATED",
    id: "NOT GENERATED",
    colorClassName: "danger",
  },
  ...distributorInvoiceStatus,
]

export const dematTransferStatus = [
  { statusText: "MAPPED", id: "MAPPED", colorClassName: "success" },
  { statusText: "UNMAPPED", id: "UNMAPPED", colorClassName: "danger" },
]

export const dematTransferRequestsStatus = [
  {
    statusText: "TRANSFER INITIATED",
    id: "TRANSFER_INITIATED",
    colorClassName: "secondary",
  },
  {
    statusText: "RE-INITIATED",
    id: "RE_INITIATED",
    colorClassName: "secondary",
  },
  {
    statusText: "TRANSFER FAILED",
    id: "TRANSFER_FAILED",
    colorClassName: "danger",
  },
  {
    statusText: "TRANSFERRED",
    id: "TRANSFERRED",
    colorClassName: "success",
  },
  {
    statusText: "MAPPING PENDING",
    id: "ACTION_PENDING",
    colorClassName: "danger",
  },
]

export const dematTransferRequestsStatusForStf = [
  {
    statusText: "TRANSFER INITIATED",
    id: "TRANSFER_INITIATED",
    colorClassName: "secondary",
  },
  {
    statusText: "NOT INITIATED",
    id: "NOT_INITIATED",
    colorClassName: "secondary",
  },
  {
    statusText: "RE-INITIATED",
    id: "RE_INITIATED",
    colorClassName: "secondary",
  },
  {
    statusText: "TRANSFER FAILED",
    id: "TRANSFER_FAILED",
    colorClassName: "danger",
  },
  {
    statusText: "TRANSFERRED",
    id: "TRANSFERRED",
    colorClassName: "success",
  },
  {
    statusText: "MAPPING PENDING",
    id: "ACTION_PENDING",
    colorClassName: "danger",
  },
]

export const invStageForRm = [
  {
    statusText: "Initiated",
    id: "Initiated",
  },
  {
    statusText: "Commitment",
    id: "Commitment",
  },
  // {
  //   statusText: "Awaiting Token Manual Transfer",
  //   id: "Awaiting_Token_Manual_Transfer",
  // },
  // {
  //   statusText: "Token Paid",
  //   id: "Token_Paid",
  // },
  // {
  //   statusText: "EOI Signed",
  //   id: "EOI_Signed",
  // },
  {
    statusText: "Awaiting Manual Transfer",
    id: "Awaiting_Manual_Transfer",
  },
  {
    statusText: "Partially Paid",
    id: "Partially_Paid",
  },
  {
    statusText: "Declined",
    id: "Declined",
  },
  {
    statusText: "Initiate Balance Payment",
    id: "Initiate_Balance_Payment",
  },
]

export const transactionTypeForRepayment = [
  { statusText: "Interest Payment", id: "Interest_Payment" },
  { statusText: "Tds", id: "Tds" },
  { statusText: "Principal Repayment", id: "Principal_Repayment" },
  { statusText: "Principal Prepayment", id: "Principal_Prepayment" },
]
export const transactionTypeForTransactions = [
  { statusText: "Token", id: "Token" },
  { statusText: "Balance Investment", id: "Balance_Investment" },
  { statusText: "Direct", id: "Direct" },
  { statusText: "Token Refund", id: "Token_Refund" },
]
export const paymentStatusForTransactions = [
  { statusText: "Initiated", id: "initiated", colorClassName: "secondary" },
  { statusText: "Failed", id: "failed", colorClassName: "danger" },
  { statusText: "Success", id: "success", colorClassName: "success" },
]
export const kycStatusType = [
  {
    statusText: "IN VERIFICATION",
    id: "IN_VERIFICATION",
    colorClassName: "warning",
  },
  { statusText: "VERIFIED", id: "VERIFIED", colorClassName: "success" },
  { statusText: "RE SUBMIT", id: "RE_SUBMIT", colorClassName: "danger" },
  { statusText: "NOT SUBMITTED", id: "NOT_SUBMIT", colorClassName: "danger" },
]
export const investmentCountFilter = [
  { statusText: "Not Invested", id: "=0" },
  { statusText: "One Time Investor", id: "=1" },
  { statusText: "Repeat Investor", id: ">1" },
]

export const stfStatus = [
  { statusText: "Draft", id: "DRAFT", colorClassName: "secondary" },
  { statusText: "Created", id: "CREATED", colorClassName: "secondary" },
  {
    statusText: "Sent For Sign",
    id: "SENT_FOR_SIGN",
    colorClassName: "warning",
  },
  { statusText: "Signed", id: "SIGNED", colorClassName: "success" },
  { statusText: "Cancelled", id: "CANCELLED", colorClassName: "danger" },
]
export const stfStatusForTopup = [
  ...stfStatus,
  { statusText: "Not Created", id: null, colorClassName: "secondary" },
]
export const agreementStatus = [
  { statusText: "Not Created", id: "NOT_CREATED", colorClassName: "secondary" },

  { statusText: "Created", id: "CREATED", colorClassName: "secondary" },
  {
    statusText: "Sent For Sign",
    id: "SENT_FOR_SIGN",
    colorClassName: "warning",
  },
  { statusText: "Cancelled", id: "CANCELLED", colorClassName: "danger" },

  { statusText: "Signed", id: "SIGNED", colorClassName: "success" },
]
export const ticketStatus = [
  {
    statusText: "Open",
    id: "OPEN",
    colorClassName: "primary",
  },
  {
    statusText: "In Progress",
    id: "IN_PROGRESS",
    colorClassName: "info",
  },
  {
    statusText: "Rejected",
    id: "REJECTED",
    colorClassName: "danger",
  },
  {
    statusText: "Resolved",
    id: "RESOLVED",
    colorClassName: "warning",
  },
  {
    statusText: "Closed",
    id: "CLOSED",
    colorClassName: "success",
  },
]

export const addendumStatus = [
  { statusText: "Not Created", id: "NOT_CREATED", colorClassName: "secondary" },
  { statusText: "Created", id: "CREATED", colorClassName: "secondary" },
  {
    statusText: "Sent For Sign",
    id: "SENT_FOR_SIGN",
    colorClassName: "warning",
  },
  { statusText: "Cancelled", id: "CANCELLED", colorClassName: "danger" },
  {
    statusText: "Pending Approval",
    id: "PENDING_APPROVAL",
    colorClassName: "primary",
  },

  { statusText: "Signed", id: "SIGNED", colorClassName: "success" },
]

export const campaignStatusList = [
  { statusText: "Created", id: "Created" },
  { statusText: "Test Emails Sent", id: "Test_Emails_Sent" },
  { statusText: "Test Emails Verified", id: "Test_Emails_Verified" },
  { statusText: "Sent", id: "Sent" },
  { statusText: "Scheduled", id: "Scheduled" },
  { statusText: "Suspended", id: "Suspended" },
]

export const PaymentTypeForRepayment = [
  "Online",
  "Bank_Transfer",
  "Cash",
  "Cheque",
  "RTGS",
  "IMPS",
  "NEFT",
  "UPI",
]

export const webhookPaymentModes = [
  {
    statusText: "RTGS",
    id: "RTGS",
  },
  {
    statusText: "IMPS",
    id: "IMPS",
  },
  {
    statusText: "NEFT",
    id: "NEFT",
  },
  {
    statusText: "UPI",
    id: "UPI",
  },
  {
    statusText: "Online",
    id: "Online",
  },
  {
    statusText: "Bank Transfer",
    id: "Bank_Transfer",
  },
  {
    statusText: "Cash",
    id: "Cash",
  },
  {
    statusText: "Cheque",
    id: "Cheque",
  },
]

export const AccessId = {
  USER_MANAGEMENT: 1,
  KYC: 2,
  DEAL_PUBLISHING: 3,
  TRANSACTION: 4,
  SUPPORT: 5,
  ASSET_MANAGEMENT: 6,
  DISTRIBUTOR_MANAGEMENT: 7,
  DISTRIBUTOR_PORTAL: 8,
  SALES_INVESTOR_SERVICING: 9,
  DASHBOARD: 10,
  REPORTS: 11,
  MARKETING: 12,
}

export const PermissionNotDefied =
  "Your Permissions are not defined, Please contact approver!!"

export const billingFrequencyEnums = [
  { enum: "SEMI_MONTHLY", name: "SEMI-MONTHLY" },
  { enum: "MONTHLY", name: "MONTHLY" },
]

export const defaultSignatory = {
  name: "Ashish Khandelia",
  email: "ashish.khandelia@certuscapital.in",
}

export const selectStrToBool = {
  true: true,
  false: false,
}
