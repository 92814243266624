import React from "react"
import { Modal, ModalHeader, ModalBody, Button, Col } from "reactstrap"
import PropTypes from "prop-types"

const ActionModal = ({
  modalType,
  isOpen,
  toggleModal,
  updateTicketStatusHandler,
  deleteTicketHandler,
  ticketStatus,
}) => {
  const getModalContent = () => {
    switch (modalType) {
      case "close":
        return {
          title: "Are you sure you want to close this ticket?",
          statusText: "Closed",
          colorClassName: ticketStatus.find(
            item => item?.statusText === "Closed"
          )?.colorClassName,
          buttonText: "Save",
          actionHandler: () => updateTicketStatusHandler("close"),
        }
      case "re-open":
        return {
          title: "Are you sure you want to reopen this ticket?",
          statusText: "In Progress",
          colorClassName: ticketStatus.find(
            item => item?.statusText === "In Progress"
          )?.colorClassName,
          buttonText: "Save",
          actionHandler: () => updateTicketStatusHandler("re-open"),
        }
      case "resolve":
        return {
          title: "Are you sure you want to resolve this ticket?",
          statusText: "Resolved",
          colorClassName: ticketStatus.find(
            item => item?.statusText === "Resolved"
          )?.colorClassName,
          buttonText: "Save",
          actionHandler: () => updateTicketStatusHandler("resolve"),
        }
      case "delete":
        return {
          title: "Confirm Permanent Deletion",
          statusText: "Delete",
          colorClassName: "danger",
          buttonText: "Delete",
          actionHandler: deleteTicketHandler,
          description:
            "This action will permanently remove the ticket and cannot be undone.",
        }
      default:
        return {}
    }
  }

  const {
    title,
    statusText,
    colorClassName,
    buttonText,
    actionHandler,
    description,
  } = getModalContent()

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} tag="h4" className="text-center">
        {title}
      </ModalHeader>
      <ModalBody>
        <p className="mb-4">
          {description && description}
          {modalType !== "delete" && (
            <span className="d-block mt-2">
              Ticket status will change to{" "}
              <span
                className={`badge badge-pill font-size-12 badge-soft-${colorClassName}`}
              >
                {statusText}
              </span>
            </span>
          )}
        </p>

        <Col className="mt-3 col-12">
          <div className="d-flex justify-content-end gap-2">
            <Button color="secondary" outline onClick={toggleModal}>
              Cancel
            </Button>
            <Button
              color={modalType === "delete" ? "danger" : "success"}
              onClick={actionHandler}
            >
              {buttonText}
            </Button>
          </div>
        </Col>
      </ModalBody>
    </Modal>
  )
}

ActionModal.propTypes = {
  modalType: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  updateTicketStatusHandler: PropTypes.func.isRequired,
  deleteTicketHandler: PropTypes.func.isRequired,
  ticketStatus: PropTypes.array,
}

export default ActionModal
