import { axiosInstance } from "ConfigAxioxinstance"
import ReactSelect from "constants/ReactSelect"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { toast } from "react-toastify"
import { Col } from "reactstrap"

const ParentDropdown = ({
  users,
  setUsers,
  setLoading,
  handleFilterChange,
}) => {
  const [searchValue, setSearchValue] = useState("")
  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const isDist = localUser?.role?.department == "Distributor"
  const isRm = localUser?.role?.department == "Relationship_Manager"

  const handleUserSearch = async e => {
    setSearchValue(e)
    if (e.length >= 3) {
      setLoading(true)
      if (e) {
        const usersRes = await axiosInstance.get(
          `/users?$sort[created_at]=-1&$or[0][permissions]=USER&$or[1][permissions]=OPS_USER
              &name=${e}&$limit=50&$skip=0&is_registered=true&parentId=null${
            isDist ? `&createdById=${localUser.id}` : ""
          }${isRm ? `&relManagerId=${localUser.id}` : ""}`
        )
        const users = usersRes?.data?.data || usersRes?.data
        if (users) {
          setUsers(users.filter(item => item.children.length > 0))
        } else {
          toast.error("something went wrong")
        }
      } else {
        setUsers([])
      }
      setLoading(false)
    }
  }

  return (
    <Col md={3}>
      <div className="mb-3">
        <label>Select Parent User</label>
        <ReactSelect
          users={users}
          searchValue={searchValue}
          setSearchValue={handleUserSearch}
          setSelectedOption={e => {
            handleFilterChange(e, "parent")
          }}
          multiOptionLabel={false}
          optionLabelKeys={[
            "user_pan?.name",
            "email",
            "phone",
            "user_pan?.pan_number",
          ]}
          isClearable={true}
        />
      </div>
    </Col>
  )
}

ParentDropdown.propTypes = {
  users: PropTypes.array,
  setUsers: PropTypes.func,
  setLoading: PropTypes.bool,
  handleFilterChange: PropTypes.func,
}

export default ParentDropdown
