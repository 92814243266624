import PropTypes from "prop-types"
import React from "react"
import { useHistory } from "react-router-dom"

const HeaderWithBackBtn = ({ title, backBtnVisible = true }) => {
  const history = useHistory()
  return (
    <div className="d-flex align-items-center mb-2">
      <div
        className="border bg-white rounded-3 p-1 pb-0"
        style={{ cursor: "pointer" }}
        onClick={() => history.goBack()}
      >
        <i className="bx bx-chevron-left fs-2"></i>
      </div>
      <h4 className="ms-2 mb-0">{title}</h4>
    </div>
  )
}

HeaderWithBackBtn.propTypes = {
  title: PropTypes.string,
  backBtnVisible: PropTypes.bool,
}

export default HeaderWithBackBtn
