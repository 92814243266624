import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const InvestmentCreationRequest = () => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [loading, setLoading] = useState(false)
  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `investment-creation-request?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setData(response?.data?.data)
        setLimit(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  const selectRow = {
    mode: "checkbox",
  }

  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  //pagination customization
  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }

  const { SearchBar } = Search
  const CreationRequestColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessControlData?.permissions?.REQUESTS_EDIT ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {accessControlData?.permissions?.REQUESTS_EDIT}
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
            {accessControlData?.permissions?.REQUESTS_DELETE ? (
              <Link
                to="#"
                className="text-danger"
                onClick={() => handleDeleteOrder(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {accessControlData?.permissions?.REQUESTS_DELETE}
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },

    {
      dataField: "user.user_name",
      text: "Created By",
      sort: true,
      // eslint-disable-next-line react/display-name
      // formatter: (cellContent, row) => handleDepartment(row.user),
    },

    {
      dataField: "investment_request_name",
      text: "Request Name",
      sort: true,
    },
    {
      dataField: "investment_type",
      text: "Investment Type",
      sort: true,
    },
    {
      dataField: "investment_amount",
      text: "Investment Amount",
      sort: true,
    },
    {
      dataField: "creation_level",
      text: "Creation Level",
      sort: true,
    },
    {
      dataField: "creation_status",
      text: "Creation Status",
      sort: true,
    },
    {
      dataField: "publishing_level",
      text: "Publishing Level",
      sort: true,
    },
    {
      dataField: "publishing_status",
      text: "Publishing Status",
      sort: true,
    },
    {
      dataField: "investment_desc",
      text: "Investment Description",
      sort: true,
    },

    {
      dataField: "created_at",
      text: "Created On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },

    {
      dataField: "updated_at",
      text: "Updated On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const handleOrderClick = arg => {
    const order = arg

    setOrderList({
      id: order.id,
      user_name: order.userId,
      investment_type: order.investment_type,
      investment_request_name: order.investment_request_name,
      investment_desc: order.investment_desc,
      investment_amount: order.investment_amount,

      created_at: order.created_at,
    })

    setIsEdit(true)

    toggle()
  }

  const userId = localStorage.getItem("userId")

  const handleDeleteOrder = async order => {
    setLoading(true)
    var r = confirm(`Are you sure want to delete ${order.investment_type}`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `investment-creation-request/${order.id}`
        )
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    setLoading(false)
  }
  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEdit) {
      const updateOrder = {
        userId: userId,
        investment_type: values.investment_type,
        investment_request_name: values.investment_request_name,
        investment_desc: values.investment_desc,
        investment_amount: values.investment_amount,

        created_at: values.created_at,
      }

      // update order

      try {
        const response = await axiosInstance.patch(
          `investment-creation-request/${orderList.id}`,
          updateOrder
        )
        if (response) {
          masterData()
          toast.success("Successfully Edited")
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      const newOrder = {
        userId: userId,
        investment_type: values.investment_type,
        investment_request_name: values.investment_request_name,
        investment_desc: values.investment_desc,
        investment_amount: values.investment_amount,
      }
      // save new order

      try {
        const response = await axiosInstance.post(
          `investment-creation-request`,
          newOrder
        )
        if (response) {
          masterData()
          toast.success(" Request Successfully Added")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    toggle()
    setLoading(false)
  }

  const handleOrderClicks = () => {
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.REQUESTS_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={CreationRequestColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              {accessControlData?.permissions?.REQUESTS_ADD && (
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleOrderClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    {
                                      accessControlData?.permissions
                                        ?.REQUESTS_ADD
                                    }
                                  </Button>
                                </div>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                columns={CreationRequestColumns(toggle)}
                                data={orders}
                                pagination={paginationFactory(pageOptions)}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit
                                    ? "Edit Creation Request"
                                    : "Add Creation Request"}
                                </ModalHeader>
                                <ModalBody>
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <AvField
                                            name="investment_request_name"
                                            label="Creation Request Name"
                                            type="text"
                                            errorMessage="Invalid Creation Request Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList.investment_request_name ||
                                              ""
                                            }
                                          ></AvField>
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="investment_type"
                                            label="Investment Type"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid Investment Type"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList.investment_type || ""
                                            }
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            <option value="REAL_ESTATE">
                                              Real Estate
                                            </option>
                                            <option value="INFRA">Infra</option>
                                          </AvField>
                                        </div>

                                        <div className="mb-3">
                                          <AvField
                                            name="investment_amount"
                                            label="Investment Amount"
                                            type="number"
                                            errorMessage="Invalid Investment Amount"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList.investment_amount || ""
                                            }
                                          ></AvField>
                                        </div>

                                        <div className="mb-3">
                                          <AvField
                                            name="investment_desc"
                                            label="Investment Description"
                                            type="textarea"
                                            placeholder="Enter description here..."
                                            errorMessage="Invalid Investment Description"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList.investment_desc || ""
                                            }
                                          ></AvField>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

InvestmentCreationRequest.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(InvestmentCreationRequest)
