import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter, useHistory, useLocation } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import { investmentData, maskEmail, maskPhoneNumber } from "constants/common"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"
import ParentDropdown from "components/Common/ParentDropdown"

const CompletedUserInvestment = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [investmentFilter, setInvestmentFilter] = useState({})
  const [investmentList, setInvestmentList] = useState([])
  const [userFilter, setUserFilter] = useState("")
  const [downloadErrorModal, setDownloadErrorModal] = useState(false)
  const [distributorList, setDistributorList] = useState([])
  const [selectedDistrinutor, setSelectedDistrinutor] = useState(null)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [rmList, setRmList] = useState([])
  const [selectedRm, setSelectedRm] = useState(null)

  const [users, setUsers] = useState([])
  const [parentFilter, setParentFilter] = useState()

  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const isDist = localUser?.role?.department == "Distributor"
  const isRm = localUser?.role?.department == "Relationship_Manager"

  const location = useLocation()
  const history = useHistory()
  const urlData = new URLSearchParams(location.search)
  let urlStateDateFilter = urlData.get("start_date")
  let urlEndDateFilter = urlData.get("end_date")

  useEffect(() => {
    const initializeFilters = () => {
      if (urlStateDateFilter) {
        setStartDate(urlStateDateFilter)
      } else {
        setStartDate("")
      }
      if (urlEndDateFilter) {
        setEndDate(urlEndDateFilter)
      } else {
        setEndDate("")
      }
    }

    initializeFilters()
  }, [location])

  const filterUrl = `&inv_stage=Completed${
    userFilter.length >= 3 ? `&name=${userFilter}` : ``
  }${investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""}${
    isDist ? `&distributorId=${localUser.id}` : ""
  }${isRm ? `&relManagerId=${localUser.id}` : ""}${
    selectedDistrinutor ? `&distributorId=${selectedDistrinutor.id}` : ""
  }${selectedRm ? `&relManagerId=${selectedRm.id}` : ""}${
    parentFilter ? `&parentId=${parentFilter.id}` : ""
  }${startDate ? `&start_date=${startDate}` : ""}${
    endDate ? `&end_date=${endDate}` : ""
  }`

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const masterData = async () => {
    setLoading(true)

    const topUpGetUrl = `admin-user-investment-listing?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`
    try {
      const topUpResponse = await axiosInstance.get(topUpGetUrl)

      if (topUpResponse) {
        setTotal(topUpResponse.data.total)
        setData(topUpResponse.data?.data || topUpResponse.data)
        let pages = Math.ceil(
          (topUpResponse.data?.total || topUpResponse.total) /
            (topUpResponse.data?.limit || topUpResponse.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const getFiltersDataList = async () => {
    try {
      setLoading(true)

      const invResponse = await investmentData("Approved", "Exited", "Closed")
      if (invResponse) {
        setInvestmentList(invResponse?.data?.data || invResponse?.data)
      }

      if (!isDist) {
        const opsUsersRes = await axiosInstance.get(
          `/users?$sort[created_at]=-1&permissions=OPS_USER`
        )
        if (opsUsersRes) {
          const opsUsers = opsUsersRes.data.data
          setDistributorList(
            opsUsers?.filter(user => {
              if (user?.role?.department === "Distributor") {
                user.user_name = user.distributor_kyc?.name
                if (isRm && user.relManagerId != localUser.id) {
                  return false
                }
                return true
              }
            })
          )
          setRmList(opsUsers?.filter(ele => ele.roleId == 1))
        }
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      // setLoading(false)
    }
  }

  const updateQueryParams = (key, value) => {
    const currentParams = new URLSearchParams(window.location.search || "")

    if (value) {
      currentParams.set(key, value)
    } else {
      currentParams.delete(key)
    }

    history.replace({
      search: currentParams.toString() ? `?${currentParams.toString()}` : "",
    })
  }

  useEffect(() => {
    getFiltersDataList()
  }, [])

  useEffect(() => {
    if (
      !((urlStateDateFilter && !startDate) || (urlEndDateFilter && !endDate))
    ) {
      masterData()
    }
  }, [
    limit,
    skip,
    investmentFilter,
    selectedDistrinutor,
    selectedRm,
    parentFilter,
    startDate,
    endDate,
  ])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const UserInvestmentColumns = () => {
    let col = [
      {
        dataField: "created_at",
        text: "Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleValidDate(row.created_at),
      },
      {
        dataField: "project_name",
        text: "Project Name",
        sort: true,
      },
      {
        dataField: "name_on_pan",
        text: "Investor Pan Name",
        sort: true,
      },
      {
        dataField: "lot_size",
        text: "Bonds",
        sort: true,
        // eslint-disable-next-line react/display-name
        headerFormatter: (column, colIndex) => (
          <div className="text-end">{column.text}</div>
        ),
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{row.lot_size}</div>
        ),
      },
      {
        dataField: "amount",
        text: "Commitment",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{handleAmount(row.amount)}</div>
        ),
        // eslint-disable-next-line react/display-name
        headerFormatter: (column, colIndex) => (
          <div className="text-end">{column.text}</div>
        ),
      },
      {
        dataField: "pendings.amountPaid",
        text: "Amount Paid",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">
            {handleAmount(row.pendings.amountPaid)}
          </div>
        ),
        // eslint-disable-next-line react/display-name
        headerFormatter: (column, colIndex) => (
          <div className="text-end">{column.text}</div>
        ),
      },
      {
        dataField: "parent_name",
        text: "Parent Name",
        sort: true,
      },
      {
        dataField: "email",
        text: "Investor Email",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => <div>{maskEmail(cellContent)}</div>,
      },

      {
        dataField: "phone",
        text: "Investor Phone",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div>{maskPhoneNumber(cellContent)}</div>
        ),
      },
    ]
    if (!isDist) {
      const distCol = [
        {
          dataField: "distributorName",
          text: "Distributor Name",
          sort: true,
        },
        {
          dataField: "distributor_legal_entity_name",
          text: "Distributor Legal Entity Name",
          sort: true,
        },
        {
          dataField: "distributorEmail",
          text: "Distributor Email",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => <div>{maskEmail(cellContent)}</div>,
        },
        {
          dataField: "distributorPhone",
          text: "Distributor Phone",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => (
            <div>{maskPhoneNumber(cellContent)}</div>
          ),
        },
      ]
      col = [...col, ...distCol]
    }

    if (!isDist && !isRm) {
      const rmCol = [
        {
          dataField: "rm_user_name",
          text: "RM Name",
          sort: true,
        },
        {
          dataField: "rm_email",
          text: "RM Email",
          sort: true,
        },
        {
          dataField: "rm_phone",
          text: "RM Phone",
          sort: true,
        },
      ]
      col = [...col, ...rmCol]
    }
    return col
  }

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  const toggleDownloadErrorModal = () => {
    setDownloadErrorModal(!downloadErrorModal)
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "projectType":
        setInvestmentFilter(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      case "distributor":
        setSelectedDistrinutor(e)
        break
      case "rm":
        setSelectedRm(e)
        break
      case "parent":
        setParentFilter(e)
        break
      case "start_date":
        setStartDate(e?.target?.value)
        updateQueryParams("start_date", e?.target?.value)
        break
      case "end_date":
        setEndDate(e?.target?.value)
        updateQueryParams("end_date", e?.target?.value)
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const downloadExcel = async () => {
    if (total > 500) {
      setDownloadErrorModal(true)
      return
    }
    try {
      setLoading(true)

      const downloadUrl = `/download-excel?type=completed-user-investment-report&$sort[created_at]=-1${filterUrl}`
      const response = await axiosInstance.get(downloadUrl, {
        responseType: "blob",
      })

      const contentDispositionHeader = response.headers["content-disposition"]
      console.log("headf", contentDispositionHeader)
      let match
      if (contentDispositionHeader) {
        match = contentDispositionHeader.split("filename=")
      }

      const filename = match ? match[1] : "download.xlsx"
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const downloadLink = document.createElement("a")
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = filename
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.COMPLETED_INVESTMENT_REPORT_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem="Completed Investments" />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between">
                  <Col md={2}>
                    <select
                      className="form-select w-75"
                      value={limit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <div className="col-auto">
                    {accessControlData?.permissions
                      ?.COMPLETED_INVESTMENT_REPORT_DOWNLOAD && (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded  mb-2 me-2"
                        onClick={() => {
                          downloadExcel()
                        }}
                        style={{ marginRight: "3px" }}
                      >
                        <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                        Download Excel
                      </Button>
                    )}
                  </div>
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={data}
                      columns={UserInvestmentColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row">
                            <Col md={3}>
                              <div className="col-auto">
                                <label>Search Investor (By Pan Name)</label>
                                <input
                                  onChange={e => handleFilterChange(e, "user")}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control rounded custom-input-height"
                                  placeholder={`Search`}
                                  value={userFilter || ""}
                                />
                                {/* <i className="bx bx-search-alt"></i> */}
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Project Name</label>
                                <ReactSelect
                                  users={investmentList}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "projectType")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["project_name"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                            <ParentDropdown
                              users={users}
                              setUsers={setUsers}
                              setLoading={setLoading}
                              handleFilterChange={handleFilterChange}
                            />
                            {!isDist ? (
                              <Col md={3}>
                                <div className="mb-3">
                                  <label>Distributor</label>
                                  <ReactSelect
                                    users={distributorList}
                                    setSelectedOption={e =>
                                      handleFilterChange(e, "distributor")
                                    }
                                    multiOptionLabel={true}
                                    optionLabelKeys={[
                                      "user_name",
                                      "email",
                                      "phone",
                                    ]}
                                    isClearable={true}
                                  />
                                </div>
                              </Col>
                            ) : null}
                            {!isDist && !isRm ? (
                              <Col md={3}>
                                <div className="mb-3">
                                  <label>Relationship Manager</label>
                                  <ReactSelect
                                    users={rmList}
                                    setSelectedOption={e =>
                                      handleFilterChange(e, "rm")
                                    }
                                    multiOptionLabel={true}
                                    optionLabelKeys={[
                                      "user_name",
                                      "email",
                                      "phone",
                                    ]}
                                    isClearable={true}
                                  />
                                </div>
                              </Col>
                            ) : null}
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Start Date</label>
                                <Input
                                  name="date"
                                  type="date"
                                  value={startDate}
                                  onChange={e => {
                                    handleFilterChange(e, "start_date")
                                  }}
                                  className="custom-input-height"
                                  max={endDate || undefined}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>End Date</label>
                                <Input
                                  name="date"
                                  type="date"
                                  value={endDate}
                                  onChange={e =>
                                    handleFilterChange(e, "end_date")
                                  }
                                  className="custom-input-height"
                                  min={startDate || undefined}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                columns={UserInvestmentColumns()}
                                data={data}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                            </Col>
                          </Row>
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}
                    `}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              isOpen={downloadErrorModal}
              toggle={toggleDownloadErrorModal}
            >
              <ModalHeader toggle={toggleDownloadErrorModal} tag="h4">
                Invalid Request!
              </ModalHeader>
              <ModalBody>
                <Row form>
                  Download requested for more than 500 entries. Please select a
                  filter before proceeding.
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-success save-user"
                        onClick={toggleDownloadErrorModal}
                      >
                        OK
                      </button>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(CompletedUserInvestment)
