import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { Link } from "react-router-dom"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import Loader from "common/Loader"
import { Pagination } from "constants/common"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { DealManagementAccess } from "common/AccessManagement"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const DebentureTrusteeMaster = props => {
  const [loading, setLoading] = useState(false)

  const [debentureTrusteeData, setDebentureTrusteeData] = useState([])

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [debentureTrusteeFormData, setDebentureTrusteeFormData] = useState(null)

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(`debenture-trustee`)

      if (response) {
        setDebentureTrusteeData(response?.data?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    masterData()
  }, [])

  const selectRow = {
    mode: "checkbox",
  }

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const DebentureTrusteeColumns = () => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessControlData?.permissions?.DEBENTURE_TRUSTEE_MASTER_EDIT ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => editDebentureTrustee(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {
                    accessControlData?.permissions
                      ?.DEBENTURE_TRUSTEE_MASTER_EDIT
                  }
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
            {accessControlData?.permissions?.DEBENTURE_TRUSTEE_MASTER_DELETE ? (
              <Link
                to="#"
                className="text-danger"
                onClick={() => deleteDebentureTrustee(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {
                    accessControlData?.permissions
                      ?.DEBENTURE_TRUSTEE_MASTER_DELETE
                  }
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },
    {
      dataField: "debenture_trustee_name",
      text: "Debenture Trustee",
      sort: true,
    },
    {
      dataField: "debenture_trustee_cin",
      text: "Debenture Trustee CIN",
      sort: true,
    },
    {
      dataField: "debenture_trustee_email",
      text: "Debenture Trustee Email",
      sort: true,
    },
    {
      dataField: "debenture_trustee_phone",
      text: "Debenture Trustee Phone",
      sort: true,
    },
  ]

  const toggle = () => {
    setModal(!modal)
  }

  const addDebentureTrustee = () => {
    setDebentureTrusteeFormData(null)
    setIsEdit(false)
    toggle()
  }
  const editDebentureTrustee = order => {
    setDebentureTrusteeFormData(order)
    setIsEdit(true)
    toggle()
  }
  const deleteDebentureTrustee = async order => {
    var r = confirm(
      `Are you sure want to delete ${order.debenture_trustee_name || ""}`
    )
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `debenture-trustee/${order.id}`
        )
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const handleValidDebentureSubmit = async (e, values) => {
    setLoading(true)
    try {
      let debentureTrusteeData = {
        debenture_trustee_cin: values.debenture_trustee_cin,
        debenture_trustee_email: values.debenture_trustee_email,
        debenture_trustee_name: values.debenture_trustee_name,
        debenture_trustee_phone: values.debenture_trustee_phone,
      }
      let debentureTrusteeRes = isEdit
        ? await axiosInstance.patch(
            `debenture-trustee/${debentureTrusteeFormData?.id}`,
            debentureTrusteeData
          )
        : await axiosInstance.post("debenture-trustee", debentureTrusteeData)
      if (debentureTrusteeRes) {
        toast.success("Debenture Trustee added successfully")
        masterData()
        toggle()
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.DEBENTURE_TRUSTEE_MASTER_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={debentureTrusteeData}
                      columns={DebentureTrusteeColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <Row className="justify-content-end">
                                <div className="col-auto">
                                  {accessControlData?.permissions
                                    ?.DEBENTURE_TRUSTEE_MASTER_ADD && (
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={addDebentureTrustee}
                                    >
                                      <i className="mdi mdi-plus me-1" />
                                      {
                                        accessControlData?.permissions
                                          ?.DEBENTURE_TRUSTEE_MASTER_ADD
                                      }
                                    </Button>
                                  )}
                                  {accessControlData?.permissions
                                    ?.DEBENTURE_TRUSTEE_MASTER_DOWNLOAD && (
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={() =>
                                        JSONToCSVConvertor(
                                          downloadableArr,
                                          "Debenture Trustee Master",
                                          true
                                        )
                                      }
                                      style={{ marginRight: "3px" }}
                                    >
                                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                      {
                                        accessControlData?.permissions
                                          ?.DEBENTURE_TRUSTEE_MASTER_DOWNLOAD
                                      }
                                    </Button>
                                  )}
                                </div>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                columns={DebentureTrusteeColumns()}
                                data={debentureTrusteeData}
                                pagination={paginationFactory(pageOptions)}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {isEdit
                                    ? "Edit Debenture Trustee"
                                    : "Add Debenture Trustee"}
                                </ModalHeader>
                                <ModalBody>
                                  {loading && <Loader />}
                                  <AvForm
                                    onValidSubmit={handleValidDebentureSubmit}
                                  >
                                    <Row form>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <AvField
                                            name="debenture_trustee_name"
                                            label="Debenture Trustee"
                                            type="text"
                                            errorMessage="Invalid value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              debentureTrusteeFormData?.debenture_trustee_name ||
                                              ""
                                            }
                                          ></AvField>
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="debenture_trustee_cin"
                                            label="Debenture Trustee CIN"
                                            type="text"
                                            errorMessage="Invalid value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              debentureTrusteeFormData?.debenture_trustee_cin ||
                                              ""
                                            }
                                          ></AvField>
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="debenture_trustee_email"
                                            label="Debenture Trustee Email"
                                            type="text"
                                            errorMessage="Invalid value"
                                            value={
                                              debentureTrusteeFormData?.debenture_trustee_email ||
                                              ""
                                            }
                                          ></AvField>
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="debenture_trustee_phone"
                                            label="Debenture Trustee Phone"
                                            type="text"
                                            errorMessage="Invalid value"
                                            value={
                                              debentureTrusteeFormData?.debenture_trustee_phone ||
                                              ""
                                            }
                                          ></AvField>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <Button
                                            type="submit"
                                            color="success"
                                            className="save-user"
                                          >
                                            Save
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

DebentureTrusteeMaster.propTypes = {
  debentureTrusteeData: PropTypes.array,
}

export default withRouter(DebentureTrusteeMaster)
