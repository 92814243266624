import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { handleValidDate, humanize } from "constants/common"
import ReactSelect from "constants/ReactSelect"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  UncontrolledTooltip,
  ModalFooter,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import {
  getSectionByPageId,
  SidebarOptions,
} from "components/VerticalLayout/SidebarOptions"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const NewPermission = () => {
  const [data, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState([])
  const [modal, setModal] = useState(false)
  const [finalSelectedRows, setFinalSelectedRows] = useState([])
  const [selectedRoleFilter, setSelectedRoleFilter] = useState(null)
  const [selectedRole, setSelectedRole] = useState(null)
  const [permissionOptions, setPermissionOptions] = useState(
    JSON.parse(JSON.stringify(SidebarOptions))
  )

  const [toCloneRoles, setToCloneRoles] = useState([])
  const [selectedCloneRole, setSelectedCloneRole] = useState(null)

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const filterUrl = `${
    selectedRoleFilter ? `&roleId=${selectedRoleFilter.id}` : ""
  }`

  const masterData = async () => {
    setLoading(true)

    const response = await axiosInstance.get(
      `/permission?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`
    )

    if (response) {
      const dataArr = response.data?.data || response.data
      setData(
        dataArr.map(item => ({
          ...item,
          section: getSectionByPageId(item.pageId),
        }))
      )
      setTotal(response.data.total)
      let pages = Math.ceil(
        (response.data?.total || response.total) /
          (response.data?.limit || response.limit)
      )
      setTotalPage(pages)
    }
    setLoading(false)
  }

  const getRoles = async () => {
    const response = await axiosInstance.get(`/role?$sort[created_at]=-1`)
    if (response) {
      setRoles(response.data.data || response.data)
    }
  }

  useEffect(() => {
    getRoles()
  }, [])

  const toggle = () => {
    setModal(!modal)
  }

  useEffect(async () => {
    masterData()
  }, [limit, skip, selectedRoleFilter])

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    clickToExpand: true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setFinalSelectedRows([...finalSelectedRows, row])
      } else {
        setFinalSelectedRows(finalSelectedRows.filter(r => r.id !== row.id))
      }
    },
    onSelectAll: (isSelect, rows) => {
      setFinalSelectedRows(isSelect ? rows : [])
    },
    selected: finalSelectedRows.map(row => row.id),
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "limit":
        setLimit(Number(e.target.value))
        break
      case "role":
        setSelectedRoleFilter(e)
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  const Columns = download => {
    let cols = []
    cols.push(
      {
        dataField: "role.role_name",
        text: "Role Name",
        sort: true,
      },
      {
        dataField: "role.department",
        text: "Department",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => humanize(row?.role?.department),
      },
      {
        dataField: "section.section.displayName",
        text: "Section",
        sort: true,
      },
      {
        dataField: "section.page.displayName",
        text: "Page",
        sort: true,
      },
      {
        dataField: "actionId",
        text: "Action",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          row?.section?.page?.actions?.find(action => action.key == cellContent)
            ?.displayName || cellContent,
      },
      {
        dataField: "created_at",
        text: "Created At",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleValidDate(cellContent),
      },
      {
        dataField: "updated_at",
        text: "Updated At",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleValidDate(cellContent),
      }
    )

    return cols
  }

  useEffect(async () => {
    if (selectedRole?.id || selectedCloneRole?.id) {
      setPermissionOptions(JSON.parse(JSON.stringify(SidebarOptions)))

      const my_role = selectedCloneRole || selectedRole
      const isDist = my_role?.department == "Distributor"

      try {
        setLoading(true)
        const response = await axiosInstance.get(
          `/permission?roleId=${my_role.id}`
        )

        if (response) {
          const permissionData = response.data.data
          if (permissionData.length) {
            let newArr = []
            const permissionOptionsCopy = JSON.parse(
              JSON.stringify(SidebarOptions)
            )
            for (const section of permissionOptionsCopy) {
              let isSectionSelected = false
              section["pages"] = section.pages.map(page => {
                const allowedPage = permissionData.find(
                  pd => pd.pageId === page.id
                )
                if (allowedPage) {
                  isSectionSelected = true
                  if (section.groups) {
                    section.groups.forEach(group => {
                      if (page.groupId == group.id) {
                        group["selected"] = true
                      }
                    })
                  }
                  page["selected"] = true
                  page["actions"] = page.actions.map(action => {
                    const allowedActions = permissionData.find(
                      pd => pd.pageId === page.id && pd.actionId === action.id
                    )
                    if (allowedActions) {
                      action.selected = true
                    } else {
                      action.selected = false
                    }
                    return action
                  })
                } else {
                  page["selected"] = false
                  page["actions"] = page.actions.map(action => ({
                    ...action,
                    selected: false,
                  }))
                }

                return page
              })
              section["selected"] = isSectionSelected
              if (isDist) {
                if (
                  section.key == "DISTRIBUTOR_PORTAL" ||
                  section.key == "REPORTS"
                ) {
                  newArr.push(section)
                }
              } else {
                newArr.push(section)
              }
            }
            setPermissionOptions(newArr)
          } else {
            if (isDist) {
              const options = JSON.parse(JSON.stringify(SidebarOptions))
              setPermissionOptions(
                options.filter(
                  option =>
                    option.key == "DISTRIBUTOR_PORTAL" ||
                    option.key == "REPORTS"
                )
              )
            } else {
              setPermissionOptions(JSON.parse(JSON.stringify(SidebarOptions)))
            }
          }
        }
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    }
  }, [selectedRole, selectedCloneRole])

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const handleAdd = () => {
    setSelectedRole(null)
    toggle()
  }

  const handleValidSubmit = async () => {
    try {
      if (!selectedRole) {
        toast.error("Select a Role")
        return
      }

      const payload = {
        roleId: selectedRole.id,
        data: [],
      }

      for (const section of permissionOptions) {
        if (section.selected) {
          for (const page of section.pages) {
            if (page.selected) {
              for (const action of page.actions) {
                if (action.selected) {
                  payload.data.push({
                    roleId: selectedRole.id,
                    pageId: page.id,
                    actionId: action.id,
                  })
                }
              }
            }
          }
        }
      }

      setLoading(true)
      const res = await axiosInstance.post(`/permission`, payload)
      if (res) {
        toast.success("Permissions Updated")
        await masterData()
        toggle()
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleCheckboxChange = (e, type, item, sectionId, pageId = null) => {
    const selected = e.target.checked
    switch (type) {
      case "section":
        let newArr = []
        for (const section of permissionOptions) {
          if (section.id === item.id) {
            section["selected"] = selected
            if (section.groups) {
              section.groups.forEach(group => {
                group["selected"] = selected ? true : false
              })
            }
            section["pages"] = section.pages.map(page => ({
              ...page,
              selected: selected ? true : false,
              actions: page.actions.map(action => ({
                ...action,
                selected: selected ? true : false,
              })),
            }))
          } else {
            section["selected"] = section.selected || false
          }
          newArr.push(section)
        }
        setPermissionOptions(newArr)
        break
      case "page":
        let pageArr = []
        for (const section of permissionOptions) {
          section["pages"] = section.pages.map(page => {
            if (page.id === item.id) {
              page["selected"] = selected
              page["actions"] = page.actions.map(action => ({
                ...action,
                selected: selected ? true : false,
              }))
            } else {
              page["selected"] = page.selected || false
            }

            return page
          })
          pageArr.push(section)
        }
        const selectedGroupId = new Set()

        const pages = pageArr
          .find(opt => opt.id === sectionId)
          ?.pages.filter(page => {
            if (page.selected) {
              if (page.groupId) {
                selectedGroupId.add(page.groupId)
              }
              return true
            }
          })

        setPermissionOptions(
          pageArr.map(sec => {
            if (sec.id == sectionId) {
              sec.selected = pages.length > 0 ? true : false
              if (sec?.groups) {
                sec["groups"] = sec.groups.map(group => {
                  if (selectedGroupId.has(group.id)) {
                    group["selected"] = true
                  } else {
                    group["selected"] = false
                  }
                  return group
                })
              }
            }
            return sec
          })
        )

        break
      case "action":
        let actionArr = []
        for (const section of permissionOptions) {
          section["pages"] = section.pages.map(page => {
            if (page.id === pageId) {
              page["actions"] = page.actions.map(action => {
                if (action.id === item.id) {
                  action["selected"] = selected
                }
                return action
              })
            }
            return page
          })
          actionArr.push(section)
        }
        setPermissionOptions(actionArr)
        break
      case "group":
        let groupArr = []
        for (const section of permissionOptions) {
          if (section.id == sectionId) {
            section["pages"] = section.pages.map(page => {
              if (page.groupId == item.id) {
                return {
                  ...page,
                  selected: selected,
                  actions: page.actions.map(action => ({
                    ...action,
                    selected: selected ? true : false,
                  })),
                }
              }
              return page
            })
          }
          groupArr.push(section)
        }
        const pagesSelected = groupArr
          .find(opt => opt.id === sectionId)
          ?.pages.filter(page => page.selected)

        setPermissionOptions(
          groupArr.map(sec => {
            if (sec.id == sectionId) {
              sec.selected = pagesSelected.length > 0 ? true : false
            }
            return sec
          })
        )
        break
      default:
        break
    }
  }

  const handleRoleSelection = myRole => {
    setSelectedRole(myRole)

    setToCloneRoles(
      roles.filter(
        item => item.department == myRole.department && item.id !== myRole.id
      )
    )
    setSelectedCloneRole(null)
  }
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.NEW_PERMISSIONS_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between">
                  <Col md={2}>
                    <select
                      className="form-select w-75"
                      value={limit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <div className="col-auto">
                    {accessControlData?.permissions
                      ?.NEW_PERMISSIONS_ADD_UPDATE && (
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded mb-2 me-2"
                        onClick={handleAdd}
                        style={{ marginRight: "3px" }}
                      >
                        <i className="mdi mdi-plus me-1" />{" "}
                        {
                          accessControlData?.permissions
                            ?.NEW_PERMISSIONS_ADD_UPDATE
                        }
                      </Button>
                    )}
                  </div>
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={data}
                      columns={Columns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row">
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Role</label>
                                <ReactSelect
                                  users={roles.map(role => ({
                                    ...role,
                                    label:
                                      role.role_name +
                                      " (" +
                                      humanize(role.department) +
                                      ")",
                                  }))}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "role")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["label"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                //   selectRow={selectRow}
                                columns={Columns()}
                                data={data}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                            </Col>
                          </Row>
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}${
                                finalSelectedRows.length
                                  ? ` | Selected rows: ${finalSelectedRows.length}`
                                  : ""
                              }
                    `}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-lg"
        scrollable={selectedRole?.id ? true : false}
      >
        <ModalHeader toggle={toggle} tag="h4">
          Add/Update Permissions
        </ModalHeader>
        <ModalBody>
          <Row>
            <AvForm>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <label>Select Role</label>
                    <ReactSelect
                      users={roles.map(role => ({
                        ...role,
                        label:
                          role.role_name +
                          " (" +
                          role?.department?.replaceAll("_", " ") +
                          ")",
                      }))}
                      setSelectedOption={handleRoleSelection}
                      multiOptionLabel={true}
                      selectedOption={selectedRole}
                      optionLabelKeys={["label"]}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <label>Clone From Role</label>
                    <ReactSelect
                      users={toCloneRoles.map(role => ({
                        ...role,
                        label:
                          role.role_name +
                          " (" +
                          role?.department?.replaceAll("_", " ") +
                          ")",
                      }))}
                      setSelectedOption={setSelectedCloneRole}
                      multiOptionLabel={true}
                      selectedOption={selectedCloneRole}
                      optionLabelKeys={["label"]}
                      isDisabled={!selectedRole?.id}
                    />
                  </div>
                </Col>
              </Row>
              {selectedRole && selectedRole.id ? (
                <div>
                  {permissionOptions.map((section, index) => {
                    const isDist = selectedRole?.department === "Distributor"
                    if (!isDist && section.key == "DISTRIBUTOR_PORTAL") {
                      return null
                    }
                    return (
                      <div className="mb-3" key={section.id}>
                        <AvField
                          name={section.id}
                          label={
                            <strong
                              className={section.selected ? "" : "text-muted"}
                            >
                              {section.displayName}
                            </strong>
                          }
                          type="checkbox"
                          checked={section?.selected || false}
                          value={section?.selected || false}
                          onChange={e =>
                            handleCheckboxChange(
                              e,
                              "section",
                              section,
                              section.id
                            )
                          }
                        ></AvField>
                        <>
                          {section.groups ? (
                            <div className="mx-3">
                              {section.groups.map((group, index) => (
                                <div key={group.key}>
                                  <AvField
                                    name={section.id}
                                    label={
                                      <strong
                                        className={
                                          group.selected ? "" : "text-muted"
                                        }
                                      >
                                        {group.displayName}
                                      </strong>
                                    }
                                    type="checkbox"
                                    checked={group?.selected || false}
                                    value={group?.selected || false}
                                    onChange={e =>
                                      handleCheckboxChange(
                                        e,
                                        "group",
                                        group,
                                        section.id
                                      )
                                    }
                                  ></AvField>
                                  <div className="mx-4">
                                    {section.pages.map((page, index) => {
                                      if (page.groupId == group.id) {
                                        return (
                                          <div key={page.id}>
                                            <AvField
                                              name={page.id}
                                              label={
                                                <span
                                                  className={
                                                    page.selected
                                                      ? ""
                                                      : "text-muted"
                                                  }
                                                >
                                                  {page.displayName}
                                                </span>
                                              }
                                              type="checkbox"
                                              checked={page?.selected || false}
                                              value={page?.selected || false}
                                              onChange={e =>
                                                handleCheckboxChange(
                                                  e,
                                                  "page",
                                                  page,
                                                  section.id
                                                )
                                              }
                                            ></AvField>
                                            {page.selected ? (
                                              <div className="d-flex gap-2 mx-4 mb-3 flex-wrap">
                                                {page.actions.map(
                                                  (action, index) => (
                                                    <div key={action.id}>
                                                      <AvField
                                                        disabled={
                                                          action.displayName ==
                                                          "View"
                                                            ? true
                                                            : false
                                                        }
                                                        name={action.id}
                                                        label={
                                                          <span
                                                            className={
                                                              action?.selected
                                                                ? ""
                                                                : "text-muted"
                                                            }
                                                          >
                                                            {action.displayName}
                                                          </span>
                                                        }
                                                        type="checkbox"
                                                        value={
                                                          action?.selected ||
                                                          false
                                                        }
                                                        onChange={e =>
                                                          handleCheckboxChange(
                                                            e,
                                                            "action",
                                                            action,
                                                            section.id,
                                                            page.id
                                                          )
                                                        }
                                                      ></AvField>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            ) : null}
                                          </div>
                                        )
                                      }
                                    })}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="mx-4">
                              {section.pages.map((page, index) => (
                                <div key={page.id}>
                                  <AvField
                                    name={page.id}
                                    label={
                                      <span
                                        className={
                                          page.selected ? "" : "text-muted"
                                        }
                                      >
                                        {page.displayName}
                                      </span>
                                    }
                                    type="checkbox"
                                    checked={page?.selected || false}
                                    value={page?.selected || false}
                                    onChange={e =>
                                      handleCheckboxChange(
                                        e,
                                        "page",
                                        page,
                                        section.id
                                      )
                                    }
                                  ></AvField>
                                  {page.selected ? (
                                    <div className="d-flex gap-2 mx-4 mb-3 flex-wrap">
                                      {page.actions.map((action, index) => (
                                        <div key={action.id}>
                                          <AvField
                                            disabled={
                                              action.displayName == "View"
                                                ? true
                                                : false
                                            }
                                            name={action.id}
                                            label={
                                              <span
                                                className={
                                                  action?.selected
                                                    ? ""
                                                    : "text-muted"
                                                }
                                              >
                                                {action.displayName}
                                              </span>
                                            }
                                            type="checkbox"
                                            value={action?.selected || false}
                                            onChange={e =>
                                              handleCheckboxChange(
                                                e,
                                                "action",
                                                action,
                                                section.id,
                                                page.id
                                              )
                                            }
                                          ></AvField>
                                        </div>
                                      ))}
                                    </div>
                                  ) : null}
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      </div>
                    )
                  })}
                </div>
              ) : null}
            </AvForm>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="button"
                  onClick={handleValidSubmit}
                  className="btn btn-success save-user"
                >
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default NewPermission
