import Breadcrumbs from "components/Common/Breadcrumb"
import { axiosInstance } from "ConfigAxioxinstance"
import { DealManagementAccess } from "common/AccessManagement"
import Loader from "common/Loader"
import { AccessId } from "constants/ConstantFields"
import ReactSelect from "constants/ReactSelect"
import { csvDownloadData } from "constants/common"
import moment from "moment"
import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { MetaTags } from "react-meta-tags"
import { toast } from "react-toastify"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"
import nodata from "../../assets/images/nodata/no-data.svg"
import logo from "../../assets/images/earnnest-me-logo.png"
import parse from "html-react-parser"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const DealCashflowReturns = () => {
  // Initialize states
  const [loading, setLoading] = useState(false)

  const [investments, setInvestments] = useState([])

  const [tableData, setTableData] = useState([])
  const [investmentCalcData, setInvestmentCalcData] = useState()

  const [selectedInvestment, setSelectedInvestment] = useState({})
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  )
  const [selectedLotSize, setSelectedLotSize] = useState(0)

  // Initialize constants
  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const isDist = localUser?.role?.department === "Distributor"

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const maxDate =
    selectedInvestment?.expiry_date <
    moment().add(14, "days").format("YYYY-MM-DD")
      ? selectedInvestment.expiry_date
      : moment().add(14, "days").format("YYYY-MM-DD")
  const minDate = moment(new Date()).subtract(2, "days").format("YYYY-MM-DD")

  // ComponentDidMount
  useEffect(async () => {
    setLoading(true)
    try {
      const investmentResponse = await axiosInstance.get(
        `/investment?$sort[created_at]=-1`
      )
      if (investmentResponse) {
        const investmentData =
          investmentResponse?.data?.data || investmentResponse?.data
        setInvestments(
          investmentData.filter(
            ele => ele.status == "Approved" || ele.status == "Closed"
          )
        )
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }, [])

  /* Component Logic */
  const getRepayment = async (investment, lots, date) => {
    setLoading(true)
    try {
      if (investment?.id && date && lots) {
        const repaymentGetUrl = `repayment/${investment?.id}?lots=${lots}&date=${date}`
        const investmentCalcGetUrl = `investment-calc-new/${investment?.id}?multr=${lots}&date=${date}`

        const repaymentResponse = await axiosInstance.get(repaymentGetUrl)
        if (repaymentResponse) {
          let repaymentData =
            repaymentResponse?.data?.data || repaymentResponse?.data
          let summary =
            repaymentResponse?.data?.summary || repaymentResponse?.summary
          if (summary) {
            summary["date"] = "Total"
            repaymentData.push(summary)
          }
          setTableData(repaymentData)

          const investmentCalcResponse = await axiosInstance.get(
            investmentCalcGetUrl
          )
          if (investmentCalcResponse) {
            let investmentCalcData =
              investmentCalcResponse?.data?.data || investmentCalcResponse?.data
            setInvestmentCalcData(investmentCalcData)
          }
        }
      }
    } catch (error) {
      toast.error(error.message)
    }
    setLoading(false)
  }

  const repaymentColumns = () => [
    { dataField: "date", text: "Date", sort: true },
    {
      dataField: "investment_amount",
      text: "Investment Amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      headerFormatter: data => (
        <div className="text-end">
          {data.text}
          <sup>(1)</sup>
        </div>
      ),
      // eslint-disable-next-line react/display-name
      formatter: (order, row) => (
        <div className="text-end">
          {order < 0
            ? `(${Math.round(Math.abs(order)).toLocaleString("en-IN")})`
            : Math.round(order).toLocaleString("en-IN")}
          {row?.date === moment(selectedDate).format("DD-MMM-YYYY") ? (
            <sup>
              <b>*</b>
            </sup>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      dataField: "interest_amount",
      text: "Interest Amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      headerFormatter: data => (
        <div className="text-end">
          {data.text}
          <sup>(2)</sup>
        </div>
      ),
      // eslint-disable-next-line react/display-name
      formatter: order => (
        <div className="text-end">
          {order < 0
            ? `(${Math.round(Math.abs(order)).toLocaleString("en-IN")})`
            : Math.round(order).toLocaleString("en-IN")}
        </div>
      ),
    },
    {
      dataField: "principal_repayment",
      text: "Principal Repayment",
      sort: true,
      // eslint-disable-next-line react/display-name
      headerFormatter: data => (
        <div className="text-end">
          {data.text}
          <sup>(3)</sup>
        </div>
      ),
      // eslint-disable-next-line react/display-name
      formatter: order => (
        <div className="text-end">
          {order < 0
            ? `(${Math.round(Math.abs(order)).toLocaleString("en-IN")})`
            : Math.round(order).toLocaleString("en-IN")}
        </div>
      ),
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
      // eslint-disable-next-line react/display-name
      headerFormatter: data => <div className="text-end">{data.text}</div>,
      // eslint-disable-next-line react/display-name
      formatter: order => (
        <div className="text-end">
          {order < 0
            ? `(${Math.round(Math.abs(order)).toLocaleString("en-IN")})`
            : Math.round(order).toLocaleString("en-IN")}
        </div>
      ),
    },
  ]

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const handleFilterChange = (e, key) => {
    switch (key) {
      case "projectName":
        const investment = e
        const lots = 1
        setSelectedInvestment(investment)
        setSelectedLotSize(lots)
        getRepayment(investment, lots, selectedDate)
        break
      default:
        break
    }
  }

  const handleDate = e => {
    const date = e.target.value
    setSelectedDate(date)
    getRepayment(selectedInvestment, selectedLotSize, date)
  }

  const handleLotSize = async e => {
    let lotSize = parseInt(e.target.value)
    setSelectedLotSize(lotSize)
    if (lotSize >= 1) {
      getRepayment(selectedInvestment, lotSize, selectedDate)
    }
  }

  let downloadableArr = []

  let arr
  if (tableData) {
    let csvTableHeaders = repaymentColumns()
    var str = JSON.stringify(tableData)
    arr = JSON.parse(str)
    downloadableArr = csvDownloadData(csvTableHeaders, arr)
  }

  const downloadExcel = async (id, lots, date) => {
    try {
      setLoading(true)
      const downloadUrl = `/download-excel?lots=${lots}&date=${date}&id=${id}&type=deal-cashflow-returns`
      const response = await axiosInstance.get(downloadUrl, {
        responseType: "blob",
      })

      console.log(response.headers)
      const contentDispositionHeader = response.headers["content-disposition"]
      const match = contentDispositionHeader.split("filename=")
      console.log(match)
      const filename = match ? match[1] : "download.xlsx"
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const downloadLink = document.createElement("a")
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = filename
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  // Render on UI
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>
            Earnnest
            {selectedInvestment?.id
              ? ` ${selectedInvestment?.project_name} - Cashflow & Returns`
              : "Admin"}
          </title>
        </MetaTags>
        {accessControlData?.permissions
          ?.DISTRIBUTOR_PORTAL_DEAL_CASHFLOW_RETURNS_VIEW ||
        accessControlData?.permissions
          ?.SALES_AND_INVESTOR_SERVICE_DEAL_CASHFLOW_RETURNS_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem="Deal Cashflow & Returns" />
            <Row>
              <Col xs="12">
                {/* Element will only show on print */}
                <Row className="justify-content-between print d-none">
                  <Col>
                    <h4 className="my-1 fw-bold">
                      {selectedInvestment.project_name}{" "}
                      <span className="fs-5">
                        (ISIN:{" "}
                        <span className="earnnest-green">
                          <u>{selectedInvestment.isin_number}</u>
                        </span>
                        )
                      </span>
                    </h4>
                    <p className="text-nowrap">
                      Indicative Cash Flow for {selectedLotSize} debentures
                      dated {moment(selectedDate).format("DD-MMMM-YYYY")}
                    </p>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <img className="w-50 align-self-center" src={logo}></img>
                  </Col>
                </Row>
                <Card>
                  <CardBody id="card-body">
                    {/* Element will be hidden on print */}
                    <Row className="mb-3 hide-on-print align-items-center">
                      <Col lg={8} xl={9}>
                        <Row className="flex-wrap">
                          <Col xs={12} md={4}>
                            <div className="mb-3">
                              <label>Project Name</label>
                              <ReactSelect
                                users={investments}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "projectName")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["project_name"]}
                              />
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-4">
                              <Label>Date</Label>
                              <Input
                                name="date"
                                type="date"
                                value={selectedDate}
                                min={
                                  accessControlData?.permissions
                                    ?.SALES_AND_INVESTOR_SERVICE_DEAL_CASHFLOW_RETURNS_MIN_DATE
                                    ? null
                                    : minDate
                                }
                                max={
                                  accessControlData?.permissions
                                    ?.SALES_AND_INVESTOR_SERVICE_DEAL_CASHFLOW_RETURNS_MAX_DATE
                                    ? null
                                    : maxDate
                                }
                                onChange={handleDate}
                                disabled={
                                  !selectedInvestment?.id ? true : false
                                }
                                className="custom-input-height"
                              />
                            </div>
                          </Col>
                          <Col>
                            <div>
                              <Label>No. of Bonds</Label>
                              <Input
                                name="lotSize"
                                type="number"
                                value={selectedLotSize}
                                onChange={handleLotSize}
                                disabled={
                                  !selectedInvestment?.id ? true : false
                                }
                                pattern="^\d+$"
                                step={1}
                                className="custom-input-height"
                              />
                              {selectedInvestment?.id &&
                                selectedLotSize < 1 && (
                                  <div className="invalid-feedback position-absolute d-inline-block">
                                    Please select at least one bond
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="mt-md-2 pt-1">
                        <div className="align-items-center justify-content-end d-flex gap-1 col-auto">
                          <div className="p-0 text-center">
                            {accessControlData?.permissions
                              ?.SALES_AND_INVESTOR_SERVICE_DEAL_CASHFLOW_RETURNS_DOWNLOAD_EXCEL ||
                            accessControlData?.permissions
                              ?.DISTRIBUTOR_PORTAL_DEAL_CASHFLOW_RETURNS_DOWNLOAD_EXCEL ? (
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded mb-2"
                                onClick={() =>
                                  downloadExcel(
                                    selectedInvestment?.id,
                                    selectedLotSize,
                                    selectedDate
                                  )
                                }
                                style={{ marginRight: "3px" }}
                                disabled={
                                  !selectedInvestment?.id ? true : false
                                }
                              >
                                <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                Excel
                              </Button>
                            ) : null}
                          </div>
                          <div className="p-0 text-center">
                            {accessControlData?.permissions
                              ?.DISTRIBUTOR_PORTAL_DEAL_CASHFLOW_RETURNS_DOWNLOAD_PDF ||
                            accessControlData?.permissions
                              ?.SALES_AND_INVESTOR_SERVICE_DEAL_CASHFLOW_RETURNS_DOWNLOAD_PDF ? (
                              <Button
                                type="button"
                                color="primary"
                                className="btn-rounded mb-2"
                                onClick={() => window.print()}
                                style={{ marginRight: "3px" }}
                                disabled={
                                  !selectedInvestment?.id ? true : false
                                }
                              >
                                <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                PDF
                              </Button>
                            ) : null}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {tableData.length ? (
                      <ToolkitProvider
                        keyField="id"
                        data={tableData}
                        columns={repaymentColumns()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <BootstrapTable
                                  keyField="id"
                                  id="bold-last-child"
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  data={tableData}
                                  columns={repaymentColumns()}
                                  wrapperClasses={"table-responsive"}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </Col>
                            </Row>
                            <div className="d-flex gap-4">
                              <div className="d-flex justify-content-center align-items-center px-2 py-1 border border-dark w-50 mb-3 flex-wrap gap-2">
                                <div className="text-center py-3">
                                  <h5 className="d-block fw-bold">IRR</h5>
                                  <p className="m-0 font-size-15">
                                    {investmentCalcData?.annual_return}%
                                  </p>
                                </div>
                                <div className="text-center py-3 px-4">
                                  <h5 className="d-block fw-bold">Multiple</h5>
                                  <p className="m-0 font-size-15">
                                    {selectedInvestment?.multiple}x
                                  </p>
                                </div>
                                <div className="text-center py-3">
                                  <h5 className="d-block fw-bold">
                                    Profit Amount
                                  </h5>
                                  <p className="m-0 font-size-15">
                                    ₹
                                    {Math.round(
                                      parseInt(
                                        investmentCalcData?.profit_amount
                                      )
                                    ).toLocaleString("en-IN")}
                                  </p>
                                </div>
                              </div>
                              <div className="w-50 px-2 py-1 border border-dark mb-3">
                                <p className="m-0">
                                  <div>
                                    <b>*</b>Breakup of the Investment Amount is
                                    as follows:
                                  </div>
                                  <div>
                                    Principal: &#8377;
                                    {Math.round(
                                      parseInt(
                                        investmentCalcData?.called_face_value
                                      ) -
                                        parseInt(
                                          investmentCalcData?.redeemed_face_value
                                        ) <
                                        0
                                        ? "(" +
                                            parseInt(
                                              investmentCalcData?.called_face_value
                                            ) -
                                            parseInt(
                                              investmentCalcData?.redeemed_face_value
                                            ) *
                                              parseInt(selectedLotSize) +
                                            ")"
                                        : (parseInt(
                                            investmentCalcData?.called_face_value
                                          ) -
                                            parseInt(
                                              investmentCalcData?.redeemed_face_value
                                            )) *
                                            parseInt(selectedLotSize)
                                    )?.toLocaleString("en-IN")}
                                  </div>
                                  <div>
                                    {" "}
                                    Accrued Interest: &#8377;
                                    {parseInt(
                                      investmentCalcData?.accrued_interest
                                    ) < 0
                                      ? `(${Math.round(
                                          Math.abs(
                                            parseInt(
                                              investmentCalcData?.accrued_interest
                                            ) * parseInt(selectedLotSize)
                                          )
                                        ).toLocaleString("en-IN")})`
                                      : Math.round(
                                          parseInt(
                                            investmentCalcData?.accrued_interest
                                          ) * parseInt(selectedLotSize)
                                        )?.toLocaleString("en-IN")}
                                  </div>
                                  <div>
                                    {" "}
                                    Premium/(Discount): &#8377;
                                    {parseInt(investmentCalcData?.premium) < 0
                                      ? `(${Math.round(
                                          Math.abs(
                                            parseInt(
                                              investmentCalcData?.premium
                                            ) * parseInt(selectedLotSize)
                                          )
                                        ).toLocaleString("en-IN")})`
                                      : Math.round(
                                          parseInt(
                                            investmentCalcData?.premium
                                          ) * parseInt(selectedLotSize)
                                        )?.toLocaleString("en-IN")}
                                  </div>
                                </p>
                              </div>
                            </div>
                            <p className="">
                              {selectedInvestment?.cashflow_notes
                                ? parse(selectedInvestment?.cashflow_notes)
                                : null}
                            </p>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    ) : (
                      <Row>
                        <Col lg="12">
                          <div className="text-center">
                            <Row className="justify-content-center">
                              <Col sm="4">
                                <div className="maintenance-img">
                                  <img
                                    src={nodata}
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <h4 className="mt-5">Please select a Project</h4>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default DealCashflowReturns
