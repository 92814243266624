import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  csvDownloadData,
  humanize,
  investmentData,
} from "../../constants/common"
import { JSONToCSVConvertor } from "common/jsontocsv"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import Unauthorized from "common/Unauthorized"
import ReactSelect from "constants/ReactSelect"
import { permissionChecker } from "common/PermissionChecker"

const BondInventoryManagement = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [selectedInvestment, setSelectedInvestment] = useState()
  const [selectedInvestmentLot, setSelectedInvestmentLot] = useState()
  const [investmentList, setInvestmentList] = useState([])
  const [orderList, setOrderList] = useState()
  const [invLotList, setInvLotList] = useState([])

  const [blockedLots, setBlockedLots] = useState()
  const [manualSoldLots, setManualSoldLots] = useState()
  const [minLot, setMinLot] = useState()
  const [maxLot, setMaxLot] = useState()

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const { SearchBar } = Search

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/bond-inventory-management?$sort[created_at]=-1`
      )
      const invResponse = await investmentData()
      if (response) {
        setData(response?.data?.data || response.data)
      }
      if (invResponse) {
        setInvestmentList(invResponse?.data?.data || invResponse.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    masterData()
  }, [])

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }

  const DealTranchesColumns = toggleModal => [
    {
      dataField: "project_name",
      text: "Project Name",
      sort: true,
    },
    {
      dataField: "investment_lot_name",
      text: "Investment Lot Name",
      sort: true,
    },
    {
      dataField: "investment_lot_no",
      text: "Investment Lot No.",
      sort: true,
    },
    {
      dataField: "open_status",
      text: "Open Status",
      sort: true,
    },
    {
      dataField: "disbursement_date",
      text: "Disbursement Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent = false, row) =>
        handleValidDate(row.disbursement_date, false),
    },
    {
      dataField: "lot_size",
      text: "Total No. of Debentures",
      sort: true,
    },
    {
      dataField: "stf.transferred",
      text: "Transferred",
      sort: true,
    },
    {
      dataField: "stf.transfer_initiated",
      text: "Transfer Initiated",
      sort: true,
    },

    {
      dataField: "stf.transfer_failed",
      text: "Transferred Failed",
      sort: true,
    },
    {
      dataField: "stf.signed",
      text: "STF Signed",
      sort: true,
    },
    {
      dataField: "stf.sent_for_sign",
      text: "STF Sent For Sign",
      sort: true,
    },
    {
      dataField: "stf.created",
      text: "STF Created",
      sort: true,
    },
    {
      dataField: "manual_sold_lots",
      text: "Total Sold Debentures (Manual)",
      sort: true,
    },
    {
      dataField: "completed",
      text: "Completed Investment",
      sort: true,
    },
    {
      dataField: "partiallyPaid",
      text: "Partially Paid",
      sort: true,
    },
    {
      dataField: "awaitingManualTransfer",
      text: "Awaiting Manual Transfer",
      sort: true,
    },
    {
      dataField: "blocked_lots",
      text: "Blocked Debentures",
      sort: true,
    },
    {
      dataField: "maxBondPerTransaction",
      text: "Maximum Bond Per Transaction",
      sort: true,
    },
    {
      dataField: "minBondPerTransaction",
      text: "Minimum Bond Per Transaction",
      sort: true,
    },
    {
      dataField: "maxBondPerTransaction",
      text: "Maximum Bond Per Transaction",
      sort: true,
    },
    {
      dataField: "id",
      text: "Investment Lot Id",
      sort: true,
    },
  ]

  const toggle = () => {
    setModal(!modal)
  }

  const handleValidOrderSubmit = async (e, values) => {
    if (!selectedInvestment?.id) {
      toast.error("Select a project")
      return
    }
    if (!selectedInvestmentLot?.id) {
      toast.error("Select Investment Lot")
      return
    }
    if (Number(values.blocked_lots) > Number(orderList.lot_size)) {
      toast.error("Blocked lots should be smaller then no. of lots")
      return
    }
    const newOrder = {
      investmentId: selectedInvestment.id,
      blocked_lots: values.blocked_lots || 0,
      min_lot: values.min_lot || 0,
      max_lot: values.max_lot || 0,
      manual_sold_lots: values.manual_sold_lots || 0,
    }
    try {
      setLoading(true)
      const response = await axiosInstance.patch(
        `bond-inventory-management/${selectedInvestmentLot.id}`,
        newOrder
      )
      if (response) {
        masterData()
        toast.success("Successfully Edited")
        toggle()
      }
    } catch (error) {
      const msg =
        humanize(error?.response?.data?.errors[0]?.message) || error.message
      toast.error(msg)
    } finally {
      setLoading(false)
    }
  }
  let downloadableArr = []

  let arr
  if (data) {
    let csvTableHeaders = DealTranchesColumns()
    var str = JSON.stringify(data)
    arr = JSON.parse(str)
    downloadableArr = csvDownloadData(csvTableHeaders, arr)
  }

  const handleOrderClicks = () => {
    setSelectedInvestment()
    setSelectedInvestmentLot()
    setInvLotList([])
    setOrderList()
    setBlockedLots()
    setManualSoldLots()
    setMinLot()
    setMaxLot()
    toggle()
  }

  const handleValidDate = (date, cellContent) => {
    const date1 = moment(date).format(
      cellContent ? "DD MMM Y hh:mm a" : "DD MMM Y"
    )
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const handleInvestmentChange = async inv => {
    try {
      setSelectedInvestment(inv)
      setSelectedInvestmentLot(null)
      setInvLotList([])
      setOrderList()
      setBlockedLots(0)
      setManualSoldLots(0)
      setMinLot(inv.min_lot)
      setMaxLot(inv.max_lot)
      setLoading(true)
      const response = await axiosInstance.get(
        `/investment-lot?$sort[created_at]=-1&investmentId=${inv.id}`
      )
      const data = response?.data?.data || response.data
      if (response) {
        setInvLotList(
          data.map(item => {
            if (item.status) {
              return {
                ...item,
                investment_lot_name: item.investment_lot_name + " (Active)",
              }
            }
            return item
          })
        )
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleInvestmentLotChange = async invLot => {
    try {
      setSelectedInvestmentLot(invLot)
      setLoading(true)
      const response = await axiosInstance.get(
        `/bond-inventory-management/${invLot.id}`
      )
      if (response) {
        const resData = response?.data?.data || response.data
        setOrderList(resData)
        setBlockedLots(resData?.blocked_lots)
        setManualSoldLots(resData?.manual_sold_lots)
        setMinLot(resData?.min_lot)
        setMaxLot(resData?.max_lot)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(async () => {
    if (selectedInvestmentLot) {
      try {
        setLoading(true)
        let getUrl = `?blocked_lots=${blockedLots}&manual_sold_lots=${manualSoldLots}&min_lot=${minLot}&max_lot=${maxLot}`

        const response = await axiosInstance.get(
          `/bond-inventory-management/${selectedInvestmentLot.id}${getUrl}`
        )
        if (response) {
          const resData = response?.data?.data || response.data
          setOrderList(resData)
        }
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    }
  }, [blockedLots, manualSoldLots, minLot, maxLot])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.BOND_INVENTORY_MANAGEMENT_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={data}
                      columns={DealTranchesColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <Row className="justify-content-end">
                                <div className="col-auto">
                                  {accessControlData?.permissions
                                    ?.BOND_INVENTORY_MANAGEMENT_ADD_UPDATE && (
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={handleOrderClicks}
                                    >
                                      <i className="mdi mdi-plus me-1" />
                                      {
                                        accessControlData?.permissions
                                          ?.BOND_INVENTORY_MANAGEMENT_ADD_UPDATE
                                      }
                                    </Button>
                                  )}
                                  {accessControlData?.permissions
                                    ?.BOND_INVENTORY_MANAGEMENT_DOWNLOAD && (
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={() =>
                                        JSONToCSVConvertor(
                                          downloadableArr,
                                          "Investment Lot",
                                          true
                                        )
                                      }
                                      style={{ marginRight: "3px" }}
                                    >
                                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                      {
                                        accessControlData?.permissions
                                          ?.BOND_INVENTORY_MANAGEMENT_DOWNLOAD
                                      }
                                    </Button>
                                  )}
                                </div>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            {/* {
    "sent_for_sign": 0,
    "signed": 0,
    "created": 0,
    "not_created": 0,
    "transfer_not_initiated": 0,
    "transfer_initiated": 0,
    "transferred": 0,
    "transfer_failed": 0
} */}
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                columns={DealTranchesColumns(toggle)}
                                data={data}
                                pagination={paginationFactory(pageOptions)}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal
                                isOpen={modal}
                                toggle={toggle}
                                className="modal-lg"
                              >
                                <ModalHeader toggle={toggle} tag="h4">
                                  Manage Bond Inventory
                                </ModalHeader>
                                <ModalBody>
                                  {loading && <Loader />}
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row>
                                      <div className="mb-3 col-md-6">
                                        <label>Project Name</label>
                                        <ReactSelect
                                          users={investmentList}
                                          setSelectedOption={
                                            handleInvestmentChange
                                          }
                                          selectedOption={selectedInvestment}
                                          multiOptionLabel={true}
                                          optionLabelKeys={["project_name"]}
                                        />
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <label>Investment Lot</label>
                                        <ReactSelect
                                          isDisabled={!selectedInvestment?.id}
                                          users={invLotList}
                                          setSelectedOption={
                                            handleInvestmentLotChange
                                          }
                                          selectedOption={selectedInvestmentLot}
                                          multiOptionLabel={true}
                                          optionLabelKeys={[
                                            "investment_lot_name",
                                            "disbursement_date",
                                          ]}
                                        />
                                      </div>
                                    </Row>
                                    <Row>
                                      {selectedInvestmentLot ? (
                                        <>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="lot_size"
                                              label="Total No. of Bonds"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList
                                                  ? orderList?.lot_size == 0
                                                    ? "0"
                                                    : orderList?.lot_size
                                                  : "0"
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="balance"
                                              label="Available Debentures"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList
                                                  ? orderList?.balance == 0
                                                    ? "0"
                                                    : orderList?.balance
                                                  : "0"
                                              }
                                              disabled={true}
                                              style={{
                                                color: "#006400",
                                                fontWeight: "600",
                                              }}
                                            ></AvField>
                                          </div>
                                          <hr />
                                          <h5 className="mb-3 fw-bold">
                                            Investment Status
                                          </h5>
                                          <div className="mb-3 col-md-4">
                                            <AvField
                                              name="completed"
                                              label="Sold (Completed)"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList
                                                  ? orderList?.completed == 0
                                                    ? "0"
                                                    : orderList?.completed
                                                  : "0"
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-4">
                                            <AvField
                                              name="partiallyPaid"
                                              label="Partially Paid"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList
                                                  ? orderList?.partiallyPaid ==
                                                    0
                                                    ? "0"
                                                    : orderList?.partiallyPaid
                                                  : "0"
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-4">
                                            <AvField
                                              name="awaitingManualTransfer"
                                              label="Awaiting Manual Transfer"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList
                                                  ? orderList?.awaitingManualTransfer ==
                                                    0
                                                    ? "0"
                                                    : orderList?.awaitingManualTransfer
                                                  : "0"
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <hr />
                                          <h5 className="mb-3 fw-bold">
                                            STF Status
                                          </h5>
                                          <div className="mb-3 col-md-4">
                                            <AvField
                                              name="stfSigned"
                                              label="STF Signed"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList
                                                  ? orderList?.stf?.signed == 0
                                                    ? "0"
                                                    : orderList?.stf?.signed
                                                  : "0"
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-4">
                                            <AvField
                                              name="stfSentForSign"
                                              label="STF Sent For Sign"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList
                                                  ? orderList?.stf
                                                      ?.sent_for_sign == 0
                                                    ? "0"
                                                    : orderList?.stf
                                                        ?.sent_for_sign
                                                  : "0"
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-4">
                                            <AvField
                                              name="stfCreated"
                                              label="STF Created"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList
                                                  ? orderList?.stf?.created == 0
                                                    ? "0"
                                                    : orderList?.stf?.created
                                                  : "0"
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <hr />
                                          <h5 className="mb-3 fw-bold">
                                            Transfer Status
                                          </h5>
                                          <div className="mb-3 col-md-4">
                                            <AvField
                                              name="Transferred"
                                              label="Transferred"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList
                                                  ? orderList?.stf
                                                      ?.transferred == 0
                                                    ? "0"
                                                    : orderList?.stf
                                                        ?.transferred
                                                  : "0"
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-4">
                                            <AvField
                                              name="TransferInitiated"
                                              label="Transfer Initiated"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList
                                                  ? orderList?.stf
                                                      ?.transfer_initiated == 0
                                                    ? "0"
                                                    : orderList?.stf
                                                        ?.transfer_initiated
                                                  : "0"
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-4">
                                            <AvField
                                              name="stfSentForSign"
                                              label="Transfer Failed"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList
                                                  ? orderList?.stf
                                                      ?.transfer_failed == 0
                                                    ? "0"
                                                    : orderList?.stf
                                                        ?.transfer_failed
                                                  : "0"
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <hr />
                                          {/* <div className="mb-3 col-md-6">
                                            <AvField
                                              name="sold"
                                              label="Total Debentures Sold"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList
                                                  ? orderList?.sold == 0
                                                    ? "0"
                                                    : orderList?.sold
                                                  : "0"
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div> */}
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="minBondPerTransaction"
                                              label="Minimum Bonds per Transaction"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList
                                                  ? orderList?.minBondPerTransaction ==
                                                    0
                                                    ? "0"
                                                    : orderList?.minBondPerTransaction
                                                  : "0"
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="maxBondPerTransaction"
                                              label="Maximum Bonds per Transaction"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList
                                                  ? orderList?.maxBondPerTransaction ==
                                                    0
                                                    ? "0"
                                                    : orderList?.maxBondPerTransaction
                                                  : "0"
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                        </>
                                      ) : null}
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="blocked_lots"
                                          label="No. of Blocked Debentures"
                                          type="number"
                                          errorMessage="Invalid value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            blockedLots === 0
                                              ? "0"
                                              : blockedLots
                                          }
                                          onChange={e =>
                                            setBlockedLots(e.target.value)
                                          }
                                        ></AvField>
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="manual_sold_lots"
                                          label="Total Sold Debentures (Manual)"
                                          type="number"
                                          errorMessage="Invalid value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            manualSoldLots === 0
                                              ? "0"
                                              : manualSoldLots
                                          }
                                          onChange={e =>
                                            setManualSoldLots(e.target.value)
                                          }
                                        ></AvField>
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="min_lot"
                                          label="Minimum No. of Bonds"
                                          type="number"
                                          errorMessage="Invalid value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={minLot === 0 ? "0" : minLot}
                                          onChange={e =>
                                            setMinLot(e.target.value)
                                          }
                                        ></AvField>
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="max_lot"
                                          label="Maximum No. of Bonds"
                                          type="number"
                                          errorMessage="Invalid value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={maxLot === 0 ? "0" : maxLot}
                                          onChange={e =>
                                            setMaxLot(e.target.value)
                                          }
                                        ></AvField>
                                      </div>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="d-flex justify-content-end">
                                          {orderList?.balance < 0 && (
                                            <p className="text-danger m-0 me-auto">
                                              Invalid value for Available
                                              Debentures
                                            </p>
                                          )}
                                          <button
                                            disabled={orderList?.balance < 0}
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(BondInventoryManagement)
