import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  csvDownloadData,
  humanize,
  investmentData,
} from "../../constants/common"
import { JSONToCSVConvertor } from "common/jsontocsv"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import Unauthorized from "common/Unauthorized"
import ReactSelect from "constants/ReactSelect"
import { permissionChecker } from "common/PermissionChecker"

const InvestmentLot = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedInvestment, setSelectedInvestment] = useState()
  const [investmentList, setInvestmentList] = useState([])
  const [orderList, setOrderList] = useState()

  const [selectedFeeStructure, setSelectedFeeStructure] = useState()
  const [addendumError, setAddendumError] = useState(false)

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const { SearchBar } = Search

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/investment-lot?$sort[created_at]=-1`
      )
      const invResponse = await investmentData()
      if (response) {
        setData(response?.data?.data || response.data)
      }
      if (invResponse) {
        setInvestmentList(invResponse?.data?.data || invResponse.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    masterData()
  }, [])

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }

  const DealTranchesColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          {accessControlData?.permissions?.INVESTMENT_LOT_EDIT ? (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            </div>
          ) : (
            "N/A"
          )}
        </>
      ),
    },
    {
      dataField: "investment.project_name",
      text: "Project Name",
      sort: true,
    },
    {
      dataField: "investment_lot_name",
      text: "Investment Lot Name",
      sort: true,
    },
    {
      dataField: "investment_lot_no",
      text: "Investment Lot No.",
      sort: true,
    },
    {
      dataField: "lot_size",
      text: "No. Of Lots",
      sort: true,
    },
    {
      dataField: "blocked_lots",
      text: "Blocked Lots",
      sort: true,
    },
    {
      dataField: "manual_sold_lots",
      text: "Manual Sold Lots",
      sort: true,
    },

    {
      dataField: "status",
      text: "Open Status",
      sort: true,
    },

    {
      dataField: "distributor_upfront_fee_percent",
      text: "Upfront Fee Percent",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{Number(cellContent).toFixed(2)}</div>
      ),
    },
    {
      dataField: "distributor_additional_fee_percent",
      text: "Trail Fee Percent",
      sort: true,
    },
    {
      dataField: "distributor_additional_fee_payment_frequency",
      text: "Trail Fee Payment Frequency",
      sort: true,
    },
    {
      dataField: "distributor_upfront_fee_applicable_date",
      text: "Upfront Fee Applicable Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent = false, row) =>
        row.distributor_upfront_fee_applicable_date
          ? handleValidDate(row.distributor_upfront_fee_applicable_date, false)
          : "",
    },
    {
      dataField: "distributor_trail_fee_applicable_days",
      text: "Trail Fee Start Days",
      sort: true,
    },
    {
      dataField: "distributor_minimum_aggregate_face_value",
      text: "Minimum Business Volume",
      sort: true,
    },

    {
      dataField: "remarks",
      text: "Remarks",
      sort: true,
    },

    {
      dataField: "disbursement_date",
      text: "Disbursement Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent = false, row) =>
        handleValidDate(row.disbursement_date, false),
    },
    {
      dataField: "id",
      text: "Investment Lot Id",
      sort: true,
    },
  ]

  const toggle = () => {
    setModal(!modal)
  }
  const handleOrderClick = arg => {
    const order = arg
    setSelectedInvestment(order.investment)
    setOrderList({
      id: order.id,
      blocked_lots: order.blocked_lots,
      no_of_lots: order.lot_size,
      disbursement_date: order.disbursement_date,
      manual_sold_lots: order.manual_sold_lots,
      investment_lot_name: order.investment_lot_name,
      distributor_upfront_fee_percent: order.distributor_upfront_fee_percent,
      distributor_additional_fee_percent:
        order.distributor_additional_fee_percent,
      distributor_additional_fee_payment_frequency:
        order.distributor_additional_fee_payment_frequency,
      distributor_upfront_fee_applicable_date:
        order.distributor_upfront_fee_applicable_date,
      distributor_trail_fee_applicable_days:
        order.distributor_trail_fee_applicable_days,
      distributor_minimum_aggregate_face_value:
        order.distributor_minimum_aggregate_face_value,
      remarks: order.remarks,
    })

    setIsEdit(true)
    toggle()
  }

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEdit) {
      if (Number(values.blocked_lots) > Number(values.no_of_lots)) {
        setLoading(false)
        toast.error("Blocked lots should be smaller then no. of lots")
        return
      }
      const updateOrder = {
        investmentId: selectedInvestment.id,
        lot_size: values.no_of_lots,
        disbursement_date: values.disbursement_date,
        investment_lot_name: values.investment_lot_name,
        distributor_upfront_fee_percent: values.distributor_upfront_fee_percent,
        distributor_additional_fee_percent:
          values.distributor_additional_fee_percent,
        distributor_additional_fee_payment_frequency:
          values.distributor_additional_fee_payment_frequency,
        distributor_upfront_fee_applicable_date:
          values.distributor_upfront_fee_applicable_date,
        distributor_trail_fee_applicable_days:
          values.distributor_trail_fee_applicable_days,
        distributor_minimum_aggregate_face_value:
          values.distributor_minimum_aggregate_face_value,
        remarks: values.remarks,
      }

      try {
        const response = await axiosInstance.patch(
          `investment-lot/${orderList.id}`,
          updateOrder
        )
        if (response) {
          masterData()
          toast.success("Successfully Edited")
          toggle()
        }
      } catch (error) {
        const msg =
          humanize(error?.response?.data?.errors[0]?.message) || error.message
        toast.error(msg)
      }
    } else {
      if (!selectedInvestment?.id) {
        setLoading(false)
        toast.error("Select a project")
        return
      }
      if (Number(values.blocked_lots) > Number(values.no_of_lots)) {
        setLoading(false)
        console.log(values)
        toast.error("Blocked lots should be smaller then no. of lots")
        return
      }
      const newOrder = {
        investmentId: selectedInvestment.id,
        lot_size: values.no_of_lots,
        disbursement_date: values.disbursement_date,
        investment_lot_name: values.investment_lot_name,
        distributor_upfront_fee_percent: values.distributor_upfront_fee_percent,
        distributor_additional_fee_percent:
          values.distributor_additional_fee_percent,
        distributor_additional_fee_payment_frequency:
          values.distributor_additional_fee_payment_frequency,
        distributor_upfront_fee_applicable_date:
          values.distributor_upfront_fee_applicable_date,
        distributor_trail_fee_applicable_days:
          values.distributor_trail_fee_applicable_days,
        distributor_minimum_aggregate_face_value:
          values.distributor_minimum_aggregate_face_value,
        remarks: values.remarks,
      }

      try {
        const response = await axiosInstance.post(`investment-lot`, newOrder)
        if (response) {
          masterData()
          toast.success("Investment Lot Successfully Added")
          toggle()
        }
      } catch (error) {
        const msg =
          humanize(error?.response?.data?.errors[0]?.message) || error.message
        toast.error(msg)
      }
    }
    setLoading(false)
  }
  let downloadableArr = []

  let arr
  if (data) {
    let csvTableHeaders = DealTranchesColumns()
    var str = JSON.stringify(data)
    arr = JSON.parse(str)
    downloadableArr = csvDownloadData(csvTableHeaders, arr)
  }

  const handleOrderClicks = () => {
    setSelectedInvestment()
    setSelectedFeeStructure()
    setAddendumError(false)
    setOrderList()
    setIsEdit(false)
    toggle()
  }

  const handleValidDate = (date, cellContent) => {
    const date1 = moment(date).format(
      cellContent ? "DD MMM Y hh:mm a" : "DD MMM Y"
    )
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  useEffect(async () => {
    if (selectedFeeStructure === "update_fee_structure" && !isEdit) {
      try {
        setLoading(true)
        const res = await axiosInstance.get(
          `distributor-addendum?investmentId=${selectedInvestment?.id}`
        )
        const activeAddendum = res.data.data.find(el => el.status)
        if (activeAddendum?.id) {
          toast.error(
            "Old Addendums are not deactivated, please deactivate them and try again!"
          )
          setAddendumError(true)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    } else {
      setAddendumError(false)
    }
  }, [selectedFeeStructure])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.INVESTMENT_LOT_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={data}
                      columns={DealTranchesColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <Row className="justify-content-end">
                                <div className="col-auto">
                                  {accessControlData?.permissions
                                    ?.INVESTMENT_LOT_ADD && (
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={handleOrderClicks}
                                    >
                                      <i className="mdi mdi-plus me-1" />
                                      {
                                        accessControlData?.permissions
                                          ?.INVESTMENT_LOT_ADD
                                      }
                                    </Button>
                                  )}
                                  {accessControlData?.permissions
                                    ?.INVESTMENT_LOT_DOWNLOAD && (
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={() =>
                                        JSONToCSVConvertor(
                                          downloadableArr,
                                          "Investment Lot",
                                          true
                                        )
                                      }
                                      style={{ marginRight: "3px" }}
                                    >
                                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                      {
                                        accessControlData?.permissions
                                          ?.INVESTMENT_LOT_DOWNLOAD
                                      }
                                    </Button>
                                  )}
                                </div>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                columns={DealTranchesColumns(toggle)}
                                data={data}
                                pagination={paginationFactory(pageOptions)}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit
                                    ? "Edit Investment Lot"
                                    : "Add Investment Lot"}
                                </ModalHeader>
                                <ModalBody>
                                  {loading && <Loader />}
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <label>Project Name</label>
                                          <ReactSelect
                                            isDisabled={isEdit}
                                            users={investmentList}
                                            setSelectedOption={
                                              setSelectedInvestment
                                            }
                                            selectedOption={selectedInvestment}
                                            multiOptionLabel={true}
                                            optionLabelKeys={["project_name"]}
                                          />
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="investment_lot_name"
                                            label="Investment Lot Name"
                                            type="text"
                                            errorMessage="Invalid value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList?.investment_lot_name ||
                                              ""
                                            }
                                          ></AvField>
                                        </div>
                                        {!isEdit && selectedInvestment?.id ? (
                                          <div className="row mb-3 gap-3">
                                            <div className="col-auto">
                                              <AvField
                                                type="radio"
                                                name="feeOptions"
                                                id="update_fee_structure"
                                                label="Update Fee Structure"
                                                value="update_fee_structure"
                                                checked={
                                                  selectedFeeStructure ===
                                                  "update_fee_structure"
                                                }
                                                style={{
                                                  marginLeft: "10px",
                                                }}
                                                onChange={e => {
                                                  setSelectedFeeStructure(
                                                    e.target.value
                                                  )
                                                }}
                                              />
                                            </div>
                                            <div className="col-auto">
                                              <AvField
                                                type="radio"
                                                name="feeOptions"
                                                label="Same Fee Structure"
                                                id="same_fee_structure"
                                                value="same_fee_structure"
                                                checked={
                                                  selectedFeeStructure ===
                                                  "same_fee_structure"
                                                }
                                                style={{
                                                  marginLeft: "10px",
                                                }}
                                                onChange={e => {
                                                  setSelectedFeeStructure(
                                                    e.target.value
                                                  )
                                                }}
                                              />
                                            </div>
                                          </div>
                                        ) : null}
                                        {(!isEdit &&
                                          selectedFeeStructure &&
                                          selectedInvestment?.id) ||
                                        isEdit ? (
                                          <div className="row">
                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="no_of_lots"
                                                disabled={addendumError}
                                                label="No. of Debentures in this lot"
                                                type="number"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                min={1}
                                                value={
                                                  orderList?.no_of_lots || ""
                                                }
                                              ></AvField>
                                            </div>

                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="disbursement_date"
                                                disabled={addendumError}
                                                label="Disbursement Date"
                                                type="date"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  orderList?.disbursement_date ||
                                                  ""
                                                }
                                              ></AvField>
                                            </div>
                                            {/* additional distributor field */}
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="distributor_upfront_fee_percent"
                                                  disabled={addendumError}
                                                  label="Upfront Fee Percent"
                                                  type="number"
                                                  min={0}
                                                  errorMessage="Invalid Value"
                                                  validate={{
                                                    required: {
                                                      value: true,
                                                    },
                                                  }}
                                                  value={
                                                    orderList
                                                      ? orderList.distributor_upfront_fee_percent ==
                                                        0
                                                        ? "0"
                                                        : orderList.distributor_upfront_fee_percent
                                                      : ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="distributor_additional_fee_percent"
                                                  disabled={addendumError}
                                                  label="Trail Fee Percent"
                                                  type="number"
                                                  errorMessage="Invalid Value"
                                                  validate={{
                                                    required: {
                                                      value: true,
                                                    },
                                                  }}
                                                  min={0}
                                                  value={
                                                    orderList
                                                      ? orderList.distributor_additional_fee_percent ==
                                                        0
                                                        ? "0"
                                                        : orderList.distributor_additional_fee_percent
                                                      : ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="distributor_additional_fee_payment_frequency"
                                                  disabled={addendumError}
                                                  label="Trail Fee Payment Frequency"
                                                  type="select"
                                                  className="form-select"
                                                  errorMessage="Invalid value"
                                                  value={
                                                    orderList?.distributor_additional_fee_payment_frequency ||
                                                    ""
                                                  }
                                                >
                                                  <option disabled value="">
                                                    Select
                                                  </option>
                                                  <option value="NOT_APPLICABLE">
                                                    Not Applicable
                                                  </option>
                                                  <option value="MONTHLY">
                                                    Monthly
                                                  </option>
                                                  <option value="QUARTERLY">
                                                    Quarterly
                                                  </option>
                                                  <option value="HALF_YEARLY">
                                                    Half Yearly
                                                  </option>
                                                  <option value="ANNUALLY">
                                                    Yearly
                                                  </option>
                                                </AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="distributor_upfront_fee_applicable_date"
                                                  disabled={addendumError}
                                                  label="Upfront Fee Applicable Date"
                                                  type="date"
                                                  errorMessage="Invalid value"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    orderList?.distributor_upfront_fee_applicable_date ||
                                                    ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="distributor_trail_fee_applicable_days"
                                                  disabled={addendumError}
                                                  label="Trail Fee Start Days"
                                                  type="number"
                                                  errorMessage="Invalid Value"
                                                  validate={{
                                                    required: {
                                                      value: true,
                                                    },
                                                  }}
                                                  value={
                                                    orderList
                                                      ? orderList.distributor_trail_fee_applicable_days ==
                                                        0
                                                        ? "0"
                                                        : orderList.distributor_trail_fee_applicable_days
                                                      : ""
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="distributor_minimum_aggregate_face_value"
                                                  disabled={addendumError}
                                                  label="Minimum Business Volume"
                                                  type="number"
                                                  errorMessage="Invalid Value"
                                                  min={0}
                                                  validate={{
                                                    required: {
                                                      value: true,
                                                    },
                                                  }}
                                                  value={
                                                    orderList
                                                      ? orderList.distributor_minimum_aggregate_face_value ==
                                                        0
                                                        ? "0"
                                                        : orderList.distributor_minimum_aggregate_face_value
                                                      : ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col">
                                              <div className="mb-3">
                                                <AvField
                                                  name="remarks"
                                                  disabled={addendumError}
                                                  label="Remarks"
                                                  type="text"
                                                  errorMessage="Invalid Value"
                                                  validate={{
                                                    required: {
                                                      value: true,
                                                    },
                                                  }}
                                                  value={
                                                    orderList?.remarks || ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                            disabled={addendumError}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(InvestmentLot)
