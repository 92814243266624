import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { axiosInstance } from "ConfigAxioxinstance"
import PropTypes from "prop-types"
import { toast } from "react-toastify"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Loader from "common/Loader"
import { permissionChecker } from "common/PermissionChecker"
import { useParams, Link } from "react-router-dom"
import BenposProjectCard from "components/Common/BenposProjectCard"
import AuditTrail from "common/AuditTrail/AuditTrail"
import Unauthorized from "common/Unauthorized"
import HeaderWithBackBtn from "components/Common/HeaderWithBackBtn"
import RemarkCard from "../../components/Remark/RemarkCard"

const InterestAndRepaymentDashboard = () => {
  const [loading, setLoading] = useState(false)
  const [reportsData, setReportsData] = useState([])
  const [approversList, setApproversList] = useState([])
  const [accessControlData, setAccessControlData] = useState([])
  const [selectedLogFilter, setSelectedLogFilter] = useState("")
  const [auditTrailModal, setAuditTrailModal] = useState(false)
  const [showApproversData, setShowApproversData] = useState({})

  useEffect(() => {
    const permissions = permissionChecker("/benpos-master")
    setAccessControlData(permissions)
  }, [])

  const params = useParams()
  const { benposdate, investmentId } = params

  const masterData = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(
        `/interest-approval-policy?investmentId=${investmentId}`
      )
      if (response) {
        const interestApprovalData = await axiosInstance.get(
          `/interest-approval?investmentId=${investmentId}`
        )

        const approverData = response?.data?.data || response?.data

        const interestData = interestApprovalData?.data?.data || response?.data

        const combinedData = approverData.map(approver => {
          const matchingApproval = interestData?.find(
            approval =>
              approval.report_type === approver.report_type &&
              approval.modifiedById === approver.userId
          )

          return {
            ...approver,
            approvalStatus: matchingApproval?.status || "PENDING",
          }
        })

        const reportsData = [
          {
            path: `/benpos-master?type=validation&investmentId=${investmentId}&benpos-date=${benposdate}`,
            label: "Validation Report",
          },
          {
            path: `/benpos-master/bond-reconciliation/${investmentId}/${benposdate}`,
            label: "Bond Reconciliation",
          },
          {
            path: `/benpos-master/exception-report/${investmentId}/${benposdate}`,
            label: "Exception Report",
          },
          {
            path: `/benpos-master/tranche-allocation/${investmentId}/${benposdate}`,
            label: "Tranche & TDS Allocation",
          },
          {
            path: `/benpos-master?type=interest&investmentId=${investmentId}&benpos-date=${benposdate}`,
            label: "Interest Calculation",
            policies: combinedData.filter(
              item => item.report_type === "INTEREST_CALCULATION"
            ),
          },
          // {
          //   path: `/benpos-master?type=interest&investmentId=${investmentId}&benpos-date=${benposdate}`,
          //   label: "Bank Transfer File",
          //   policies: combinedData.filter(
          //     item => item.report_type === "BANK_TRANSFER_FILE"
          //   ),
          // },
        ]
        setReportsData(reportsData)

        const approverList = approverData.reduce((acc, item) => {
          const reportType = item?.report_type

          if (!acc[reportType]) {
            acc[reportType] = []
          }

          acc[reportType].push(item)

          return acc
        }, {})

        setApproversList(approverList)
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    masterData()
  }, [])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.BENPOS_VIEW ? (
          <Container fluid>
            <HeaderWithBackBtn title={"Interest Processing"} />
            <hr />
            <BenposProjectCard
              benpos_date={benposdate}
              investmentId={investmentId}
            />
            <Row className="my-4 d-flex align-items-stretch">
              <Col md={7} className="d-flex flex-column">
                <Card style={{ maxHeight: "80vh", overflow: "auto" }}>
                  <CardBody>
                    <h5>Reports</h5>
                    <hr className="my-3" />
                    <div className="d-flex flex-column">
                      {reportsData &&
                        reportsData.map((report, index) => {
                          return (
                            <div
                              key={report.label}
                              className="p-3 border-bottom d-flex justify-content-between align-items-center flex-column"
                            >
                              <div className="d-flex justify-content-between align-items-center w-100">
                                <div className="d-flex align-items-center gap-2">
                                  <span className="bg-light p-2 rounded-2">
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="26px"
                                      fill={"#777"}
                                    >
                                      <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                                    </svg>
                                  </span>
                                  <div className="ms-3">
                                    <h5 className="my-2">{report.label}</h5>
                                    {report.policies &&
                                    report.policies.length ? (
                                      <Link
                                        className="mb-0 text-muted d-inline-flex align-items-center justify-content-between gap-1"
                                        onClick={() =>
                                          setShowApproversData(prev => ({
                                            ...prev,
                                            [index]: !prev[index],
                                          }))
                                        }
                                      >
                                        <span>
                                          {
                                            report.policies.filter(
                                              item =>
                                                item.approvalStatus !==
                                                "APPROVED"
                                            )?.length
                                          }{" "}
                                          Approvals Pending
                                        </span>
                                        <i
                                          className={`bx bx-chevron-${
                                            showApproversData[index]
                                              ? "up"
                                              : "down"
                                          }`}
                                          style={{ fontSize: "20px" }}
                                        ></i>
                                      </Link>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                                <div className="d-flex flex-column gap-2 align-items-end">
                                  {report.policies && report.policies.length ? (
                                    report?.policies?.every(
                                      item => !item.interest_approval
                                    ) ? (
                                      <div className="d-flex align-items-center border rounded border-warning px-2">
                                        <i className="mdi mdi-clock-outline text-warning font-size-17"></i>
                                        <p className="mb-0 ms-1 font-size-12">
                                          Approval Pending
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="d-flex align-items-center border rounded border-success px-2">
                                        <i className="mdi mdi-check-circle-outline text-success font-size-17"></i>
                                        <p className="mb-0 ms-1 font-size-12">
                                          Approved
                                        </p>
                                      </div>
                                    )
                                  ) : (report.label === "Exception Report" ||
                                      report.label ===
                                        "Tranche & TDS Allocation") &&
                                    reportsData
                                      .find(
                                        item =>
                                          item.label === "Interest Calculation"
                                      )
                                      .policies.some(
                                        el => el.approvalStatus === "APPROVED"
                                      ) ? (
                                    <div className="d-flex align-items-center border rounded border-success px-2">
                                      <i className="mdi mdi-check-circle-outline text-success font-size-17"></i>
                                      <p className="mb-0 ms-1 font-size-12">
                                        Completed
                                      </p>
                                    </div>
                                  ) : report.label === "Exception Report" ||
                                    report.label ===
                                      "Tranche & TDS Allocation" ? (
                                    <div className="d-flex align-items-center border rounded border-warning px-2">
                                      <i className="mdi mdi mdi-information-outline text-warning font-size-17"></i>
                                      <p className="mb-0 ms-1 font-size-12">
                                        Action Required
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="d-flex align-items-center border rounded border-secondary px-2">
                                      <i className="mdi mdi-check-circle-outline text-secondary font-size-17"></i>
                                      <p className="mb-0 ms-1 font-size-12">
                                        Generated
                                      </p>
                                    </div>
                                  )}
                                  <div>
                                    <Link
                                      className="d-flex align-items-center"
                                      to={
                                        report.label === "Bank Transfer File"
                                          ? "#"
                                          : report.path
                                      }
                                    >
                                      <p className="mb-0 ms-1 font-size-12">
                                        View Report
                                      </p>
                                      <i
                                        className="mdi mdi-chevron-right"
                                        style={{ fontSize: 16 }}
                                      ></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              {showApproversData[index] && report.policies && (
                                <div className="py-3 w-100">
                                  <div className="mt-1 d-flex justify-content-between align-items-center">
                                    <h6 className="text-muted">
                                      Approval Status
                                    </h6>
                                    <>
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          setSelectedLogFilter(
                                            `current_state.report_type=${report.policies[0].report_type}`
                                          )
                                          setAuditTrailModal(!auditTrailModal)
                                        }}
                                      >
                                        <i
                                          className="mdi mdi-content-paste font-size-20"
                                          id="logsToolTip"
                                        />
                                      </Link>
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="logsToolTip"
                                      >
                                        Logs
                                      </UncontrolledTooltip>
                                    </>
                                  </div>
                                  <div className="">
                                    {report.policies.map(
                                      (policy, policyIndex) => (
                                        <div
                                          key={policy.userId + policyIndex}
                                          className="d-flex justify-content-between align-items-center py-2 border-bottom"
                                        >
                                          <div>
                                            <p className="mb-0 font-size-13">
                                              {policy.user.user_name}
                                            </p>
                                            <p className="mb-0 text-muted small">
                                              {policy.user.role.role_name} (
                                              {policy.user.role.department.replace(
                                                /_/g,
                                                " "
                                              )}
                                              )
                                            </p>
                                          </div>
                                          <div>
                                            <span
                                              className={`badge badge-soft-${
                                                policy.approvalStatus ===
                                                "APPROVED"
                                                  ? "success"
                                                  : policy.approvalStatus ===
                                                    "REJECTED"
                                                  ? "danger"
                                                  : "warning"
                                              } font-size-12`}
                                            >
                                              {policy.approvalStatus}
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          )
                        })}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col
                md={5}
                className="h-100 overflow-hidden"
                style={{ maxHeight: "80vh" }}
              >
                <Card
                  className="h-100"
                  style={{
                    maxHeight: "38vh",
                  }}
                >
                  <CardBody>
                    <h5>Approvers</h5>
                    <hr />
                    {approversList?.INTEREST_CALCULATION?.length &&
                    approversList?.BANK_TRANSFER_FILE?.length ? (
                      <div
                        className="d-flex flex-column mt-3 p-1"
                        style={{ maxHeight: "25vh", overflowY: "auto" }}
                      >
                        <b>Interest Calculation</b>
                        <div className="px-1 pt-1">
                          {approversList?.INTEREST_CALCULATION?.map(
                            (approver, index) => {
                              return (
                                <p
                                  key={approver?.user?.id + index}
                                  className="mb-0 d-flex align-items-center gap-1"
                                >
                                  <i className="mdi mdi-account-outline fs-4"></i>
                                  {approver?.user?.user_name} (
                                  {approver?.user?.role?.role_name})
                                </p>
                              )
                            }
                          )}
                        </div>
                        <b className="mt-2">Bank Transfer File</b>
                        <div className="px-1 pt-1">
                          {approversList?.BANK_TRANSFER_FILE?.map(
                            (approver, index) => {
                              return (
                                <p
                                  key={approver?.user?.id + index}
                                  className="mb-0 d-flex align-items-center gap-1"
                                >
                                  <i className="mdi mdi-account-outline fs-4"></i>
                                  {approver?.user?.user_name} (
                                  {approver?.user?.role?.role_name})
                                </p>
                              )
                            }
                          )}
                        </div>
                      </div>
                    ) : (
                      <>No Approvers Found!</>
                    )}
                  </CardBody>
                </Card>
                <RemarkCard investmentId={investmentId} />
              </Col>
            </Row>

            <AuditTrail
              filterUrl={
                selectedLogFilter +
                `&current_state.benpos_date=${benposdate}&current_state.investmentId=${investmentId}`
              }
              modal={auditTrailModal}
              setModal={setAuditTrailModal}
            />
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

const UserDetails = ({ item }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div>
        <p className="mb-0 font-size-14">{item.user.user_name}</p>
        <p className="font-size-12 text-muted">
          {item.user?.role?.department?.replaceAll("_", " ")}
        </p>
      </div>
      {item.interest_approval?.status ? (
        <p
          className={`mb-0 badge badge-pill font-size-11 badge-soft-${
            item.interest_approval?.status == "APPROVED" ? "success" : "danger"
          }`}
        >
          {item.interest_approval?.status}
        </p>
      ) : (
        <p className={`mb-0 badge badge-pill font-size-11 badge-soft-primary`}>
          PENDING
        </p>
      )}
    </div>
  )
}
UserDetails.propTypes = {
  item: PropTypes.any,
}

export default InterestAndRepaymentDashboard
