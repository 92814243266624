import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { JSONToCSVConvertor } from "common/jsontocsv"
import {
  countryList,
  createFullAdress,
  csvDownloadData,
  indianStates,
} from "constants/common"
import ReactSelect from "constants/ReactSelect"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "components/Common/Breadcrumb"
import { distributorUsers } from "constants/common"
import Loader from "common/Loader"
import { DealManagementAccess } from "common/AccessManagement"
import { AccessId, kycStatusType } from "constants/ConstantFields"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"
const DistributorKyc = () => {
  const [orders, setData] = useState([])
  const [kycStatus, setKycStatus] = useState("")
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [startKyc, setStartKyc] = useState(false)
  const [file, setFile] = useState(null)
  const [users, setUsers] = useState([])
  const [selectedOption, setSelectedOption] = useState({})
  const [panType, setPanType] = useState("")
  const [kycFormState, setKycFormState] = useState({})
  const [currentFileUpload, setCurrentFileUpload] = useState("")

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const masterData = async () => {
    setLoading(true)
    try {
      // const response = await axiosInstance.get(`/users?$sort[updated_at]=-1`)
      const response = await axiosInstance.get(`/distributor-kyc`)

      if (response) {
        setData(response.data?.data || response.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    const usersListRes = await distributorUsers()
    const usersList = usersListRes?.data?.data || usersListRes?.data
    if (usersList) {
      const distributors = usersList.filter(
        user => user.roleId && user.role?.department == "Distributor"
      )
      setUsers(distributors)
      setLoading(false)
    } else {
      toast.error("something went wrong")
      setLoading(false)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [])

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const KYCColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <div className="d-flex gap-3">
          {accessControlData?.permissions?.DISTRIBUTOR_KYC_UPDATE ? (
            <Link
              to="#"
              className="text-success"
              onClick={() => handleOrderClick(order)}
            >
              <Button
                style={{
                  backgroundColor:
                    order.kyc_verification_status == "VERIFIED"
                      ? "green"
                      : "grey",
                }}
              >
                Update
              </Button>
            </Link>
          ) : (
            "NA"
          )}
        </div>
      ),
    },
    {
      dataField: "user.user_email",
      text: "Email",
      sort: true,
    },

    {
      dataField: "user.user_name",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "user.user_phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "kyc_verification_status",
      text: "KYC Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const status = kycStatusType.find(obj => obj.id == cellContent)
        return (
          <span
            className={`badge badge-pill font-size-12 badge-soft-${
              status ? status?.colorClassName : "secondary"
            }`}
          >
            {status?.statusText}
          </span>
        )
      },
    },
    {
      dataField: "name",
      text: "Legal Entity Name",
      sort: true,
    },
    {
      dataField: "pan_number",
      text: "Pan Number",
      sort: true,
    },

    {
      dataField: "pan_type",
      text: "Pan Type",
      sort: true,
    },
    {
      dataField: "pan_document_url",
      text: "Pan Document URL",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() => handleDocList(order?.pan_document_url)}
            >
              {order?.pan_document_url}
            </Link>
          </div>
        </>
      ),
    },
    { dataField: "registered_address", text: "Registered Address", sort: true },
    {
      dataField: "address_line_1",
      text: "Address Line 1",
      sort: true,
    },
    {
      dataField: "address_line_2",
      text: "Address Line 2",
      sort: true,
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
    },
    {
      dataField: "state",
      text: "State",
      sort: true,
    },
    {
      dataField: "zip_code",
      text: "Pin Code",
      sort: true,
    },
    { dataField: "signatory", text: "Authorized Signatory", sort: true },
    {
      dataField: "aadhar_number",
      text: "Aadhar Number",
      sort: true,
    },
    {
      dataField: "aadhar_document_url",
      text: "Aadhar Document URL",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() => handleDocList(order?.aadhar_document_url)}
            >
              {order?.aadhar_document_url}
            </Link>
          </div>
        </>
      ),
    },
    {
      dataField: "gst_number",
      text: "GST Number",
      sort: true,
    },
    {
      dataField: "gst_certificate_url",
      text: "GST Certificate URL",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() => handleDocList(order?.gst_certificate_url)}
            >
              {order?.gst_certificate_url}
            </Link>
          </div>
        </>
      ),
    },
    {
      dataField: "msme_registration_number",
      text: "MSME Registration Number",
      sort: true,
    },
    {
      dataField: "msme_registration_doc",
      text: "MSME Registration Document",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() => handleDocList(order?.msme_registration_doc)}
            >
              {order?.msme_registration_doc}
            </Link>
          </div>
        </>
      ),
    },
    {
      dataField: "bank_name",
      text: "Bank Name",
      sort: true,
    },
    {
      dataField: "branch_name",
      text: "Branch Name",
      sort: true,
    },
    {
      dataField: "ifsc_code",
      text: "IFSC Code",
      sort: true,
    },
    {
      dataField: "account_name",
      text: "Account Name",
      sort: true,
    },
    {
      dataField: "account_number",
      text: "Account Number",
      sort: true,
    },
    {
      dataField: "cancelled_cheque_url",
      text: "Cancelled Cheque URL",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() => handleDocList(order?.cancelled_cheque_url)}
            >
              {order?.cancelled_cheque_url}
            </Link>
          </div>
        </>
      ),
    },
    {
      dataField: "bank_statement_url",
      text: "Bank Statement URL",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() => handleDocList(order?.bank_statement_url)}
            >
              {order?.bank_statement_url}
            </Link>
          </div>
        </>
      ),
    },
    {
      dataField: "rm_name",
      text: "RM Name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        if (row?.user?.relManager?.rm_name)
          return row?.user?.relManager?.rm_name
        else {
          return ""
        }
      },
    },
    {
      dataField: "rm_email",
      text: "RM Email",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        if (row?.user?.relManager?.rm_email)
          return row?.user?.relManager?.rm_email
        else {
          return ""
        }
      },
    },
    {
      dataField: "rm_phone",
      text: "RM Phone",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        if (row?.user?.relManager?.rm_phone)
          return row?.user?.relManager?.rm_phone
        else {
          return ""
        }
      },
    },
    {
      dataField: "created_at",
      text: "Created On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row?.created_at),
    },

    {
      dataField: "updated_at",
      text: "Updated On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row?.updated_at),
    },
  ]

  const setKycUser = e => {
    if (e.id !== selectedOption.id) {
      const selectedKyc = orders.find(order => order.userId == e.id)
      setSelectedOption(e)
      if (selectedKyc) {
        setKycFormState({
          panType: selectedKyc.pan_type,
          panNumber: selectedKyc.pan_number,
          registeredAddress: selectedKyc.registered_address,
          address_line_1: selectedKyc.address_line_1,
          address_line_2: selectedKyc.address_line_2,
          zip_code: selectedKyc.zip_code,
          city: selectedKyc.city,
          state: selectedKyc.state,
          country: selectedKyc.country,
          authorizedSignatory: selectedKyc.signatory,
          aadharNumber: selectedKyc.aadhar_number,
          gstNumber: selectedKyc.gst_number,
          accountName: selectedKyc.account_name,
          ifscCode: selectedKyc.ifsc_code,
          accountNumber: selectedKyc.account_number,
          panFile: selectedKyc.pan_document_url,
          aadharFile: selectedKyc.aadhar_document_url,
          gstCertificateFile: selectedKyc.gst_certificate_url,
          cancelledChequeFile: selectedKyc.cancelled_cheque_url,
          bankStatementFile: selectedKyc.bank_statement_url,
          name: selectedKyc.name,
          branchName: selectedKyc.branch_name,
          bankName: selectedKyc.bank_name,
          kycVerificationStatus: selectedKyc.kyc_verification_status,
          msmeRegistrationNumber: selectedKyc.msme_registration_number,
          msmeDoc: selectedKyc.msme_registration_doc,
        })
      } else {
        setKycFormState({})
      }
      // if (selectedKyc?.kyc_verification_status == "VERIFIED") {
      //   toast.error("KYC Already Verified")
      // }
    }
  }

  const handleDocList = file => {
    handleDoc(file)
  }

  const handleDoc = async file => {
    setLoading(true)
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    const updateOrder = {
      kyc_verification_status:
        values.kyc_verification_status || orderList.kyc_verification_status,
      kyc_notes:
        values.kyc_notes ||
        (orderList.kyc_notes &&
          orderList.kyc_verification_status &&
          values.kyc_verification_status == "RE_SUBMIT")
          ? values.kyc_notes || orderList.kyc_notes
          : null,
    }

    //   // update order
    if (!startKyc) {
      try {
        const response = await axiosInstance.patch(
          `distributor-kyc/${orderList.id}`,
          updateOrder
        )
        if (response) {
          masterData()
          toast.success("Successfully Updated")
          toggle()
        }
      } catch (error) {
        toast.error(error.message)
      }
      setKycStatus("")
    } else if (startKyc) {
      const distributorKycData = {
        pan_type: kycFormState.panType,
        pan_number: kycFormState.panNumber.toUpperCase(),
        pan_document_url: kycFormState.panFile,
        registered_address: createFullAdress(
          kycFormState.address_line_1,
          kycFormState.address_line_2,
          kycFormState.zip_code,
          kycFormState.city,
          kycFormState.state,
          kycFormState.country
        ),
        address_line_1: kycFormState.address_line_1,
        address_line_2: kycFormState.address_line_2,
        zip_code: kycFormState.zip_code,
        city: kycFormState.city,
        state: kycFormState.state,
        country: kycFormState.country,
        signatory: kycFormState.authorizedSignatory,
        aadhar_number: kycFormState?.aadharNumber,
        aadhar_document_url: kycFormState?.aadharFile,
        gst_number: kycFormState?.gstNumber,
        gst_certificate_url: kycFormState?.gstCertificateFile,
        account_name: kycFormState.accountName.toUpperCase(),
        ifsc_code: kycFormState.ifscCode.toUpperCase(),
        account_number: kycFormState.accountNumber,
        cancelled_cheque_url: kycFormState.cancelledChequeFile,
        bank_statement_url: kycFormState.bankStatementFile,
        kyc_verification_status:
          selectedOption?.distributor_kyc?.kyc_verification_status == "VERIFIED"
            ? undefined
            : "IN_VERIFICATION",
        userId: selectedOption?.id,
        name: kycFormState.name.toUpperCase(),
        bank_name: kycFormState.bankName.toUpperCase(),
        branch_name: kycFormState.branchName.toUpperCase(),
        msme_registration_number:
          kycFormState?.msmeRegistrationNumber?.toUpperCase(),
        msme_registration_doc: kycFormState.msmeDoc,
      }
      try {
        const response = selectedOption.distributor_kyc?.id
          ? await axiosInstance.patch(
              `distributor-kyc/${selectedOption.distributor_kyc.id}`,
              distributorKycData
            )
          : await axiosInstance.post(`distributor-kyc`, distributorKycData)
        if (response) {
          masterData()
          toast.success("Successfully Updated")
          toggle()
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    const usersList = await distributorUsers()
    if (usersList) {
      const users = usersList?.data?.data || usersList?.data
      const distributors = users?.filter(
        user => user.roleId && user.role?.department == "Distributor"
      )
      setUsers(distributors)
      // let currentUserInv = users?.data.find(
      //   user => user?.id == selectedOption?.id
      // )
      // setSelectedOption(currentUserInv)
    } else {
      toast.error("something went wrong")
    }
    setLoading(false)
  }

  const toggle = () => {
    setModal(!modal)
  }
  const handleOrderClick = arg => {
    const order = arg

    setOrderList({
      id: order.id,
      kyc_verification_status: order.kyc_verification_status,
      kyc_notes:
        order?.kyc_verification_status === "RE_SUBMIT" ? order.kyc_notes : null,
    })
    setStartKyc(false)
    setKycStatus(order.kyc_verification_status)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  let downladableArr = []

  let arr
  if (orders) {
    let csvTableHeaders = KYCColumns()
    var str = JSON.stringify(orders)
    arr = JSON.parse(str)
    arr.forEach(el => {
      if (el?.user?.relManager?.rm_name) {
        el.rm_name = el?.user?.relManager?.rm_name
      }
      if (el?.user?.relManager?.rm_email) {
        el.rm_email = el?.user?.relManager?.rm_email
      }
      if (el?.user?.relManager?.rm_phone) {
        el.rm_phone = el?.user?.relManager?.rm_phone
      }
    })
    downladableArr = csvDownloadData(csvTableHeaders, arr)
  }
  const handleInitiateKYC = async () => {
    setLoading(true)
    setSelectedOption({})
    setKycFormState({})
    setStartKyc(true)
    setLoading(false)
    toggle()
  }

  const handleFileUpload = e => {
    setFile(e.target.files[0])
    setCurrentFileUpload(e.target.name)
  }

  const handleOnChange = e => {
    const { name, value } = e.target
    switch (name) {
      case "panType":
        if (value == "Person") {
          setKycFormState({
            ...kycFormState,
            panType: value,
            authorizedSignatory: "",
          })
        } else {
          setKycFormState({
            ...kycFormState,
            panType: value,
          })
        }
        break
      case "panNumber":
        setKycFormState({
          ...kycFormState,
          panNumber: value,
        })
        break
      case "registeredAddress":
        setKycFormState({
          ...kycFormState,
          registeredAddress: value,
        })
        break
      case "authorizedSignatory":
        setKycFormState({
          ...kycFormState,
          authorizedSignatory: value,
        })
        break
      case "aadharNumber":
        setKycFormState({
          ...kycFormState,
          aadharNumber: value,
        })
        break
      case "gstNumber":
        setKycFormState({
          ...kycFormState,
          gstNumber: value,
        })
        break
      case "accountName":
        setKycFormState({
          ...kycFormState,
          accountName: value,
        })
        break
      case "ifscCode":
        setKycFormState({
          ...kycFormState,
          ifscCode: value,
        })
        break
      case "accountNumber":
        setKycFormState({
          ...kycFormState,
          accountNumber: value,
        })
        break
      case "legalEntityName":
        setKycFormState({
          ...kycFormState,
          name: value,
        })
        break
      case "bankName":
        setKycFormState({
          ...kycFormState,
          bankName: value,
        })
        break
      case "branchName":
        setKycFormState({
          ...kycFormState,
          branchName: value,
        })
        break
      case "kycVerificationStatus":
        setKycFormState({
          ...kycFormState,
          kycVerificationStatus: value,
        })
      case "msmeRegistrationNumber":
        setKycFormState({
          ...kycFormState,
          msmeRegistrationNumber: value,
        })
        break
      case "address_line_1":
        setKycFormState({
          ...kycFormState,
          address_line_1: value,
        })
        break
      case "address_line_2":
        setKycFormState({
          ...kycFormState,
          address_line_2: value,
        })
        break
      case "city":
        setKycFormState({
          ...kycFormState,
          city: value,
        })
        break
      case "state":
        setKycFormState({
          ...kycFormState,
          state: value,
        })
        break
      case "zip_code":
        setKycFormState({
          ...kycFormState,
          zip_code: value,
        })
        break
      case "country":
        setKycFormState({
          ...kycFormState,
          country: value,
        })
        break
      default:
        break
    }
  }

  useEffect(async () => {
    if (file) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      formData.append("userId", selectedOption?.id)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=user_docs`,
          formData
        )
        if (response) {
          setFileUploadUrl(currentFileUpload, response)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [file])

  const setFileUploadUrl = (currentFile, response) => {
    switch (currentFile) {
      case "panFile":
        setKycFormState({
          ...kycFormState,
          panFile: response?.data?.[0]?.name,
        })
        break
      case "aadharFile":
        setKycFormState({
          ...kycFormState,
          aadharFile: response?.data?.[0]?.name,
        })
        break
      case "gstCertificateFile":
        setKycFormState({
          ...kycFormState,
          gstCertificateFile: response?.data?.[0]?.name,
        })
        break
      case "cancelledChequeFile":
        setKycFormState({
          ...kycFormState,
          cancelledChequeFile: response?.data?.[0]?.name,
        })
        break
      case "bankStatementFile":
        setKycFormState({
          ...kycFormState,
          bankStatementFile: response?.data?.[0]?.name,
        })
        break
      case "msmeDoc":
        setKycFormState({
          ...kycFormState,
          msmeDoc: response?.data?.[0]?.name,
        })
        break
      default:
        break
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.DISTRIBUTOR_KYC_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem="Kyc List" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={KYCColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row justify-content-between">
                            <Col className="col">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <div className="col-auto">
                              {accessControlData?.permissions
                                ?.DISTRIBUTOR_KYC_ADD && (
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded  mb-2 me-2"
                                  onClick={handleInitiateKYC}
                                  style={{ marginRight: "3px" }}
                                >
                                  <i className="mdi mdi-plus me-1" /> Add/Update
                                </Button>
                              )}
                              {accessControlData?.permissions
                                ?.DISTRIBUTOR_KYC_DOWNLOAD && (
                                <Button
                                  type="button"
                                  color="primary"
                                  className="btn-rounded  mb-2 me-2"
                                  onClick={() =>
                                    JSONToCSVConvertor(
                                      downladableArr,
                                      "KYC Data",
                                      true
                                    )
                                  }
                                  style={{ marginRight: "3px" }}
                                >
                                  <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                  Download
                                </Button>
                              )}
                            </div>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                columns={KYCColumns(toggle)}
                                data={orders}
                                pagination={paginationFactory(pageOptions)}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal
                                isOpen={modal}
                                toggle={toggle}
                                className={startKyc ? "modal-lg" : ""}
                              >
                                <ModalHeader toggle={toggle} tag="h4">
                                  {startKyc
                                    ? "Distributor KYC"
                                    : orderList.kyc_verification_status ==
                                      "VERIFIED"
                                    ? "KYC Verified"
                                    : "Update KYC Status"}
                                </ModalHeader>
                                <ModalBody>
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row
                                      form
                                      style={{
                                        color:
                                          orderList.kyc_verification_status ==
                                            "VERIFIED" && !startKyc
                                            ? "green"
                                            : "grey",
                                      }}
                                    >
                                      <Col className="row">
                                        {!startKyc && (
                                          <div className="mb-3">
                                            <AvField
                                              name="kyc_verification_status"
                                              label="Kyc Status"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid Status"
                                              onChange={e =>
                                                setKycStatus(e.target.value)
                                              }
                                              value={
                                                orderList.kyc_verification_status ||
                                                ""
                                              }
                                              validate={{
                                                required: { value: true },
                                              }}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="IN_VERIFICATION">
                                                In Verification
                                              </option>
                                              <option value="VERIFIED">
                                                Verified
                                              </option>
                                              <option value="RE_SUBMIT">
                                                Re Submit
                                              </option>
                                            </AvField>
                                          </div>
                                        )}
                                        {!startKyc &&
                                          kycStatus === "RE_SUBMIT" && (
                                            <div className="mb-3">
                                              <AvField
                                                name="kyc_notes"
                                                label="Kyc Note"
                                                type="textarea"
                                                errorMessage="Invalid Note"
                                                value={
                                                  orderList.kyc_notes || ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                              ></AvField>
                                            </div>
                                          )}
                                        {startKyc && (
                                          <>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <label>Distributor</label>
                                                {selectedOption.id ? (
                                                  <ReactSelect
                                                    users={users}
                                                    selectedOption={{
                                                      user_name:
                                                        selectedOption.user_name,
                                                      email:
                                                        selectedOption.email,
                                                      phone:
                                                        selectedOption.phone,
                                                    }}
                                                    setSelectedOption={
                                                      setKycUser
                                                    }
                                                  />
                                                ) : (
                                                  <ReactSelect
                                                    users={users}
                                                    setSelectedOption={
                                                      setKycUser
                                                    }
                                                  />
                                                )}
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  style={{
                                                    textTransform: "uppercase",
                                                  }}
                                                  name="legalEntityName"
                                                  label="Enter Legal Entity Name"
                                                  type="text"
                                                  errorMessage="Enter a valid input"
                                                  value={
                                                    kycFormState.name || ""
                                                  }
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={handleOnChange}
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="panType"
                                                  label="Pan Type"
                                                  type="select"
                                                  className="form-select"
                                                  errorMessage="Invalid Input"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    kycFormState.panType || ""
                                                  }
                                                  onChange={handleOnChange}
                                                >
                                                  <option value="" disabled>
                                                    Select
                                                  </option>
                                                  <option value="Person">
                                                    Person
                                                  </option>
                                                  <option value="Firm/ Limited Liability Partnership">
                                                    Limited Liability
                                                    Partnership
                                                  </option>
                                                  <option value="Sole Proprietor">
                                                    Sole Proprietor
                                                  </option>
                                                  <option value="Partnership Firm">
                                                    Partnership Firm
                                                  </option>
                                                  <option value="Company">
                                                    Company
                                                  </option>
                                                </AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  style={{
                                                    textTransform: "uppercase",
                                                  }}
                                                  name="panNumber"
                                                  label="Enter PAN"
                                                  type="text"
                                                  errorMessage="Enter a valid input"
                                                  value={
                                                    kycFormState.panNumber || ""
                                                  }
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  maxLength="10"
                                                  onChange={handleOnChange}
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                {loading && <Loader />}
                                                <AvField
                                                  className="form-control"
                                                  type="file"
                                                  id="formFile"
                                                  errorMessage="Please Enter valid input"
                                                  validate={{
                                                    required: {
                                                      value:
                                                        kycFormState.panFile
                                                          ? false
                                                          : true,
                                                    },
                                                  }}
                                                  onChange={handleFileUpload}
                                                  name="panFile"
                                                  label="Upload PAN Card"
                                                ></AvField>
                                                <p>
                                                  {kycFormState.panFile
                                                    ? `File uploaded: ${kycFormState.panFile
                                                        .split("/")
                                                        .pop()}`
                                                    : ""}
                                                </p>
                                              </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                            <div className="mb-3">
                                              <AvField
                                                style={{
                                                  textTransform: "uppercase",
                                                }}
                                                name="registeredAddress"
                                                label="Enter Registered Address"
                                                type="textarea"
                                                errorMessage="Enter a valid input"
                                                value={
                                                  kycFormState.registeredAddress ||
                                                  ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                onChange={handleOnChange}
                                              ></AvField>
                                            </div>
                                          </div> */}
                                            <div className="col-md-6 mb-3">
                                              <AvField
                                                name="address_line_1"
                                                label="Address Line 1"
                                                type="textarea"
                                                errorMessage="Please Enter valid input"
                                                value={
                                                  kycFormState.address_line_1 ||
                                                  ""
                                                }
                                                validate={{
                                                  required: {
                                                    value: true,
                                                  },
                                                }}
                                                onChange={handleOnChange}
                                              ></AvField>
                                            </div>
                                            <Col md="6" className="mb-3">
                                              <AvField
                                                name="address_line_2"
                                                label="Address Line 2"
                                                type="textarea"
                                                value={
                                                  kycFormState.address_line_2 ||
                                                  ""
                                                }
                                                onChange={handleOnChange}
                                              ></AvField>
                                            </Col>
                                            <Col md="6" className="mb-3">
                                              <AvField
                                                name="city"
                                                label="City*"
                                                type="text"
                                                errorMessage="Please Enter valid input"
                                                value={kycFormState.city || ""}
                                                validate={{
                                                  required: {
                                                    value: true,
                                                  },
                                                }}
                                                onChange={handleOnChange}
                                              ></AvField>
                                            </Col>

                                            <Col md="6" className="mb-3">
                                              <AvField
                                                name="state"
                                                label="State*"
                                                type="select"
                                                errorMessage="Please Enter valid input"
                                                value={kycFormState.state || ""}
                                                validate={{
                                                  required: {
                                                    value: true,
                                                  },
                                                }}
                                                onChange={handleOnChange}
                                              >
                                                <option value="" disabled>
                                                  Select
                                                </option>
                                                {indianStates.map(item => (
                                                  <option
                                                    key={item.id}
                                                    value={item.name}
                                                  >
                                                    {item.name}
                                                  </option>
                                                ))}
                                              </AvField>
                                            </Col>
                                            <Col md="6" className="mb-3">
                                              <AvField
                                                name="zip_code"
                                                label="Pin Code*"
                                                type="text"
                                                errorMessage="Please Enter valid input"
                                                value={
                                                  kycFormState.zip_code || ""
                                                }
                                                validate={{
                                                  required: {
                                                    value: true,
                                                  },
                                                }}
                                                onChange={handleOnChange}
                                              ></AvField>
                                            </Col>
                                            <Col md="6" className="mb-3">
                                              <AvField
                                                name="country"
                                                label="Country*"
                                                type="select"
                                                errorMessage="Please Enter valid input"
                                                value={
                                                  kycFormState.country || ""
                                                }
                                                validate={{
                                                  required: {
                                                    value: true,
                                                  },
                                                }}
                                                onChange={handleOnChange}
                                              >
                                                <option value="" disabled>
                                                  Select
                                                </option>
                                                {countryList.map(item => (
                                                  <option
                                                    key={item.id}
                                                    value={item.name}
                                                  >
                                                    {item.name}
                                                  </option>
                                                ))}
                                              </AvField>
                                            </Col>
                                            {kycFormState?.panType ==
                                              "Firm/ Limited Liability Partnership" ||
                                            kycFormState?.panType ==
                                              "Sole Proprietor" ||
                                            kycFormState?.panType ==
                                              "Partnership Firm" ||
                                            kycFormState?.panType ==
                                              "Company" ? (
                                              <div className="col-md-6">
                                                <div className="mb-3">
                                                  <AvField
                                                    style={{
                                                      textTransform:
                                                        "uppercase",
                                                    }}
                                                    name="authorizedSignatory"
                                                    label="Enter Authorized Signatory Name"
                                                    type="text"
                                                    errorMessage="Enter a valid input"
                                                    value={
                                                      kycFormState.authorizedSignatory ||
                                                      ""
                                                    }
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                    onChange={handleOnChange}
                                                  ></AvField>
                                                </div>
                                              </div>
                                            ) : null}
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="aadharNumber"
                                                  label="Enter Aadhar Number (if applicable)"
                                                  type="number"
                                                  errorMessage="Enter a valid input"
                                                  value={
                                                    kycFormState?.aadharNumber ||
                                                    ""
                                                  }
                                                  onChange={handleOnChange}
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                {loading && <Loader />}
                                                <AvField
                                                  className="form-control"
                                                  type="file"
                                                  id="formFile"
                                                  errorMessage="Please Enter valid input"
                                                  onChange={handleFileUpload}
                                                  name="aadharFile"
                                                  label="Upload Aadhar Card"
                                                ></AvField>
                                                <p>
                                                  {kycFormState?.aadharFile
                                                    ? `File uploaded: ${kycFormState?.aadharFile
                                                        .split("/")
                                                        .pop()}`
                                                    : ""}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  style={{
                                                    textTransform: "uppercase",
                                                  }}
                                                  name="gstNumber"
                                                  label="Enter GST Number (if applicable)"
                                                  type="text"
                                                  errorMessage="Enter a valid input"
                                                  value={
                                                    kycFormState?.gstNumber ||
                                                    ""
                                                  }
                                                  onChange={handleOnChange}
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                {loading && <Loader />}
                                                <AvField
                                                  className="form-control"
                                                  type="file"
                                                  id="formFile"
                                                  errorMessage="Please Enter valid input"
                                                  onChange={handleFileUpload}
                                                  name="gstCertificateFile"
                                                  label="Upload GST Certificate"
                                                ></AvField>
                                                <p>
                                                  {kycFormState?.gstCertificateFile
                                                    ? `File uploaded: ${kycFormState?.gstCertificateFile
                                                        .split("/")
                                                        .pop()}`
                                                    : ""}
                                                </p>
                                              </div>
                                            </div>

                                            {/* msme fields */}
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  style={{
                                                    textTransform: "uppercase",
                                                  }}
                                                  name="msmeRegistrationNumber"
                                                  label="Enter MSME Registration Number (if applicable)"
                                                  type="text"
                                                  errorMessage="Enter a valid input"
                                                  value={
                                                    kycFormState?.msmeRegistrationNumber ||
                                                    ""
                                                  }
                                                  onChange={handleOnChange}
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                {loading && <Loader />}
                                                <AvField
                                                  className="form-control"
                                                  type="file"
                                                  id="formFile"
                                                  errorMessage="Please Select a file"
                                                  onChange={handleFileUpload}
                                                  name="msmeDoc"
                                                  label="Upload MSME Registration Document"
                                                ></AvField>
                                                <p>
                                                  {kycFormState?.msmeDoc
                                                    ? `File uploaded: ${kycFormState?.msmeDoc
                                                        .split("/")
                                                        .pop()}`
                                                    : ""}
                                                </p>
                                              </div>
                                            </div>
                                            {/* msme fields end */}

                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  style={{
                                                    textTransform: "uppercase",
                                                  }}
                                                  name="bankName"
                                                  label="Enter Bank Name"
                                                  type="text"
                                                  errorMessage="Enter a valid input"
                                                  value={
                                                    kycFormState.bankName || ""
                                                  }
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={handleOnChange}
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  style={{
                                                    textTransform: "uppercase",
                                                  }}
                                                  name="branchName"
                                                  label="Enter Branch Name"
                                                  type="text"
                                                  errorMessage="Enter a valid input"
                                                  value={
                                                    kycFormState.branchName ||
                                                    ""
                                                  }
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={handleOnChange}
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  style={{
                                                    textTransform: "uppercase",
                                                  }}
                                                  name="accountName"
                                                  label="Enter Beneficiary Name"
                                                  type="text"
                                                  errorMessage="Enter a valid input"
                                                  value={
                                                    kycFormState.accountName ||
                                                    ""
                                                  }
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={handleOnChange}
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  style={{
                                                    textTransform: "uppercase",
                                                  }}
                                                  name="ifscCode"
                                                  label="Enter IFSC Code"
                                                  type="text"
                                                  errorMessage="Enter a valid input"
                                                  value={
                                                    kycFormState.ifscCode || ""
                                                  }
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={handleOnChange}
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="accountNumber"
                                                  label="Enter Account Number"
                                                  type="number"
                                                  errorMessage="Enter a valid input"
                                                  value={
                                                    kycFormState.accountNumber ||
                                                    ""
                                                  }
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={handleOnChange}
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                {loading && <Loader />}
                                                <AvField
                                                  className="form-control"
                                                  type="file"
                                                  id="formFile"
                                                  errorMessage="Please Enter valid input"
                                                  onChange={handleFileUpload}
                                                  name="cancelledChequeFile"
                                                  label="Upload Cancelled Cheque"
                                                ></AvField>
                                                <p>
                                                  {kycFormState.cancelledChequeFile
                                                    ? `File uploaded: ${kycFormState.cancelledChequeFile
                                                        .split("/")
                                                        .pop()}`
                                                    : ""}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                {loading && <Loader />}
                                                <AvField
                                                  className="form-control"
                                                  type="file"
                                                  id="formFile"
                                                  errorMessage="Please Enter valid input"
                                                  onChange={handleFileUpload}
                                                  name="bankStatementFile"
                                                  label="Upload Bank Statement"
                                                ></AvField>
                                                <p>
                                                  {kycFormState.bankStatementFile
                                                    ? `File uploaded: ${kycFormState.bankStatementFile
                                                        .split("/")
                                                        .pop()}`
                                                    : ""}
                                                </p>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      {(!startKyc ||
                                        kycFormState.kycVerificationStatus !=
                                          "") && (
                                        <Col>
                                          <div
                                            className={
                                              !startKyc
                                                ? "text-end mt-3"
                                                : "text-center mt-3"
                                            }
                                          >
                                            <button
                                              type="submit"
                                              className="btn btn-success save-user"
                                              disabled={
                                                loading ||
                                                !(
                                                  !startKyc ||
                                                  kycFormState.kycVerificationStatus !=
                                                    ""
                                                )
                                                  ? true
                                                  : false
                                              }
                                            >
                                              SAVE
                                            </button>
                                          </div>
                                        </Col>
                                      )}
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default DistributorKyc
