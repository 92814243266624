import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Row } from "reactstrap"
import { handleValidDate } from "constants/common"
import { axiosInstance } from "ConfigAxioxinstance"
import Loader from "common/Loader"

const BenposProjectCard = ({
  investmentId,
  benpos_date,
  onLoadComplete = data => {},
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])

  const getInvestmentDetails = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(`/investment?id=${investmentId}`)
      onLoadComplete(response?.data?.data[0])
      setData(response?.data?.data[0] || response?.data[0])
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getInvestmentDetails()
  }, [])

  const fields = [
    { label: "Ex-Interest Date", value: data?.ex_interest_date },
    { label: "Record Date", value: data?.record_date },
    { label: "Benpos Date", value: benpos_date },
    { label: "Interest Payout Date", value: data?.interest_payout_date },
  ]

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <Card>
          <CardBody>
            {data && (
              <Row className="d-flex justify-content-between">
                {data.registered_issuer_name && (
                  <Col md={4} className="text-left">
                    <h4 className="mb-2 fw-bold">{data.project_name}</h4>
                    <p className="mb-1 text-muted">
                      {data.registered_issuer_name}
                    </p>
                  </Col>
                )}
                {fields.map(field => (
                  <Col className="text-left" key={field.label}>
                    <p className="mb-1 text-muted">{field.label}</p>
                    <h5 className="mb-0">
                      {field.value ? handleValidDate(field.value) : "NA"}
                    </h5>
                  </Col>
                ))}
              </Row>
            )}
          </CardBody>
        </Card>
      )}
    </>
  )
}

BenposProjectCard.propTypes = {
  investmentId: PropTypes.string,
  benpos_date: PropTypes.string,
  onLoadComplete: PropTypes.func,
}

export default BenposProjectCard
