import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { Button, Card, CardBody, Col, Container, Row, Input } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import moment from "moment"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const DealUpdates = () => {
  // Loader State
  const [loading, setLoading] = useState(false)

  // Master Data State
  const [tableData, setTableData] = useState([])

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  // SSP State
  const [limit, setLimit] = useState(10)
  const [skip, setSkip] = useState(0)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  // Other States
  const [investmentList, setInvestmentList] = useState([])
  const [investmentFilter, setInvestmentFilter] = useState({})
  //   const [sortBy, setSortBy] = useState({})

  // File Constants
  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const isDist = localUser?.role?.department == "Distributor"
  const isRm = localUser?.role?.department == "Relationship_Manager"

  // Constant Functions
  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ]
  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y")
    return date1
  }

  const handleDoc = async file => {
    setLoading(true)
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      console.log(error.message)
    }
    setLoading(false)
  }

  // Table Columns
  const TableColumns = download => {
    const columns = [
      {
        dataField: "created_at",
        text: "Created On",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleValidDate(row?.created_at),
      },
      {
        dataField: "investment.project_name",
        text: "Project Name",
        sort: true,
      },
      { dataField: "document_name", text: "Document", sort: true },
      {
        dataField: "url",
        text: "Download",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() => handleDoc(row?.url)}
            >
              <svg viewBox="0 0 24 24" width="26px" fill={"#556ee6"}>
                <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
              </svg>
            </Link>
          </div>
        ),
      },
      { dataField: "document_desc", text: "Description", sort: true },
    ]
    return columns
  }

  // Main Function - Fetch Table Data
  const masterData = async () => {
    setTableData([])
    setLoading(true)

    const filterUrl = `${
      investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
    }`
    const getUrl = `/document?documentTypeId=644a12eb-fdc9-49dc-a9eb-8c0abd7e8bda&userId=${
      localUser?.id
    }&type=${
      isDist ? "distributor" : isRm ? "rm" : "admin"
    }&$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`

    try {
      const response = await axiosInstance.get(getUrl)

      if (response) {
        setTotal(response?.data?.total)
        const data = response?.data?.data || response?.data
        setTableData(data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    try {
      const investmentGetUrl =
        `user-investment-filter?$sort[updated_at]=-1` +
        (isDist
          ? `&userType=distributor&distributorId=${localUser?.id}`
          : isRm
          ? "&userType=rm"
          : "&userType=admin")
      const invResponse = await axiosInstance.get(investmentGetUrl)
      if (invResponse) {
        setInvestmentList(invResponse?.data?.data || invResponse?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  // SSP Filters
  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "projectType":
        setInvestmentFilter(e)
        break
      //   case "sortBy":
      //     setSortBy(e)
      //     break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }
  useEffect(() => {
    masterData()
  }, [investmentFilter])

  // Pagination handler - Start
  useEffect(async () => {
    masterData()
  }, [limit, skip])
  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }
  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }
  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.DEAL_UPDATES_REPORT_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem="Deal Updates" />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between">
                  <Col md={2}>
                    <select
                      className="form-select w-75"
                      value={limit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={tableData}
                      columns={TableColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Project Name</label>
                                <ReactSelect
                                  users={investmentList}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "projectType")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["project_name"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                            {/* <Col md={3}>
                            <div className="mb-3">
                              <label>Sort By</label>
                              <ReactSelect
                                users={[
                                  {
                                    id: "-1",
                                    statusText: "Newest First",
                                  },
                                  {
                                    id: "1",
                                    statusText: "Oldest First",
                                  },
                                ]}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "sortBy")
                                }
                                selectedOption={{
                                  id: "-1",
                                  statusText: "Newest First",
                                }}
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col> */}
                          </Row>
                          {/* Table and Modal start */}
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                columns={TableColumns()}
                                data={tableData}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                            </Col>
                          </Row>
                          {/* Table and Modal End */}
                          {/* SSP Pagination Start */}
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}
                 `}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          {/* SSP Pagination End */}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

DealUpdates.propTypes = {
  tableData: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(DealUpdates)
