import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"
import root from ".././config.js"

//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL = ""

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

// Admin Login API call
export async function post(URL, value, config = {}) {
  // return axiosApi
  //   .post(URL, { ...data }, { ...config })
  //   .then(response => response.data)
  const url = process.env.REACT_APP_EARNNEST_DOMAIN + URL
  // const express = require("express")
  // const request = require("request")

  // const app = express()

  // app.use((req, res, next) => {
  //   res.header("Access-Control-Allow-Origin", "*")
  //   next()
  // })

  // // app.get('/jokes/random', (req, res) => {
  // //   request(
  // //     { url: 'https://joke-api-strict-cors.appspot.com/jokes/random' },
  // //     (error, response, body) => {
  // //       if (error || response.statusCode !== 200) {
  // //         return res.status(500).json({ type: 'error', message: err.message });
  // //       }

  // //       res.json(JSON.parse(body));
  // //     }
  // //   )
  // // });

  // const PORT = process.env.REACT_APP_EARNNEST_DOMAIN || 3000
  // app.listen(PORT, () => console.log(`listening on ${PORT}`))

  const data = {
    ...value,
    strategy: "local",
    permission: "ADMIN",
  }
  const response = fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).catch(error => console.log(error))
  return response
}
/****************************************/

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
