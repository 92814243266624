import { AvField, AvForm } from "availity-reactstrap-validation"
import Loader from "common/Loader"
import { axiosInstance } from "ConfigAxioxinstance"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { toast } from "react-toastify"
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap"

const AddRemarkBtn = ({ investmentId, benpos_date, report_type }) => {
  const [loading, setLoading] = useState(false)
  const [remarkModal, setRemarkModal] = useState(false)
  const localUser = JSON.parse(localStorage.getItem("userInfo"))

  const toggleRemark = () => {
    setRemarkModal(!remarkModal)
  }
  const AddRemarkHandler = async (e, values) => {
    try {
      setLoading(true)
      const payload = {
        benpos_date: benpos_date,
        report_type: report_type,
        remark: values.remark,
        investmentId: investmentId,
        createdById: localUser.id,
      }
      const response = await axiosInstance.post("/deal-remarks", payload)
      if (response) {
        toast.success("Remark Added")
      }
      toggleRemark()
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {loading && <Loader />}
      <Button
        type="button"
        color="primary"
        className="btn-rounded mb-2"
        onClick={toggleRemark}
      >
        <i className="mdi mdi-plus"></i> Add Remark
      </Button>
      <Modal isOpen={remarkModal} toggle={toggleRemark}>
        <ModalHeader toggle={toggleRemark} tag="h4">
          Add Remark
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={AddRemarkHandler}>
            <Row form>
              <Col className="col-12 mb-3">
                <AvField
                  name="remark"
                  label="Remark"
                  type="textarea"
                  errorMessage="Invalid value"
                  validate={{
                    required: { value: true },
                  }}
                  rows={5}
                ></AvField>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <Button type="submit" color="success" className="save-user">
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  )
}

AddRemarkBtn.propTypes = {
  investmentId: PropTypes.string,
  benpos_date: PropTypes.string,
  report_type: PropTypes.string,
}

export default AddRemarkBtn
