import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import Loader from "common/Loader"
import { sortingByAlphabet } from "constants/sort"
import ReactSelect from "constants/ReactSelect"
import { usersData, investmentData } from "constants/common"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const PortfolioDocument = () => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [file, setFile] = useState(null)
  const [dp, setDp] = useState("")
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [users, setUsers] = useState([])
  const [investments, setInvestments] = useState([])
  const [selectedOption, setSelectedOption] = useState({})
  const [documentType, setDocumentType] = useState([])
  const [documentSelection, setDocumentSelection] = useState("")
  const [docData, setDocData] = useState()
  const [searchValue, setSearchValue] = useState("")

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const handleDocumentSelection = e => {
    setDocumentSelection(e?.target?.value)
  }
  useEffect(() => {
    if (documentSelection) {
      const docData = documentType.find(item => item?.id == documentSelection)
      setDocData(docData)
    }
  }, [documentSelection])

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/document?$sort[created_at]=-1&documentTypeId[$nin][]=83262ac2-a8b4-463b-b889-c17ad6e0f7e5`
      )

      if (response) {
        setData(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }

    const investments = await investmentData()
    if (investments) {
      setInvestments(investments?.data?.data)
      setLoading(false)
    } else {
      toast.error("something went wrong")
      setLoading(false)
    }
    setLoading(false)
  }

  const handleUserSearch = async e => {
    setSearchValue(e)
    if (e.length >= 3) {
      setLoading(true)
      if (e) {
        const usersRes = await usersData("VERIFIED", e)
        const users = usersRes?.data?.data || usersRes?.data
        if (users) {
          setUsers(users)
        } else {
          toast.error("something went wrong")
        }
      } else {
        setUsers([])
      }
      setLoading(false)
    }
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  const selectRow = {
    mode: "checkbox",
  }

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search

  const PortfolioDocumentColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessControlData?.permissions?.PORTFOLIO_DOCUMENTS_EDIT ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {accessControlData?.permissions?.PORTFOLIO_DOCUMENTS_EDIT}
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
            {accessControlData?.permissions?.PORTFOLIO_DOCUMENTS_DELETE ? (
              <Link
                to="#"
                className="text-danger"
                onClick={() => handleDeleteOrder(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {accessControlData?.permissions?.PORTFOLIO_DOCUMENTS_DELETE}
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },

    {
      dataField: "investment.project_name",
      text: "Project Name",
      sort: true,
    },

    {
      dataField: "user.user_name",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "user.email",
      text: "User Email",
      sort: true,
    },
    {
      dataField: "user.user_pan.name",
      text: "Name on Pan",
      sort: true,
    },

    {
      dataField: "document_type.document_type_name",
      text: "Document Type",
      sort: true,
    },
    {
      dataField: "document_name",
      text: "Document Name",
      sort: true,
    },

    {
      dataField: "url",
      text: "Doc",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() => handleDocList(order?.url)}
            >
              {order.url}
            </Link>
          </div>
        </>
      ),
    },

    {
      dataField: "created_at",
      text: "Created On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },

    {
      dataField: "updated_at",
      text: "Updated On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  const handleDocList = file => {
    handleDoc(file)
  }

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const handleOrderClick = arg => {
    const order = arg
    setSelectedOption({})
    setDocumentSelection("")
    setDocData("")

    setOrderList({
      id: order.id,
      project_name: order.investmentId,
      document_type: order.documentTypeId,
      userId: order.userId,
      url: order.url,
      document_name: order.document_name,
      document_desc: order.document_desc,
      classification: order?.document_type?.classification,
      name: order?.user?.user_name,
      email: order?.user?.email,
      phone: order?.user?.phone,
      name_on_pan: order?.user?.user_pan?.name,
      pan_number: order?.user?.user_pan?.pan_number,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDeleteOrder = async order => {
    setLoading(true)

    var r = confirm(`Are you sure want to delete`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(`document/${order.id}`)
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    setLoading(false)
  }
  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEdit) {
      const updateOrder = {
        investmentId: values.project_name,
        userId: selectedOption.id || orderList?.userId,
        documentTypeId: values.document_type,
        url: dp || orderList.url,
        document_name: values.document_name,
        document_desc: values.document_desc,
      }

      // update order

      try {
        const response = await axiosInstance.patch(
          `document/${orderList.id}`,
          updateOrder
        )
        if (response) {
          masterData()
          toast.success("Successfully Edited")
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      const newOrder = {
        investmentId: values.project_name,
        userId: selectedOption.id,
        documentTypeId: values.document_type,
        url: dp || orderList.url,
        document_name: values.document_name,
        document_desc: values.document_desc,
      }
      // save new order

      try {
        const response = await axiosInstance.post(`document`, newOrder)
        if (response) {
          masterData()
          toast.success(" Resource Successfully Added")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    toggle()
    setLoading(false)
  }

  const handleOrderClicks = () => {
    setSelectedOption({})
    setDocumentSelection("")
    setDocData("")
    setOrderList("")
    setIsEdit(false)
    setUsers([])
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  useEffect(async () => {
    try {
      const response = await axiosInstance.get(
        "document-type?$sort[created_at]=-1&document_type_name[$nin][]=Securities Transfer Form"
      )

      if (response) {
        setDocumentType(response?.data?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }, [])

  const handleFileUpload = e => {
    setFile(e.target.files[0])
  }

  const [investId, setInvestId] = useState("")
  const handleInvestmentId = e => {
    setInvestId(e.target.value)
  }

  useEffect(async () => {
    setLoading(true)
    if (file) {
      const formData = new FormData()
      formData.append("file", file)
      formData.append("investmentId", orderList.project_name || investId)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=investment_doc`,
          formData
        )
        if (response) {
          setDp(response.data[0].name)
        }
      } catch (error) {
        toast.error(error?.message)
      }
    }
    setLoading(false)
  }, [file])

  const handleDoc = async file => {
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.PORTFOLIO_DOCUMENTS_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={PortfolioDocumentColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              {accessControlData?.permissions
                                ?.PORTFOLIO_DOCUMENTS_ADD && (
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleOrderClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add
                                  </Button>
                                </div>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                pagination={paginationFactory(pageOptions)}
                                columns={PortfolioDocumentColumns(toggle)}
                                data={orders}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit
                                    ? "Edit Portfolio Document"
                                    : "Add Portfolio Document"}
                                </ModalHeader>
                                <ModalBody>
                                  {loading && <Loader />}
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <AvField
                                            name="document_type"
                                            label="Document Type"
                                            type="select"
                                            className="form-select"
                                            onChange={handleDocumentSelection}
                                            errorMessage="Invalid value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList.document_type || ""
                                            }
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {sortingByAlphabet(
                                              documentType?.map(
                                                (item, index) => (
                                                  <option
                                                    key={item?.id}
                                                    value={item?.id}
                                                  >
                                                    {item?.document_type_name}
                                                  </option>
                                                )
                                              )
                                            )}
                                          </AvField>
                                        </div>
                                        {(!isEdit &&
                                          docData?.classification == "User") ||
                                        (isEdit &&
                                          orderList.classification == "User" &&
                                          docData?.classification !== "Deal") ||
                                        (isEdit &&
                                          docData?.classification == "User") ? (
                                          <>
                                            {!isEdit ? (
                                              <div className="mb-3">
                                                <label>User</label>
                                                <ReactSelect
                                                  users={users}
                                                  searchValue={searchValue}
                                                  setSearchValue={
                                                    handleUserSearch
                                                  }
                                                  setSelectedOption={
                                                    setSelectedOption
                                                  }
                                                  multiOptionLabel={false}
                                                  optionLabelKeys={[
                                                    "user_pan?.name",
                                                    "email",
                                                    "phone",
                                                    "user_pan?.pan_number",
                                                  ]}
                                                />
                                              </div>
                                            ) : (
                                              <div className="mb-3">
                                                <label>User</label>
                                                <ReactSelect
                                                  users={users}
                                                  selectedOption={{
                                                    user_pan: {
                                                      name: orderList.name_on_pan,
                                                      pan_number:
                                                        orderList.pan_number,
                                                    },
                                                    email: orderList.email,
                                                    phone: orderList.phone,
                                                  }}
                                                  setSelectedOption={
                                                    setSelectedOption
                                                  }
                                                  multiOptionLabel={false}
                                                  optionLabelKeys={[
                                                    "user_pan?.name",
                                                    "email",
                                                    "phone",
                                                    "user_pan?.pan_number",
                                                  ]}
                                                />
                                              </div>
                                            )}
                                          </>
                                        ) : null}
                                        <div className="mb-3">
                                          <AvField
                                            name="project_name"
                                            label="Project Name"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                            sort="true"
                                            onChange={handleInvestmentId}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.project_name || ""}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {sortingByAlphabet(
                                              investments?.map(
                                                (item, index) => (
                                                  <option
                                                    key={item?.project_name}
                                                    value={item?.id}
                                                  >
                                                    {item?.project_name}
                                                  </option>
                                                )
                                              )
                                            )}
                                          </AvField>
                                        </div>

                                        <div className="mb-3">
                                          <AvField
                                            name="document_name"
                                            label="Document Name"
                                            type="text"
                                            errorMessage="Invalid Document Name "
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList.document_name || ""
                                            }
                                          ></AvField>
                                        </div>

                                        <div className="mb-3">
                                          <AvField
                                            name="document_desc"
                                            label="Document Description"
                                            type="textarea"
                                            errorMessage="Invalid Document Description"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList.document_desc || ""
                                            }
                                          ></AvField>
                                        </div>

                                        {orderList?.url ? (
                                          <p
                                            onClick={() =>
                                              handleDoc(orderList.url)
                                            }
                                            style={{
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {orderList.url}
                                          </p>
                                        ) : null}
                                        <div className="mt-3">
                                          <Label
                                            htmlFor="formFile"
                                            className="form-label"
                                          >
                                            {orderList.url
                                              ? "Update document"
                                              : "Upload document"}
                                          </Label>
                                          <Input
                                            className="form-control"
                                            type="file"
                                            id="formFile"
                                            // accept=".pdf"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={handleFileUpload}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end mt-2">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

PortfolioDocument.propTypes = {
  orders: PropTypes.array,
}

export default withRouter(PortfolioDocument)
