import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "components/Common/Breadcrumb"

import Loader from "common/Loader"
import Unauthorized from "common/Unauthorized"
import { DealManagementAccess } from "common/AccessManagement"
import { permissionChecker } from "common/PermissionChecker"

const RolePermission = () => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [role, setRole] = useState([])
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [roleId, setRoleId] = useState(null)
  const [roleDepartment, setRoleDepartment] = useState(null)
  const [kyc, setKyc] = useState("")
  const [userManagement, setUserManagement] = useState("")
  const [support, setSupport] = useState("")
  const [deal, setDeal] = useState("")
  const [transaction, setTransaction] = useState("")
  const [assetManagement, setAssetManagement] = useState("")
  const [distributorPortal, setDistributorPortal] = useState("")
  const [marketing, setMarketing] = useState("")
  const [distributorManagement, setDistributorManagement] = useState("")
  const [salesInvestorService, setSalesInvestorService] = useState("")
  const [reports, setReports] = useState("")
  const [dashboard, setDashboard] = useState("")

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const masterData = async () => {
    setLoading(true)
    const response = await axiosInstance.get(
      `role-permision?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
    )

    if (response) {
      setData(response?.data?.data)
      setLimit(response?.data)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  const selectRow = {
    mode: "checkbox",
  }

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search

  const RolePermissionColumns = toggleModal => [
    {
      dataField: "role.department",
      text: "Department",
      sort: true,
      // formatter: (cellContent, row) => handleSponsorName(row.role),
    },
    {
      dataField: "role.role_name",
      text: "Role",
      sort: true,
      // formatter: (cellContent, row) => handleDesignation(row.role),
    },
    {
      dataField: "permision.module",
      text: "Module",
      sort: true,
      // formatter: (cellContent, row) => handlePermision(row.permision),
    },

    {
      dataField: "acl",
      text: "acl",
      sort: true,
    },

    {
      dataField: "updated_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEdit) {
      const brokerOrder = [
        {
          roleId: values.role_name,
          permisionId: 8,
          acl: values.DISTRIBUTOR_PORTAL,
        },
      ]
      const updateOrder = [
        {
          roleId: values.role_name,
          permisionId: 12,
          acl: values.MARKETING,
        },
        {
          roleId: values.role_name,
          permisionId: 11,
          acl: values.REPORTS,
        },
        {
          roleId: values.role_name,
          permisionId: 10,
          acl: values.DASHBOARD,
        },
        {
          roleId: values.role_name,
          permisionId: 9,
          acl: values.SALES_INVESTOR_SERVICING,
        },
        {
          roleId: values.role_name,
          permisionId: 8,
          acl: values.DISTRIBUTOR_PORTAL,
        },
        {
          roleId: values.role_name,
          permisionId: 7,
          acl: values.DISTRIBUTOR_MANAGEMENT,
        },
        {
          roleId: values.role_name,
          permisionId: 6,
          acl: values.ASSET_MANAGEMENT,
        },
        {
          roleId: values.role_name,
          permisionId: 5,
          acl: values.support,
        },
        {
          roleId: values.role_name,
          permisionId: 4,
          acl: values.transaction,
        },
        {
          roleId: values.role_name,
          permisionId: 3,
          acl: values.deal,
        },
        {
          roleId: values.role_name,
          permisionId: 2,
          acl: values.kyc,
        },
        {
          roleId: values.role_name,
          permisionId: 1,
          acl: values.user_management,
        },
      ]

      // update order

      try {
        const response = await axiosInstance.patch(
          `role-permision/${orderList.department}`,
          roleDepartment == "Distributor" ? brokerOrder : updateOrder
        )
        if (response) {
          masterData()
          toast.success("Successfully Edited")
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      const brokerOrder = [
        {
          roleId: values.role_name,
          permisionId: 8,
          acl: values.DISTRIBUTOR_PORTAL,
        },
      ]
      const newOrder = [
        {
          roleId: values.role_name,
          permisionId: 12,
          acl: values.MARKETING,
        },
        {
          roleId: values.role_name,
          permisionId: 11,
          acl: values.REPORTS,
        },
        {
          roleId: values.role_name,
          permisionId: 10,
          acl: values.DASHBOARD,
        },
        {
          roleId: values.role_name,
          permisionId: 9,
          acl: values.SALES_INVESTOR_SERVICING,
        },
        {
          roleId: values.role_name,
          permisionId: 8,
          acl: values.DISTRIBUTOR_PORTAL,
        },
        {
          roleId: values.role_name,
          permisionId: 7,
          acl: values.DISTRIBUTOR_MANAGEMENT,
        },
        {
          roleId: values.role_name,
          permisionId: 6,
          acl: values.ASSET_MANAGEMENT,
        },
        {
          roleId: values.role_name,
          permisionId: 5,
          acl: values.support,
        },
        {
          roleId: values.role_name,
          permisionId: 4,
          acl: values.transaction,
        },
        {
          roleId: values.role_name,
          permisionId: 3,
          acl: values.deal,
        },
        {
          roleId: values.role_name,
          permisionId: 2,
          acl: values.kyc,
        },
        {
          roleId: values.role_name,
          permisionId: 1,
          acl: values.user_management,
        },
      ]
      // save new order

      try {
        const response = await axiosInstance.post(
          `role-permision`,
          roleDepartment == "Distributor" ? brokerOrder : newOrder
        )
        if (response) {
          masterData()
          toast.success(" Role permission Successfully Added")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    toggle()
    setLoading(false)
  }

  const handleOrderClicks = () => {
    setRoleId("")
    setRoleDepartment("")
    setOrderList("")
    setDashboard("")
    setUserManagement("")
    setSupport("")
    setTransaction("")
    setDeal("")
    setKyc("")
    setAssetManagement("")
    setDistributorManagement("")
    setSalesInvestorService("")
    setReports("")
    setDistributorPortal("")
    setMarketing("")
    setIsEdit(false)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  useEffect(async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(`role?$sort[created_at]=-1`)

      if (response) {
        setRole(response?.data?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }, [])

  const handleRoleSelection = e => {
    setRoleId(e.target.value)
    let roleObj = role.find(el => el.id == e.target.value)
    setRoleDepartment(roleObj.department)
  }
  useEffect(() => {
    if (roleId) {
      let newArray = orders.filter(item => item.roleId == roleId)

      let dashboard = newArray.find(
        item => item.permision.module.toUpperCase() == "DASHBOARD"
      )
      setDashboard(dashboard?.acl || "none")

      let userManagment = newArray.find(
        item => item.permision.module == "USER_MANAGEMENT"
      )
      setUserManagement(userManagment?.acl || "none")

      let support = newArray.find(item => item.permision.module == "SUPPORT")
      setSupport(support?.acl || "none")

      let transaction = newArray.find(
        item => item.permision.module == "TRANSACTION"
      )
      setTransaction(transaction?.acl || "none")

      let deal = newArray.find(
        item => item.permision.module == "DEAL_PUBLISHING"
      )
      setDeal(deal?.acl || "none")

      let kyc = newArray.find(item => item.permision.module == "KYC")
      setKyc(kyc?.acl || "none")

      let AssetManagement = newArray.find(
        item => item.permision.module == "ASSET_MANAGEMENT"
      )
      setAssetManagement(AssetManagement?.acl || "none")

      let distributorManagement = newArray.find(
        item => item.permision.module.toUpperCase() == "DISTRIBUTOR_MANAGEMENT"
      )
      setDistributorManagement(distributorManagement?.acl || "none")

      let salesInvestorService = newArray.find(
        item =>
          item.permision.module.toUpperCase() == "SALES_INVESTOR_SERVICING"
      )
      setSalesInvestorService(salesInvestorService?.acl || "none")

      let DistributorPortal = newArray.find(
        item => item.permision.module.toUpperCase() == "DISTRIBUTOR_PORTAL"
      )
      setDistributorPortal(DistributorPortal?.acl || "none")

      let Reports = newArray.find(
        item => item.permision.module.toUpperCase() == "REPORTS"
      )
      setReports(Reports?.acl || "none")

      let marketing = newArray.find(
        item => item.permision.module == "MARKETING"
      )
      setMarketing(marketing?.acl || "none")
    }
  }, [roleId])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.ROLE_PERMISSIONS_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={RolePermissionColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            {accessControlData?.permissions
                              ?.ROLE_PERMISSIONS_MANAGE_ROLE_PERMISSIONS && (
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleOrderClicks}
                                  >
                                    <i className="mdi mdi-pencil me-1" />
                                    {
                                      accessControlData?.permissions
                                        ?.ROLE_PERMISSIONS_MANAGE_ROLE_PERMISSIONS
                                    }
                                  </Button>
                                </div>
                              </Col>
                            )}
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                columns={RolePermissionColumns(toggle)}
                                data={orders}
                                pagination={paginationFactory(pageOptions)}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit
                                    ? "Edit Role Permission"
                                    : "Manage Role Permission"}
                                </ModalHeader>
                                <ModalBody>
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <AvField
                                            name="role_name"
                                            label="Role"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid Role"
                                            validate={{
                                              required: {
                                                value: true,
                                              },
                                            }}
                                            value={orderList.department || null}
                                            onChange={handleRoleSelection}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {role.map((item, index) => (
                                              <option
                                                key={item.id}
                                                value={item.id}
                                              >
                                                {item.role_name}
                                              </option>
                                            ))}
                                          </AvField>
                                        </div>
                                        <div className="row align items center mb-3">
                                          <div className="col-12">
                                            <AvField
                                              name="DASHBOARD"
                                              label="Dashboard"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid input"
                                              disabled={
                                                roleDepartment == "Distributor"
                                                  ? true
                                                  : false
                                              }
                                              validate={{
                                                required: {
                                                  value:
                                                    roleDepartment ==
                                                    "Distributor"
                                                      ? false
                                                      : true,
                                                },
                                              }}
                                              value={
                                                orderList.DASHBOARD ||
                                                dashboard ||
                                                ""
                                              }
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="none">None</option>
                                              <option value="read">Read</option>
                                              <option value="read,update">
                                                Read,Update
                                              </option>
                                              <option value="read,update,create">
                                                Read,Update,Create
                                              </option>
                                              <option value="read,update,create,delete">
                                                Read,Update,Create,Delete
                                              </option>
                                            </AvField>
                                          </div>
                                        </div>
                                        <div className="row align items center mb-3">
                                          <div className="col-12">
                                            <AvField
                                              name="user_management"
                                              label="User Management"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid User Management"
                                              disabled={
                                                roleDepartment == "Distributor"
                                                  ? true
                                                  : false
                                              }
                                              validate={{
                                                required: {
                                                  value:
                                                    roleDepartment ==
                                                    "Distributor"
                                                      ? false
                                                      : true,
                                                },
                                              }}
                                              value={
                                                orderList.user_management ||
                                                userManagement ||
                                                ""
                                              }
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="none">None</option>
                                              <option value="read">Read</option>
                                              <option value="read,update">
                                                Read,Update
                                              </option>
                                              <option value="read,update,create">
                                                Read,Update,Create
                                              </option>
                                              <option value="read,update,create,delete">
                                                Read,Update,Create,Delete
                                              </option>
                                            </AvField>
                                          </div>
                                        </div>
                                        <div className="row align items center mb-3">
                                          {/*<div className="col-4">Support</div> */}
                                          <div className="col-12">
                                            <AvField
                                              name="support"
                                              label="Support"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid Support"
                                              disabled={
                                                roleDepartment == "Distributor"
                                                  ? true
                                                  : false
                                              }
                                              validate={{
                                                required: {
                                                  value:
                                                    roleDepartment ==
                                                    "Distributor"
                                                      ? false
                                                      : true,
                                                },
                                              }}
                                              value={
                                                orderList.support ||
                                                support ||
                                                ""
                                              }
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="none">None</option>
                                              <option value="read">Read</option>
                                              <option value="read,update">
                                                Read,Update
                                              </option>
                                              <option value="read,update,create">
                                                Read,Update,Create
                                              </option>
                                              <option value="read,update,create,delete">
                                                Read,Update,Create,Delete
                                              </option>
                                            </AvField>
                                          </div>
                                        </div>

                                        <div className="row align items center mb-3">
                                          {/*<div className="col-4">
                                              Transaction
                                              </div> */}
                                          <div className="col-12">
                                            <AvField
                                              name="transaction"
                                              label="Transaction"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid Transaction"
                                              disabled={
                                                roleDepartment == "Distributor"
                                                  ? true
                                                  : false
                                              }
                                              validate={{
                                                required: {
                                                  value:
                                                    roleDepartment ==
                                                    "Distributor"
                                                      ? false
                                                      : true,
                                                },
                                              }}
                                              value={
                                                orderList.transaction ||
                                                transaction ||
                                                ""
                                              }
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="none">None</option>
                                              <option value="read">Read</option>
                                              <option value="read,update">
                                                Read,Update
                                              </option>
                                              <option value="read,update,create">
                                                Read,Update,Create
                                              </option>
                                              <option value="read,update,create,delete">
                                                Read,Update,Create,Delete
                                              </option>
                                            </AvField>
                                          </div>
                                        </div>

                                        <div className="row align items center mb-3">
                                          {/*<div className="col-4">
                                              Deal Publishing
                                              </div> */}
                                          <div className="col-12">
                                            <AvField
                                              name="deal"
                                              label="Deal Publishing"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid Deal Publishing"
                                              disabled={
                                                roleDepartment == "Distributor"
                                                  ? true
                                                  : false
                                              }
                                              validate={{
                                                required: {
                                                  value:
                                                    roleDepartment ==
                                                    "Distributor"
                                                      ? false
                                                      : true,
                                                },
                                              }}
                                              value={
                                                orderList.deal || deal || ""
                                              }
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="none">None</option>
                                              <option value="read">Read</option>
                                              <option value="read,update">
                                                Read,Update
                                              </option>
                                              <option value="read,update,create">
                                                Read,Update,Create
                                              </option>
                                              <option value="read,update,create,delete">
                                                Read,Update,Create,Delete
                                              </option>
                                            </AvField>
                                          </div>
                                        </div>

                                        <div className="row align items center mb-3">
                                          <div className="col-12">
                                            <AvField
                                              name="kyc"
                                              label="KYC"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid KYC"
                                              disabled={
                                                roleDepartment == "Distributor"
                                                  ? true
                                                  : false
                                              }
                                              validate={{
                                                required: {
                                                  value:
                                                    roleDepartment ==
                                                    "Distributor"
                                                      ? false
                                                      : true,
                                                },
                                              }}
                                              value={orderList.kyc || kyc || ""}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="none">None</option>
                                              <option value="read">Read</option>
                                              <option value="read,update">
                                                Read,Update
                                              </option>
                                              <option value="read,update,create">
                                                Read,Update,Create
                                              </option>
                                              <option value="read,update,create,delete">
                                                Read,Update,Create,Delete
                                              </option>
                                            </AvField>
                                          </div>
                                        </div>
                                        <div className="row align items center mb-3">
                                          <div className="col-12">
                                            <AvField
                                              name="ASSET_MANAGEMENT"
                                              label="Asset Management"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid Asset Management"
                                              disabled={
                                                roleDepartment == "Distributor"
                                                  ? true
                                                  : false
                                              }
                                              validate={{
                                                required: {
                                                  value:
                                                    roleDepartment ==
                                                    "Distributor"
                                                      ? false
                                                      : true,
                                                },
                                              }}
                                              value={
                                                orderList.ASSET_MANAGEMENT ||
                                                assetManagement ||
                                                ""
                                              }
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="none">None</option>
                                              <option value="read">Read</option>
                                              <option value="read,update">
                                                Read,Update
                                              </option>
                                              <option value="read,update,create">
                                                Read,Update,Create
                                              </option>
                                              <option value="read,update,create,delete">
                                                Read,Update,Create,Delete
                                              </option>
                                            </AvField>
                                          </div>
                                        </div>

                                        <div className="row align items center mb-3">
                                          <div className="col-12">
                                            <AvField
                                              name="DISTRIBUTOR_MANAGEMENT"
                                              label="Distributor Management"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid input"
                                              disabled={
                                                roleDepartment == "Distributor"
                                                  ? true
                                                  : false
                                              }
                                              validate={{
                                                required: {
                                                  value:
                                                    roleDepartment ==
                                                    "Distributor"
                                                      ? false
                                                      : true,
                                                },
                                              }}
                                              value={
                                                orderList.DISTRIBUTOR_MANAGEMENT ||
                                                distributorManagement ||
                                                ""
                                              }
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="none">None</option>
                                              <option value="read">Read</option>
                                              <option value="read,update">
                                                Read,Update
                                              </option>
                                              <option value="read,update,create">
                                                Read,Update,Create
                                              </option>
                                              <option value="read,update,create,delete">
                                                Read,Update,Create,Delete
                                              </option>
                                            </AvField>
                                          </div>
                                        </div>

                                        <div className="row align items center mb-3">
                                          <div className="col-12">
                                            <AvField
                                              name="SALES_INVESTOR_SERVICING"
                                              label="Sales & Investor Service"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid input"
                                              disabled={
                                                roleDepartment == "Distributor"
                                                  ? true
                                                  : false
                                              }
                                              validate={{
                                                required: {
                                                  value:
                                                    roleDepartment ==
                                                    "Distributor"
                                                      ? false
                                                      : true,
                                                },
                                              }}
                                              value={
                                                orderList.SALES_INVESTOR_SERVICING ||
                                                salesInvestorService ||
                                                ""
                                              }
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="none">None</option>
                                              <option value="read">Read</option>
                                              <option value="read,update">
                                                Read,Update
                                              </option>
                                              <option value="read,update,create">
                                                Read,Update,Create
                                              </option>
                                              <option value="read,update,create,delete">
                                                Read,Update,Create,Delete
                                              </option>
                                            </AvField>
                                          </div>
                                        </div>

                                        <div className="row align items center mb-3">
                                          <div className="col-12">
                                            <AvField
                                              name="REPORTS"
                                              label="Reports"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid input"
                                              validate={{
                                                required: {
                                                  value:
                                                    roleDepartment ==
                                                    "Distributor"
                                                      ? false
                                                      : true,
                                                },
                                              }}
                                              value={
                                                orderList.REPORTS ||
                                                reports ||
                                                ""
                                              }
                                              disabled={
                                                roleDepartment == "Distributor"
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="none">None</option>
                                              <option value="read">Read</option>
                                              <option value="read,update">
                                                Read,Update
                                              </option>
                                              <option value="read,update,create">
                                                Read,Update,Create
                                              </option>
                                              <option value="read,update,create,delete">
                                                Read,Update,Create,Delete
                                              </option>
                                            </AvField>
                                          </div>
                                        </div>

                                        <div className="row align items center mb-3">
                                          <div className="col-12">
                                            <AvField
                                              name="MARKETING"
                                              label="Marketing"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid input"
                                              validate={{
                                                required: {
                                                  value:
                                                    roleDepartment ==
                                                    "Distributor"
                                                      ? false
                                                      : true,
                                                },
                                              }}
                                              disabled={
                                                roleDepartment == "Distributor"
                                                  ? true
                                                  : false
                                              }
                                              value={
                                                orderList.MARKETING ||
                                                marketing ||
                                                ""
                                              }
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="none">None</option>
                                              <option value="read">Read</option>
                                              <option value="read,update">
                                                Read,Update
                                              </option>
                                              <option value="read,update,create">
                                                Read,Update,Create
                                              </option>
                                              <option value="read,update,create,delete">
                                                Read,Update,Create,Delete
                                              </option>
                                            </AvField>
                                          </div>
                                        </div>

                                        <div className="row align items center mb-3">
                                          <div className="col-12">
                                            <AvField
                                              name="DISTRIBUTOR_PORTAL"
                                              label="Distributor Portal"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid input"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList.DISTRIBUTOR_PORTAL ||
                                                distributorPortal ||
                                                ""
                                              }
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="none">None</option>
                                              <option value="read">Read</option>
                                              <option value="read,update">
                                                Read,Update
                                              </option>
                                              <option value="read,update,create">
                                                Read,Update,Create
                                              </option>
                                              <option value="read,update,create,delete">
                                                Read,Update,Create,Delete
                                              </option>
                                            </AvField>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

RolePermission.propTypes = {
  orders: PropTypes.array,
}

export default withRouter(RolePermission)
