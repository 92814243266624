import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import Unauthorized from "common/Unauthorized"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch } from "react-redux"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getOrders as onGetOrders,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "store/actions"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import { permissionChecker } from "common/PermissionChecker"

const InvestmentCreationPolicy = props => {
  const dispatch = useDispatch()

  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [loading, setLoading] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `investment-creation-policy?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setData(response.data?.data || response.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  const selectRow = {
    mode: "checkbox",
  }

  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  //pagination customization
  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search

  const EcommerceOrderColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessControlData?.permissions?.INVESTMENT_CREATION_EDIT ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {accessControlData?.permissions?.INVESTMENT_CREATION_EDIT}
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
            {accessControlData?.permissions?.INVESTMENT_CREATION_DELETE ? (
              <Link
                to="#"
                className="text-danger"
                onClick={() => handleDeleteOrder(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {accessControlData?.permissions?.INVESTMENT_CREATION_DELETE}
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },

    {
      dataField: "user.user_name",
      text: "Name",
      sort: true,
      // eslint-disable-next-line react/display-name
      // formatter: (cellContent, row) => handleDepartment(row.user),
    },
    {
      dataField: "investment_type",
      text: "Investment Type",
      sort: true,
    },
    {
      dataField: "approval_level",
      text: "Approval Level",
      sort: true,
    },

    {
      dataField: "updated_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  useEffect(() => {
    if (orders && !orders.length) {
      dispatch(onGetOrders())
    }
  }, [dispatch, orders])

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const toLowerCase1 = str => {
    return str.toLowerCase()
  }

  const handleOrderClick = arg => {
    const order = arg
    setSelectedUser(order.user)
    setOrderList({
      id: order.id,
      user_name: order.userId,
      investment_type: order.investment_type,
      approval_level: order.approval_level,
      created_at: order.created_at,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDeleteOrder = async order => {
    var r = confirm(`Are you sure want to delete ${order.investment_type}`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `investment-creation-policy/${order.id}`
        )
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
      dispatch(onDeleteOrder(order))
    }
  }
  const handleValidOrderSubmit = async (e, values) => {
    if (!selectedUser?.id) {
      toast.error("Select a user")
      return
    }
    setLoading(true)
    if (isEdit) {
      const updateOrder = {
        userId: selectedUser.id,
        investment_type: values.investment_type,
        approval_level: values.approval_level,
        created_at: values.created_at,
      }

      // update order

      try {
        const response = await axiosInstance.patch(
          `investment-creation-policy/${orderList.id}`,
          updateOrder
        )
        if (response) {
          masterData()
          toast.success("Successfully Edited")
        }
      } catch (error) {
        toast.error(error.message)
      }
      dispatch(onUpdateOrder(updateOrder))
    } else {
      const newOrder = {
        userId: selectedUser.id,
        investment_type: values.investment_type,
        approval_level: values.approval_level,
        created_at: values.created_at,
      }
      // save new order

      try {
        const response = await axiosInstance.post(
          `investment-creation-policy`,
          newOrder
        )
        if (response) {
          masterData()
          toast.success("Successfully Added")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    toggle()
    setLoading(false)
  }

  const handleOrderClicks = () => {
    setOrderList("")
    setSelectedUser(null)
    setIsEdit(false)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const [sponser, setSponser] = useState([])

  useEffect(async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `users?$sort[created_at]=-1&permissions[$ne]=USER`
      )

      if (response) {
        const users = response.data?.data || response.data
        setSponser(
          users.filter(item => item?.role?.department != "Distributor")
        )
        setLoading(false)
      }
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
  }, [])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.INVESTMENT_CREATION_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={EcommerceOrderColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            {accessControlData?.permissions
                              ?.INVESTMENT_CREATION_ADD && (
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleOrderClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add
                                  </Button>
                                </div>
                              </Col>
                            )}
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                columns={EcommerceOrderColumns(toggle)}
                                data={orders}
                                pagination={paginationFactory(pageOptions)}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit
                                    ? "Edit Creation Policy"
                                    : "Add Creation Policy"}
                                </ModalHeader>
                                <ModalBody>
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <AvField
                                            name="investment_type"
                                            label="Investment Type"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid Investment Type"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList.investment_type || ""
                                            }
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            <option value="REAL_ESTATE">
                                              Real Estate
                                            </option>
                                            <option value="INFRA">Infra</option>
                                          </AvField>
                                        </div>

                                        <div className="mb-3">
                                          <label>User</label>
                                          <ReactSelect
                                            users={sponser.sort((a, b) =>
                                              a.user_name?.toLowerCase() >
                                              b.user_name?.toLowerCase()
                                                ? 1
                                                : -1
                                            )}
                                            selectedOption={
                                              selectedUser && selectedUser
                                            }
                                            setSelectedOption={setSelectedUser}
                                            multiOptionLabel={false}
                                            optionLabelKeys={[
                                              "user_name",
                                              "email",
                                              "phone",
                                              "role?.role_name",
                                            ]}
                                          />
                                        </div>

                                        <div className="mb-3">
                                          <AvField
                                            name="approval_level"
                                            label="Approval Level"
                                            type="number"
                                            errorMessage="Invalid Approval Level"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList.approval_level || null
                                            }
                                          ></AvField>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

InvestmentCreationPolicy.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(InvestmentCreationPolicy)
