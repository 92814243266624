import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { AccessDenied, picUrl } from "../../constants/common"
import { serverBaseUrl } from "../../ConfigAxioxinstance"
import Cropper from "react-easy-crop"
import Slider from "@material-ui/core/Slider"
import getCroppedImg from "../AssetImage/cropImage"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { sortingByAlphabet } from "../../constants/sort"
import Breadcrumbs from "components/Common/Breadcrumb"
import ReactQuill from "react-quill"
import EditorToolbar, {
  modules,
  formats,
} from "../SponserOtherProjects/EditorToolbar"
import "react-quill/dist/quill.snow.css"
import "../SponserOtherProjects/styles.css"
import Loader from "common/Loader"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"
import { selectStrToBool } from "constants/ConstantFields"

const Investment = () => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [tokenAmountApplicable, setTokenAmountApplicable] = useState("")
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [dp, setDp] = useState(orderList.display_pic_url)
  const [descriptionState, setDescriptionState] = useState({ value: null })
  const [sponser, setSponser] = useState([])
  const [creationRequestData, setCreationRequestData] = useState([])
  const [creationRequestId, setCreationRequestId] = useState("")
  const [forEditRequestId, setForEditRequestId] = useState("")
  const [transactionType, setTransactionType] = useState()
  const [dealStatusUpdate, setDealStatusUpdate] = useState(false)
  const [dealStatus, setDealStaus] = useState("")
  const [deal, setDeal] = useState("")
  const [imgAltDescModal, setImgAltDescModal] = useState(false)

  const [debentureTrusteeList, setDebentureTrusteeList] = useState([])
  const [selectedDebentureTrustee, setSelectedDebentureTrustee] = useState(null)
  const [onWebsiteVisible, setOnWebsiteVisible] = useState(false)
  const [onDetailsPageVisible, setOnDetailsPageVisible] = useState(false)
  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const shortenedUrl = serverBaseUrl.replace("/api/v1", "")

  const handleNext = prev => {
    setSkip(prev => prev + 10)
  }

  const handlePrevious = prev => {
    setSkip(prev => prev - 10)
  }

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `investment?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )
      const creationRequest = await axiosInstance.get(
        `investment-creation-request?$sort[created_at]=-1&creation_status=Approved`
      )
      if (creationRequest) {
        setCreationRequestData(creationRequest?.data?.data)
      }

      if (response) {
        setData(response?.data?.data)
        setLimit(response?.data)
        setLoading(false)
      }
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  const selectRow = {
    mode: "checkbox",
  }

  const selectedRequestData = creationRequestData.find(
    item => item?.id === creationRequestId
  )

  const selectedEditRequest = creationRequestData.find(
    item => item?.id === forEditRequestId
  )
  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search

  const InvestmentColumns = toggleModal => [
    {
      dataField: "Send For Approval",
      isDummyField: true,
      text: "Send For Approval",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          {accessControlData?.permissions?.INVESTMENTS_SEND_FOR_APPROVAL ? (
            <div className="d-flex gap-3">
              <Link to="#" className="text-success">
                <Button
                  type="button"
                  color="primary"
                  className="btn-sm btn-rounded"
                  onClick={() => handleApprovalClick(order)}
                  disabled={
                    order.status == "Sent_for_Approval" ||
                    order.status == "Approved" ||
                    order.status == "Closed" ||
                    order.status == "Exited"
                      ? true
                      : false
                  }
                >
                  {order.status ==
                    accessControlData?.permissions
                      ?.INVESTMENTS_SEND_FOR_APPROVAL ||
                  order.status == "Approved"
                    ? "Sent for Approval"
                    : "Send for Approval"}
                </Button>
              </Link>
            </div>
          ) : (
            AccessDenied
          )}
        </>
      ),
    },

    {
      dataField: "Update Deal Status",
      isDummyField: true,
      text: "Update Deal Status",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          {accessControlData?.permissions?.INVESTMENTS_UPDATE_STATUS ? (
            <div className="d-flex gap-3">
              {order.status === "Approved" ||
              order.status === "Closed" ||
              order.status === "Exited" ? (
                <Link to="#" className="text-success">
                  <Button
                    type="button"
                    color="primary"
                    className="btn-sm btn-rounded"
                    onClick={() => handleDealStatusUpdate(order?.id)}
                  >
                    {accessControlData?.permissions?.INVESTMENTS_UPDATE_STATUS}
                  </Button>
                </Link>
              ) : (
                "NA"
              )}
            </div>
          ) : (
            AccessDenied
          )}
        </>
      ),
    },

    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) =>
        accessControlData?.permissions?.INVESTMENTS_EDIT ? (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleOrderClick(order)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                {accessControlData?.permissions?.INVESTMENTS_EDIT}
              </UncontrolledTooltip>
            </Link>
          </div>
        ) : (
          AccessDenied
        ),
    },
    {
      dataField: "Preview",
      isDummyField: true,
      text: "Preview Draft",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => {
        return accessControlData?.permissions?.INVESTMENTS_VIEW_DRAFT ? (
          <Button
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
            onClick={() => handleViewUrl(order)}
          >
            {accessControlData?.permissions?.INVESTMENTS_VIEW_DRAFT}
          </Button>
        ) : (
          "N/A"
        )
      },
    },

    {
      dataField: "sponsor_master",
      text: "Sponsor Name",
      sort: true,
      formatter: (cellContent, row) => handleSponsorName(row.sponsor_master),
    },
    {
      dataField: "project_name",
      text: "Project Name",
      sort: true,
    },
    {
      dataField: "registered_issuer_name",
      text: "Registered Issuer Name",
      sort: true,
    },

    {
      dataField: "total_bonds",
      text: "Total Bonds",
      sort: true,
    },
    {
      dataField: "annual_return",
      text: "Annual Return",
      sort: true,
    },

    {
      dataField: "multiple",
      text: "Multiple",
      sort: true,
    },
    {
      dataField: "interest_payment",
      text: "Interest Payment",
      sort: true,
    },
    {
      dataField: "compounding_frequency",
      text: "Compounding Frequency",
      sort: true,
    },

    {
      dataField: "issue_size",
      text: "Issue Size",
      sort: true,
      formatter: (cellContent, row) => handleAmount(row.issue_size),
    },
    {
      dataField: "release_commitment",
      text: "Release commitment",
      sort: true,
    },

    {
      dataField: "display_pic_url",
      text: "Display Pic",
      sort: true,
      formatter: (cellContent, row) => handleimg(row.display_pic_url),
    },
    {
      dataField: "image_alt_desc",
      text: "Display Pic Alt Description",
      sort: true,
    },
    {
      dataField: "updated_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
    {
      dataField: "location",
      text: "Location",
      sort: true,
    },
    {
      dataField: "is_visible_on_website",
      text: "Show on Website?",
      sort: true,
    },
    {
      dataField: "show_details_on_website",
      text: "Show Investment Details on Website?",
      sort: true,
    },
    {
      dataField: "featured",
      text: "Featured",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    // {
    //   dataField: "total_funded",
    //   text: "Total Funded",
    //   sort: true,
    // },
    {
      dataField: "slug",
      text: "Slug",
      sort: true,
    },
    {
      dataField: "maturity_date",
      text: "Maturity Date",
      sort: true,
    },
    {
      dataField: "is_ref_visible",
      text: "Is Ref Visible",
      sort: true,
    },
    {
      dataField: "face_value",
      text: "Face Value",
      sort: true,
    },
    // {
    //   dataField: "isin_number",
    //   text: "ISIN Number",
    //   sort: true,
    // },
    // {
    //   dataField: "issuer_cin",
    //   text: "Issuer CIN",
    //   sort: true,
    // },
    // {
    //   dataField: "issuer_code",
    //   text: "Issuer Code",
    //   sort: true,
    // },
    // {
    //   dataField: "isin_description",
    //   text: "ISIN Description",
    //   sort: true,
    // },
    // {
    //   dataField: "redemption_start_date",
    //   text: "Redemption Start Date",
    //   sort: true,
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, row) => row.redemption_start_date,
    // },
    // {
    //   dataField: "debenture_trustee_name",
    //   text: "Debenture Trustee Name",
    //   sort: true,
    // },
    // { dataField: "debenture_trustee_cin", text: "Debenture CIN", sort: true },
  ]

  const handleDealStatusUpdate = deal => {
    setDeal(deal)
    setDealStaus("")
    setDealStatusUpdate(true)
    toggle()
  }

  const handleimg = img => {
    const date1 = (
      <img
        src={`${picUrl}${img}`}
        alt="display pic"
        style={{ maxWidth: "100px", maxHeight: "120px" }}
      ></img>
    )
    return date1
  }

  const handleSponsorName = overview => {
    const overviewSorted = overview?.sponsor_name
    return overviewSorted
  }

  const handleApprovalClick = async order => {
    var r = confirm(
      `Are you sure you want to send ${order.project_name} for publishing`
    )
    if (r == true) {
      try {
        const response = await axiosInstance.patch(`/investment/${order.id}`, {
          status: "Sent_for_Approval",
          investmentCreationRequestId: order.investmentCreationRequestId,
        })
        if (response) {
          setTimeout(() => {
            masterData()
          }, 1000)

          toast.success("Sent For Approval")
        }
      } catch (error) {
        toast.error(error?.message)
      }
    }
  }

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const handleViewUrl = arg => {
    const order = arg
    if (order) {
      window.open(
        `${shortenedUrl}/opportunity-detail-draft/${order?.id}/${
          order?.slug ? order?.slug : "null"
        }`
      )
    }
  }

  const handleOrderClick = async arg => {
    const order = arg
    setImage(null)
    setZoom(1)
    setTokenAmountApplicable("")
    setDescriptionState({ value: null })
    setCreationRequestId("")
    setTransactionType("")
    setForEditRequestId(order?.investmentCreationRequestId)
    setDealStatusUpdate(false)

    const debentureTrusteeRes = await axiosInstance.get(`debenture-trustee`)
    const debentureTrusteeData =
      debentureTrusteeRes?.data?.data || debentureTrusteeRes?.data
    setDebentureTrusteeList(debentureTrusteeData)
    setSelectedDebentureTrustee(
      debentureTrusteeData.find(el => el.id === order.debentureTrusteeId)
    )
    setOnWebsiteVisible(order.is_visible_on_website)
    setOnDetailsPageVisible(
      order.is_visible_on_website || order.show_details_on_website
    )
    setOrderList({
      id: order.id,
      sponsor_name: order.sponsorMasterId,
      investment_request_name: order.investmentCreationRequestId,
      project_name: order.project_name,
      min_investment: order.min_investment,
      max_investment: order.max_investment,
      min_lot: order.min_lot,
      max_lot: order.max_lot,
      annual_return: order.annual_return,
      annual_return_tooltip: order.annual_return_tooltip,
      coupon_return: order.coupon_return,
      multiple: order.multiple,
      interest_payment: order.interest_payment,
      compounding_frequency: order.compounding_frequency,
      repaid_over: order.repaid_over,
      issue_size: order.issue_size,
      display_pic_url: order.display_pic_url,
      location: order.location,
      featured: order.featured,
      status: order.status,
      slug: order.slug,
      maturity_date: order.maturity_date,
      description: order.description,
      irr: order.irr,
      lot_price: order.lot_price,
      token_fixed_amount: order.token_fixed_amount,
      token_perentage: order.token_perentage,
      token_amount_applicable: order.token_amount_applicable,
      token_calculation_method: order.token_calculation_method,
      transaction_type: order.transaction_type,
      is_ref_visible: order.is_ref_visible,
      face_value: order.face_value,
      isin_number: order.isin_number,
      release_commitment: order.release_commitment,
      registered_issuer_name: order.registered_issuer_name,
      total_bonds: order.total_bonds,
      share_msg: order.share_msg,
      stf_disclaimer: order.stf_disclaimer,
      stf_risk_disclosures: order.stf_risk_disclosures,
      created_at: order.created_at,
      issuer_cin: order.issuer_cin,
      issuer_code: order.issuer_code,
      isin_description: order.isin_description,
      redemption_start_date: order.redemption_start_date,
      image_alt_desc: order.image_alt_desc,
    })

    setIsEdit(true)

    toggle()
  }

  const handleValidOrderSubmit = async (e, values) => {
    if (dealStatusUpdate) {
      const updateData = { status: dealStatus }
      try {
        const response = await axiosInstance.patch(
          `/investment/${deal}`,
          updateData
        )
        if (response?.status === 200) {
          toast.success("Deal Status Updated")
          masterData()
        }
      } catch (error) {
        toast.error(error?.message)
      }
    }
    if (isEdit && !dealStatusUpdate) {
      const updateOrder = {
        sponsorMasterId: values.sponsor_name,
        investmentCreationRequestId: values.investment_request_name,
        project_name: values.project_name,
        interest_payment: values.interest_payment,
        compounding_frequency: values.compounding_frequency,
        annual_return_tooltip: values.annual_return_tooltip,
        issue_size: values.investment_amount,
        location: values.location,
        is_visible_on_website: onWebsiteVisible,
        show_details_on_website: onWebsiteVisible || onDetailsPageVisible,

        featured: values.featured,
        status: values.Status,
        coupon_return: values.coupon_return,
        slug: values.slug,
        maturity_date: values.maturity_date,
        display_pic_url: dp || orderList.display_pic_url,
        irr: values.irr,
        token_fixed_amount:
          transactionType == "Secondary" ? null : values.token_fixed_amount,
        token_perentage:
          transactionType == "Secondary" ? null : values.token_perentage,
        token_amount_applicable:
          orderList.token_amount_applicable == "false" ||
          transactionType == "Secondary"
            ? false
            : values.token_amount_applicable,
        token_calculation_method: values.token_calculation_method,
        description: descriptionState.value || orderList.description,
        transaction_type: values.transaction_type,
        is_ref_visible: values.is_ref_visible,
        isin_number: values.isin_number,
        release_commitment: values.release_commitment,
        registered_issuer_name: values.registered_issuer_name,
        total_bonds: values.total_bonds,
        share_msg: values.share_msg,
        stf_disclaimer: values.stf_disclaimer,
        stf_risk_disclosures: values.stf_risk_disclosures,
        issuer_cin: values.issuer_cin,
        issuer_code: values.issuer_code,
        isin_description: values.isin_description,
        redemption_start_date: values.redemption_start_date,
        debentureTrusteeId: selectedDebentureTrustee?.id,
        image_alt_desc: values.image_alt_desc,
      }
      if (orderList.status === "Exited") {
        updateOrder.annual_return = values.annual_return
      }

      // update order
      try {
        const response = await axiosInstance.patch(
          `investment/${orderList.id}`,
          updateOrder
        )
        if (response) {
          setDescriptionState({ value: null })
          setDp(null)
          masterData()
          toast.success("Successfully Edited")
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else if (!dealStatusUpdate) {
      const newOrder = {
        sponsorMasterId: values["sponsor_name"],
        investmentCreationRequestId: values.investment_request_name,
        project_name: values["project_name"],
        interest_payment: values["interest_payment"],
        annual_return_tooltip: values.annual_return_tooltip,
        compounding_frequency: values.compounding_frequency,
        investment_type: selectedRequestData?.investment_type,
        issue_size: selectedRequestData?.investment_amount,
        location: values["location"],
        is_visible_on_website: onWebsiteVisible,
        show_details_on_website: onWebsiteVisible || onDetailsPageVisible,
        featured: values["featured"],
        status: "Draft",
        slug: values["slug"],
        maturity_date: values["maturity_date"],
        display_pic_url: dp || orderList.display_pic_url,
        description: descriptionState.value,
        irr: values.irr,
        token_fixed_amount:
          transactionType == "Secondary" ? null : values.token_fixed_amount,
        token_perentage:
          transactionType == "Secondary" ? null : values.token_perentage,
        token_amount_applicable:
          transactionType == "Secondary"
            ? false
            : values.token_amount_applicable,
        token_calculation_method:
          transactionType == "Secondary"
            ? "Fixed"
            : values.token_calculation_method,
        transaction_type: values.transaction_type,
        is_ref_visible: values.is_ref_visible,
        isin_number: values.isin_number,
        release_commitment: values.release_commitment,
        registered_issuer_name: values.registered_issuer_name,
        total_bonds: values.total_bonds,
        share_msg: values.share_msg,
        stf_disclaimer: values.stf_disclaimer,
        stf_risk_disclosures: values.stf_risk_disclosures,
        issuer_cin: values.issuer_cin,
        issuer_code: values.issuer_code,
        isin_description: values.isin_description,
        redemption_start_date: values.redemption_start_date,
        debentureTrusteeId: selectedDebentureTrustee?.id,
        image_alt_desc: values.image_alt_desc,
      }
      // save new order
      if (orderList.status === "Exited") {
        newOrder.annual_return = values.annual_return
      }

      try {
        const response = await axiosInstance.post(`investment`, newOrder)
        if (response) {
          setDescriptionState({ value: null })
          setDp(null)
          masterData()
          toast.success(" Investment Successfully Added")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    toggle()
  }
  const handleOrderClicks = () => {
    setImage(null)
    setZoom(1)
    setTokenAmountApplicable("")
    setTransactionType("")
    setDescriptionState({ value: null })
    setOnWebsiteVisible(false)
    setOnDetailsPageVisible(false)
    setOrderList("")
    setDebentureTrusteeList([])
    setSelectedDebentureTrustee(null)
    setIsEdit(false)
    setCreationRequestId("")
    setForEditRequestId("")
    setDealStatusUpdate(false)

    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  useEffect(async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `sponsor-master?$sort[created_at]=-1`
      )

      if (response) {
        setSponser(response?.data?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }, [])

  useEffect(async () => {
    if (file) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=underlying_asset_image`,
          formData
        )
        if (response) {
          setDp(response.data[0].name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [file])
  const handleDescriptionChange = value => {
    setDescriptionState({ value })
  }

  const creationData = creationRequestData.filter(
    item => item.investmentId == null
  )

  /*Image Crop */

  const inputRef = React.useRef()

  const triggerFileSelectPopup = () => inputRef.current.click()

  const [image, setImage] = useState(null)
  const [croppedArea, setCroppedArea] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels)
  }

  const onSelectFile = event => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.addEventListener("load", () => {
        setImage(reader.result)
      })
    }
  }

  const onDownload = () => {
    const response = getCroppedImg(image, croppedArea)
    if (response) {
      response.then(file => setFile(file))
      setImage(null)
      setZoom(1)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.INVESTMENTS_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={InvestmentColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              {accessControlData?.permissions
                                ?.INVESTMENTS_ADD && (
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleOrderClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    {
                                      accessControlData?.permissions
                                        ?.INVESTMENTS_ADD
                                    }
                                  </Button>
                                </div>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                columns={InvestmentColumns(toggle)}
                                data={orders}
                                pagination={paginationFactory(pageOptions)}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal
                                isOpen={modal}
                                toggle={toggle}
                                className={!dealStatusUpdate ? "modal-lg" : ""}
                              >
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit
                                    ? "Edit Investment"
                                    : !isEdit && dealStatusUpdate
                                    ? "Deal Status Update"
                                    : "Add Investment"}
                                </ModalHeader>
                                <ModalBody>
                                  {loading && <Loader />}
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      <div className="row">
                                        {dealStatusUpdate ? (
                                          <div className="mb-3">
                                            <AvField
                                              name="status_update"
                                              label="Deal Status Update"
                                              type="select"
                                              className="form-select"
                                              onChange={e =>
                                                setDealStaus(e.target.value)
                                              }
                                              validate={{
                                                required: { value: true },
                                              }}
                                              errorMessage="Invalid selection"
                                              value={orderList.status || ""}
                                              style={{ widht: "100%" }}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="Approved">
                                                Approved
                                              </option>
                                              <option value="Closed">
                                                Closed
                                              </option>
                                              <option value="Exited">
                                                Exited
                                              </option>
                                            </AvField>
                                          </div>
                                        ) : (
                                          <>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="investment_request_name"
                                                  label="Investment Request Name"
                                                  type="select"
                                                  className="form-select"
                                                  disabled={
                                                    isEdit ? true : false
                                                  }
                                                  onChange={e =>
                                                    setCreationRequestId(
                                                      e.target.value
                                                    )
                                                  }
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  errorMessage="Invalid Investment Request Name"
                                                  value={
                                                    orderList.investment_request_name ||
                                                    ""
                                                  }
                                                  style={{ widht: "100%" }}
                                                >
                                                  <option disabled value="">
                                                    Select
                                                  </option>
                                                  {isEdit
                                                    ? sortingByAlphabet(
                                                        creationRequestData
                                                      )?.map((item, index) => (
                                                        <option
                                                          key={item?.id}
                                                          value={item?.id}
                                                        >
                                                          {
                                                            item?.investment_request_name
                                                          }
                                                        </option>
                                                      ))
                                                    : sortingByAlphabet(
                                                        creationData
                                                      )?.map((item, index) => (
                                                        <option
                                                          key={index}
                                                          value={item?.id}
                                                        >
                                                          {
                                                            item?.investment_request_name
                                                          }
                                                        </option>
                                                      ))}
                                                </AvField>
                                              </div>
                                            </div>
                                            {creationRequestId ||
                                            forEditRequestId ? (
                                              <>
                                                <div className="col-md-6">
                                                  <div className="mb-3">
                                                    <AvField
                                                      name="investment_type"
                                                      label="Investment Type"
                                                      type="text"
                                                      readOnly={true}
                                                      errorMessage="Invalid Investment Type"
                                                      value={
                                                        selectedRequestData?.investment_type ||
                                                        selectedEditRequest?.investment_type ||
                                                        ""
                                                      }
                                                    ></AvField>
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="mb-3">
                                                    <AvField
                                                      name="investment_amount"
                                                      label="Issue Size"
                                                      type="text"
                                                      readOnly={
                                                        isEdit ? false : true
                                                      }
                                                      errorMessage="Invalid Investment Amount"
                                                      value={
                                                        isEdit
                                                          ? orderList.issue_size
                                                          : handleAmount(
                                                              selectedRequestData?.investment_amount ||
                                                                ""
                                                            )
                                                      }
                                                    ></AvField>
                                                  </div>
                                                </div>
                                              </>
                                            ) : null}
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="sponsor_name"
                                                  label="Sponsor Master"
                                                  type="select"
                                                  className="form-select"
                                                  errorMessage="Invalid Sponsor Master"
                                                  value={
                                                    orderList.sponsor_name || ""
                                                  }
                                                  style={{ widht: "100%" }}
                                                >
                                                  <option disabled value="">
                                                    Select
                                                  </option>
                                                  {sortingByAlphabet(
                                                    sponser
                                                  ).map((item, index) => (
                                                    <option
                                                      key={index}
                                                      value={item?.id}
                                                    >
                                                      {item?.sponsor_name}
                                                    </option>
                                                  ))}
                                                </AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="project_name"
                                                  label="Project Name"
                                                  type="text"
                                                  errorMessage="Invalid Project Name"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    orderList.project_name || ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="registered_issuer_name"
                                                  label="Registered Issuer Name"
                                                  type="text"
                                                  errorMessage="Invalid Project Name"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    orderList.registered_issuer_name ||
                                                    ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="maturity_date"
                                                  label="Maturity Date"
                                                  type="date"
                                                  errorMessage=" Invalid maturity_date"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    orderList.maturity_date ||
                                                    ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="annual_return"
                                                  label={
                                                    orderList.status !==
                                                    "Exited"
                                                      ? "Annual Return (%)"
                                                      : "Exited Annual Return (%)"
                                                  }
                                                  type="number"
                                                  errorMessage="Invalid Annual Return"
                                                  value={
                                                    orderList.annual_return ===
                                                    0
                                                      ? "0"
                                                      : orderList.annual_return
                                                  }
                                                  validate={{
                                                    required: {
                                                      value:
                                                        orderList.status ===
                                                        "Exited",
                                                    },
                                                  }}
                                                  disabled={
                                                    orderList.status !==
                                                    "Exited"
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="mb-3">
                                                <AvField
                                                  name="annual_return_tooltip"
                                                  label="Annual Return Tooltip"
                                                  type="text"
                                                  validate={{
                                                    required: { value: false },
                                                  }}
                                                  value={
                                                    orderList.annual_return_tooltip ||
                                                    ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="coupon_return"
                                                  label={"Coupon Rate (%)"}
                                                  type="number"
                                                  errorMessage="Invalid Annual Return"
                                                  value={
                                                    orderList.coupon_return ===
                                                    0
                                                      ? "0"
                                                      : orderList.coupon_return
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="multiple"
                                                  label="Multiple"
                                                  type="number"
                                                  errorMessage="Invalid Multiple"
                                                  value={
                                                    orderList.multiple === 0
                                                      ? "0"
                                                      : orderList.multiple
                                                  }
                                                  style={{ widht: "100%" }}
                                                  disabled
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="interest_payment"
                                                  label="Interest Payment"
                                                  type="select"
                                                  className="form-select"
                                                  errorMessage="Invalid Interest Payment"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    orderList.interest_payment
                                                  }
                                                >
                                                  <option disabled value="">
                                                    Select
                                                  </option>
                                                  <option value="Monthly">
                                                    Monthly
                                                  </option>
                                                  <option value="Quarterly">
                                                    Quarterly
                                                  </option>
                                                  <option value="Half_Yearly">
                                                    Half yearly
                                                  </option>
                                                  <option value="Yearly">
                                                    Yearly
                                                  </option>
                                                </AvField>
                                              </div>
                                              <div className="mb-3">
                                                <AvField
                                                  name="compounding_frequency"
                                                  label="Compounding Frequency"
                                                  type="select"
                                                  className="form-select"
                                                  errorMessage="Invalid Interest Payment"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    orderList.compounding_frequency
                                                  }
                                                >
                                                  <option disabled value="">
                                                    Select
                                                  </option>
                                                  <option value="Monthly">
                                                    Monthly
                                                  </option>
                                                  <option value="Quarterly">
                                                    Quarterly
                                                  </option>
                                                  <option value="Half_Yearly">
                                                    Half yearly
                                                  </option>
                                                  <option value="Yearly">
                                                    Yearly
                                                  </option>
                                                </AvField>
                                              </div>
                                            </div>

                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                {orderList.display_pic_url ||
                                                dp ? (
                                                  <img
                                                    src={`${picUrl}${
                                                      dp ||
                                                      orderList.display_pic_url
                                                    }`}
                                                    alt="display pic"
                                                    style={{
                                                      maxWidth: "100px",
                                                      maxHeight: "120px",
                                                    }}
                                                  ></img>
                                                ) : null}
                                                {/*<div className="mt-3">
                                                <Label
                                                  htmlFor="formFile"
                                                  className="form-label"
                                                >
                                                  {orderList.display_pic_url
                                                    ? "Update Pic (Recommended Image Resolution : 696*244 pixels)"
                                                    : "Display Pic (Recommended Image Resolution : 696*244 pixels)"}
                                                </Label>
                                                <Input
                                                  className="form-control"
                                                  type="file"
                                                  id="formFile"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={handleFileUpload}
                                                />
                                              </div>
                                              */}
                                                <div className="container">
                                                  <div className="container-cropper">
                                                    {image ? (
                                                      <>
                                                        <div className="cropper">
                                                          <Cropper
                                                            image={image}
                                                            crop={crop}
                                                            zoom={zoom}
                                                            aspect={1.86}
                                                            onCropChange={
                                                              setCrop
                                                            }
                                                            onZoomChange={
                                                              setZoom
                                                            }
                                                            onCropComplete={
                                                              onCropComplete
                                                            }
                                                            style={{
                                                              containerStyle: {
                                                                height: 240,
                                                              },
                                                            }}
                                                          />
                                                        </div>

                                                        <div
                                                          className="slider"
                                                          style={{
                                                            marginTop: "190px",
                                                          }}
                                                        >
                                                          <Slider
                                                            min={1}
                                                            max={3}
                                                            step={0.1}
                                                            value={zoom}
                                                            onChange={(
                                                              e,
                                                              zoom
                                                            ) => setZoom(zoom)}
                                                          />
                                                        </div>
                                                      </>
                                                    ) : null}
                                                  </div>

                                                  <div
                                                    className="container-buttons"
                                                    style={{
                                                      marginLeft: "-20px",
                                                    }}
                                                  >
                                                    <input
                                                      type="file"
                                                      accept="image/*"
                                                      ref={inputRef}
                                                      onChange={onSelectFile}
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <label>
                                                      Card Display Pic
                                                    </label>
                                                    <div
                                                      style={{
                                                        marginTop: "50px",
                                                      }}
                                                    >
                                                      <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={
                                                          triggerFileSelectPopup
                                                        }
                                                        style={{
                                                          marginRight: "10px",
                                                        }}
                                                      >
                                                        {orderList.display_pic_url
                                                          ? "Update Image"
                                                          : "Upload Image"}
                                                      </Button>
                                                      {image && (
                                                        <Button
                                                          variant="contained"
                                                          color="primary"
                                                          onClick={onDownload}
                                                        >
                                                          Save
                                                        </Button>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12">
                                              <div className="mb-3">
                                                <Modal
                                                  isOpen={imgAltDescModal}
                                                  toggle={() => {
                                                    setImgAltDescModal(
                                                      !imgAltDescModal
                                                    )
                                                  }}
                                                >
                                                  <ModalHeader
                                                    toggle={() => {
                                                      setImgAltDescModal(
                                                        !imgAltDescModal
                                                      )
                                                    }}
                                                    tag="h3"
                                                  >
                                                    Display Pic Alt Description
                                                  </ModalHeader>
                                                  <ModalBody>
                                                    <p className="font-size-16">
                                                      This will not appear on
                                                      the website but it is
                                                      required for SEO.
                                                    </p>
                                                  </ModalBody>
                                                </Modal>
                                                <Label>
                                                  Display Pic Alt Description{" "}
                                                  <i
                                                    className="fas fa-info-circle"
                                                    id="TooltipTop"
                                                    onClick={() => {
                                                      setImgAltDescModal(true)
                                                    }}
                                                  ></i>
                                                </Label>
                                                <AvField
                                                  name="image_alt_desc"
                                                  type="textarea"
                                                  errorMessage="Invalid input"
                                                  value={
                                                    orderList.image_alt_desc ||
                                                    ""
                                                  }
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="location"
                                                  label="Location"
                                                  type="text"
                                                  errorMessage=" Invalid Location"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    orderList.location || ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="Status"
                                                  label="Status"
                                                  type="select"
                                                  className="form-select"
                                                  errorMessage=" Invalid status"
                                                  disabled={true}
                                                  value={orderList.status || ""}
                                                >
                                                  <option disabled value="">
                                                    Draft
                                                  </option>
                                                  <option value="Draft">
                                                    Draft
                                                  </option>
                                                  <option value="Sent_for_Approval">
                                                    Sent for Approval
                                                  </option>
                                                  <option value="Reject">
                                                    Reject
                                                  </option>
                                                  <option value="Re_Apply">
                                                    Reapply
                                                  </option>
                                                  <option value="Approved">
                                                    Approved
                                                  </option>
                                                  <option value="Rejected">
                                                    Rejected
                                                  </option>
                                                  <option value="Exited">
                                                    Exited
                                                  </option>
                                                  <option value="Closed">
                                                    Closed
                                                  </option>
                                                </AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="is_visible_on_website"
                                                  label="Show on Website?"
                                                  type="select"
                                                  className="form-select"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={onWebsiteVisible.toString()}
                                                  onChange={e =>
                                                    setOnWebsiteVisible(
                                                      selectStrToBool[
                                                        e.target.value
                                                      ]
                                                    )
                                                  }
                                                >
                                                  <option disabled value="">
                                                    Select
                                                  </option>
                                                  <option value={false}>
                                                    False
                                                  </option>
                                                  <option value={true}>
                                                    True
                                                  </option>
                                                </AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="show_details_on_website"
                                                  label="Show Investment Details on Website?"
                                                  type="select"
                                                  className="form-select"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={(
                                                    onWebsiteVisible ||
                                                    onDetailsPageVisible
                                                  ).toString()}
                                                  disabled={onWebsiteVisible}
                                                  onChange={e =>
                                                    setOnDetailsPageVisible(
                                                      selectStrToBool[
                                                        e.target.value
                                                      ]
                                                    )
                                                  }
                                                >
                                                  <option value={false}>
                                                    False
                                                  </option>
                                                  <option value={true}>
                                                    True
                                                  </option>
                                                </AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="featured"
                                                  label="Featured"
                                                  type="select"
                                                  className="form-select"
                                                  errorMessage=" Invalid featured"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    orderList.featured?.toString() ||
                                                    ""
                                                  }
                                                >
                                                  <option disabled value="">
                                                    Select
                                                  </option>
                                                  <option value={false}>
                                                    False
                                                  </option>
                                                  <option value={true}>
                                                    True
                                                  </option>
                                                </AvField>
                                              </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                            <div className="mb-3">
                                              <AvField
                                                name="total_funded"
                                                label="Total Funded (%)"
                                                type="number"
                                                errorMessage=" Invalid total funded"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  orderList.total_funded || ""
                                                }
                                              ></AvField>
                                            </div>
                                          </div> */}
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="slug"
                                                  label="Slug (URL for SEO)"
                                                  type="text"
                                                  errorMessage=" Invalid Slug"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={orderList.slug || ""}
                                                ></AvField>
                                              </div>
                                            </div>
                                            {/* <div className="mb-3">
                                            <AvField
                                              name="irr"
                                              label="IRR"
                                              type="number"
                                              errorMessage=" Invalid IRR"
                                              value={orderList.irr || ""}
                                            ></AvField>
                                            </div> */}
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="total_bonds"
                                                  label="Total Bonds"
                                                  type="number"
                                                  errorMessage=" Invalid Max Investment"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    orderList.total_bonds || ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="lot_price"
                                                  label="Bond Price"
                                                  type="number"
                                                  errorMessage=" Invalid  Lot Price"
                                                  value={
                                                    orderList.lot_price === 0
                                                      ? "0"
                                                      : orderList.lot_price
                                                  }
                                                  disabled={true}
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="min_lot"
                                                  label="Minimum No. of Bonds"
                                                  type="number"
                                                  disabled={true}
                                                  value={
                                                    orderList.min_lot || ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="max_lot"
                                                  label="Maximum No. of Bonds"
                                                  type="number"
                                                  disabled={true}
                                                  value={
                                                    orderList.max_lot || ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="transaction_type"
                                                  label="Transaction Type"
                                                  type="select"
                                                  className="form-select"
                                                  errorMessage=" Invalid Transaction Type"
                                                  onChange={e => {
                                                    setTransactionType(
                                                      e.target.value
                                                    )
                                                  }}
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    orderList.transaction_type ||
                                                    ""
                                                  }
                                                >
                                                  <option disabled value="">
                                                    Select
                                                  </option>
                                                  <option value="Primary">
                                                    Primary
                                                  </option>
                                                  <option value="Secondary">
                                                    Secondary
                                                  </option>
                                                </AvField>
                                              </div>
                                            </div>
                                            {(!isEdit &&
                                              transactionType == "Primary") ||
                                            (isEdit &&
                                              orderList.transaction_type ==
                                                "Primary" &&
                                              transactionType !==
                                                "Secondary") ||
                                            (isEdit &&
                                              transactionType == "Primary") ? (
                                              <>
                                                <div className="col-md-6">
                                                  <div className="mb-3">
                                                    <AvField
                                                      name="token_amount_applicable"
                                                      label="Token Amount Applicable"
                                                      type="select"
                                                      onChange={e =>
                                                        setTokenAmountApplicable(
                                                          e.target.value
                                                        )
                                                      }
                                                      className="form-select"
                                                      errorMessage=" Invalid Token Amount Applicable"
                                                      validate={{
                                                        required: {
                                                          value: true,
                                                        },
                                                      }}
                                                      value={
                                                        orderList.token_amount_applicable?.toString() ||
                                                        ""
                                                      }
                                                    >
                                                      <option disabled value="">
                                                        Select
                                                      </option>
                                                      <option value={true}>
                                                        True
                                                      </option>
                                                      <option value={false}>
                                                        False
                                                      </option>
                                                    </AvField>
                                                  </div>
                                                </div>
                                                {tokenAmountApplicable ==
                                                  "true" ||
                                                (tokenAmountApplicable == "" &&
                                                  orderList.token_amount_applicable ==
                                                    true) ? (
                                                  <>
                                                    <div className="col-md-6">
                                                      <div className="mb-3">
                                                        <AvField
                                                          name="token_fixed_amount"
                                                          label="Token Fixed Amount"
                                                          type="number"
                                                          errorMessage=" Invalid Token Fixed Amount"
                                                          validate={{
                                                            required: {
                                                              value: true,
                                                            },
                                                          }}
                                                          value={
                                                            orderList.token_fixed_amount ||
                                                            ""
                                                          }
                                                        ></AvField>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                      <div className="mb-3">
                                                        <AvField
                                                          name="token_perentage"
                                                          label="Token Percentage"
                                                          type="number"
                                                          errorMessage=" Invalid Token Percentage"
                                                          validate={{
                                                            required: {
                                                              value: true,
                                                            },
                                                          }}
                                                          value={
                                                            orderList.token_perentage ||
                                                            ""
                                                          }
                                                        ></AvField>
                                                      </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                      <div className="mb-3">
                                                        <AvField
                                                          name="token_calculation_method"
                                                          label="Token Calculation Method"
                                                          type="select"
                                                          className="form-select"
                                                          errorMessage=" Invalid Token Calculation Method"
                                                          validate={{
                                                            required: {
                                                              value: true,
                                                            },
                                                          }}
                                                          value={
                                                            orderList.token_calculation_method ||
                                                            ""
                                                          }
                                                        >
                                                          <option
                                                            disabled
                                                            value=""
                                                          >
                                                            Select
                                                          </option>
                                                          <option value="Fixed">
                                                            Fixed
                                                          </option>
                                                          <option value="Percentage">
                                                            Percentage
                                                          </option>
                                                        </AvField>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : null}
                                              </>
                                            ) : null}
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="is_ref_visible"
                                                  label="Is Ref Visible"
                                                  type="select"
                                                  className="form-select"
                                                  errorMessage=" Invalid Value"
                                                  validate={{
                                                    required: {
                                                      value: true,
                                                    },
                                                  }}
                                                  value={
                                                    orderList.is_ref_visible?.toString() ||
                                                    ""
                                                  }
                                                >
                                                  <option disabled value="">
                                                    Select
                                                  </option>
                                                  <option value={true}>
                                                    True
                                                  </option>
                                                  <option value={false}>
                                                    False
                                                  </option>
                                                </AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="release_commitment"
                                                  label="Release commitment"
                                                  type="number"
                                                  errorMessage="Invalid Value"
                                                  validate={{
                                                    required: {
                                                      value: true,
                                                    },
                                                  }}
                                                  value={
                                                    orderList.release_commitment ===
                                                    0
                                                      ? "0"
                                                      : orderList.release_commitment
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="issuer_cin"
                                                  label="Issuer CIN"
                                                  type="text"
                                                  errorMessage="Invalid Issuer CIN"
                                                  value={
                                                    orderList.issuer_cin || ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="issuer_code"
                                                  label="Issuer Code"
                                                  type="text"
                                                  errorMessage="Invalid Issuer Code"
                                                  value={
                                                    orderList.issuer_code || ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="isin_number"
                                                  label="ISIN Number"
                                                  type="text"
                                                  errorMessage="Invalid ISIN Number"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    orderList.isin_number || ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="isin_description"
                                                  label="ISIN Description"
                                                  type="text"
                                                  errorMessage="ISIN Description Required"
                                                  value={
                                                    orderList.isin_description ||
                                                    ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <label>
                                                  Select Debenture Trustee
                                                </label>
                                                <ReactSelect
                                                  users={debentureTrusteeList}
                                                  setSelectedOption={
                                                    setSelectedDebentureTrustee
                                                  }
                                                  multiOptionLabel={true}
                                                  optionLabelKeys={[
                                                    "debenture_trustee_name",
                                                    "debenture_trustee_cin",
                                                  ]}
                                                  selectedOption={
                                                    selectedDebentureTrustee
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="mb-3">
                                                <AvField
                                                  name="redemption_start_date"
                                                  label="Redemption Start Date"
                                                  type="date"
                                                  errorMessage="Invalid redemption_start_date"
                                                  value={
                                                    orderList.redemption_start_date ||
                                                    ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div> */}
                                            <div className="col-md-12">
                                              <div className="mb-3">
                                                <AvField
                                                  name="share_msg"
                                                  label="Share Message"
                                                  type="textarea"
                                                  errorMessage=" Invalid input"
                                                  validate={{
                                                    required: {
                                                      value: true,
                                                    },
                                                  }}
                                                  value={
                                                    orderList.share_msg || ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div>
                                            {/* <div className="col-12">
                                              <div className="mb-3">
                                                <AvField
                                                  name="stf_disclaimer"
                                                  label="STF Disclaimer"
                                                  type="textarea"
                                                  errorMessage=" Invalid input"
                                                  value={
                                                    orderList.stf_disclaimer ||
                                                    ""
                                                  }
                                                ></AvField>
                                              </div>
                                            </div> */}
                                          </>
                                        )}
                                      </div>
                                      {!dealStatusUpdate && (
                                        <Col className="col-12">
                                          {/* <div className="mb-3">
                                            <AvField
                                              name="stf_risk_disclosures"
                                              label="STF Risk Disclosure"
                                              type="textarea"
                                              errorMessage=" Invalid input"
                                              value={
                                                orderList.stf_risk_disclosures ||
                                                ""
                                              }
                                            ></AvField>
                                          </div> */}
                                          <div
                                            className="mb-3"
                                            style={{ fontWeight: "bold" }}
                                          >
                                            Description
                                          </div>
                                          <div
                                            className="text-editor"
                                            style={{
                                              width: "100%",
                                              marginBottom: "5px",
                                            }}
                                          >
                                            <EditorToolbar />
                                            <ReactQuill
                                              theme="snow"
                                              value={
                                                descriptionState?.value ||
                                                orderList?.description ||
                                                " "
                                              }
                                              onChange={handleDescriptionChange}
                                              placeholder={
                                                "Write something awesome..."
                                              }
                                              modules={modules}
                                              formats={formats}
                                            />
                                          </div>
                                        </Col>
                                      )}
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

Investment.propTypes = {
  orders: PropTypes.array,
}
export default withRouter(Investment)
