import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { investmentData } from "constants/common"
import { sortingByAlphabet } from "constants/sort"
import getCroppedImg from "../AssetImage/cropImage"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { picUrl } from "../../constants/common"
import Loader from "common/Loader"
import { showToastError, showToastSuccess } from "utils"
import Cropper from "react-easy-crop"
import Slider from "@material-ui/core/Slider"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const InvestmentProgressPhoto = () => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [investments, setInvestments] = useState([])
  const [dp, setDp] = useState(orderList.display_pic_url)
  const inputRef = React.useRef()
  const inputVideoRef = React.useRef()
  const triggerFileSelectPopup = () => inputRef.current.click()
  const triggerVideoFileSelectPopup = () => inputVideoRef.current.click()
  const [image, setImage] = useState(null)
  const [croppedArea, setCroppedArea] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [file, setFile] = useState(null)

  const [assetType, setAssetType] = useState("")
  const [videoFile, setVideoFile] = useState(null)
  const [videoUrl, setVideoUrl] = useState("")
  const [videoRemoteSrc, setVideoRemoteSrc] = useState("")
  const [imgAltDescModal, setImgAltDescModal] = useState(false)

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/investment-progress-photo?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setData(response?.data?.data)
        setLimit(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    const investments = await investmentData()
    if (investments) {
      setInvestments(investments?.data?.data)
      setLoading(false)
    } else {
      toast.error("something went wrong")
      setLoading(false)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  const selectRow = {
    mode: "checkbox",
  }

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search

  const InvestmentProgressColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessControlData?.permissions?.PROGRESS_MEDIA_EDIT ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {accessControlData?.permissions?.PROGRESS_MEDIA_EDIT}
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
            {accessControlData?.permissions?.PROGRESS_MEDIA_DELETE ? (
              <Link
                to="#"
                className="text-danger"
                onClick={() => handleDeleteOrder(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {accessControlData?.permissions?.PROGRESS_MEDIA_DELETE}
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },

    {
      dataField: "investment.project_name",
      text: "Project Name",
      sort: true,
    },

    {
      dataField: "disp_order",
      text: "Display Order",
      sort: true,
    },

    {
      dataField: "desc",
      text: "Description",
      sort: true,
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
    },
    {
      dataField: "img_url",
      text: "Image",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidImage(row.img_url),
    },
    {
      dataField: "image_alt_desc",
      text: "Image Alt Description",
      sort: true,
    },

    {
      dataField: "description",
      text: "Video Description",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Created On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },

    {
      dataField: "updated_at",
      text: "Updated On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  const handleValidImage = img => {
    const Image = (
      <img
        src={`${picUrl}${img}`}
        alt="Image"
        style={{ maxWidth: "100px", maxHeight: "120px" }}
      ></img>
    )
    return Image
  }

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const handleOrderClick = arg => {
    const order = arg
    setImage(null)
    setZoom(1)
    setDp("")
    setAssetType(order.type)
    setVideoFile(null)
    setVideoRemoteSrc(order.video)
    setVideoUrl("")
    setOrderList({
      id: order.id,
      project_name: order.investmentId,
      disp_order: order.disp_order,
      desc: order.desc,
      img_url: order.img_url,
      type: order.type,
      video: order.video,
      description: order.description,
      image_alt_desc: order.image_alt_desc,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDeleteOrder = async order => {
    setLoading(true)

    var r = confirm(`Are you sure want to delete`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `/investment-progress-photo/${order.id}`
        )
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    setLoading(false)
  }
  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEdit) {
      const updateOrder = {
        investmentId: values.project_name,
        type: values.type,
        desc: values.desc,
        img_url: dp || orderList.img_url,
        disp_order: values.disp_order,
      }

      // update order
      if (assetType == "video") {
        if (!videoRemoteSrc) {
          toast.error("Please upload valid video")
          setLoading(false)
          return
        }
        updateOrder["video"] = videoRemoteSrc
        updateOrder["description"] = values.description
      }
      if (assetType == "img") {
        updateOrder["image_alt_desc"] = values.image_alt_desc
      }
      try {
        const response = await axiosInstance.patch(
          `/investment-progress-photo/${orderList.id}`,
          updateOrder
        )
        if (response) {
          masterData()
          setDp(null)
          setVideoFile(null)
          setVideoRemoteSrc("")
          setVideoUrl("")
          showToastSuccess("Successfully Edited")
        }
      } catch (error) {
        showToastError(error.message)
      }
    } else {
      const newOrder = {
        investmentId: values.project_name,
        desc: values.desc,
        type: values.type,
        img_url: dp || orderList.img_url,
        disp_order: values.disp_order,
      }
      // save new order
      if (assetType == "video") {
        if (!videoRemoteSrc) {
          toast.error("Please upload valid video")
          setLoading(false)
          return
        }
        newOrder["video"] = videoRemoteSrc
        newOrder["description"] = values.description
      }
      if (assetType == "img") {
        newOrder["image_alt_desc"] = values.image_alt_desc
      }
      if (!newOrder.img_url) {
        toast.error("Please upload valid Image ")
        setLoading(false)
        return
      }
      try {
        const response = await axiosInstance.post(
          `investment-progress-photo`,
          newOrder
        )
        if (response) {
          masterData()
          setDp(null)
          setVideoFile(null)
          setVideoRemoteSrc("")
          setVideoUrl("")
          showToastSuccess(" Asset Successfully Added")
        }
      } catch (error) {
        showToastError(error.message)
      }
    }
    toggle()
    setLoading(false)
  }

  const handleOrderClicks = () => {
    setImage(null)
    setOrderList("")
    setIsEdit(false)
    setDp("")
    setAssetType("")
    setVideoFile(null)
    setVideoRemoteSrc("")
    setVideoUrl("")
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels)
  }

  const onSelectFile = event => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.addEventListener("load", () => {
        setImage(reader.result)
      })
      if (inputRef.current) {
        inputRef.current.value = null
      }
    }
  }
  const onSelectVideoFile = event => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader()
      setVideoFile(event.target.files[0])
      reader.readAsDataURL(event.target.files[0])
      reader.addEventListener("load", () => {
        setVideoUrl(reader.result)
      })
      if (inputVideoRef.current) {
        inputVideoRef.current.value = null
      }
    }
  }

  const uploadvideo = () => {
    const file = videoFile
    setFile(file)
  }

  const onDownload = () => {
    const response = getCroppedImg(image, croppedArea)
    if (response) {
      response.then(file => setFile(file))
      setImage(null)
      setZoom(1)
    }
  }

  useEffect(async () => {
    if (file) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=underlying_asset_image`,
          formData
        )
        if (response) {
          if (videoFile) {
            setVideoFile(null)
            setVideoRemoteSrc(response.data[0].name)
          } else {
            setDp(response.data[0].name)
          }

          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [file])

  useEffect(async () => {
    setZoom(1)
    setDp("")
    setImage(null)
    setVideoFile(null)
  }, [assetType])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.PROGRESS_MEDIA_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={InvestmentProgressColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              {accessControlData?.permissions
                                ?.PROGRESS_MEDIA_ADD && (
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleOrderClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    {
                                      accessControlData?.permissions
                                        ?.PROGRESS_MEDIA_ADD
                                    }
                                  </Button>
                                </div>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                columns={InvestmentProgressColumns(toggle)}
                                data={orders}
                                pagination={paginationFactory(pageOptions)}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit
                                    ? "Edit Progress Media"
                                    : "Add Progress Media"}
                                </ModalHeader>
                                <ModalBody>
                                  {loading && <Loader />}
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <AvField
                                            name="project_name"
                                            label="Project Name"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                            sort="true"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.project_name || ""}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {sortingByAlphabet(
                                              investments?.map(
                                                (item, index) => (
                                                  <option
                                                    key={item?.project_name}
                                                    value={item?.id}
                                                  >
                                                    {item?.project_name}
                                                  </option>
                                                )
                                              )
                                            )}
                                          </AvField>
                                        </div>

                                        <div className="mb-3">
                                          <AvField
                                            name="disp_order"
                                            label="Disp Order"
                                            type="number"
                                            errormessage="Invalid Disp Order"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.disp_order || ""}
                                          ></AvField>
                                        </div>

                                        <div className="mb-3">
                                          <AvField
                                            name="type"
                                            label="Type"
                                            type="select"
                                            className="form-select"
                                            errormessage="Invalid Type"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            disabled={(videoFile, isEdit)}
                                            value={assetType}
                                            onChange={e => {
                                              setAssetType(e.target.value)
                                            }}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            <option value="img">Image</option>
                                            <option value="video">Video</option>
                                          </AvField>
                                        </div>
                                        {assetType == "img" ? (
                                          <div className="mb-3">
                                            <AvField
                                              name="desc"
                                              label="Description"
                                              type="textarea"
                                              errorMessage="Invalid Description"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={orderList.desc || ""}
                                            />
                                          </div>
                                        ) : null}
                                        {assetType == "video" ? (
                                          <div className="mb-3">
                                            <AvField
                                              name="description"
                                              label="Video Description"
                                              type="textarea"
                                              errormessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList.description || ""
                                              }
                                            ></AvField>
                                          </div>
                                        ) : null}
                                        {assetType == "img" ? (
                                          <div className="mb-3">
                                            <Modal
                                              isOpen={imgAltDescModal}
                                              toggle={() => {
                                                setImgAltDescModal(
                                                  !imgAltDescModal
                                                )
                                              }}
                                            >
                                              <ModalHeader
                                                toggle={() => {
                                                  setImgAltDescModal(
                                                    !imgAltDescModal
                                                  )
                                                }}
                                                tag="h3"
                                              >
                                                Image Alt Description
                                              </ModalHeader>
                                              <ModalBody>
                                                <p className="font-size-16">
                                                  This will not appear on the
                                                  website but it is required for
                                                  SEO.
                                                </p>
                                              </ModalBody>
                                            </Modal>
                                            <Label>
                                              Image Alt Description{" "}
                                              <i
                                                className="fas fa-info-circle"
                                                id="TooltipTop"
                                                onClick={() => {
                                                  setImgAltDescModal(true)
                                                }}
                                              ></i>
                                            </Label>
                                            <AvField
                                              name="image_alt_desc"
                                              type="textarea"
                                              errormessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList.image_alt_desc || ""
                                              }
                                            ></AvField>
                                          </div>
                                        ) : null}
                                        <div className="d-flex gap-2">
                                          {orderList.img_url || dp ? (
                                            <img
                                              src={`${picUrl}${
                                                dp || orderList.img_url
                                              }`}
                                              alt="Image"
                                              style={{
                                                width: "50%",
                                                maxHeight: "130px",
                                                marginBottom: "10px",
                                              }}
                                            ></img>
                                          ) : null}
                                          {assetType == "video" &&
                                          (videoRemoteSrc || videoUrl) ? (
                                            <video
                                              style={{
                                                width: "50%",
                                                maxHeight: "150px",
                                                marginBottom: "10px",
                                              }}
                                              controls={true}
                                              src={
                                                videoUrl
                                                  ? videoUrl
                                                  : picUrl + videoRemoteSrc
                                              }
                                            >
                                              {/* <source
                                                src={videoUrl}
                                                type="video/mp4"
                                              /> */}
                                            </video>
                                          ) : null}
                                        </div>
                                        <div className="container">
                                          <div className="container-cropper">
                                            {image ? (
                                              <>
                                                <div className="cropper">
                                                  <Cropper
                                                    image={image}
                                                    crop={crop}
                                                    zoom={zoom}
                                                    aspect={1.58}
                                                    onCropChange={setCrop}
                                                    onZoomChange={setZoom}
                                                    onCropComplete={
                                                      onCropComplete
                                                    }
                                                    style={{
                                                      containerStyle: {
                                                        height: 340,
                                                      },
                                                    }}
                                                  />
                                                </div>

                                                <div
                                                  className="slider"
                                                  style={
                                                    {
                                                      // marginTop: "190px",
                                                    }
                                                  }
                                                >
                                                  <Slider
                                                    min={1}
                                                    max={3}
                                                    step={0.1}
                                                    value={zoom}
                                                    onChange={(e, zoom) =>
                                                      setZoom(zoom)
                                                    }
                                                  />
                                                </div>
                                              </>
                                            ) : null}
                                          </div>
                                          {assetType ? (
                                            <div
                                              className="container-buttons"
                                              style={{
                                                marginLeft: "-10px",
                                              }}
                                            >
                                              <input
                                                type="file"
                                                accept="image/*"
                                                ref={inputRef}
                                                onChange={onSelectFile}
                                                style={{ display: "none" }}
                                              />

                                              <div>
                                                {!videoFile ? (
                                                  <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={
                                                      triggerFileSelectPopup
                                                    }
                                                    style={{
                                                      marginRight: "15px",
                                                    }}
                                                  >
                                                    {orderList.img_url
                                                      ? "Update Image"
                                                      : "Upload Image"}
                                                  </Button>
                                                ) : null}
                                                {assetType == "video" &&
                                                !image ? (
                                                  <>
                                                    <input
                                                      type="file"
                                                      accept="video/*"
                                                      ref={inputVideoRef}
                                                      onChange={
                                                        onSelectVideoFile
                                                      }
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <Button
                                                      variant="contained"
                                                      color="primary"
                                                      onClick={() => {
                                                        // setVideoFile(null)
                                                        // setVideoUrl("")
                                                        triggerVideoFileSelectPopup()
                                                      }}
                                                      style={{
                                                        marginRight: "10px",
                                                      }}
                                                    >
                                                      {videoRemoteSrc ||
                                                      videoUrl
                                                        ? "Update Video"
                                                        : "Upload Video"}
                                                    </Button>
                                                  </>
                                                ) : null}
                                                {image || videoFile ? (
                                                  <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={
                                                      videoFile
                                                        ? uploadvideo
                                                        : onDownload
                                                    }
                                                  >
                                                    Save
                                                  </Button>
                                                ) : null}
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          {!image && !videoFile ? (
                                            <button
                                              type="submit"
                                              className="btn btn-success save-user"
                                              style={{ marginTop: "5px" }}
                                            >
                                              Save
                                            </button>
                                          ) : null}
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

InvestmentProgressPhoto.propTypes = {
  orders: PropTypes.array,
}

export default withRouter(InvestmentProgressPhoto)
