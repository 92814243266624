import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { investmentData } from "constants/common"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import Loader from "common/Loader"
import { sortingByAlphabet } from "constants/sort"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const InvestmentResource = () => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [file, setFile] = useState(null)
  const [dp, setDp] = useState("")
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [sponser, setSponser] = useState([])

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/investment-resource?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setData(response?.data?.data)
        setLimit(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  const selectRow = {
    mode: "checkbox",
  }

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const ResourceColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessControlData?.permissions?.INVESTMENT_RESOURCES_EDIT ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {accessControlData?.permissions?.INVESTMENT_RESOURCES_EDIT}
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
            {accessControlData?.permissions?.INVESTMENT_RESOURCES_DELETE ? (
              <Link
                to="#"
                className="text-danger"
                onClick={() => handleDeleteOrder(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {accessControlData?.permissions?.INVESTMENT_RESOURCES_DELETE}
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },

    {
      dataField: "investment",
      text: "Project Name",
      sort: true,
      formatter: (cellContent, row) => handleSponsorName(row.investment),
    },

    {
      dataField: "type",
      text: "Resource Type",
      sort: true,
    },

    {
      dataField: "text",
      text: "Text",
      sort: true,
    },
    {
      dataField: "disp_order",
      text: "Disp Order",
      sort: true,
    },

    {
      dataField: "doc",
      text: "Doc",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() => handleDocList(order.doc)}
            >
              {order.doc}
            </Link>
          </div>
        </>
      ),
    },

    {
      dataField: "updated_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  const handleSponsorName = overview => {
    const overviewSorted = overview?.project_name
    return overviewSorted
  }

  const handleDocList = file => {
    handleDoc(file)
  }

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const handleOrderClick = arg => {
    const order = arg

    setOrderList({
      id: order.id,
      project_name: order.investmentId,
      type: order.type,
      text: order.text,
      disp_order: order.disp_order,
      doc: order.doc,
      created_at: order.created_at,
      desc: order.desc,
      is_downloadable: order.is_downloadable,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDeleteOrder = async order => {
    setLoading(true)

    var r = confirm(`Are you sure want to delete ${order.text}`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `investment-resource/${order.id}`
        )
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    setLoading(false)
  }
  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEdit) {
      const updateOrder = {
        investmentId: values.project_name,
        text: values.text,
        type: values.type,
        disp_order: values.disp_order,
        doc: dp || orderList.doc,
        desc: values.desc,
        is_downloadable: values.is_downloadable,
        created_at: values.created_at,
      }

      // update order

      try {
        const response = await axiosInstance.patch(
          `investment-resource/${orderList.id}`,
          updateOrder
        )
        if (response) {
          masterData()
          toast.success("Successfully Edited")
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      const newOrder = {
        investmentId: values["project_name"],
        text: values["text"],
        disp_order: values["disp_order"],
        type: values.type,
        doc: dp,
        desc: values.desc,
        is_downloadable: values.is_downloadable,
        created_at: values["created_at"],
      }
      // save new order

      try {
        const response = await axiosInstance.post(
          `investment-resource`,
          newOrder
        )
        if (response) {
          masterData()
          toast.success(" Resource Successfully Added")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    toggle()
    setLoading(false)
  }

  const handleOrderClicks = () => {
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  useEffect(async () => {
    try {
      const response = await investmentData()
      if (response) {
        setSponser(response?.data?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }, [])

  const handleFileUpload = e => {
    setFile(e.target.files[0])
  }
  const [investId, setInvestId] = useState("")
  const handleInvestmentId = e => {
    setInvestId(e.target.value)
  }
  useEffect(async () => {
    setLoading(true)
    if (file) {
      const formData = new FormData()
      formData.append("file", file)
      formData.append("investmentId", orderList.project_name || investId)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=investment_doc`,
          formData
        )
        if (response) {
          setDp(response.data[0].name)
        }
      } catch (error) {
        toast.error(error?.message)
      }
    }
    setLoading(false)
  }, [file])

  const handleDoc = async file => {
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.INVESTMENT_RESOURCES_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={ResourceColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              {accessControlData?.permissions
                                ?.INVESTMENT_RESOURCES_ADD && (
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleOrderClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    {
                                      accessControlData?.permissions
                                        ?.INVESTMENT_RESOURCES_ADD
                                    }
                                  </Button>
                                </div>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                columns={ResourceColumns(toggle)}
                                data={orders}
                                pagination={paginationFactory(pageOptions)}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit ? "Edit Resource" : "Add Resource"}
                                </ModalHeader>
                                <ModalBody>
                                  {loading && <Loader />}
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <AvField
                                            name="project_name"
                                            label="Project Name"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid Project Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.project_name || ""}
                                            onChange={handleInvestmentId}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {sortingByAlphabet(sponser).map(
                                              (item, index) => (
                                                <option
                                                  key={item.project_name}
                                                  value={item.id}
                                                >
                                                  {item.project_name}
                                                </option>
                                              )
                                            )}
                                          </AvField>
                                        </div>

                                        <div
                                          className="mb-3"
                                          style={{ marginTop: "10px" }}
                                        >
                                          <AvField
                                            name="type"
                                            label="Resource Type"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.type || ""}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            <option value="Resource">
                                              Resource
                                            </option>
                                            <option value="Investment_Status">
                                              Investment Status
                                            </option>
                                          </AvField>
                                        </div>

                                        <div className="mb-3">
                                          <AvField
                                            name="text"
                                            label="Text"
                                            type="text"
                                            errorMessage="Invalid Text"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.text || ""}
                                          ></AvField>
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="disp_order"
                                            label="Disp Order"
                                            type="number"
                                            errorMessage="Invalid Disp Order"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.disp_order || ""}
                                          />
                                        </div>
                                        {orderList.doc ? (
                                          <p
                                            onClick={() =>
                                              handleDoc(orderList.doc)
                                            }
                                            style={{
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {orderList.doc}
                                          </p>
                                        ) : null}
                                        <div className="mt-3">
                                          <Label
                                            htmlFor="formFile"
                                            className="form-label"
                                          >
                                            {orderList.doc
                                              ? "Update doc (Pdf)"
                                              : "Upload doc (Pdf)"}
                                          </Label>
                                          <Input
                                            className="form-control"
                                            type="file"
                                            id="formFile"
                                            accept=".pdf"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={handleFileUpload}
                                          />
                                        </div>
                                        <div
                                          className="mb-3"
                                          style={{ marginTop: "10px" }}
                                        >
                                          <AvField
                                            name="is_downloadable"
                                            label="Is Doc Downloadable"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList.is_downloadable?.toString() ||
                                              ""
                                            }
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            <option value="true">True</option>
                                            <option value="false">False</option>
                                          </AvField>
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="desc"
                                            label="Description"
                                            type="textarea"
                                            errorMessage="Invalid Description"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.desc || ""}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

InvestmentResource.propTypes = {
  orders: PropTypes.array,
}

export default withRouter(InvestmentResource)
