import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  distributorUserInvData,
  distributorInvData,
  csvDownloadData,
  isInvestmentTrancheOpen,
  getTimestamp,
  getActiveInvestmentLot,
} from "constants/common"
import {
  AccessId,
  invStageForRm,
  StatusObjForUserInvestment,
} from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { humanize, getInvestmentLotByProject } from "constants/common"
import { sortingByAlphabet } from "constants/sort"
import ReactSelect from "constants/ReactSelect"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const RmUserInvestment = () => {
  const [orders, setData] = useState([])

  const [investmentList, setInvestmentList] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [selectedUser, setSelectedUser] = useState({})
  const [investmentFilter, setInvestmentFilter] = useState({})
  const [selectInvStage, setSelectInvStage] = useState({})
  const [userFilter, setUserFilter] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const [total, setTotal] = useState(0)
  const [isUserInvExists, setIsUserInvExists] = useState(false)

  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState()
  const [investments, setInvestments] = useState([])
  const [modal, setModal] = useState(false)
  const [reload, setReload] = useState(true)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [selectedProject, setSelectedProject] = useState({})
  const [lotSize, setLotSize] = useState()
  const [userInvestment, setUserInvestment] = useState(1)
  const [userInvestmentRes, setUserInvestmentRes] = useState({})
  const [vpaDetails, setVpaDetails] = useState({})
  const [userInvPatchSuccess, setUserInvPatchSuccess] = useState(false)
  const [userInvPostSuccess, setUserInvPostSuccess] = useState(false)
  const [customBankDetails, setCustomBankDetails] = useState()

  const [commitmentAmount, setCommitmentAmount] = useState("")
  const [tcsAmount, setTcsAmount] = useState("")
  const [tcsModal, setTcsModal] = useState(false)
  const [breakupData, setBreakupData] = useState()
  const [trancheList, setTrancheList] = useState([])
  const [selectedTranche, setSelectedTranche] = useState(null)
  const [investmentDate, setInvestmentDate] = useState(
    moment().format("YYYY-MM-DD")
  )

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const maxDate = moment().add(10, "days").format("YYYY-MM-DD")
  const minDate = moment(new Date()).subtract(2, "days").format("YYYY-MM-DD")

  const masterData = async () => {
    setLoading(true)

    const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
      investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
    }${
      selectInvStage?.id
        ? `&inv_stage=${selectInvStage.id}`
        : "&inv_stage[$ne]=Completed"
    }${
      investmentFilter?.id && selectedTranche?.id
        ? `&investmentLotId=${selectedTranche?.id}`
        : ""
    }`

    const userInvGetUrl = `admin-user-investment-listing?$limit=${limit}&$skip=${skip}&sort[created_at]=-1&relManagerId=${localUser.id}${filterUrl}`

    try {
      const userInvResponse = await axiosInstance.get(userInvGetUrl)

      if (userInvResponse) {
        setTotal(userInvResponse.data.total)
        setData(userInvResponse?.data?.data || userInvResponse?.data)
        let pages = Math.ceil(
          (userInvResponse.data?.total || userInvResponse.total) /
            (userInvResponse.data?.limit || userInvResponse.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }

    try {
      const invResponse = await distributorInvData()
      if (invResponse) {
        setInvestmentList(invResponse?.data?.data || invResponse?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "projectType":
        setInvestmentFilter(e)
        break
      case "invLots":
        setSelectedTranche(e)
        break
      case "invStage":
        setSelectInvStage(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const handleUserSearch = async e => {
    setSearchValue(e)
    if (e.length >= 3) {
      setLoading(true)
      if (e) {
        const usersRes = await axiosInstance.get(
          `/users?$sort[created_at]=-1&$or[0][permissions]=USER&$or[0][permissions]=OPS_USER&relManagerId=${localUser.id}&is_registered=true`
        )
        const users = usersRes?.data?.data || usersRes?.data
        if (users) {
          setUsers(users)
        } else {
          toast.error("something went wrong")
        }
      } else {
        setUsers([])
      }
      setLoading(false)
    }
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(() => {
    masterData()
    setReload(false)
  }, [limit, skip, selectInvStage, investmentFilter, selectedTranche])

  useEffect(async () => {
    setSelectedTranche(null)
    if (investmentFilter?.id) {
      const invLots = await getInvestmentLotByProject(investmentFilter?.id)
      setTrancheList(invLots)
    } else {
      setTrancheList([])
    }
  }, [investmentFilter])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const selectRow = {
    mode: "checkbox",
  }

  const UserInvestmentColumns = (download = false) => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => {
        return (
          <>
            <div className="d-flex gap-3">
              {accessControlData?.permissions
                ?.SALES_AND_INVESTOR_SERVICE_ADDMODIFY_INVESTMENTS_EDIT &&
              order.pendings.investment.status == "Approved" &&
              isInvestmentTrancheOpen(order.pendings) &&
              order.inv_stage != "Completed" ? (
                <Link
                  to={{}}
                  className="text-success"
                  onClick={() => handleOrderClick(order)}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              ) : (
                "NA"
              )}
            </div>
          </>
        )
      },
    },

    {
      dataField: "created_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
    {
      dataField: "project_name",
      text: "Project Name",
      sort: true,
    },
    {
      dataField: "investment_lot_name",
      text: "Investment Lot",
      sort: true,
    },
    {
      dataField: "user_name",
      text: "Investor Name",
      sort: true,
    },
    {
      dataField: "inv_stage",
      text: "Investment Stage",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const status = StatusObjForUserInvestment.find(
          obj => obj.id == cellContent
        )
        return (
          <span
            className={`badge badge-pill font-size-12 badge-soft-${
              status ? status?.colorClassName : "secondary"
            }`}
          >
            {status?.statusText || cellContent}
          </span>
        )
      },
    },
    {
      dataField: "lot_size",
      text: "Bonds",
      sort: true,
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{row.lot_size}</div>
      ),
    },
    {
      dataField: "amount",
      text: "Commitment",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.amount)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "tcs_amount",
      text: "Tcs Amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.tcs_amount)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: download ? "amount_payable" : "",
      text: "Amount Payable",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">
          {handleAmount(row.pendings.current_tranche_all + row.tcs_amount)}
        </div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "pendings.amountPaid",
      text: "Amount Paid",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.pendings.amountPaid)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: download ? "balance_payable" : "pendings.payable_amount",
      text: "Balance Payable",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">
          {handleAmount(
            row.pendings.current_tranche_all +
              row.tcs_amount -
              row.pendings.amountPaid
          )}
        </div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "email",
      text: "User Email",
      sort: true,
    },

    {
      dataField: "phone",
      text: "User Phone",
      sort: true,
    },
  ]
  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  const investmentCalc = async (lot = lotSize, date = investmentDate) => {
    const lots =
      lot < selectedProject?.min_lot || lot > selectedProject?.max_lot ? 0 : lot
    setLoading(true)
    try {
      const getUrl = `investment-calc-new/${selectedProject?.id}?multr=${lots}&userId=${selectedUser?.id}&date=${date}`
      // check user investment already exists for this user in this project
      const existingUserInv = await axiosInstance.get(
        `admin-user-investment-listing?investmentId=${selectedProject?.id}&userId=${selectedUser?.id}`
      )
      if (existingUserInv) {
        const userInv = existingUserInv.data?.data || existingUserInv.data
        if (userInv.length) {
          setIsUserInvExists(true)
          setCommitmentAmount(0)
          setTcsAmount(0)
          setBreakupData({
            face_value: 0,
            called_face_value: 0,
            redeemed_face_value: 0,
            accrued_interest: 0,
            annual_return: 0,
          })
          if (userInv[0].inv_stage == "Completed") {
            toast.error(
              "User investment already exists, create top-up user investment"
            )
            setLoading(false)
            return
          }
          if (!isEdit) {
            toast.error("Edit existing user investment for this user")
            setLoading(false)
            return
          }
        }
      }
      setIsUserInvExists(false)
      const tcsAmountRes = await axiosInstance.get(getUrl)
      if (tcsAmountRes) {
        setCommitmentAmount(
          Math.floor(tcsAmountRes?.data?.base_amount).toLocaleString("en-IN")
        )
        setTcsAmount(
          Math.ceil(tcsAmountRes?.data?.tcs_amount).toLocaleString("en-IN")
        )
        setBreakupData({
          face_value: tcsAmountRes?.data?.face_value * Number(lots),
          called_face_value:
            tcsAmountRes?.data?.called_face_value * Number(lots),
          redeemed_face_value:
            tcsAmountRes?.data?.redeemed_face_value * Number(lots),
          accrued_interest: tcsAmountRes?.data?.accrued_interest * Number(lots),
          premium: tcsAmountRes?.data?.premium * Number(lots),
          annual_return: tcsAmountRes?.data?.annual_return,
          bond_price: tcsAmountRes?.data?.bond_price,
        })
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleProjectName = e => {
    const currentInv = investments.find(val => val.id == e.target.value)
    setSelectedProject(currentInv)
    let event = {
      target: {
        value: currentInv.min_lot,
      },
    }
    handleLotSize(event)
  }
  const handleLotSize = async e => {
    let lotSize = parseInt(e.target.value)
    setLotSize(lotSize)
    if (
      isEdit &&
      selectedProject?.id &&
      selectedUser?.id &&
      (lotSize || lotSize === 0) &&
      investmentDate
    ) {
      investmentCalc(lotSize)
    }
  }

  const handleInvestmentDate = e => {
    const date = e.target.value
    setInvestmentDate(date)
    if (isEdit && selectedProject?.id && selectedUser?.id && lotSize && date) {
      investmentCalc(lotSize, date)
    }
  }

  useEffect(async () => {
    if (
      !isEdit &&
      selectedProject?.id &&
      selectedUser?.id &&
      (lotSize || lotSize === 0) &&
      investmentDate
    ) {
      investmentCalc()
    }
  }, [selectedProject, selectedUser, lotSize, investmentDate])

  const handleOrderClick = async arg => {
    setIsEdit(true)

    const order = arg
    setLoading(true)

    const investmentRes = await distributorInvData()
    setLoading(false)
    const currentInv = investmentRes?.data?.data.find(
      val => val.id == order.investmentId
    )

    const usersRes = await axiosInstance.get(
      `/users?$sort[created_at]=-1&$or[0][permissions]=USER&$or[0][permissions]=OPS_USER&relManagerId=${localUser.id}&is_registered=true`
    )
    if (usersRes) {
      const users = usersRes?.data?.data || usersRes?.data
      setUsers(users)
      const selectedUser = users?.find(el => el.id == order.userId)
      setSelectedUser(selectedUser)
    }
    setLoading(false)

    if (
      currentInv.investment_tranche.bank_account_type === "VPA" ||
      (currentInv.investment_tranche.bank_account_type === "VPA_AND_CUSTOM" &&
        order.amount <= currentInv.investment_tranche.vpa_threshold_amount)
    ) {
      setCustomBankDetails()
    } else {
      setCustomBankDetails(currentInv.investment_tranche?.transferor)
    }
    setInvestmentDate(moment(new Date(order.created_at)).format("YYYY-MM-DD"))
    setBreakupData({
      face_value: order?.face_value,
      called_face_value: order?.called_face_value,
      redeemed_face_value: order?.redeemed_face_value,
      accrued_interest: order?.accrued_interest,
      premium: order?.premium,
      annual_return: order?.annual_return,
    })
    setSelectedProject(currentInv)
    setLotSize(order.lot_size || order.min_lot)
    setCommitmentAmount(Math.floor(order.amount).toLocaleString("en-IN"))
    setTcsAmount(Math.ceil(order.tcs_amount).toLocaleString("en-IN"))
    setOrderList({
      id: order.id,
      amount: order.amount,
      currency: order.currency,
      email: order.email,
      inv_stage: "Commitment",
      project_name: order.investmentId,
      is_balance_amount_received: order.is_balance_amount_received,
      is_token_amount_received: order.is_token_amount_received,
      lot_size: order.lot_size,
      phone: order.phone,
      userId: order.userId,
      name: order.user_name,
      name_on_pan: order.name_on_pan,
      pan_number: order.pan_number,
    })
    toggle()
  }

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (userInvestment == 1 && selectedUser) {
      if (isEdit) {
        if (
          (selectedUser.id || orderList?.userId) &&
          values.project_name &&
          lotSize &&
          values.amount &&
          localUser.id
        ) {
          if (
            lotSize >= selectedProject.min_lot &&
            lotSize <= selectedProject.max_lot
          ) {
            const updateOrder = {
              userId: selectedUser.id || orderList?.userId,
              investmentId: values.project_name,
              lot_size: lotSize,
              inv_stage: "Commitment",
              amount: parseFloat(values.amount.replaceAll(",", "")) || 0,
              tcs_amount: parseFloat(tcsAmount?.replaceAll(",", "")) || 0,
              face_value: parseFloat(values.face_value.replaceAll(",", "")),
              called_face_value: parseFloat(
                values.called_face_value.replaceAll(",", "")
              ),
              redeemed_face_value: parseFloat(
                values.redeemed_face_value.replaceAll(",", "")
              ),
              accrued_interest: parseFloat(
                values.accrued_interest.replaceAll(",", "")
              ),
              premium: parseFloat(values.premium.replaceAll(",", "")),
              annual_return: parseFloat(breakupData?.annual_return),
              created_at: getTimestamp(investmentDate),
            }
            // update order

            try {
              const activeinvLot = await getActiveInvestmentLot(
                values.project_name
              )
              if (activeinvLot) {
                updateOrder["investmentLotId"] = activeinvLot.id
              }
              const response = await axiosInstance.patch(
                `user-investment/${orderList.id}?send_email=false`,
                updateOrder
              )
              if (response) {
                if (
                  response.data?.pendings?.investment_tranche
                    .bank_account_type === "VPA" ||
                  (response.data?.pendings?.investment_tranche
                    .bank_account_type === "VPA_AND_CUSTOM" &&
                    response.data?.amount <=
                      response.data?.pendings?.investment_tranche
                        ?.vpa_threshold_amount)
                ) {
                  setCustomBankDetails()
                } else {
                  setCustomBankDetails(
                    response.data.pendings.investment_tranche?.transferor
                  )
                }
                setUserInvestmentRes(response.data)
                if (selectedProject.transaction_type == "Secondary") {
                  const statusData = {
                    inv_stage: "Initiate_Balance_Payment",
                    userId: selectedUser.id || orderList?.userId,
                  }
                  try {
                    const res = await axiosInstance.patch(
                      `user-investment/${orderList.id}`,
                      statusData
                    )
                    if (res) {
                      toast.success("Successfully Edited")
                      setUserInvPatchSuccess(true)
                    }
                  } catch (error) {
                    toast.error(
                      "Something went wrong. Please try after sometime"
                    )
                  }
                }
                setUserInvestment(2)
                // toast.success("Successfully Edited")
              }

              // toggle()
            } catch (error) {
              const msg = error?.response?.data?.errors[0]?.message
              let isUniqueError = true
              error?.response?.data?.errors?.length
                ? error?.response?.data?.errors?.forEach(el =>
                    el.origin !== "DB" && validator_key !== "not_unique"
                      ? (isUniqueError = false)
                      : null
                  )
                : (isUniqueError = false)
              if (isUniqueError) {
                toast.error("An active user investment already exists.")
              } else {
                toast.error(humanize(msg))
              }
            }
          } else {
            toast.error("Please choose a valid Bond Size")
          }
        } else {
          toast.error("Something Went Wrong. Please try after sometime")
        }
      } else {
        if (
          selectedUser.id &&
          values.project_name &&
          lotSize &&
          values.amount &&
          localUser.id
        ) {
          if (
            lotSize >= selectedProject.min_lot &&
            lotSize <= selectedProject.max_lot
          ) {
            const newOrder = {
              userId: selectedUser.id,
              investmentId: values.project_name,
              lot_size: lotSize,
              inv_stage: "Commitment",
              amount: parseFloat(values.amount.replaceAll(",", "")) || 0,
              tcs_amount: parseFloat(tcsAmount?.replaceAll(",", "")) || 0,
              face_value: parseFloat(values.face_value.replaceAll(",", "")),
              called_face_value: parseFloat(
                values.called_face_value.replaceAll(",", "")
              ),
              redeemed_face_value: parseFloat(
                values.redeemed_face_value.replaceAll(",", "")
              ),
              accrued_interest: parseFloat(
                values.accrued_interest.replaceAll(",", "")
              ),
              premium: parseFloat(values.premium.replaceAll(",", "")),
              annual_return: parseFloat(breakupData?.annual_return),
              created_at: getTimestamp(investmentDate),
            }
            // save new order

            try {
              const activeinvLot = await getActiveInvestmentLot(
                values.project_name
              )
              if (activeinvLot) {
                newOrder["investmentLotId"] = activeinvLot.id
              }

              const response = await axiosInstance.post(
                `user-investment?send_email=false`,
                newOrder
              )
              if (response) {
                if (
                  response.data?.pendings?.investment_tranche
                    .bank_account_type === "VPA" ||
                  (response.data?.pendings?.investment_tranche
                    .bank_account_type === "VPA_AND_CUSTOM" &&
                    response.data?.amount <=
                      response.data?.pendings?.investment_tranche
                        ?.vpa_threshold_amount)
                ) {
                  setCustomBankDetails()
                } else {
                  setCustomBankDetails(
                    response.data.pendings.investment_tranche?.transferor
                  )
                }
                masterData()
                setUserInvestmentRes(response.data)
                if (selectedProject.transaction_type == "Secondary") {
                  const statusData = {
                    inv_stage: "Initiate_Balance_Payment",
                    userId: selectedUser.id || orderList?.userId,
                  }
                  try {
                    const res = await axiosInstance.patch(
                      `user-investment/${response.data.id}`,
                      statusData
                    )
                    if (res) {
                      toast.success("Successfully Added")
                      setUserInvPostSuccess(true)
                    }
                  } catch (error) {
                    // toast.error(
                    //   "Something went wrong. Please try after sometime"
                    // )
                  }
                }
                setUserInvestment(2)
                // toast.success("Successfully Added")
              }

              // toggle()
            } catch (error) {
              const msg = error?.response?.data?.errors[0]?.message
              let isUniqueError = true
              error?.response?.data?.errors?.length
                ? error?.response?.data?.errors?.forEach(el =>
                    el.origin !== "DB" && validator_key !== "not_unique"
                      ? (isUniqueError = false)
                      : null
                  )
                : (isUniqueError = false)
              if (isUniqueError) {
                toast.error("An active user investment already exists.")
              } else {
                toast.error(humanize(msg))
              }
            }
          } else {
            toast.error("Please choose a valid Bond Size")
          }
        } else {
          toast.error("Something Went Wrong. Please try after sometime")
        }
      }
    }

    setLoading(false)
  }

  const downloadData = async fileName => {
    try {
      setLoading(true)
      const filterUrl = `${
        userFilter.length >= 3 ? `&name=${userFilter}` : ``
      }${investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""}${
        selectInvStage?.id
          ? `&inv_stage=${selectInvStage.id}`
          : "&inv_stage[$ne]=Completed"
      }${
        investmentFilter?.id && selectedTranche?.id
          ? `&investmentLotId=${selectedTranche?.id}`
          : ""
      }`

      const url = `admin-user-investment-listing?$sort[created_at]=-1&relManagerId=${localUser.id}${filterUrl}`
      const res = await axiosInstance.get(url)
      if (res) {
        const data = res.data?.data

        data.forEach(el => {
          el["amount_payable"] = el.pendings.current_tranche_all + el.tcs_amount
          el["balance_payable"] =
            el.pendings.current_tranche_all +
            el.tcs_amount -
            el.pendings.amountPaid
        })

        const csvTableHeaders = UserInvestmentColumns(true)
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  useEffect(() => {
    setVpaDetails({
      va_account_number: selectedUser?.va_account_number,
      va_ifsc: selectedUser?.va_ifsc,
      va_name: selectedUser?.va_name,
    })
  }, [selectedUser])

  useEffect(() => {
    if (!modal) {
      if (!reload) {
        window.location.reload()
      }
    }
  }, [modal])

  useEffect(async () => {
    if (userInvPatchSuccess || userInvPostSuccess) {
      const transactionData = {
        transaction_type: "Direct",
        payment_type: "Bank_Transfer",
        userInvestmentId: userInvestmentRes.id,
        transaction_amount: userInvestmentRes.pendings.payable_amount * 100,
        payment_status: "initiated",
        userId: userInvestmentRes.userId,
        created_at: userInvestmentRes.created_at,
      }
      try {
        const res = await axiosInstance.post(`transaction`, transactionData)
        if (res) {
          // toast.success("Successfully Added Transaction")
          const statusData = {
            inv_stage: "Awaiting_Manual_Transfer",
            userId: userInvestmentRes.userId,
          }
          try {
            const invRes = await axiosInstance.patch(
              `user-investment/${userInvestmentRes.id}`,
              statusData
            )
            if (invRes) {
              // toast.success("Successfully Updated")
              // toggle()
              // setUserInvestmentRes({})
              // setUserInvestment(1)
            }
          } catch (error) {
            toast.error("Status update failed")
          }
        }
      } catch (error) {
        toast.error("Something went wrong. Please try after sometime")
      }
      if (tcsAmount !== "0") {
        const newOrder = {
          userInvestmentId: userInvestmentRes?.id,
          userId: selectedUser?.id,
          transaction_amount:
            parseFloat(tcsAmount.replaceAll(",", "")) * 100 || 0,
          payment_status: "initiated",
          payment_type: "Bank_Transfer",
          created_at: userInvestmentRes.created_at,
        }
        // save new order

        try {
          const response = await axiosInstance.post(
            `tcs-transactions`,
            newOrder
          )
          if (response) {
            masterData()
            // toast.success("Transaction Successfully Added")
          }
        } catch (error) {
          const msg = error?.response?.data?.errors[0]?.message
          toast.error(msg)
        }
      }
    }
  }, [userInvPatchSuccess, userInvPostSuccess])

  useEffect(async () => {
    if (modal) {
      setUsers([])
      const investmentRes = await distributorInvData()
      setInvestments(
        investmentRes?.data?.data?.filter(obj => {
          if (isInvestmentTrancheOpen(obj)) {
            return obj
          }
        })
      )
    } else {
      setSelectedUser(null)
      setIsUserInvExists(false)
      setCustomBankDetails()
      setOrderList("")
      setSelectedProject(null)
      setUserInvestmentRes({})
      setUserInvestment(1)
      setVpaDetails({})
      setLotSize()
      setInvestmentDate(moment(new Date()).format("YYYY-MM-DD"))
      setBreakupData()
      setCommitmentAmount("")
      setTcsAmount("")
      setIsEdit(false)
    }
  }, [modal])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions
          ?.SALES_AND_INVESTOR_SERVICE_ADDMODIFY_INVESTMENTS_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem="Investor Investments" />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between">
                  <Col md={2}>
                    <select
                      className="form-select w-75"
                      value={limit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <div className="col-auto">
                    {accessControlData?.permissions
                      ?.SALES_AND_INVESTOR_SERVICE_ADDMODIFY_INVESTMENTS_ADD && (
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-2 me-2"
                        onClick={toggle}
                      >
                        <i className="mdi mdi-plus me-1" /> Add
                      </Button>
                    )}
                    {accessControlData?.permissions
                      ?.SALES_AND_INVESTOR_SERVICE_ADDMODIFY_INVESTMENTS_DOWNLOAD && (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded  mb-2 me-2"
                        onClick={() => {
                          downloadData("Investor Investments")
                        }}
                        style={{ marginRight: "3px" }}
                      >
                        <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                        Download
                      </Button>
                    )}
                  </div>
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={UserInvestmentColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row">
                            <Col md={3}>
                              {/* <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block"> */}
                              <div className="position-relative">
                                <label>User</label>
                                <input
                                  onChange={e => handleFilterChange(e, "user")}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control rounded custom-input-height"
                                  placeholder={`Search by User Name, Email or Phone`}
                                  value={userFilter || ""}
                                />
                                {/* <i className="bx bx-search-alt"></i> */}
                              </div>
                              {/* </div> */}
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Project Name</label>
                                <ReactSelect
                                  users={investmentList}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "projectType")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["project_name"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Investment Lot</label>
                                <ReactSelect
                                  users={[...trancheList]}
                                  selectedOption={selectedTranche}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "invLots")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["investment_lot_name"]}
                                  isClearable={true}
                                  isDisabled={!investmentFilter?.id}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Investment Stage</label>
                                <ReactSelect
                                  users={invStageForRm}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "invStage")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["statusText"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                            {/* <div className="col">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </div> */}
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                columns={UserInvestmentColumns()}
                                data={orders}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal isOpen={modal} toggle={() => toggle()}>
                                <ModalHeader toggle={() => toggle()} tag="h4">
                                  {!!isEdit
                                    ? userInvestment == 2
                                      ? "Payment Details"
                                      : "Edit Investment"
                                    : userInvestment == 1
                                    ? "Add Investment"
                                    : userInvestment == 2
                                    ? "Payment Details"
                                    : "Investment"}
                                </ModalHeader>
                                <ModalBody>
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      {userInvestment == 1 && (
                                        <Col className="col-12">
                                          {!isEdit ? (
                                            <div className="mb-3">
                                              <label>Investor</label>
                                              <ReactSelect
                                                users={users}
                                                searchValue={searchValue}
                                                setSearchValue={
                                                  handleUserSearch
                                                }
                                                selectedOption={selectedUser}
                                                setSelectedOption={
                                                  setSelectedUser
                                                }
                                                multiOptionLabel={false}
                                                optionLabelKeys={[
                                                  "user_pan?.name",
                                                  "email",
                                                  "phone",
                                                  "user_pan?.pan_number",
                                                ]}
                                              />
                                            </div>
                                          ) : (
                                            <div className="mb-3">
                                              <label>Investor</label>
                                              <ReactSelect
                                                isDisabled={isEdit}
                                                users={users}
                                                selectedOption={{
                                                  user_pan: {
                                                    name: orderList.name_on_pan,
                                                    pan_number:
                                                      orderList.pan_number,
                                                  },
                                                  email: orderList.email,
                                                  phone: orderList.phone,
                                                }}
                                                setSelectedOption={
                                                  setSelectedUser
                                                }
                                                multiOptionLabel={false}
                                                optionLabelKeys={[
                                                  "user_pan?.name",
                                                  "email",
                                                  "phone",
                                                  "user_pan?.pan_number",
                                                ]}
                                              />
                                            </div>
                                          )}
                                          <div className="row">
                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="project_name"
                                                label="Project Name"
                                                type="select"
                                                className="form-select"
                                                errorMessage="Invalid value"
                                                sort="true"
                                                disabled={isEdit}
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={selectedProject?.id}
                                                onChange={handleProjectName}
                                              >
                                                <option disabled value="">
                                                  Select
                                                </option>
                                                {sortingByAlphabet(
                                                  investments?.map(
                                                    (item, index) => (
                                                      <option
                                                        key={item?.project_name}
                                                        value={item?.id}
                                                      >
                                                        {item?.project_name}
                                                      </option>
                                                    )
                                                  )
                                                )}
                                              </AvField>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="created_at"
                                                label="Investment Date (IST)"
                                                type="date"
                                                errorMessage="Invalid Date"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                max={maxDate}
                                                min={minDate}
                                                value={investmentDate}
                                                disabled={isUserInvExists}
                                                onChange={handleInvestmentDate}
                                              ></AvField>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="lot_size"
                                                label={`Bonds ${
                                                  selectedProject?.min_lot &&
                                                  selectedProject?.max_lot
                                                    ? `(Min ${selectedProject?.min_lot} and Max ${selectedProject?.max_lot})`
                                                    : ""
                                                }`}
                                                type="number"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  lotSize === 0 ? "0" : lotSize
                                                }
                                                onChange={handleLotSize}
                                                pattern="^\d+$"
                                                step={1}
                                                disabled={isUserInvExists}
                                              ></AvField>
                                              {(lotSize <
                                                selectedProject?.min_lot ||
                                                lotSize >
                                                  selectedProject?.max_lot) && (
                                                <div className="invalid-feedback d-block position-absolute mt-0">
                                                  Please select a value between{" "}
                                                  {selectedProject?.min_lot} and{" "}
                                                  {selectedProject?.max_lot}
                                                </div>
                                              )}
                                            </div>
                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="face_value"
                                                label="Face Value"
                                                type="text"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  breakupData?.face_value === 0
                                                    ? "0"
                                                    : breakupData?.face_value.toLocaleString(
                                                        "en-IN"
                                                      )
                                                }
                                                disabled
                                              ></AvField>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="called_face_value"
                                                label="Called Face Value"
                                                type="text"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  breakupData?.called_face_value ===
                                                  0
                                                    ? "0"
                                                    : breakupData?.called_face_value.toLocaleString(
                                                        "en-IN"
                                                      )
                                                }
                                                disabled
                                              ></AvField>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="redeemed_face_value"
                                                label="Redeemed Face Value"
                                                type="text"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  breakupData?.redeemed_face_value ===
                                                  0
                                                    ? "0"
                                                    : breakupData?.redeemed_face_value.toLocaleString(
                                                        "en-IN"
                                                      )
                                                }
                                                disabled
                                              ></AvField>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="accrued_interest"
                                                label="Accrued Interest"
                                                type="text"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  breakupData?.accrued_interest ===
                                                  0
                                                    ? "0"
                                                    : breakupData?.accrued_interest?.toLocaleString(
                                                        "en-IN"
                                                      )
                                                }
                                                disabled
                                              ></AvField>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="premium"
                                                label="Premium"
                                                type="text"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  breakupData?.premium === 0
                                                    ? "0"
                                                    : breakupData?.premium?.toLocaleString(
                                                        "en-IN"
                                                      )
                                                }
                                                disabled
                                              ></AvField>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="lotPrice"
                                                label="Today's Price/Bond (INR)"
                                                type="text"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  breakupData?.bond_price
                                                    ? Math.round(
                                                        breakupData.bond_price
                                                      ).toLocaleString("en-IN")
                                                    : selectedProject?.lot_price
                                                    ? Math.round(
                                                        selectedProject?.lot_price
                                                      ).toLocaleString("en-IN")
                                                    : ""
                                                }
                                                disabled
                                              ></AvField>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                              <AvField
                                                name="amount"
                                                label="Commitment Amount (INR)"
                                                type="text"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={commitmentAmount}
                                                disabled
                                              ></AvField>
                                            </div>
                                          </div>

                                          {/* <div className="mb-3">
                                            <AvField
                                              name="inv_stage"
                                              label="Status"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort={true}
                                              validate={{
                                                required: { value: true },
                                              }}
                                              // value={orderList.inv_stage || ""}
                                              value={"Commitment"}
                                              disabled
                                            >
                                              <option
                                                defaultValue={"Commitment"}
                                              >
                                                Commitment
                                              </option>
                                            </AvField>
                                          </div> */}

                                          {tcsAmount && tcsAmount != "0" && (
                                            <div className="mb-3">
                                              <Modal
                                                isOpen={tcsModal}
                                                toggle={() => {
                                                  setTcsModal(!tcsModal)
                                                }}
                                              >
                                                <ModalHeader
                                                  toggle={() => {
                                                    setTcsModal(!tcsModal)
                                                  }}
                                                  tag="h3"
                                                >
                                                  TCS
                                                </ModalHeader>
                                                <ModalBody>
                                                  <p className="font-size-16">
                                                    0.1% TCS is applicable on
                                                    gross investments above 50
                                                    lacs in a financial year.
                                                    The same will be deposited
                                                    against investor’s PAN (like
                                                    TDS) and can be claimed in
                                                    his/her tax return.
                                                  </p>
                                                </ModalBody>
                                              </Modal>
                                              <Label>
                                                TCS{" "}
                                                <i
                                                  className="fas fa-info-circle"
                                                  id="TooltipTop"
                                                  onClick={() => {
                                                    setTcsModal(true)
                                                  }}
                                                ></i>
                                              </Label>
                                              <AvField
                                                name="tcsAmount"
                                                type="text"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={tcsAmount}
                                                disabled
                                              ></AvField>
                                            </div>
                                          )}
                                          <p className="mt-3 text-muted">
                                            Investment Amount should be
                                            transferred within one day
                                          </p>
                                        </Col>
                                      )}
                                      {userInvestment == 2 && (
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <AvField
                                              name="userInvestmentInfo"
                                              label="Investment Details"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort="true"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList?.id ||
                                                userInvestmentRes?.id ||
                                                ""
                                              }
                                              disabled
                                            >
                                              <option value={""}>select</option>
                                              {sortingByAlphabet(
                                                orders?.map((item, index) => (
                                                  <option
                                                    key={item?.project_name}
                                                    value={item?.id}
                                                  >
                                                    {`${item?.project_name}, ${item?.user_name}, ${item?.email}, ${item?.phone}`}
                                                  </option>
                                                ))
                                              )}
                                            </AvField>
                                          </div>
                                          <Row>
                                            <div className="mb-3 col-12 col-md-6">
                                              <AvField
                                                name="currentTranche"
                                                label="Current Tranche"
                                                type="text"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  handleAmount(
                                                    userInvestmentRes?.pendings
                                                      ?.current_tranche_all
                                                  ) || "0"
                                                }
                                                disabled
                                              ></AvField>
                                            </div>
                                            <div className="mb-3 col-12 col-md-6">
                                              <AvField
                                                name="paidAmount"
                                                label="Paid Amount"
                                                type="text"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  handleAmount(
                                                    userInvestmentRes?.pendings
                                                      ?.amountPaid
                                                  ) || "0"
                                                }
                                                disabled
                                              ></AvField>
                                            </div>
                                          </Row>
                                          <div className="mb-3">
                                            <AvField
                                              name="balanceAmount"
                                              label="Balance Amount"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                handleAmount(
                                                  userInvestmentRes?.pendings
                                                    ?.payable_amount
                                                ) || "0"
                                              }
                                              disabled
                                            ></AvField>
                                          </div>
                                          {customBankDetails ? (
                                            <Row>
                                              <div className="mb-3 col-12 col-md-6">
                                                <AvField
                                                  name="bank_name"
                                                  label="Bank Name"
                                                  type="text"
                                                  errorMessage="Invalid value"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    customBankDetails.bank_name ||
                                                    ""
                                                  }
                                                  disabled
                                                ></AvField>
                                              </div>
                                              <div className="mb-3 col-12 col-md-6">
                                                <AvField
                                                  name="branch_name"
                                                  label="Branch Name"
                                                  type="text"
                                                  errorMessage="Invalid value"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    customBankDetails.branch_name ||
                                                    ""
                                                  }
                                                  disabled
                                                ></AvField>
                                              </div>
                                            </Row>
                                          ) : null}
                                          <Row>
                                            <div className="mb-3 col-12 col-md-6">
                                              <AvField
                                                name="Beneficiary name"
                                                label="Beneficiary name"
                                                type="text"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  customBankDetails
                                                    ? customBankDetails?.bank_account_name
                                                    : vpaDetails
                                                    ? vpaDetails.va_name
                                                    : ""
                                                }
                                                disabled
                                              ></AvField>
                                            </div>
                                            <div className="mb-3 col-12 col-md-6">
                                              <AvField
                                                name="Account number"
                                                label="Account number"
                                                type="text"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  customBankDetails
                                                    ? customBankDetails?.bank_account_number
                                                    : vpaDetails
                                                    ? vpaDetails.va_account_number
                                                    : ""
                                                }
                                                disabled
                                              ></AvField>
                                            </div>
                                          </Row>
                                          <div className="mb-3">
                                            <AvField
                                              name="IFSC"
                                              label="IFSC"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                customBankDetails
                                                  ? customBankDetails?.bank_ifsc
                                                  : vpaDetails
                                                  ? vpaDetails.va_ifsc
                                                  : ""
                                              }
                                              disabled
                                            ></AvField>
                                          </div>
                                          <p className="mt-3">
                                            Above payment details have been
                                            mailed to the investor with a cc to
                                            you
                                          </p>
                                          <div className="text-end">
                                            <button
                                              className="btn btn-success save-user"
                                              onClick={toggle}
                                            >
                                              OK
                                            </button>
                                          </div>
                                        </Col>
                                      )}
                                    </Row>
                                    <Row>
                                      <Col>
                                        {userInvestment == 1 && (
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-user"
                                              disabled={
                                                lotSize <
                                                  selectedProject?.min_lot ||
                                                lotSize >
                                                  selectedProject?.max_lot
                                              }
                                            >
                                              Save and Continue
                                            </button>
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}
                    `}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

RmUserInvestment.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(RmUserInvestment)
