import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  AccessId,
  addendumStatus,
  distributorInvoiceReportStatus,
  distributorInvoiceStatus,
  stfStatus,
} from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"
import classnames from "classnames"

import { JSONToCSVConvertor } from "common/jsontocsv"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import { getBillingCycle, investmentData } from "constants/common"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const Billing = () => {
  const [data, setData] = useState([])
  const [reportData, setReportData] = useState([])
  const [loading, setLoading] = useState(false)
  // const [boxData, setBoxData] = useState([
  //   {
  //     title: "Total Earnings",
  //     amount: 0,
  //   },
  //   {
  //     title: "Total Paid",
  //     amount: 0,
  //   },
  //   {
  //     title: "Unbilled Amount",
  //     amount: 0,
  //   },
  // ])

  const [uploadingLater, setUploadingLater] = useState(true)
  const [submitConsent, setSubmitConsent] = useState(false)
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [reportSkip, setReportSkip] = useState(0)
  const [reportLimit, setReportLimit] = useState(10)
  const [reportCurrentPage, setReportCurrentPage] = useState(1)
  const [reportTotalPage, setReportTotalPage] = useState(0)
  const [reportTotal, setReportTotal] = useState(0)
  const [reportInvestmentFilter, setReportInvestmentFilter] = useState({})
  const [reportDateRangeFilter, setReportDateRangeFilter] = useState()

  const [investmentFilter, setInvestmentFilter] = useState({})
  const [investmentList, setInvestmentList] = useState([])

  const [userFilter, setUserFilter] = useState("")

  const [statusFilter, setStatusFilter] = useState({})
  const [selectedRow, setSelectedRow] = useState({})
  const [selectedFile, setSelectedFile] = useState(null)
  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const [invoiceConsent, setInvoiceConsent] = useState(false)

  const [selectedProject, setSelectedProject] = useState()
  const [dateRange, setDateRange] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [kycDetails, setKycDetails] = useState()
  const [billingCycle, setBillingCycle] = useState([])
  const [invoiceRes, setInvoiceRes] = useState()

  const [customActiveTab, setCustomActiveTab] = useState("1")
  const [selectedInvoiceTypeOption, setSelectedInvoiceTypeOption] = useState("")
  const [availableInvestments, setAvailableInvestments] = useState([])
  const [invoiceStatus, setInvoiceStatus] = useState(null)

  const filterUrl = `&distributorId=${localUser.id}${
    investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
  }${statusFilter?.id ? `&status=${statusFilter.id}` : ""}`

  const [activeTab, setActiveTab] = useState(1)
  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])
  const masterData = async (tab = customActiveTab) => {
    if (tab == "1") {
      return
    }
    setLoading(true)

    const getUrl = `distributor-invoice?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`
    try {
      const response = await axiosInstance.get(getUrl)
      // const invoice_report = await axiosInstance.get(
      //   `/distributor-invoice-report?distributorId=${localUser.id}`
      // )
      // setBoxData([
      //   {
      //     title: "Total Earnings",
      //     amount: invoice_report.data.data[0].total_earning,
      //   },
      //   {
      //     title: "Total Paid",
      //     amount: invoice_report.data.data[0].paid_amount,
      //   },
      //   {
      //     title: "Unbilled Amount",
      //     amount: invoice_report.data.data[0].unbilled_amount,
      //   },
      // ])
      if (response) {
        setTotal(response.data.total)
        setData(response.data?.data || response.data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }
  const getFiltersDataList = async () => {
    try {
      setLoading(true)
      const invResponse = await investmentData("Approved", "Closed", "Exited")
      const agreementRes = await axiosInstance.get(
        `/distributor-agreement?distributorId=${localUser.id}`
      )
      const data = agreementRes.data.data[0]
      setBillingCycle(getBillingCycle(data.billing_frequency))
      if (invResponse) {
        setInvestmentList(invResponse?.data?.data || invResponse?.data)
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getFiltersDataList()
  }, [])
  useEffect(() => {
    masterData()
  }, [limit, skip, investmentFilter, statusFilter])
  useEffect(() => {
    getReports()
  }, [
    reportLimit,
    reportSkip,
    reportInvestmentFilter,
    reportDateRangeFilter,
    invoiceStatus,
  ])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      getReports()
    }
  }, [userFilter])

  const handleDoc = async file => {
    setLoading(true)
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      throw error
    }
    setLoading(false)
  }

  const handleRowUploadClick = async order => {
    try {
      setLoading(true)
      const res = await axiosInstance.get(`/distributor-invoice/${order.id}`)
      setSelectedRow(res.data)
      setSelectedFile()
      setActiveTab(3)
      setUploadingLater(true)
      if (!order.uploaded_invoice && order.is_auto_generated) {
        setSelectedInvoiceTypeOption("auto")
      } else {
        setSelectedInvoiceTypeOption("")
      }
      setIsModalOpen(true)
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async item => {
    var r = confirm(`Are you sure want to delete?`)
    if (r) {
      try {
        setLoading(true)
        await axiosInstance.delete(`distributor-invoice/${item.id}`)
        masterData()
        toast.success("Deleted successfully")
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    }
  }
  const Columns = () => {
    let col = [
      {
        dataField: "action",
        isDummyField: true,
        text: "Delete",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => (
          <>
            <div className="d-flex gap-3">
              {accessControlData?.permissions
                ?.DISTRIBUTOR_PORTAL_BILLING_UPLOAD_INVOICE &&
              !(order.status == "APPROVED" || order.status == "PAID") ? (
                <Link
                  className="text-danger"
                  onClick={() => handleDelete(order)}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              ) : (
                "NA"
              )}
            </div>
          </>
        ),
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => (
          <>
            <div className="d-flex gap-3">
              {accessControlData?.permissions
                ?.DISTRIBUTOR_PORTAL_BILLING_UPLOAD_INVOICE &&
              (order.status == "GENERATED" || order.status == "RE_SUBMIT") ? (
                <Button
                  type="button"
                  color="primary"
                  className="btn-rounded"
                  onClick={() => handleRowUploadClick(order)}
                >
                  Upload
                </Button>
              ) : (
                "NA"
              )}
            </div>
          </>
        ),
      },

      {
        dataField: "investment.project_name",
        text: "Project Name",
        sort: true,
      },

      {
        dataField: "status",
        text: "Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = distributorInvoiceStatus.find(
            obj => obj.id == cellContent
          )
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              }`}
            >
              {status?.statusText || cellContent}
            </span>
          )
        },
      },
      {
        dataField: "sample_invoice",
        text: "System Generated Proforma Invoice",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order.sample_invoice ? (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => handleDoc(order?.sample_invoice)}
                >
                  <svg viewBox="0 0 24 24" width="26px" fill={"#556ee6"}>
                    <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            ""
          ),
      },
      {
        dataField: "uploaded_invoice",
        text: "Uploaded Invoice",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order.uploaded_invoice ? (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => handleDoc(order?.uploaded_invoice)}
                >
                  <svg viewBox="0 0 24 24" fill={"#556ee6"} width="26px">
                    <path d="M19.7 12.9L14 18.6H11.7V16.3L17.4 10.6L19.7 12.9M23.1 12.1C23.1 12.4 22.8 12.7 22.5 13L20 15.5L19.1 14.6L21.7 12L21.1 11.4L20.4 12.1L18.1 9.8L20.3 7.7C20.5 7.5 20.9 7.5 21.2 7.7L22.6 9.1C22.8 9.3 22.8 9.7 22.6 10C22.4 10.2 22.2 10.4 22.2 10.6C22.2 10.8 22.4 11 22.6 11.2C22.9 11.5 23.2 11.8 23.1 12.1M3 20V4H10V9H15V10.5L17 8.5V8L11 2H3C1.9 2 1 2.9 1 4V20C1 21.1 1.9 22 3 22H15C16.1 22 17 21.1 17 20H3M11 17.1C10.8 17.1 10.6 17.2 10.5 17.2L10 15H8.5L6.4 16.7L7 14H5.5L4.5 19H6L8.9 16.4L9.5 18.7H10.5L11 18.6V17.1Z" />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            ""
          ),
      },
      {
        dataField: "invoice_date",
        text: "Invoice Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          cellContent ? handleValidDate(row.invoice_date) : null,
      },
      {
        dataField: "invoice_number",
        text: "Invoice Number",
        sort: true,
      },
      {
        dataField: "start_date",
        text: "Billing Period",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          moment(cellContent).format("DD MMM Y") +
          " - " +
          moment(row.end_date).format("DD MMM Y"),
      },
      // {
      //   dataField: "end_date",
      //   text: "End Date",
      //   sort: true,
      //   // eslint-disable-next-line react/display-name
      //   formatter: (cellContent, row) => handleValidDate(row.end_date),
      // },

      {
        dataField: "capital_raised",
        text: "Capital Raised",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleAmount(row.capital_raised),
      },
      {
        dataField: "fee_amount",
        text: "Fee Amount",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleAmount(row.fee_amount),
      },
      {
        dataField: "cgst",
        text: "CGST",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleAmount(cellContent),
      },
      {
        dataField: "sgst",
        text: "SGST",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleAmount(cellContent),
      },
      {
        dataField: "igst",
        text: "IGST",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleAmount(cellContent),
      },
      {
        dataField: "",
        text: "GST Amount",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          handleAmount(row.cgst + row.igst + row.sgst),
      },
      {
        dataField: "",
        text: "Invoice Amount",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          handleAmount(row.cgst + row.igst + row.sgst + row.fee_amount),
      },
      {
        dataField: "fee_type",
        text: "Fee Type",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div>{cellContent.replaceAll("_", " ")}</div>
        ),
      },
      {
        dataField: "distributor.distributor_kyc.pan_number",
        text: "PAN Number",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.gst_number",
        text: "GST Number",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.account_name",
        text: "Bank Account Name",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.bank_name",
        text: "Bank Name",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.branch_name",
        text: "Branch Name",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.account_number",
        text: "Bank Account Number",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.ifsc_code",
        text: "IFSC Code",
        sort: true,
      },
      {
        dataField: "created_at",
        text: "Created At",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleValidDate(row.created_at),
      },
    ]
    return col
  }
  const DistributorReportColumns = download => {
    const columns = []

    columns.push(
      {
        dataField: "investmentDate",
        text: "Investment Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleValidDate(row.investmentDate),
      },
      {
        dataField: "projectName",
        text: "Project Name",
        sort: true,
      },

      {
        dataField: "userName",
        text: "Investor Name",
        sort: true,
      },
      {
        dataField: "lot_size",
        text: "Bonds",
        sort: true,
      },
      {
        dataField: "outstandingPrincipal",
        text: "Capital Raised",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          return (
            <div className="text-end">
              {handleAmount(row.faceValue - row.redeemedFaceValue)}
            </div>
          )
        },
      },
      {
        dataField: "addendum_status",
        text: "Addendum Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = addendumStatus.find(obj => obj.id == cellContent)
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              } text-capitalize`}
            >
              {status?.statusText ||
                cellContent?.toLowerCase()?.replaceAll("_", " ")}
            </span>
          )
        },
      },
      {
        dataField: "stf_status",
        text: "STF Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = stfStatus.find(obj => obj.id == cellContent)
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              } text-capitalize`}
            >
              {status?.statusText ||
                cellContent?.toLowerCase()?.replaceAll("_", " ")}
            </span>
          )
        },
      },
      {
        dataField: "upfront_fee_percent",
        text: "Fee Percent",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">
            {cellContent ? Number(cellContent).toFixed(2) : "0"}
          </div>
        ),
      },
      {
        dataField: "upfrontFee",
        text: "Fee Amount",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">
            {row.upfrontFee ? handleAmount(row.upfrontFee) : "0"}
          </div>
        ),
      },
      {
        dataField: "invoice_status",
        text: "Invoice Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = distributorInvoiceReportStatus.find(
            obj => obj.id == cellContent
          )
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status
                  ? status?.colorClassName
                  : cellContent == "NOT GENERATED"
                  ? "danger"
                  : "secondary"
              }`}
            >
              {status?.statusText || cellContent}
            </span>
          )
        },
      },
      {
        dataField: "invoice_number",
        text: "Invoice Number",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => <div>{cellContent}</div>,
      },
      {
        dataField: "invoice_date",
        text: "Invoice Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div>{cellContent ? handleValidDate(cellContent) : ""}</div>
        ),
      },

      {
        dataField: "nameOnPan",
        text: "Investor PAN Name",
        sort: true,
      }
    )

    return columns
  }
  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const handleNext = prev => {
    if (customActiveTab == 2) {
      setSkip(prev + limit)
      let page = (prev + limit) / limit + 1
      setCurrentPage(page)
    } else {
      setReportSkip(prev + reportLimit)
      let page = (prev + reportLimit) / reportLimit + 1
      setReportCurrentPage(page)
    }
  }

  const handlePrevious = prev => {
    if (customActiveTab == 2) {
      setSkip(prev - limit)
      let page = (prev - limit) / limit + 1
      setCurrentPage(page)
    } else {
      setReportSkip(prev - reportLimit)
      let page = (prev - reportLimit) / reportLimit + 1
      setReportCurrentPage(page)
    }
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    if (customActiveTab == 2) {
      setCurrentPage(value)
    } else {
      setReportCurrentPage(value)
    }
    if (value) {
      if (customActiveTab == 2) {
        setSkip((value - 1) * limit)
      } else {
        setReportSkip((value - 1) * reportLimit)
      }
    }
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "projectType":
        if (customActiveTab == 2) {
          setInvestmentFilter(e)
        } else {
          setReportInvestmentFilter(e)
        }
        break
      case "limit":
        if (customActiveTab == 2) {
          setLimit(Number(e.target.value))
        } else {
          setReportLimit(Number(e.target.value))
        }
        break
      case "status":
        setStatusFilter(e)
        break
      case "invoice_status":
        setInvoiceStatus(e)
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const handleValidSubmit = async (e, values) => {
    try {
      const file = selectedFile?.target?.files[0]
      if (!file) {
        toast.error("Select an Invoice file")
        return
      }
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      const uploadResponse = await axiosInstance.post(
        `/file-upload/?type=distributor_tax_invoice`,
        formData
      )
      const payload = {
        invoice_date: values.invoice_date,
        invoice_number: values.invoice_number,
        status: "SUBMIT",
        uploaded_invoice: uploadResponse.data[0].name,
      }

      if (selectedRow?.id) {
        await axiosInstance.patch(
          `/distributor-invoice/${selectedRow.id}`,
          payload
        )
      } else {
        const postPayload = {
          ...invoiceRes.data[0],
          invoice_date: values.invoice_date,
          invoice_number: values.invoice_number,
          status: "SUBMIT",
          uploaded_invoice: uploadResponse.data[0].name,
        }

        await axiosInstance.post(`/distributor-invoice`, [postPayload])
      }

      toast.success("Submitted Successfully")
      masterData()
      toggle()
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }
  const previewSelectedInvoiceFile = () => {
    const file = selectedFile?.target?.files[0]
    const fileURL = URL.createObjectURL(file)
    window.open(fileURL, "_blank")
  }

  const toggle = () => {
    setIsModalOpen(!isModalOpen)
  }
  const moment = require("moment")

  const handleGenerateRequest = async () => {
    try {
      setLoading(true)
      const agreementRes = await axiosInstance.get(
        `/distributor-agreement?distributorId=${localUser.id}`
      )
      const data = agreementRes.data.data[0]
      const kycResDist = await axiosInstance.get(
        `/distributor-kyc?userId=${localUser.id}`
      )
      setKycDetails(kycResDist.data.data[0])
      setBillingCycle(getBillingCycle(data.billing_frequency))
      setInvoiceRes()
      setSelectedProject()
      setAvailableInvestments([])
      setSelectedRow({})
      setUploadingLater(false)
      setSubmitConsent(false)
      setSelectedFile(null)
      setDateRange()
      setActiveTab(1)
      setInvoiceConsent(false)
      setSelectedInvoiceTypeOption("")
      toggle()
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedProject?.id && dateRange?.start_date && dateRange?.end_date) {
      setInvoiceRes()
      const getInvoiceData = async () => {
        try {
          setLoading(true)
          const res = await axiosInstance.get(
            `/distributor-invoice-data?distributorId=${localUser.id}&investmentId=${selectedProject.id}&start_date=${dateRange.start_date}&end_date=${dateRange.end_date}`
          )
          setInvoiceRes(res.data)
        } catch (error) {
          throw error
        } finally {
          setLoading(false)
        }
      }
      getInvoiceData()
    }
  }, [selectedProject])

  useEffect(async () => {
    if (dateRange?.start_date && dateRange?.end_date) {
      try {
        setAvailableInvestments([])
        setSelectedProject(null)
        setInvoiceRes(null)
        setLoading(true)
        setInvoiceConsent(false)
        const res = await axiosInstance.get(
          `/distributor-fees?distributorId=${localUser.id}&startDate=${dateRange.start_date}&endDate=${dateRange.end_date}&type=upfront&is_upfront_fee_paid=false&stf_status=SIGNED&addendum_status=SIGNED`
        )
        const data = res.data.data
        if (data.length) {
          setAvailableInvestments(
            investmentList.filter(obj =>
              data.some(item => item.investmentId === obj.id)
            )
          )
        }
      } catch (error) {
        throw error
      } finally {
        setLoading(false)
      }
    }
  }, [dateRange])

  const handleInvoiceCreate = async (e, values) => {
    try {
      setLoading(true)
      const payload = [
        {
          ...invoiceRes.data[0],
          invoice_date: values.invoice_date,
          invoice_number: values.invoice_number,
          status: "GENERATED",
          is_auto_generated: true,
        },
      ]

      const res = await axiosInstance.post(`/distributor-invoice`, payload)
      const data = res.data[0]
      const createdInvoice = await axiosInstance.get(
        `/distributor-invoice/${data.id}`
      )
      setSelectedRow(createdInvoice.data)
      masterData()
      setSelectedProject()
      toast.success("Invoice generated successfully")
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
      if (tab == "2") {
        masterData(tab)
      } else {
        getReports(tab)
      }
    }
  }

  const getReports = async (tab = customActiveTab) => {
    try {
      setLoading(true)
      const reportFilterUrl = `type=upfront${
        reportDateRangeFilter
          ? `&startDate=${reportDateRangeFilter.start_date}`
          : ``
      }${
        reportDateRangeFilter
          ? `&endDate=${reportDateRangeFilter.end_date}`
          : ``
      }${
        reportInvestmentFilter?.id
          ? `&investmentId=${reportInvestmentFilter.id}`
          : ""
      }&distributorId=${localUser.id}${
        userFilter.length >= 3 ? `&name=${userFilter}` : ``
      }${invoiceStatus ? `&invoice_status=${invoiceStatus.id}` : ""}`
      const getUrl = `/distributor-fees?${reportFilterUrl}&$limit=${reportLimit}&$skip=${reportSkip}`

      const response = await axiosInstance.get(getUrl)

      if (response) {
        setReportTotal(response.data.total)
        const data = response?.data?.data || response?.data
        setReportData(data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setReportTotalPage(pages)
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const downloadBreakup = async () => {
    try {
      setLoading(true)

      const downloadUrl = `download-excel?type=distributor_invoice_data&distributorId=${localUser.id}&investmentId=${selectedProject?.id}&start_date=${dateRange.start_date}&end_date=${dateRange.end_date}`

      const response = await axiosInstance.get(downloadUrl, {
        responseType: "blob",
      })

      console.log(response.headers)
      const contentDispositionHeader = response.headers["content-disposition"]
      console.log("headf", contentDispositionHeader)
      let match
      if (contentDispositionHeader) {
        match = contentDispositionHeader.split("filename=")
      }

      const filename = match ? match[1] : "download.xlsx"
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const downloadLink = document.createElement("a")
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = filename
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const handleVerify = async () => {
    try {
      setLoading(true)

      const kycResDist = await axiosInstance.get(
        `/distributor-kyc?userId=${localUser.id}`
      )
      setKycDetails(kycResDist.data.data[0])
      setActiveTab(2)
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const handleBack = tab => {
    setSubmitConsent(false)
    if (activeTab == 2) {
      setInvoiceConsent(false)
      setActiveTab(1)
      setSelectedInvoiceTypeOption("")
    } else if (activeTab == 3) {
      setActiveTab(2)
    }
  }

  const handleNextStep = () => {
    setActiveTab(3)
  }

  const handleDocumentSigned = async resPayload => {
    try {
      setLoading(true)
      const res = await axiosInstance.patch(
        `/digio-signing-request/${resPayload.digio_doc_id}?type=distributor_tax_invoice`,
        {
          invoice_id: selectedRow.id,
          invoice_date: selectedRow.invoice_date,
          invoice_number: selectedRow.invoice_number,
          signed_res_data: resPayload,
        }
      )
      await masterData()
      toggle()
      toast.success("Signed and Submitted Successfully")
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }
  const handleSign = async () => {
    try {
      setLoading(true)
      let did = selectedRow.digio_document_id
      let identifier =
        selectedRow?.digio_signing_res_data?.signing_parties[0]?.identifier
      if (!did) {
        const res = await axiosInstance.post(
          `/digio-signing-request?type=distributor_tax_invoice`,
          {
            invoice_id: selectedRow.id,
          }
        )
        did = res?.data?.id
        identifier = res?.data?.signing_parties[0]?.identifier
        setSelectedRow({
          ...selectedRow,
          digio_document_id: res.data.id,
          digio_signing_res_data: res.data,
        })
      }

      // open popup
      const options = {
        environment: process.env.REACT_APP_DIGIO_ENV,
        callback: function (response) {
          if (response.hasOwnProperty("error_code")) {
            return toast.error(response.error_code)
          }
          handleDocumentSigned(response)
        },
        logo: "https://earnnest.me/assets/images/logo.svg",
      }
      const digio = new Digio(options)
      digio.init()
      digio.submit(did, identifier)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    // Dynamically load Digio SDK
    const script = document.createElement("script")
    script.src = "https://app.digio.in/sdk/v11/digio.js"
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const invoiceDateMinDate = () => {
    const today = new Date()
    const isBeforeFourth = today.getDate() <= 3

    if (isBeforeFourth) {
      const firstDayOfPrevMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      )
      return moment(firstDayOfPrevMonth).format("YYYY-MM-DD")
    } else {
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      return moment(firstDayOfMonth).format("YYYY-MM-DD")
    }
  }
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.DISTRIBUTOR_PORTAL_BILLING_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem="Billing" />
            <p
              className="text-muted font-size-13"
              style={{ marginTop: "-20px", marginBottom: "25px" }}
            >
              Note: Invoice uploads are enabled for billing periods from October
              1, 2024 onwards.
            </p>
            <Row>
              {/* {boxData.map((item, index) => (
              <Col lg={3} key={index}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">{item.title}</p>
                        <h5 className="mb-0">₹ {handleAmount(item.amount)}</h5>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))} */}
              <Col xs="12">
                <Row className="mb-2 row justify-content-between">
                  <Col md={2}>
                    <select
                      className="form-select w-md-75"
                      value={customActiveTab == 2 ? limit : reportLimit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <div className="col-auto">
                    {accessControlData?.permissions
                      ?.DISTRIBUTOR_PORTAL_BILLING_UPLOAD_INVOICE ? (
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-2 me-2"
                        onClick={handleGenerateRequest}
                        style={{ marginRight: "3px" }}
                      >
                        <i className="mdi mdi-plus me-1" />
                        Upload Invoice
                      </Button>
                    ) : null}
                  </div>
                </Row>
                <Card>
                  <CardBody>
                    <Nav tabs className="nav-tabs-custom">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "1",
                          })}
                          onClick={() => {
                            toggleCustom("1")
                          }}
                        >
                          <span>Report</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "2",
                          })}
                          onClick={() => {
                            toggleCustom("2")
                          }}
                        >
                          <span>Invoices</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={customActiveTab} className="p-3">
                      <TabPane tabId="1">
                        <ToolkitProvider
                          keyField="id"
                          data={reportData}
                          columns={DistributorReportColumns()}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2 row">
                                <Col md={3}>
                                  <div className="col-auto">
                                    <label>Search Investor</label>
                                    <input
                                      onChange={e =>
                                        handleFilterChange(e, "user")
                                      }
                                      id="search-bar-0"
                                      type="text"
                                      className="form-control rounded custom-input-height"
                                      placeholder={`Search Name, Email, Phone`}
                                      value={userFilter || ""}
                                    />
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <label>Project Name</label>
                                    <ReactSelect
                                      users={investmentList}
                                      setSelectedOption={e =>
                                        handleFilterChange(e, "projectType")
                                      }
                                      multiOptionLabel={true}
                                      optionLabelKeys={["project_name"]}
                                      isClearable={true}
                                    />
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <label>Billing Period</label>
                                    <ReactSelect
                                      users={billingCycle}
                                      setSelectedOption={
                                        setReportDateRangeFilter
                                      }
                                      selectedOption={reportDateRangeFilter}
                                      multiOptionLabel={true}
                                      optionLabelKeys={["name"]}
                                      isClearable={true}
                                    />
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <label>Invoice Status</label>
                                    <ReactSelect
                                      users={distributorInvoiceReportStatus}
                                      setSelectedOption={e =>
                                        handleFilterChange(e, "invoice_status")
                                      }
                                      multiOptionLabel={true}
                                      optionLabelKeys={["statusText"]}
                                      isClearable={true}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <BootstrapTable
                                    keyField="id"
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    columns={DistributorReportColumns()}
                                    data={reportData}
                                    wrapperClasses={"table-responsive mb-4"}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                  />
                                </Col>
                              </Row>
                              <Row className="justify-content-md-space-between justify-content-center align-items-center">
                                <Col className="col-12 col-md-auto mb-3">
                                  {`Showing ${
                                    reportTotal ? reportSkip + 1 + " to" : ""
                                  } ${
                                    reportLimit > reportTotal ||
                                    reportLimit + reportSkip > reportTotal
                                      ? reportTotal
                                      : reportLimit + reportSkip
                                  } rows of ${reportTotal}
                    `}
                                </Col>
                                <Col>
                                  <Row className="justify-content-md-end justify-content-center align-items-center">
                                    <Col className="col-md-auto">
                                      <div className="d-flex gap-1">
                                        <Button
                                          color="primary"
                                          onClick={() =>
                                            handlePrevious(reportLimit)
                                          }
                                          disabled={reportCurrentPage == 1}
                                        >
                                          {"<<"}
                                        </Button>
                                        <Button
                                          color="primary"
                                          onClick={() =>
                                            handlePrevious(reportSkip)
                                          }
                                          disabled={reportCurrentPage == 1}
                                        >
                                          {"<"}
                                        </Button>
                                      </div>
                                    </Col>
                                    <Col className="col-md-auto d-none d-md-block">
                                      Page{" "}
                                      <strong>{`${
                                        reportCurrentPage
                                          ? reportCurrentPage
                                          : 1
                                      } of ${
                                        reportTotalPage ? reportTotalPage : 1
                                      }`}</strong>
                                    </Col>
                                    <Col className="col-md-auto">
                                      <Input
                                        type="number"
                                        min={1}
                                        style={{ width: 70 }}
                                        max={
                                          reportTotal == 0 ? 1 : reportTotalPage
                                        }
                                        value={reportCurrentPage || 1}
                                        defaultValue={1}
                                        onChange={onChangePagination}
                                        disabled={reportTotal == 0}
                                      />
                                    </Col>

                                    <Col className="col-md-auto">
                                      <div className="d-flex gap-1">
                                        <Button
                                          color="primary"
                                          onClick={() => handleNext(reportSkip)}
                                          disabled={
                                            reportCurrentPage ==
                                              reportTotalPage ||
                                            reportTotal == 0
                                          }
                                        >
                                          {">"}
                                        </Button>
                                        <Button
                                          color="primary"
                                          onClick={() =>
                                            handleNext(
                                              (reportTotalPage - 2) *
                                                reportLimit
                                            )
                                          }
                                          disabled={
                                            reportCurrentPage ==
                                              reportTotalPage ||
                                            reportTotal == 0
                                          }
                                        >
                                          {">>"}
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      </TabPane>
                      <TabPane tabId="2">
                        <ToolkitProvider
                          keyField="id"
                          data={data}
                          columns={Columns()}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2 row">
                                <Col md={3}>
                                  <div className="mb-3">
                                    <label>Project Name</label>
                                    <ReactSelect
                                      users={investmentList}
                                      setSelectedOption={e =>
                                        handleFilterChange(e, "projectType")
                                      }
                                      multiOptionLabel={true}
                                      optionLabelKeys={["project_name"]}
                                      isClearable={true}
                                    />
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="mb-3">
                                    <label>Status</label>
                                    <ReactSelect
                                      users={distributorInvoiceStatus}
                                      setSelectedOption={e =>
                                        handleFilterChange(e, "status")
                                      }
                                      multiOptionLabel={true}
                                      optionLabelKeys={["statusText"]}
                                      isClearable={true}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <BootstrapTable
                                    keyField="id"
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    columns={Columns()}
                                    data={data}
                                    wrapperClasses={"table-responsive mb-4"}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                  />
                                </Col>
                              </Row>
                              <Row className="justify-content-md-space-between justify-content-center align-items-center">
                                <Col className="col-12 col-md-auto mb-3">
                                  {`Showing ${total ? skip + 1 + " to" : ""} ${
                                    limit > total || limit + skip > total
                                      ? total
                                      : limit + skip
                                  } rows of ${total}
                    `}
                                </Col>
                                <Col>
                                  <Row className="justify-content-md-end justify-content-center align-items-center">
                                    <Col className="col-md-auto">
                                      <div className="d-flex gap-1">
                                        <Button
                                          color="primary"
                                          onClick={() => handlePrevious(limit)}
                                          disabled={currentPage == 1}
                                        >
                                          {"<<"}
                                        </Button>
                                        <Button
                                          color="primary"
                                          onClick={() => handlePrevious(skip)}
                                          disabled={currentPage == 1}
                                        >
                                          {"<"}
                                        </Button>
                                      </div>
                                    </Col>
                                    <Col className="col-md-auto d-none d-md-block">
                                      Page{" "}
                                      <strong>{`${
                                        currentPage ? currentPage : 1
                                      } of ${
                                        totalPage ? totalPage : 1
                                      }`}</strong>
                                    </Col>
                                    <Col className="col-md-auto">
                                      <Input
                                        type="number"
                                        min={1}
                                        style={{ width: 70 }}
                                        max={total == 0 ? 1 : totalPage}
                                        value={currentPage || 1}
                                        defaultValue={1}
                                        onChange={onChangePagination}
                                        disabled={total == 0}
                                      />
                                    </Col>

                                    <Col className="col-md-auto">
                                      <div className="d-flex gap-1">
                                        <Button
                                          color="primary"
                                          onClick={() => handleNext(skip)}
                                          disabled={
                                            currentPage == totalPage ||
                                            total == 0
                                          }
                                        >
                                          {">"}
                                        </Button>
                                        <Button
                                          color="primary"
                                          onClick={() =>
                                            handleNext((totalPage - 2) * limit)
                                          }
                                          disabled={
                                            currentPage == totalPage ||
                                            total == 0
                                          }
                                        >
                                          {">>"}
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal isOpen={isModalOpen} toggle={toggle} className={"modal-lg"}>
              <ModalHeader toggle={toggle} className="pb-0">
                <h4>Upload Invoice</h4>
              </ModalHeader>
              <ModalBody className="pb-0 px-0">
                <div className="wizard clearfix">
                  <div className="steps clearfix px-3">
                    <ul>
                      <NavItem
                        className={classnames({ current: activeTab === 1 })}
                      >
                        <NavLink
                          className={classnames({ current: activeTab === 1 })}
                          // onClick={handleBack}
                          disabled={true}
                        >
                          <span className="number">1.</span> Verify Fee Amount
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({ current: activeTab === 2 })}
                      >
                        <NavLink
                          className={classnames({ active: activeTab === 2 })}
                          disabled={true}
                        >
                          <span className="number">2.</span>Select Invoice Type
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({ current: activeTab === 3 })}
                      >
                        <NavLink
                          className={classnames({ active: activeTab === 3 })}
                          disabled={true}
                        >
                          <span className="number">3.</span> Upload Invoice
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>

                  <div className="content clearfix">
                    <TabContent activeTab={activeTab} className="body">
                      <TabPane tabId={1}>
                        <Row className="px-2">
                          <div className="mb-3 col-md-6">
                            <label>Billing Period</label>
                            <ReactSelect
                              users={billingCycle}
                              setSelectedOption={setDateRange}
                              selectedOption={dateRange}
                              multiOptionLabel={true}
                              optionLabelKeys={["name"]}
                            />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label>Project Name</label>
                            <ReactSelect
                              users={availableInvestments}
                              setSelectedOption={setSelectedProject}
                              selectedOption={selectedProject}
                              multiOptionLabel={true}
                              isDisabled={!dateRange}
                              optionLabelKeys={["project_name"]}
                            />
                          </div>

                          {/* {invoiceRes ? null : <div className="my-5"></div>} */}
                          {invoiceRes ? (
                            <>
                              <div className="d-flex mt-2">
                                <div className="mb-2 col-md-4">
                                  <div
                                    className="text-muted"
                                    style={{ fontSize: "11px" }}
                                  >
                                    Fee Amount
                                  </div>
                                  <p
                                    className="fw-medium"
                                    style={{
                                      fontSize: "13px",
                                    }}
                                  >
                                    {handleAmount(
                                      invoiceRes.data[0].fee_amount || 0
                                    )}
                                  </p>
                                </div>
                                <div className="mb-2 col-md-4">
                                  <div
                                    className="text-muted"
                                    style={{ fontSize: "11px" }}
                                  >
                                    GST Amount
                                  </div>
                                  <p
                                    className="fw-medium"
                                    style={{
                                      fontSize: "13px",
                                    }}
                                  >
                                    {handleAmount(
                                      invoiceRes.data[0].total_tax_amount || 0
                                    )}
                                  </p>
                                </div>
                                <div className="mb-2 col-md-4">
                                  <div
                                    className="text-muted"
                                    style={{ fontSize: "11px" }}
                                  >
                                    Invoice Amount
                                  </div>
                                  <p
                                    className="fw-medium"
                                    style={{
                                      fontSize: "13px",
                                    }}
                                  >
                                    {handleAmount(
                                      invoiceRes.data[0].invoice_amount || 0
                                    )}
                                  </p>
                                </div>
                              </div>
                              <p onClick={downloadBreakup} className="">
                                <a className="link ">
                                  <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                  Download Fee Calculation
                                </a>
                              </p>

                              <p className="mt-3">
                                <strong>Note: </strong>
                                Please verify the fee calculations for{" "}
                                {selectedProject?.project_name}. In case of any
                                query, please reach out to your Relationship
                                Manager.
                              </p>
                            </>
                          ) : (
                            <div className="mb-4 lh-lg">
                              <b>Note: </b>
                              <ol className="my-2">
                                <li className="mb-2">
                                  Only those Projects will show up, that meet
                                  the following criteria:
                                  <ul>
                                    <li>
                                      Have investments during the selected
                                      period
                                    </li>
                                    <li>
                                      Investments are mapped to a signed Fee
                                      Addendum
                                    </li>
                                    <li>
                                      Investments have a signed STF Document
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  To see the full list of investments done and
                                  the status of Addendum, Please exit this box
                                  and click the Report tab.
                                </li>
                              </ol>
                            </div>
                          )}

                          <div className="d-flex justify-content-between">
                            <div className="d-flex gap-1 mb-3">
                              <input
                                type="checkbox"
                                id="invoiceCheckbox"
                                checked={invoiceConsent}
                                onChange={e => {
                                  setInvoiceConsent(event.target.checked)
                                }}
                                disabled={!invoiceRes}
                              />
                              <label
                                className="mb-0 font-size-14 text-muted"
                                htmlFor="invoiceCheckbox"
                              >
                                I have reviewed the fee calculation and the
                                amount is correct.
                              </label>
                            </div>

                            <div className={"text-end"}>
                              <button
                                type="button"
                                className="btn btn-primary save-user"
                                disabled={
                                  loading || !invoiceRes || !invoiceConsent
                                }
                                onClick={handleVerify}
                              >
                                Verify
                              </button>
                            </div>
                          </div>
                        </Row>
                      </TabPane>
                      <TabPane tabId={2}>
                        <AvForm
                          className="px-2"
                          onValidSubmit={handleInvoiceCreate}
                        >
                          <div className="row">
                            <div className="col-md-9 px-3 pl-0">
                              <div className="mb-4 d-flex gap-3">
                                <AvField
                                  type="radio"
                                  name="invoiceType"
                                  id="autoGenerate"
                                  value="auto"
                                  checked={selectedInvoiceTypeOption === "auto"}
                                  onChange={e => {
                                    setSelectedInvoiceTypeOption(e.target.value)
                                  }}
                                />
                                <div>
                                  <label
                                    htmlFor="autoGenerate"
                                    className="text-bold font-size-15"
                                  >
                                    Generate Proforma (Auto generated Earnnest
                                    Invoice)
                                  </label>
                                  <p className="text-muted">
                                    <strong>Note: </strong>Earnnest can
                                    automatically generate the invoice for you
                                    once you input the invoice date and invoice
                                    number.
                                  </p>

                                  {selectedInvoiceTypeOption == "auto" ? (
                                    <>
                                      <Row>
                                        <div className="col-md-6">
                                          <AvField
                                            name="invoice_number"
                                            label="Invoice Number"
                                            type="text"
                                            className="w-full"
                                            errorMessage="Invalid Value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            disabled={selectedRow?.id}
                                          />
                                        </div>
                                        <div className="col-md-6">
                                          <AvField
                                            name="invoice_date"
                                            label="Invoice Date"
                                            type="date"
                                            className="w-full"
                                            errorMessage="Invalid value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            max={moment().format("YYYY-MM-DD")}
                                            min={invoiceDateMinDate()}
                                            value={moment().format(
                                              "YYYY-MM-DD"
                                            )}
                                            disabled={selectedRow?.id}
                                          ></AvField>
                                        </div>
                                      </Row>
                                      {selectedRow?.id ? (
                                        <>
                                          <p
                                            onClick={() => {
                                              handleDoc(
                                                selectedRow?.sample_invoice
                                              )
                                            }}
                                            className="mt-3"
                                          >
                                            <a className="link font-size-15">
                                              {/* <i className="mdi mdi-arrow-down-bold-circle"></i>{" "} */}
                                              <svg
                                                viewBox="0 0 24 24"
                                                width="20px"
                                                fill={"#556ee6"}
                                              >
                                                <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                                              </svg>
                                              {"  "}
                                              View Proforma Invoice
                                            </a>
                                          </p>
                                          <p className="text-muted my-3">
                                            <strong>Note: </strong>Review your
                                            details in proforma invoice and sign
                                            before uploading.
                                          </p>
                                        </>
                                      ) : null}
                                    </>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mb-4 d-flex gap-3">
                                <AvField
                                  type="radio"
                                  name="invoiceType"
                                  id="manually"
                                  value="manually"
                                  checked={
                                    selectedInvoiceTypeOption === "manually"
                                  }
                                  onChange={e => {
                                    setSelectedInvoiceTypeOption(e.target.value)
                                  }}
                                  disabled={selectedRow?.id}
                                />
                                <div>
                                  <label
                                    htmlFor="manually"
                                    className="text-bold font-size-15"
                                  >
                                    Upload Your Own Invoice
                                  </label>
                                  <p className="text-muted">
                                    <strong>Note: </strong>Select this option if
                                    you have your own invoice.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <b>Invoice Validations</b>
                              <div className="mt-2">
                                <p className="mb-0 text-success">
                                  {kycDetails?.kyc_verification_status ==
                                  "VERIFIED" ? (
                                    <span>
                                      <i className="mdi mdi-check me-1 " />
                                      KYC Verified
                                    </span>
                                  ) : (
                                    <span className="text-danger">
                                      <i className="mdi mdi-close me-1" />
                                      KYC Not Verified
                                    </span>
                                  )}
                                </p>
                                <p>
                                  {invoiceRes?.is_addendum_signed ? (
                                    <span className="text-success">
                                      <i className="mdi mdi-check me-1 " />
                                      Addendum Signed
                                    </span>
                                  ) : (
                                    <span className="text-danger">
                                      <i className="mdi mdi-close me-1" />
                                      Addendum Not Signed
                                    </span>
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end">
                            <div className={"text-end d-flex gap-2"}>
                              {selectedRow?.id ? (
                                <button
                                  type="button"
                                  className="btn btn-outline-primary save-user"
                                  onClick={toggle}
                                  disabled={loading}
                                >
                                  Upload Later
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-outline-primary save-user"
                                  onClick={handleBack}
                                  disabled={loading}
                                >
                                  Back
                                </button>
                              )}
                              {selectedInvoiceTypeOption == "auto" &&
                              !selectedRow?.id ? (
                                <button
                                  type="submit"
                                  className="btn btn-primary save-user"
                                  disabled={
                                    loading ||
                                    (kycDetails &&
                                      kycDetails?.kyc_verification_status !=
                                        "VERIFIED") ||
                                    !invoiceRes?.is_addendum_signed ||
                                    !selectedInvoiceTypeOption
                                  }
                                >
                                  Generate
                                </button>
                              ) : (
                                <></>
                              )}
                              {selectedInvoiceTypeOption == "manually" ||
                              selectedRow?.id ? (
                                <button
                                  type="button"
                                  className="btn btn-primary save-user"
                                  disabled={
                                    loading ||
                                    (kycDetails &&
                                      kycDetails?.kyc_verification_status !=
                                        "VERIFIED") ||
                                    !invoiceRes?.is_addendum_signed ||
                                    !selectedInvoiceTypeOption
                                  }
                                  onClick={handleNextStep}
                                >
                                  Proceed to Upload
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </AvForm>
                      </TabPane>
                      <TabPane tabId={3}>
                        <AvForm
                          className="px-2"
                          onValidSubmit={handleValidSubmit}
                        >
                          <Row>
                            <div
                              className={`mb-3 ${
                                selectedInvoiceTypeOption == "auto"
                                  ? "col-md-4"
                                  : "col-md-6"
                              }`}
                            >
                              <AvField
                                name="invoice_number"
                                label="Invoice Number"
                                type="text"
                                errorMessage="Invalid Value"
                                validate={{
                                  required: { value: true },
                                }}
                                disabled={
                                  selectedRow?.id &&
                                  selectedRow?.status != "RE_SUBMIT"
                                }
                                value={
                                  selectedRow?.invoice_number ||
                                  invoiceRes?.data[0]?.invoice_number ||
                                  ""
                                }
                              />
                            </div>
                            <div
                              className={`mb-3 ${
                                selectedInvoiceTypeOption == "auto"
                                  ? "col-md-4"
                                  : "col-md-6"
                              }`}
                            >
                              <AvField
                                name="invoice_date"
                                label="Invoice Date"
                                type="date"
                                errorMessage="Invalid value"
                                validate={{
                                  required: { value: true },
                                }}
                                disabled={
                                  selectedRow?.id &&
                                  selectedRow?.status != "RE_SUBMIT"
                                }
                                value={
                                  selectedRow?.invoice_date ||
                                  invoiceRes?.data[0]?.invoice_date ||
                                  moment().format("YYYY-MM-DD")
                                }
                                max={moment().format("YYYY-MM-DD")}
                                min={invoiceDateMinDate()}
                              />
                            </div>
                            <div
                              className={`mb-3 ${
                                selectedInvoiceTypeOption == "auto"
                                  ? "col-md-4"
                                  : "col-md-6"
                              }`}
                            >
                              <AvField
                                name="invoice_amount"
                                label="Your Invoice Amount"
                                type="text"
                                errorMessage="Invalid Value"
                                validate={{
                                  required: { value: true },
                                }}
                                disabled={true}
                                value={
                                  selectedRow?.id
                                    ? handleAmount(
                                        selectedRow?.cgst +
                                          selectedRow?.sgst +
                                          selectedRow?.igst +
                                          selectedRow?.fee_amount || 0
                                      )
                                    : handleAmount(
                                        invoiceRes?.data[0]?.invoice_amount || 0
                                      )
                                }
                              />
                            </div>
                            <div
                              className={`${
                                selectedInvoiceTypeOption == "auto" &&
                                selectedFile
                                  ? "col-md-12"
                                  : "col-md-6"
                              } mb-3`}
                            >
                              {selectedFile ? (
                                <>
                                  <Label
                                    htmlFor="formFile"
                                    className="form-label"
                                  >
                                    Selected Invoice File
                                  </Label>
                                  <div
                                    className="d-flex gap-3 align-items-center justify-content-between  rounded"
                                    style={{
                                      border: "1px solid gainsboro",
                                    }}
                                  >
                                    <div
                                      style={{ width: "70%" }}
                                      className="d-flex"
                                    >
                                      <Link
                                        to="#"
                                        className="text-primary d-flex gap-3 align-items-center p-2"
                                        onClick={previewSelectedInvoiceFile}
                                      >
                                        <svg
                                          viewBox="0 0 24 24"
                                          width="20px"
                                          fill={"#556ee6"}
                                        >
                                          <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                                        </svg>
                                        <div
                                          style={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 1,
                                            overflow: "hidden",
                                            maxWidth: "70%",
                                          }}
                                        >
                                          {selectedFile?.target?.files[0]?.name}
                                        </div>
                                      </Link>
                                    </div>

                                    <button
                                      className="btn btn-outline-danger p-1 px-2"
                                      onClick={() => {
                                        setSelectedFile(null)
                                      }}
                                    >
                                      <i
                                        className="mdi mdi-close"
                                        style={{ fontSize: "16px" }}
                                      />
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <Label
                                    htmlFor="formFile"
                                    className="form-label"
                                  >
                                    Select Signed Invoice File
                                  </Label>
                                  <Input
                                    name="file"
                                    className="form-control"
                                    type="file"
                                    accept=".pdf"
                                    id="formFile"
                                    onChange={setSelectedFile}
                                  />
                                </>
                              )}
                            </div>
                            {selectedInvoiceTypeOption == "auto" &&
                            !selectedFile ? (
                              <div className="col-md-6 mb-3 d-flex align-items-end flex gap-4">
                                <div className="mb-2">or</div>
                                <button
                                  type="button"
                                  onClick={handleSign}
                                  className="btn btn-outline-primary w-100"
                                >
                                  Sign using Digio
                                </button>
                              </div>
                            ) : null}
                          </Row>
                          {selectedRow?.status == "RE_SUBMIT" &&
                          selectedRow?.re_submit_notes ? (
                            <Row className="pt-2">
                              <p>
                                Comments:{" "}
                                <strong>{selectedRow?.re_submit_notes}</strong>
                              </p>
                            </Row>
                          ) : null}
                          <Row className="mt-3">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex gap-1 mb-3">
                                <input
                                  type="checkbox"
                                  id="invoiceCheckbox"
                                  checked={submitConsent}
                                  onChange={e => {
                                    setSubmitConsent(event.target.checked)
                                  }}
                                />
                                <label
                                  className="mb-0 font-size-14 text-muted"
                                  htmlFor="invoiceCheckbox"
                                >
                                  I have reviewed the invoice details and signed
                                  it.
                                </label>
                              </div>
                              <div className={"text-end d-flex gap-2"}>
                                {uploadingLater ? null : (
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary save-user"
                                    onClick={handleBack}
                                    disabled={loading}
                                  >
                                    Back
                                  </button>
                                )}
                                <button
                                  type="submit"
                                  className="btn btn-primary save-user"
                                  disabled={loading || !submitConsent}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </Row>
                        </AvForm>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(Billing)
