import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { createFullAdress, validateEmail } from "../../constants/common"
import Loader from "../../common/Loader"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import "./styles.css"
import { kycStatusType } from "constants/ConstantFields"
import { csvDownloadData } from "constants/common"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const BrokerUserListing = ({ rmId, history }) => {
  const [orders, setData] = useState([])
  const [phone, setPhone] = useState()
  const [loading, setLoading] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [panNumber, setPanNumber] = useState("")
  const [userName, setUserName] = useState("")
  const [panName, setPanName] = useState("")
  const [panEmail, setPanEmail] = useState("")
  const [errorDetail, setErrorDetail] = useState("")
  const [editPhone, setEditPhone] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const [isPhoneVerified, setIsPhoneVerified] = useState(false)
  const [otp, setOtp] = useState(null)
  // const [panPhone, setPanPhone] = useState(null)
  const [panType, setPanType] = useState("")
  const [newUser, setNewUser] = useState({})
  const [dobFromPan, setDobFromPan] = useState("")
  const [addressFromPan, setAddressFromPan] = useState("")
  const [panDetails, setPanDetails] = useState(null)
  const [zipCodeFromPan, setZipCodeFromPan] = useState("")
  const [cityFromPan, setCityFromPan] = useState("")
  const [countryFromPan, setCountryFromPan] = useState("")
  const [stateFromPan, setStateFromPan] = useState("")
  const [addressLine1, setAddressLine1] = useState("")
  const [addressLine2, setAddressLine2] = useState("")

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const [selectedUser, setSelectedUser] = useState({})
  const [selectedRows, setSelectedRows] = useState([])

  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const isDist = localUser?.role?.department === "Distributor"
  const createdById = localUser.id

  const getUrl = `/users?$sort[created_at]=-1&$or[0][permissions]=USER&$or[0][permissions]=OPS_USER${
    (isDist ? "&createdById=" : "&relManagerId=") + createdById
  }`
  const masterData = async () => {
    setLoading(true)
    try {
      const usersRes = await axiosInstance.get(getUrl)

      if (usersRes) {
        const users = usersRes?.data?.data || usersRes?.data
        setData(users)
      }
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
    setSelectedRow({})
    setSelectedUser({})
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [])

  const [selectAll, setSelectAll] = useState()
  const [deleteItem, setDeleteItem] = useState("")

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    clickToExpand: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelectedRow({ ...selectedRow, [row.id]: isSelect })
      const selected = [...selectedRows]
      if (isSelect) {
        selected.push(row)
      }
      setSelectedRows(selected)
      setDeleteItem(row?.id)
    },
    onSelectAll: (isSelect, rows, e) => {
      // ...
      if (isSelect) {
        setSelectAll(rows)
        setSelectedRow({})
      } else {
        setSelectAll("")
        setSelectedRow({})
      }
    },
  }

  const keys = Object.keys(selectedRow)

  const [selectAllFinal, setSelectAllFinal] = useState()

  useEffect(() => {
    if (selectAll?.length && deleteItem) {
      let arr = selectAll.filter(item => item.id != deleteItem)
      // let arr = selectAll.filter(item => !keys.includes(item))
      setSelectAllFinal(arr)
      setDeleteItem("")
    }
  }, [deleteItem])

  const newData = keys.filter(item => selectedRow[item] == true)

  const dataToRemove = keys.filter(item => selectedRow[item] == false)
  const dataToAdd = keys.filter(item => selectedRow[item] == true)

  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [addPhoneModal, setAddPhoneModal] = useState(false)

  //pagination customization
  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search

  const UserListingColumns = (download = false) => {
    const cols = [
      // {
      //   dataField: "action",
      //   isDummyField: true,
      //   text: "Action",
      //   // eslint-disable-next-line react/display-name
      //   formatter: (cellContent, order) => (
      //     <div className="d-flex gap-3">
      //       {accessRestriction >= 2 || accessRestriction === "SuperAdmin" ? (
      //         !order.phone ? (
      //           <Link to="#" className="text-success">
      //             <Button
      //               type="button"
      //               color="primary"
      //               className="btn btn-rounded"
      //               onClick={() => handleAddPhoneClick(order)}
      //             >
      //               Add phone
      //             </Button>
      //           </Link>
      //         ) : (
      //           <span className="mx-auto">NA</span>
      //         )
      //       ) : (
      //         <span className="mx-auto">NA</span>
      //       )}
      //     </div>
      //   ),
      // },
      {
        dataField: "user_name",
        text: "Name",
        sort: true,
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
      },
      {
        dataField: "phone",
        text: "Phone",
        sort: true,
      },
      {
        dataField: "kyc_status",
        text: "KYC Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = kycStatusType.find(obj => obj.id == cellContent)
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              }`}
            >
              {status?.statusText}
            </span>
          )
        },
      },
    ]

    if (!isDist) {
      cols.push(
        {
          dataField: "user_pan.name",
          text: "Name on PAN",
          sort: true,
        },
        {
          dataField: download
            ? "createdByName"
            : "createdBy.distributor_kyc.name",
          text: "Distributor Name",
          sort: true,
          formatter: (cellContent, row) =>
            row?.createdBy?.distributor_kyc?.name || row?.createdBy?.user_name,
        }
      )
    }
    cols.push(
      // {
      //   dataField: "va_account_number",
      //   text: "VPA Account No.",
      //   sort: true,
      // },
      // {
      //   dataField: "va_ifsc",
      //   text: "VPA IFSC",
      //   sort: true,
      // },

      {
        dataField: "created_at",
        text: "Created At",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.created_at),
      }
    )
    return cols
  }

  const handlePhoneChange = phone => {
    setPhone(phone)
  }

  const handlePanInput = async e => {
    setErrorDetail(false)
    setDobFromPan("")
    setAddressFromPan("")
    setPanDetails(null)
    setZipCodeFromPan("")
    setCityFromPan("")
    setStateFromPan("")
    setCountryFromPan("")
    setAddressLine1("")
    setAddressLine2("")
    const panInput = e.target.value
    if (panInput?.length == 10) {
      setPanNumber(panInput)
      const panData = {
        pan: panInput.toUpperCase(),
        consent: "Y",
        consent_text:
          "MY CONSENT MY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENT",
      }
      setLoading(true)
      try {
        const response = await axiosInstance.post(`pan-verification`, panData)
        if (response) {
          setPanName(response?.data?.name)
          setUserName(response?.data?.name)
          setPanType(response?.data?.pan_type)
          // if (response?.data?.email) setPanEmail(response?.data?.email)
          // if (response?.data?.phone) {
          //   setPhone("91" + response?.data?.phone)
          //    setPanPhone("91" + response?.data?.phone)
          // }
          const fullAdress = createFullAdress(
            response?.data?.address_line_1,
            response?.data?.address_line_2,
            response?.data?.zip_code,
            response?.data?.city,
            response?.data?.state,
            response?.data?.country
          )
          setDobFromPan(response?.data?.dob)
          setAddressFromPan(fullAdress)
          setPanDetails(response?.data?.pan_details)
          setZipCodeFromPan(response?.data?.zip_code)
          setCityFromPan(response?.data?.city)
          setStateFromPan(response?.data?.state)
          setCountryFromPan(response?.data?.country)
          setAddressLine1(response?.data?.address_line_1)
          setAddressLine2(response?.data?.address_line_2)
          setIsPhoneVerified(true)
        }
      } catch (error) {
        setErrorDetail(true)
      }
      setLoading(false)
    }
  }
  // const cancelEditPhone = () => {
  //   if (panPhone) {
  //     setPhone(panPhone)
  //   } else {
  //     setPhone("91")
  //   }
  //   setOtpSent(false)
  //   setOtp(null)
  //   setEditPhone(false)
  // }
  // const handleOtp = async (str, response) => {
  //   if (phone && phone.length > 7) {
  //     setLoading(true)
  //     if (str == "send") {
  //       const req = {
  //         phone: "+" + phone,
  //         userId: newUser.id,
  //       }
  //       try {
  //         const response = await axiosInstance.post("user-phone", req)
  //         if (response) {
  //           toast.success("OTP Sent")
  //           setOtpSent(true)
  //           setEditPhone(false)
  //         }
  //       } catch (error) {
  //         toast.error("Send OTP Failed. Please try again")
  //         setOtpSent(false)
  //       }
  //     } else if (str == "verify") {
  //       if (otp) {
  //         const req = {
  //           otp: Number(otp),
  //           phone: "+" + phone,
  //           userId: newUser.id,
  //         }
  //         try {
  //           const response = await axiosInstance.post("user-phone", req)
  //           if (response) {
  //             const userObj = {
  //               userId: newUser.id,
  //             }
  //             await axiosInstance.post("mark-registration-complete", userObj)
  //             toast.success("OTP Verified")
  //             setOtp(null)
  //             setIsPhoneVerified(true)
  //             setEditPhone(false)
  //             setOtpSent(false)
  //             modal && toggle()
  //             addPhoneModal && toggleAddPhone()
  //             masterData()
  //           }
  //         } catch (error) {
  //           toast.error("OTP Verification Failed. Please try again")
  //         }
  //       } else {
  //         toast.error("Please enter OTP")
  //       }
  //     }
  //     setLoading(false)
  //   } else {
  //     toast.error("Please enter mobile number")
  //   }
  // }

  const handleOnChange = e => {
    const { name, value } = e.target
    switch (name) {
      case "panName":
        setUserName(value)
        break
      case "panEmail":
        setPanEmail(value)
        break

      default:
        break
    }
  }

  useEffect(() => {
    if (!isEmpty(orders)) {
      setOrderList(orders)
    }
  }, [orders])

  // const toggleAddPhone = () => {
  //   setAddPhoneModal(!addPhoneModal)
  // }
  const toggle = () => {
    setModal(!modal)
  }

  useEffect(() => {
    if (!modal || !addPhoneModal) {
      setOrderList("")
      setPanEmail("")
      setPanName("")
      setUserName("")
      setPanNumber("")
      setPhone("")
      // setPanPhone("")
      // setOtp(null)
      // setOtpSent(false)
      // setIsPhoneVerified(false)
      setDobFromPan("")
      setAddressFromPan("")
      setPanDetails(null)
      setZipCodeFromPan("")
      setCityFromPan("")
      setCountryFromPan("")
      setStateFromPan("")
      setAddressLine1("")
      setAddressLine2("")
    }
    if (!modal && !addPhoneModal) {
      setNewUser({})
    }
  }, [modal, addPhoneModal])

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (!phone) {
      toast.error("Please enter mobile number")
      setLoading(false)
      return
    }
    if (!validateEmail(panEmail)) {
      toast.error("Please enter valid email id")
      setLoading(false)
      return false
    }
    if (phone?.length < 7) {
      toast.error("Please enter valid mobile number")
      setLoading(false)
      return false
    }

    const newUserDob = moment(dobFromPan, "DD/MM/YYYY").format("YYYY-MM-DD")
    const newOrder = {
      user_name: userName,
      email: panEmail,
      is_phone_verified: true,
      is_email_verified: true,
      tnc: true,
      // phone: phone == panPhone ? "+" + phone : null,
      phone: phone ? "+" + phone : null,
      dob:
        panNumber && newUserDob && newUserDob != "Invalid date"
          ? newUserDob
          : undefined,
      address: panNumber && addressFromPan ? addressFromPan : undefined,
      pan_details: panNumber && panDetails ? panDetails : undefined,
      zip_code: panNumber && zipCodeFromPan ? zipCodeFromPan : undefined,
      city: panNumber && cityFromPan ? cityFromPan : undefined,
      state: panNumber && stateFromPan ? stateFromPan : undefined,
      country: panNumber && countryFromPan ? countryFromPan : undefined,
      address_line_1: panNumber && addressLine1 ? addressLine1 : undefined,
      address_line_2: panNumber && addressLine2 ? addressLine2 : undefined,
      whtsup_tnc: true,
      pan_number: panNumber.toUpperCase(),
    }
    if (isDist) {
      newOrder["createdById"] = createdById
      newOrder["relManagerId"] = localUser.relManagerId
    } else {
      newOrder["relManagerId"] = createdById
    }
    // save new order

    // if (newUser && newUser.id) {
    //   handleOtp("send")
    //   setLoading(false)
    //   return false
    // }
    try {
      const response = await axiosInstance.post(
        `users?mark_complete=true`,
        newOrder
      )
      if (response) {
        if (panType && panNumber) {
          const userPan = {
            pan_number: panNumber.toUpperCase(),
            pan_status: "VALID",
            pan_type: panType,
            name: panName,
            userId: response.data.id,
          }
          // try {
          //   const userObj = {
          //     userId: response.data.id,
          //   }
          //   await axiosInstance.post("mark-registration-complete", userObj)
          // } catch (error) {
          //   toast.error(error?.message)
          // }
          try {
            const panResponse = await axiosInstance.post(`user-pan`, userPan)
            if (panResponse) {
              // toast.success("User PAN Successfully Added")
              setLoading(false)
              setPhone("")
              setPanEmail("")
              setPanName("")
              setUserName("")
              setPanNumber("")
              setDobFromPan("")
              setAddressFromPan("")
              setPanDetails(null)
              setZipCodeFromPan("")
              setCityFromPan("")
              setCountryFromPan("")
              setStateFromPan("")
              setAddressLine1("")
              setAddressLine2("")
            }
          } catch (error) {
            toast.error(error?.message)
          }
        }
        toggle()
        toast.success("User Successfully Added")
        masterData()
      }
    } catch (error) {
      const msg = error?.response?.data?.errors[0]?.message
      toast.error(msg)
    }
    setLoading(false)
  }

  const handleAddPhoneClick = user => {
    setNewUser(user)
    setOrderList("")
    // setOtp(null)
    // setOtpSent(false)
    // setIsPhoneVerified(false)
    setEditPhone(true)
    toggleAddPhone()
  }
  const handleOrderClicks = () => {
    setOrderList("")
    setPanEmail("")
    setPanName("")
    setUserName("")
    setPanNumber("")
    setPhone("")
    // setPanPhone("")
    // setOtp(null)
    // setOtpSent(false)
    // setIsPhoneVerified(false)
    setDobFromPan("")
    setPanDetails(null)
    setAddressFromPan("")
    setZipCodeFromPan("")
    setCityFromPan("")
    setCountryFromPan("")
    setStateFromPan("")
    setAddressLine1("")
    setAddressLine2("")
    setEditPhone(false)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  let downloadableArr = []

  let arr
  if (orders) {
    let csvTableHeaders = UserListingColumns(true)
    var str = JSON.stringify(orders)
    arr = JSON.parse(str)
    arr.forEach(
      el =>
        (el.createdByName =
          el?.createdBy?.distributor_kyc?.name || el?.createdBy?.user_name)
    )
    downloadableArr = csvDownloadData(csvTableHeaders, arr)
  }

  // const handleEditPhone = () => {
  //   setOtpSent(false)
  //   setOtp(null)
  //   setEditPhone(true)
  // }

  useEffect(() => {
    const selected = selectedRows.find(selected => selected.id === newData[0])
    setSelectedUser(selected)
  }, [newData])

  return (
    <React.Fragment>
      {loading && <Loader />}

      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions
          ?.DISTRIBUTOR_PORTAL_MANAGE_INVESTORS_VIEW ||
        accessControlData?.permissions
          ?.SALES_AND_INVESTOR_SERVICE_MANAGE_MY_INVESTORS_VIEW ? (
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={rmId ? "Manage My Investors" : "Manage Investors"}
            />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={UserListingColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row justify-content-between">
                            <div className="col">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </div>
                            <div className="col-auto">
                              {(accessControlData?.permissions
                                ?.DISTRIBUTOR_PORTAL_MANAGE_INVESTORS_ADD ||
                                accessControlData?.permissions
                                  ?.SALES_AND_INVESTOR_SERVICE_MANAGE_MY_INVESTORS_ADD) && (
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded  mb-2 me-2"
                                  onClick={handleOrderClicks}
                                >
                                  <i className="mdi mdi-plus me-1" /> Add
                                </Button>
                              )}
                              {(accessControlData?.permissions
                                ?.SALES_AND_INVESTOR_SERVICE_MANAGE_MY_INVESTORS_ADDUPDATE_KYC ||
                                accessControlData?.permissions
                                  ?.DISTRIBUTOR_PORTAL_MANAGE_INVESTORS_ADDUPDATE_KYC) && (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-rounded btn-primary mb-2 me-2 text-light"
                                    disabled={
                                      newData?.length != 1 ||
                                      !selectedUser?.is_registered ||
                                      selectedUser?.kyc_status == "VERIFIED" ||
                                      selectedUser?.kyc_status ==
                                        "IN_VERIFICATION"
                                    }
                                    onClick={() => {
                                      history.push(
                                        `${
                                          isDist ? `distributor` : `rm`
                                        }-user-kyc/${newData[0]}`
                                      )
                                    }}
                                  >
                                    Add/Update KYC
                                  </button>
                                </>
                              )}
                              {/* {(accessRestriction >= "4" ||
                              accessRestriction === "SuperAdmin") && (
                              <Button
                                type="button"
                                color="primary"
                                className="btn-rounded  mb-2 me-2"
                                onClick={() =>
                                  JSONToCSVConvertor(
                                    downloadableArr,
                                    "Users",
                                    true
                                  )
                                }
                                style={{ marginRight: "3px" }}
                              >
                                <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                Download
                              </Button>
                            )} */}
                            </div>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                columns={UserListingColumns()}
                                data={orders}
                                pagination={paginationFactory(pageOptions)}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  Add Your Investor
                                </ModalHeader>
                                <ModalBody>
                                  {loading && <Loader />}
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      <Col className="col-12">
                                        <div>
                                          <div className="mb-3">
                                            <AvField
                                              style={{
                                                textTransform: "uppercase",
                                              }}
                                              name="pan_number"
                                              label="Enter Investor's PAN"
                                              type="text"
                                              errorMessage="Enter a valid input"
                                              value={panNumber || ""}
                                              maxLength="10"
                                              onChange={handlePanInput}
                                              validate={{
                                                required: { value: true },
                                              }}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="panName"
                                              label="Name (will be autofilled from PAN)"
                                              autoComplete="off"
                                              type="text"
                                              errorMessage="Invalid Name"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={userName || ""}
                                              onChange={handleOnChange}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="panEmail"
                                              label="Email"
                                              type="text"
                                              errorMessage="Invalid Email"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={panEmail || ""}
                                              onChange={handleOnChange}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <p
                                              style={{
                                                fontWeight: "500",
                                                marginBottom: "0.5rem",
                                              }}
                                            >
                                              Mobile No.
                                            </p>
                                            <div>
                                              <PhoneInput
                                                inputClass="form-controls"
                                                countryCodeEditable={false}
                                                prefix="+"
                                                value={phone || ""}
                                                country={"in"}
                                                placeholder="mobile"
                                                // disabled={
                                                //   editPhone ? false : true
                                                // }
                                                onChange={phone =>
                                                  handlePhoneChange(phone)
                                                }
                                              />
                                            </div>
                                          </div>
                                          {/* {otpSent && (
                                          <div className="mb-3">
                                            <AvField
                                              name="otp"
                                              label="OTP"
                                              autoComplete="off"
                                              type="number"
                                              maxLength="6"
                                              errorMessage="Invalid OTP"
                                              value={otp || ""}
                                              onChange={e =>
                                                setOtp(e.target.value)
                                              }
                                              validate={{
                                                required: { value: true },
                                              }}
                                            ></AvField>
                                          </div>
                                        )} <p className="mt-3 text-muted">
                                          Note: If you edit the above mobile
                                          no., OTP will be sent for
                                          verification, to the updated mobile
                                          number
                                        </p> */}
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          {/* {!editPhone && !otpSent && (
                                          <button
                                            type="button"
                                            className={
                                              "btn btn-secondary save-user"
                                            }
                                            style={{ marginRight: "1rem" }}
                                            onClick={() => {
                                              setEditPhone(true)
                                            }}
                                          >
                                            Edit Mobile
                                          </button>
                                        )}
                                        {editPhone && !otpSent && (
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            style={{ marginRight: "1rem" }}
                                            onClick={() => cancelEditPhone()}
                                          >
                                            Cancel
                                          </button>
                                        )}
                                        {otpSent && (
                                          <button
                                            type="button"
                                            className="btn btn-primary save-user"
                                            style={{ marginRight: "1rem" }}
                                            onClick={() => handleOtp("send")}
                                          >
                                            Resend Otp
                                          </button>
                                        )}
                                        {otpSent && (
                                          <button
                                            type="button"
                                            className="btn btn-success save-user"
                                            onClick={() => handleOtp("verify")}
                                          >
                                            Verify Otp
                                          </button>
                                        )} */}
                                          {!otpSent && (
                                            <button
                                              type="submit"
                                              className="btn btn-success save-user"
                                            >
                                              {orderList.relManagerId
                                                ? "Update Now"
                                                : "Add Now"}
                                            </button>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                              {/* <Modal
                              isOpen={addPhoneModal}
                              toggle={toggleAddPhone}
                            >
                              <ModalHeader toggle={toggleAddPhone} tag="h4">
                                {otpSent
                                  ? "Verify Mobile No."
                                  : "Add Mobile No."}
                              </ModalHeader>
                              <ModalBody>
                                {loading && <Loader />}
                                <AvForm onValidSubmit={() => handleOtp("send")}>
                                  <Row form>
                                    <Col className="col-12">
                                      <div>
                                        <div className="mb-3">
                                          <div>
                                            <PhoneInput
                                              inputClass="form-controls"
                                              countryCodeEditable={false}
                                              prefix="+"
                                              value={phone || ""}
                                              country={"in"}
                                              placeholder="mobile"
                                              disabled={
                                                editPhone ? false : true
                                              }
                                              onChange={phone =>
                                                handlePhoneChange(phone)
                                              }
                                            />
                                          </div>
                                        </div>
                                        {otpSent && (
                                          <div className="mb-3">
                                            <AvField
                                              name="otp"
                                              label="OTP"
                                              autoComplete="off"
                                              type="number"
                                              maxLength="6"
                                              errorMessage="Invalid OTP"
                                              value={otp || ""}
                                              onChange={e =>
                                                setOtp(e.target.value)
                                              }
                                              validate={{
                                                required: { value: true },
                                              }}
                                            ></AvField>
                                          </div>
                                        )}
                                        <p className="mt-3 text-muted">
                                          Note: OTP will be sent to the above
                                          mobile no. for verification
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        {otpSent && (
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            style={{ marginRight: "1rem" }}
                                            onClick={handleEditPhone}
                                          >
                                            Edit Mobile
                                          </button>
                                        )}
                                        {otpSent && (
                                          <button
                                            type="button"
                                            className="btn btn-primary save-user"
                                            style={{ marginRight: "1rem" }}
                                            onClick={() => handleOtp("send")}
                                          >
                                            Resend Otp
                                          </button>
                                        )}
                                        {otpSent && (
                                          <button
                                            type="button"
                                            className="btn btn-success save-user"
                                            onClick={() => handleOtp("verify")}
                                          >
                                            Verify Otp
                                          </button>
                                        )}
                                        {!otpSent && (
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Send OTP
                                          </button>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal> */}
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

BrokerUserListing.propTypes = {
  orders: PropTypes.array,
  rmId: PropTypes.string,
  history: PropTypes.any,
}

export default withRouter(BrokerUserListing)
