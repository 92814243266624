import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import CsvDownload from "react-json-to-csv"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import ReactSelect from "constants/ReactSelect"

//redux

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { csvDownloadData, humanize } from "constants/common"
import { DealManagementAccess } from "common/AccessManagement"
import {
  AccessId,
  paymentStatusForTransactions,
  webhookPaymentModes,
} from "constants/ConstantFields"

import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const RmWebhookDump = props => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [userFilter, setUserFilter] = useState("")
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [paymentMode, setPaymentMode] = useState("")

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ""}${
    paymentMode ? `&payment_mode=${paymentMode?.id}` : ""
  }`
  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])
  const localUser = JSON.parse(localStorage.getItem("userInfo"))

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `transaction-webhook-dump?relManagerId=${localUser.id}&$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`
      )

      if (response) {
        setData(response?.data?.data)
        setTotal(response.data.total)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      case "paymentMode":
        setPaymentMode(e)
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(async () => {
    masterData()
  }, [limit, skip, paymentMode])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const downloadData = async fileName => {
    try {
      setLoading(true)
      const res = await axiosInstance.get(
        `transaction-webhook-dump?$sort[created_at]=-1&relManagerId=${localUser.id}${filterUrl}`
      )
      if (res) {
        const data = res.data?.data
        const csvTableHeaders = EcommerceOrderColumns()
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        arr?.forEach(item => {
          item["amount"] = item["amount"] / 100
          item["fee"] = item["fee"] / 100
          item["tax"] = item["tax"] / 100
        })
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const EcommerceOrderColumns = toggleModal => [
    {
      dataField: "email",
      text: "Payer Email",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Transaction Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
    {
      dataField: "amount",
      text: "Amount (In Rs)",
      sort: true,
      formatter: (cellContent, row) => handleAmount(row.amount),
    },
    {
      dataField: "payment_status_dump",
      text: "Payment Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const status = paymentStatusForTransactions.find(
          obj => obj.id == cellContent
        )
        return (
          <div className="">
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              }`}
            >
              {status?.statusText}
            </span>
          </div>
        )
      },
    },
    {
      dataField: "payment_mode",
      text: "Payment Mode",
      sort: true,
    },
    {
      dataField: "method",
      text: "Payment Method",
      sort: true,
      formatter: (cellContent, row) => humanize(row.method),
    },
    {
      dataField: "payer_name",
      text: "Payer Name",
      sort: true,
    },
    {
      dataField: "contact",
      text: "Payer Contact no.",
      sort: true,
    },
  ]

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    const amountInRupees = amount / 100
    return dollarIndianLocale.format(amountInRupees)
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions
          ?.SALES_AND_INVESTOR_SERVICE_VA_TRANSACTIONS_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem="Virtual Account Transactions" />
            <Row className="mb-2 row justify-content-between">
              <Col md={2}>
                <select
                  className="form-select w-75"
                  value={limit}
                  onChange={e => handleFilterChange(e, "limit")}
                >
                  {[10, 30, 50, 100].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </Col>
              {/* <div className="col-auto">
              {(accessRestriction == "4" ||
                accessRestriction === "SuperAdmin") && (
                <Button
                  type="button"
                  color="primary"
                  className="btn-rounded  mb-2 me-2"
                  onClick={() => {
                    downloadData("Virtual Account Transactions")
                  }}
                  style={{ marginRight: "3px" }}
                >
                  <i className="mdi mdi-arrow-down-bold-circle"></i> Download
                </Button>
              )}
            </div> */}
            </Row>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={EcommerceOrderColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-4 row">
                            <Col md={3}>
                              {/* <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block"> */}
                              <div className="position-relative">
                                <label>User</label>
                                <input
                                  onChange={e => handleFilterChange(e, "user")}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control rounded custom-input-height"
                                  placeholder={`Search by User Name, Email or Phone`}
                                  value={userFilter || ""}
                                />
                                {/* <i className="bx bx-search-alt"></i> */}
                              </div>
                              {/* </div> */}
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Payment Mode</label>
                                <ReactSelect
                                  users={webhookPaymentModes}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "paymentMode")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["statusText"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                columns={EcommerceOrderColumns()}
                                data={orders}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                            </Col>
                          </Row>
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}`}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

RmWebhookDump.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(RmWebhookDump)
