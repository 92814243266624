import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import Loader from "common/Loader"
import { clientCategory, csvDownloadData } from "constants/common"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { DealManagementAccess } from "common/AccessManagement"
import ReactSelect from "constants/ReactSelect"
import { AccessId } from "constants/ConstantFields"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const isAddedFilterDropDown = [
  { id: 1, key: "false", value: "False" },
  { id: 2, key: "true", value: "True" },
]
const UniqueClientCode = props => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [uccData, setUccData] = useState([])

  const [isEdit, setIsEdit] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)

  const [userSearch, setUserSearch] = useState("")
  const [userList, setUserList] = useState([])
  const [selectedUser, setSelectedUser] = useState([])

  const [transferorMasterList, setTransferorMasterList] = useState([])
  const [selectedTransferor, setSelectedTransferor] = useState(null)

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [userFilter, setUserFilter] = useState("")
  const [transferorFilter, setTransferorFilter] = useState()
  const [isAddedFilter, setIsAddedFilter] = useState(null)
  const [total, setTotal] = useState(0)
  const [uccCodeOption, setUccCodeOption] = useState("auto")
  const [downloadAlert, setDownloadAlert] = useState(false)

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    transferorFilter?.id ? `&transferorId=${transferorFilter.id}` : ""
  }${isAddedFilter?.id ? `&is_added=${isAddedFilter.key}` : ""}`

  const masterData = async () => {
    setLoading(true)

    try {
      const uccRes = await axiosInstance.get(
        `user-ucc?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`
      )

      if (uccRes) {
        setUccData(uccRes?.data?.data)
        setTotal(uccRes?.data?.total)
        let pages = Math.ceil(
          (uccRes.data?.total || uccRes.total) /
            (uccRes.data?.limit || uccRes.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    try {
      const transferorMasterRes = await axiosInstance.get(
        `transferor-master?is_added_on_iccl=true`
      )
      const transferorMasterData =
        transferorMasterRes?.data?.data || transferorMasterRes?.data
      setTransferorMasterList(transferorMasterData)
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "transferorType":
        setTransferorFilter(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      case "isAdded":
        setIsAddedFilter(e)
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(() => {
    masterData()
  }, [limit, skip, transferorFilter, isAddedFilter])

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const handleEdit = order => {
    setSelectedRow(order)
    if (!order.is_ucc_auto_generated) {
      setUccCodeOption("manual")
    } else {
      setUccCodeOption("auto")
    }
    setDownloadAlert(false)
    setIsEdit(true)
    setSelectedTransferor(order.transferor)
    setSelectedUser([order.user])
    toggle()
  }

  const Columns = (download = false) => {
    const action = {
      dataField: "",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessControlData?.permissions?.UNIQUE_CLIENT_CODE_EDIT && (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleEdit(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {accessControlData?.permissions?.UNIQUE_CLIENT_CODE_EDIT}
                </UncontrolledTooltip>
              </Link>
            )}
          </div>
        </>
      ),
    }

    const col_1 = [
      { dataField: "transferor.transferor_ucc", text: "Owner UCC", sort: true },
    ]
    const col_2_only_download = [
      { dataField: "", text: "Custodian_code", sort: true },
    ]

    const col_3 = [
      { dataField: "ucc", text: "Client UCC", sort: true },
      { dataField: "user.user_pan.name", text: "Client Name", sort: true },
    ]
    const col_9_view_only = [
      {
        dataField: "is_added",
        text: "Added Status on ICCL",
        sort: true,
      },
    ]
    const col_4 = [
      {
        dataField: "user.user_demat.demat_id",
        text: "Depository Code",
        sort: true,
        formatter: cellContent => handleDepositoryCode(cellContent),
      },
      {
        dataField: "user.user_demat.demat_id",
        text: "DP ID",
        sort: true,
        formatter: cellContent => handleDpId(cellContent),
      },
      {
        dataField: "user.user_demat.demat_id",
        text: "Client ID",
        sort: true,
        formatter: cellContent => handleClientId(cellContent),
      },
      {
        dataField: "user.user_bank_account.ifsc_code",
        text: "Bank IFSC Code",
        sort: true,
      },
      {
        dataField: "user.user_bank_account.account_number",
        text: "Account No",
        sort: true,
      },
      {
        dataField: "user.user_pan.pan_type",
        text: "Acct Type",
        sort: true,
        formatter: cellContent => handleAccountType(cellContent),
      },
      {
        dataField: "user.user_pan.name",
        text: "Contact Person Name",
        sort: true,
      },
    ]
    const col_4_only_donload = [
      {
        dataField: "",
        text: "Contact Tel No",
        sort: true,
      },
    ]
    const col_5 = [
      {
        dataField: "user.phone",
        text: "Contact Mobile No",
        sort: true,
      },
      {
        dataField: "user.email",
        text: "Contact Mail Id",
        sort: true,
      },
      {
        dataField: "user.address",
        text: "Contact Address",
        sort: true,
      },
      {
        dataField: "user.city",
        text: "Contact City",
        sort: true,
      },
      {
        dataField: "user.user_pan.pan_number",
        text: "Contact PAN",
        sort: true,
      },
      {
        dataField: "user.state",
        text: "Contact State",
        sort: true,
      },
      {
        dataField: "user.zip_code",
        text: "Contact PINCode",
        sort: true,
      },
    ]

    const col_6_only_download = [
      {
        dataField: "invalid",
        text: "legalEntityId",
        sort: true,
      },
    ]

    const col_7 = [
      {
        dataField: "client_category",
        text: "Client Category",
        sort: true,
      },
    ]

    const col_8_view_only = [
      {
        dataField: "client_category",
        text: "Client Category Name",
        sort: true,
        formatter: cellContent =>
          clientCategory.find(item => item.code == cellContent)?.name,
      },
    ]
    if (download) {
      return [
        ...col_1,
        ...col_2_only_download,
        ...col_3,
        ...col_4,
        ...col_4_only_donload,
        ...col_5,
        ...col_6_only_download,
        ...col_7,
      ]
    }
    return [
      action,
      ...col_1,
      ...col_3,
      ...col_9_view_only,
      ...col_4,
      ...col_5,
      ...col_7,
      ...col_8_view_only,
    ]
  }

  const handleAccountType = cellContent =>
    cellContent ? (cellContent == "Person" || cellContent == "HUF" ? 1 : 2) : ""

  const handleClientId = cellContent => {
    if (!cellContent) {
      return ""
    }

    if (!cellContent.startsWith("IN")) {
      return cellContent.replaceAll("-", "")
    }
    return cellContent.replaceAll("-", "").trim().slice(8)
  }

  const handleDpId = cellContent => {
    if (!cellContent) {
      return ""
    }
    return cellContent.replaceAll("-", "").trim().slice(0, 8)
  }

  const handleDepositoryCode = cellContent =>
    cellContent ? (cellContent.startsWith("IN") ? "NSDL" : "CDSL") : ""

  const toggle = () => {
    setModal(!modal)
  }

  const addNewRow = () => {
    setSelectedUser([])
    setSelectedTransferor(null)
    setSelectedRow(null)
    setDownloadAlert(false)
    setUccCodeOption("auto")
    setIsEdit(false)
    toggle()
  }

  const handleValidSubmit = async (e, values) => {
    if (isEdit) {
      try {
        setLoading(true)
        const payload = {
          is_added: values.is_added,
          ucc: values.ucc ? values.ucc.toUpperCase() : undefined,
          client_category: values.client_category,
        }

        const response = await axiosInstance.patch(
          `/user-ucc/${selectedRow.id}`,
          payload
        )
        if (response) {
          toast.success("Updated Successfully.")
          masterData()
        }
        toggle()
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    } else {
      if (!selectedTransferor?.id) {
        toast.error("Please select a Transferor")
        return
      }
      if (!Array.isArray(selectedUser) || !selectedUser.length > 0) {
        toast.error("Please select User")
        return
      }
      try {
        setLoading(true)
        const payload = {
          transferorId: selectedTransferor.id,
          users: selectedUser.map(item => item.id),
          ucc: values.ucc ? values.ucc.toUpperCase() : undefined,
          client_category: values.client_category,
        }
        const response = await axiosInstance.post("/user-ucc", payload)
        if (response) {
          if (response.data.created == 0) {
            toast.warn("Already Created for all selected users.")
            return
          }
          if (response.data.created > 0) {
            toast.success(
              `UCC Created for ${response.data.created} ${
                response.data.created == 1 ? "user" : "users"
              }.`
            )
          }
          if (response.data.alreadyExists > 0) {
            toast.warn(
              `UCC Already created for ${response.data.alreadyExists} ${
                response.data.alreadyExists == 1 ? "user" : "users"
              }.`
            )
          }
          if (response.data.kycNotVerified > 0) {
            toast.error(
              `KYC Not verified for ${response.data.kycNotVerified} ${
                response.data.kycNotVerified == 1 ? "user" : "users"
              }.`
            )
          }
          masterData()
        }
        toggle()
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleUserSearch = async e => {
    setUserSearch(e)
    if (e.length >= 3) {
      setLoading(true)
      if (e) {
        const usersRes = await axiosInstance.get(
          `/non-ucc-users?transferorId=${selectedTransferor?.id}&name=${e}`
        )
        const users = usersRes?.data?.data || usersRes?.data
        if (users) {
          setUserList(users)
        } else {
          toast.error("something went wrong")
        }
      } else {
        setUserList([])
      }
      setLoading(false)
    }
  }

  const handleUccCodeOptionChange = e => {
    setUccCodeOption(e.target.value)
    setUserList([])
    setSelectedUser(null)
    setUserSearch("")
  }

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  useEffect(async () => {
    if (modal && !isEdit) {
      try {
        setLoading(true)
        const transferorMasterRes = await axiosInstance.get(
          `transferor-master?is_added_on_iccl=true`
        )
        const transferorMasterData =
          transferorMasterRes?.data?.data || transferorMasterRes?.data
        setTransferorMasterList(transferorMasterData)
        setSelectedTransferor()
      } catch (error) {
        toast.error(error?.message)
      } finally {
        setLoading(false)
      }
    } else {
      setUserList([])
      if (!isEdit) {
        setSelectedUser(null)
      }
    }
  }, [modal])

  const downloadData = async () => {
    if (!transferorFilter?.id || !isAddedFilter) {
      setDownloadAlert(true)
      setIsEdit(false)
      toggle()
      return
    }
    try {
      const fileName = `${
        transferorFilter ? transferorFilter.transferor_code + "-" : ""
      }Unique-Client-Code`
      setLoading(true)
      const res = await axiosInstance.get(
        `user-ucc?$sort[created_at]=-1${filterUrl}`
      )
      if (res) {
        const data = res.data?.data?.map(item => ({
          ...item,
          user: {
            ...item.user,
            zip_code: item.user.zip_code ? Number(item.user.zip_code) : null,
          },
        }))
        const csvTableHeaders = Columns(true)
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)

        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        downladableArr.forEach(item => {
          item.Depository_Code = handleDepositoryCode(item.Depository_Code)
          item.DP_ID = handleDpId(item.DP_ID)
          item.Client_ID = handleClientId(item.Client_ID)
          item.Acct_Type = handleAccountType(item.Acct_Type)
          item["Auto_Deal_Confirm"] = "Y"
          item.Client_Category = item.Client_Category
            ? item.Client_Category
            : "0"
          item.Contact_Mobile_No = item.Contact_Mobile_No.includes("+91")
            ? item.Contact_Mobile_No.replaceAll("+91", "")
            : item.Contact_Mobile_No.replaceAll("+", "")

          item.Contact_Address = item.Contact_Address.replaceAll(",", "")
          for (let key in item) {
            if (!item[key] || item[key] == "-") {
              item[key] = ""
            }
          }
        })
        console.log(downladableArr, data)

        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const handleUserSelection = e => {
    setSelectedUser(e)
    if (!(e[0].city && e[0].state && e[0].zip_code)) {
      toast.error("User's city, state and pin code are missing")
    }
  }
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.UNIQUE_CLIENT_CODE_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />{" "}
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between">
                  <Col md={2}>
                    <select
                      className="form-select w-75"
                      value={limit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <div className="col-auto">
                    {accessControlData?.permissions
                      ?.UNIQUE_CLIENT_CODE_EDIT && (
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-2 me-2"
                        onClick={addNewRow}
                      >
                        <i className="mdi mdi-plus me-1" />
                        {accessControlData?.permissions?.UNIQUE_CLIENT_CODE_ADD}
                      </Button>
                    )}
                    {accessControlData?.permissions
                      ?.UNIQUE_CLIENT_CODE_DOWNLOAD && (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded mb-2 me-2"
                        onClick={() => downloadData()}
                        style={{ marginRight: "3px" }}
                        // disabled={!transferorFilter?.id || !isAddedFilter?.id}
                      >
                        <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                        {
                          accessControlData?.permissions
                            ?.UNIQUE_CLIENT_CODE_DOWNLOAD
                        }
                      </Button>
                    )}
                  </div>
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={uccData}
                      columns={Columns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row">
                            <Col md={4}>
                              <div className="position-relative">
                                <label>User</label>
                                <input
                                  onChange={e => handleFilterChange(e, "user")}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control rounded custom-input-height"
                                  placeholder={`Email, Phone, Name on Pan or Pan Number`}
                                  value={userFilter || ""}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Select Transferor</label>
                                <ReactSelect
                                  users={transferorMasterList}
                                  setSelectedOption={setTransferorFilter}
                                  multiOptionLabel={true}
                                  optionLabelKeys={["transferor_name"]}
                                  selectedOption={transferorFilter}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Added Status on ICCL</label>
                                <ReactSelect
                                  users={isAddedFilterDropDown}
                                  setSelectedOption={setIsAddedFilter}
                                  multiOptionLabel={true}
                                  optionLabelKeys={["value"]}
                                  selectedOption={isAddedFilter}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                columns={Columns()}
                                data={uccData}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {isEdit
                                    ? "Update Unique Client Code"
                                    : downloadAlert
                                    ? "Invalid Request!"
                                    : "Add Unique Client Code"}
                                </ModalHeader>
                                <ModalBody>
                                  {downloadAlert ? (
                                    <>
                                      <Row form>
                                        {!transferorFilter?.id && !isAddedFilter
                                          ? "Please Select Transferor and Added status on ICCL to download."
                                          : !transferorFilter?.id
                                          ? "Please Select Transferor to download."
                                          : !isAddedFilter
                                          ? "Please select Added status on ICCL to download."
                                          : ""}
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end pt-2">
                                            <button
                                              type="button"
                                              className="btn btn-success save-user"
                                              onClick={toggle}
                                            >
                                              OK
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </>
                                  ) : (
                                    <AvForm onValidSubmit={handleValidSubmit}>
                                      {/* {isMarkAsAdded ? (
                                    <Row form className="mb-3">
                                      Are you sure you want to mark this
                                      document as added?
                                    </Row>
                                  ) : ( */}
                                      <Row form>
                                        {/* {isEdit ? (
                                      <Col className="col-12">
                                        <h5>
                                          Are you sure you want to regenerate?
                                        </h5>
                                        The existing document will be lost.
                                      </Col>
                                    ) : ( */}
                                        <Col className="col-12">
                                          <div className="row">
                                            <div className="mb-3">
                                              <label>Select Transferor</label>
                                              <ReactSelect
                                                users={transferorMasterList}
                                                setSelectedOption={e => {
                                                  setSelectedTransferor(e)
                                                  setUserList([])
                                                  setSelectedUser([])
                                                }}
                                                multiOptionLabel={true}
                                                optionLabelKeys={[
                                                  "transferor_name",
                                                  "transferor_depository_name",
                                                  "dp_name",
                                                  "dp_id",
                                                  "client_id",
                                                ]}
                                                selectedOption={
                                                  selectedTransferor
                                                }
                                                isDisabled={isEdit}
                                              />
                                            </div>
                                            {!isEdit ? (
                                              <div className="mb-3">
                                                <AvField
                                                  name="ucc_option"
                                                  label="Client UCC Type"
                                                  type="select"
                                                  className="form-select"
                                                  errorMessage="Invalid value"
                                                  value={uccCodeOption}
                                                  onChange={
                                                    handleUccCodeOptionChange
                                                  }
                                                >
                                                  <option value="auto">
                                                    Auto Generated
                                                  </option>
                                                  <option value="manual">
                                                    Manual
                                                  </option>
                                                </AvField>
                                              </div>
                                            ) : null}
                                            <div className="mb-3">
                                              <label>Select User</label>
                                              {!isEdit ? (
                                                <>
                                                  {uccCodeOption == "manual" ? (
                                                    <ReactSelect
                                                      users={userList}
                                                      searchValue={userSearch}
                                                      setSearchValue={
                                                        handleUserSearch
                                                      }
                                                      selectedOption={
                                                        selectedUser
                                                      }
                                                      setSelectedOption={e =>
                                                        handleUserSelection([e])
                                                      }
                                                      multiOptionLabel={false}
                                                      optionLabelKeys={[
                                                        "name_on_pan",
                                                        "email",
                                                        "phone",
                                                        "pan_number",
                                                      ]}
                                                      isDisabled={
                                                        !selectedTransferor?.id
                                                      }
                                                    />
                                                  ) : (
                                                    <ReactSelect
                                                      users={userList}
                                                      searchValue={userSearch}
                                                      setSearchValue={
                                                        handleUserSearch
                                                      }
                                                      selectedOption={
                                                        selectedUser
                                                      }
                                                      setSelectedOption={e =>
                                                        handleUserSelection([e])
                                                      }
                                                      multiOptionLabel={false}
                                                      optionLabelKeys={[
                                                        "name_on_pan",
                                                        "email",
                                                        "phone",
                                                        "pan_number",
                                                      ]}
                                                      isDisabled={
                                                        !selectedTransferor?.id
                                                      }
                                                    />
                                                  )}
                                                </>
                                              ) : (
                                                <ReactSelect
                                                  selectedOption={
                                                    selectedUser[0]
                                                  }
                                                  multiOptionLabel={false}
                                                  optionLabelKeys={[
                                                    "user_pan?.name",
                                                    "email",
                                                    "phone",
                                                    "user_pan?.pan_number",
                                                  ]}
                                                  isDisabled={true}
                                                />
                                              )}
                                            </div>
                                            {uccCodeOption == "manual" ||
                                            isEdit ? (
                                              <div className="mb-3">
                                                <AvField
                                                  name="ucc"
                                                  label="Client UCC"
                                                  type="text"
                                                  errorMessage="Invalid value"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  className="text-uppercase"
                                                  value={selectedRow?.ucc || ""}
                                                  disabled={
                                                    uccCodeOption == "auto"
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              </div>
                                            ) : null}
                                            {isEdit ? (
                                              <div className="mb-3">
                                                <AvField
                                                  name="is_added"
                                                  label="Added Status on ICCL"
                                                  type="select"
                                                  className="form-select"
                                                  errorMessage="Invalid value"
                                                  value={
                                                    selectedRow.is_added?.toString() ||
                                                    ""
                                                  }
                                                >
                                                  <option disabled value="">
                                                    Select
                                                  </option>
                                                  <option value={true}>
                                                    TRUE
                                                  </option>
                                                  <option value={false}>
                                                    FALSE
                                                  </option>
                                                </AvField>
                                              </div>
                                            ) : null}
                                            <div className="mb-3">
                                              <AvField
                                                name="client_category"
                                                label="Client Category"
                                                type="select"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  selectedRow?.client_category ==
                                                  0
                                                    ? "0"
                                                    : selectedRow?.client_category ||
                                                      ""
                                                }
                                              >
                                                <option value="" disabled>
                                                  Select
                                                </option>
                                                {clientCategory.map(item => (
                                                  <option
                                                    value={item.code}
                                                    key={item.id}
                                                  >
                                                    {item.name}
                                                  </option>
                                                ))}
                                              </AvField>
                                            </div>
                                          </div>
                                        </Col>
                                        {/* )} */}
                                      </Row>
                                      {/* )} */}
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <Button
                                              type="submit"
                                              color="success"
                                              className="save-user"
                                            >
                                              {isEdit ? "Confirm" : "Submit"}
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </AvForm>
                                  )}
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}
                    `}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(UniqueClientCode)
