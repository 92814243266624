import { axiosInstance } from "ConfigAxioxinstance"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { toast } from "react-toastify"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"

import nodata from "../../assets/images/nodata/no-data.svg"
import moment from "moment"
import { JSONToCSVConvertor } from "common/jsontocsv"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import Breadcrumbs from "components/Common/Breadcrumb"
import getBase64 from "base64"
import ProjectDetailCard from "components/Common/ProjectDetailCard"
import ReactQuill from "react-quill"
import EditorToolbar, {
  formats,
  modules,
} from "pages/SponserOtherProjects/EditorToolbar"
import ReactMultiSelect from "constants/ReactMultiSelect"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const ProjectsCashflow = () => {
  const [loading, setLoading] = useState(false)
  const [projects, setProjects] = useState([])
  const [selectedProject, setSelectedProject] = useState()
  const [projectsCashflow, setProjectsCashflow] = useState([])
  const [selectedOption, setSelectedOption] = useState()
  const [base64, setBase64] = useState("")
  const [file, setFile] = useState()
  const [modal, setModal] = useState(false)
  const [projectData, setProjectData] = useState({})
  const [postInvLotList, setPostInvLotList] = useState([])
  const [invLotList, setInvLotList] = useState([])
  const [selectedPostInvLot, setSelectedPostInvLot] = useState([])
  const [selectedInvLot, setSelectedInvLot] = useState()

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const [notes, setNotes] = useState({ value: null })
  const [prevCashflowData, setPrevCashflowData] = useState([])
  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/investment?$sort[created_at]=-1`
      )
      if (response) {
        setProjects(response.data?.data)
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  const handleInvestmentChange = async () => {
    setLoading(true)
    try {
      setInvLotList([])
      setSelectedInvLot(null)
      setProjectsCashflow([])
      const invLotRes = await axiosInstance.get(
        `/investment-lot?investmentId=${selectedProject.id}`
      )
      if (invLotRes) {
        const data = invLotRes.data?.data || invLotRes.data
        setInvLotList(
          data.map(item => {
            if (item.status) {
              return {
                ...item,
                investment_lot_name: item.investment_lot_name + " (Active)",
              }
            }
            return item
          })
        )
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  const handleSelectedInvetsmentLot = async () => {
    setLoading(true)
    let url = `/project-cash-flow?investmentId=${selectedProject.id}&investmentLotId=${selectedInvLot.id}`
    try {
      const response = await axiosInstance.get(url)
      if (response) {
        setProjectsCashflow(response.data?.data || response.data)
      }

      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    const amountInRupees = Math.round(Math.abs(amount))
    return `₹ ${
      amount < 0
        ? "(" + dollarIndianLocale.format(amountInRupees) + ")"
        : dollarIndianLocale.format(amountInRupees)
    } `
  }

  const onSelectFile = e => {
    setFile(e.target.files[0])
  }

  useEffect(() => {
    masterData()
  }, [])

  useEffect(() => {
    if (selectedInvLot?.id) {
      handleSelectedInvetsmentLot()
    }
  }, [selectedInvLot])

  useEffect(() => {
    if (selectedProject?.id) {
      handleInvestmentChange()
    }
    const projectData = {
      imageUrl: selectedProject?.display_pic_url,

      projectName: selectedProject?.project_name,
      issuerName: selectedProject?.registered_issuer_name,

      cityLabel: "City",
      city: selectedProject?.location,

      isinLabel: "ISIN ",
      isinLinkText: "(NSDL Link)",
      isin: selectedProject?.isin_number,

      // faceValueLabel: "Face Value",
      // faceValue: selectedProject?.face_value,

      bondsLabel: "Total Bonds",
      bonds: selectedProject?.total_bonds,

      dateLabel: "Expected Maturity",
      date: selectedProject?.maturity_date,

      issueSizeLabel: "Issue Size",
      issueSize: selectedProject?.issue_size,
    }
    setProjectData(projectData)
  }, [selectedProject])

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM YYYY")
    return date1
  }

  const ProjectsCashflowColumn = () => [
    {
      dataField: "date",
      text: "Date",
      sort: true,
      formatter: (cellContent, row) => handleValidDate(row.date),
    },
    {
      dataField: "investment_amount",
      text: "Investment Amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.investment_amount)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "interest_amount",
      text: "Interest Amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.interest_amount)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "principal_repayment",
      text: "Principal Repayment",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.principal_repayment)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.total)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
  ]

  const downloadData = (data, name, csvTableHeaders) => {
    const downloadableArr = data.map(item => ({
      date: item.date,
      investment_amount: item.investment_amount,
      interest_amount: item.interest_amount,
      principal_repayment: item.principal_repayment,
      total: item.total,
    }))
    JSONToCSVConvertor(downloadableArr, name, true)
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  const toggle = () => {
    setModal(!modal)
  }
  const handleOrderClicks = async () => {
    setSelectedOption()
    setFile("")
    setBase64("")
    setPostInvLotList([])
    setSelectedPostInvLot([])
    setNotes({ value: null })
    toggle()
  }
  const handleValidOrderSubmit = async (e, values) => {
    if (!selectedOption?.id) {
      toast.error("Please select an investment")
      return
    }

    if (!notes.value) {
      toast.error("Notes field is required")
      return
    }
    if (
      notes.value &&
      !file &&
      !selectedPostInvLot.length &&
      prevCashflowData.length
    ) {
      setLoading(true)
      const updateObj = {
        cashflow_notes: notes.value,
      }
      try {
        const response = await axiosInstance.patch(
          `/investment/${selectedOption.id}`,
          updateObj
        )
        if (response?.status === 200) {
          toast.success("Notes updated succesfully")
          setLoading(false)
          toggle()
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
      return
    }
    if (!selectedPostInvLot.length) {
      toast.error("Please Select Investment Lot")
      setLoading(false)
      return
    }
    const dataToUpload = {
      cash_flow: base64.replace("data:text/csv;base64,", ""),
      investmentId: selectedOption.id,
      cashflowNotes: notes.value,
      investmentLotIds: selectedPostInvLot.map(item => item.id),
    }
    if (base64) {
      setLoading(true)
      try {
        const uploadResponse = await axiosInstance.post(
          "/upload-base-64?type=project_cash_flow",
          dataToUpload
        )
        if (uploadResponse) {
          if (selectedOption?.id == selectedProject?.id) {
            handleInvestmentChange()
          }
          setSelectedProject(selectedOption)
          setLoading(false)
          toast.success("Sucessfully Uploaded")
          setBase64("")
        }
      } catch (error) {
        const msg = error?.response?.data.message
        toast.error(msg)
        setLoading(false)
      }
    } else {
      toast.error("Please select valid file")
      setLoading(false)
      return false
    }
    toggle()
    setLoading(false)
  }

  useEffect(async () => {
    if (file) {
      setLoading(true)
      new Promise((resolve, reject) => {
        getBase64(file, data => resolve(data))
      }).then(result => {
        setBase64(result)
        setLoading(false)
      })
    }
  }, [file])

  const sampleCsv = [
    {
      date: "6/30/2023",
      investment_amount: 0,
      interest_amount: 2788,
      principal_repayment: 2000000,
    },
  ]

  const handleCsvDownload = (json, projectName) => {
    JSONToCSVConvertor(json, `Cash-Flow-Master,${projectName}`, true)
  }

  const handleChange = value => {
    setNotes({ value })
  }

  const handleSelectedOptionChange = async project => {
    setSelectedOption(project)
    setLoading(true)
    setPostInvLotList([])
    setSelectedPostInvLot([])
    setNotes({ value: null })
    setPrevCashflowData([])
    try {
      const response = await axiosInstance.get(
        `project-cash-flow?investmentId=${project.id}`
      )
      const invLotRes = await axiosInstance.get(
        `investment-lot?investmentId=${project.id}`
      )
      if (response?.data?.length) {
        setNotes({ value: response.data[0].investment.cashflow_notes || null })
        setPrevCashflowData(response.data)
      }
      if (invLotRes) {
        const data = invLotRes?.data?.data || invLotRes.data
        setPostInvLotList(
          data.map(item => {
            if (item.status) {
              return {
                ...item,
                investment_lot_name: item.investment_lot_name + " (Active)",
              }
            }
            return item
          })
        )
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.CASH_FLOW_MASTER_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between align-items-center">
                  <div className="col-md-6">
                    <Row className="mb-4">
                      <div className="col-md-6">
                        <label className="card-title">
                          Select Project Name
                        </label>
                        <ReactSelect
                          users={projects}
                          multiOptionLabel={true}
                          optionLabelKeys={["project_name"]}
                          selectedOption={selectedProject}
                          setSelectedOption={setSelectedProject}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="card-title">
                          Select Investment Lot
                        </label>
                        <ReactSelect
                          users={invLotList}
                          isDisabled={!selectedProject}
                          multiOptionLabel={true}
                          optionLabelKeys={["investment_lot_name"]}
                          selectedOption={selectedInvLot}
                          setSelectedOption={setSelectedInvLot}
                        />
                      </div>
                    </Row>
                  </div>
                  <div className="col-auto">
                    {accessControlData?.permissions?.CASH_FLOW_MASTER_ADD && (
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-2 me-2"
                        onClick={handleOrderClicks}
                      >
                        <i className="mdi mdi-plus me-1" />{" "}
                        {accessControlData?.permissions?.CASH_FLOW_MASTER_ADD}
                      </Button>
                    )}
                    {accessControlData?.permissions
                      ?.CASH_FLOW_MASTER_SAMPLE_CSV ? (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded  mb-2 me-2"
                        onClick={() =>
                          handleCsvDownload(sampleCsv, "Sample", true)
                        }
                        style={{ marginRight: "3px" }}
                      >
                        <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                        {
                          accessControlData?.permissions
                            ?.CASH_FLOW_MASTER_SAMPLE_CSV
                        }
                      </Button>
                    ) : null}
                    {accessControlData?.permissions
                      ?.CASH_FLOW_MASTER_DOWNLOAD && (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded  mb-2 me-2"
                        disabled={!selectedInvLot?.id}
                        onClick={() => {
                          downloadData(
                            projectsCashflow,
                            `${
                              selectedInvLot.investment_lot_name.replaceAll(
                                " ",
                                "-"
                              ) + "-"
                            }Cash-Flow-Master`,
                            ProjectsCashflowColumn
                          )
                        }}
                        style={{ marginRight: "3px" }}
                      >
                        <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                        {
                          accessControlData?.permissions
                            ?.CASH_FLOW_MASTER_DOWNLOAD
                        }
                      </Button>
                    )}
                  </div>
                </Row>
                {selectedProject && projectsCashflow.length > 0 ? (
                  <>
                    <Row className="mt-4">
                      <Col lg="12">
                        <ProjectDetailCard
                          imageUrl={projectData?.imageUrl}
                          projectName={projectData?.projectName}
                          issuerName={projectData?.issuerName}
                          cityLabel={projectData?.cityLabel}
                          city={projectData?.city}
                          isinLabel={projectData?.isinLabel}
                          isinLinkText={projectData?.isinLinkText}
                          isin={projectData?.isin}
                          faceValueLabel={projectData?.faceValueLabel}
                          faceValue={projectData?.faceValue}
                          bondsLabel={projectData?.bondsLabel}
                          bonds={projectData?.bonds}
                          dateLabel={projectData?.dateLabel}
                          date={projectData?.date}
                          issueSizeLabel={projectData?.issueSizeLabel}
                          issueSize={projectData?.issueSize}
                        />
                      </Col>
                    </Row>
                    <Card>
                      <CardBody>
                        <React.Fragment>
                          <Row>
                            <Col xs="12">
                              <ToolkitProvider
                                keyField="id"
                                data={projectsCashflow}
                                columns={ProjectsCashflowColumn()}
                                bootstrap4
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <BootstrapTable
                                          keyField="id"
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          columns={ProjectsCashflowColumn()}
                                          data={projectsCashflow}
                                          pagination={paginationFactory(
                                            pageOptions
                                          )}
                                          wrapperClasses={
                                            "table-responsive mb-4"
                                          }
                                          classes={
                                            "table align-middle table-nowrap table-check"
                                          }
                                          headerWrapperClasses={"table-light"}
                                          {...toolkitProps.baseProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            </Col>
                          </Row>
                        </React.Fragment>
                      </CardBody>
                    </Card>
                  </>
                ) : null}
                {!selectedProject && !loading ? (
                  <Row>
                    <Col lg="12">
                      <div className="text-center">
                        <Row className="justify-content-center">
                          <Col sm="4">
                            <div className="maintenance-img">
                              <img
                                src={nodata}
                                alt=""
                                className="img-fluid mx-auto d-block"
                              />
                            </div>
                          </Col>
                        </Row>
                        <h4 className="mt-5">Please select a Project</h4>
                      </div>
                    </Col>
                  </Row>
                ) : null}
                {selectedProject && !loading && !selectedInvLot ? (
                  <Row>
                    <Col lg="12">
                      <div className="text-center">
                        <Row className="justify-content-center">
                          <Col sm="4">
                            <div className="maintenance-img">
                              <img
                                src={nodata}
                                alt=""
                                className="img-fluid mx-auto d-block"
                              />
                            </div>
                          </Col>
                        </Row>
                        <h4 className="mt-5">
                          <>Please select investment lot</>
                        </h4>
                      </div>
                    </Col>
                  </Row>
                ) : null}
                {selectedProject &&
                selectedInvLot &&
                projectsCashflow.length == 0 &&
                !loading ? (
                  <Row>
                    <Col lg="12">
                      <div className="text-center">
                        <Row className="justify-content-center">
                          <Col sm="4">
                            <div className="maintenance-img">
                              <img
                                src={nodata}
                                alt=""
                                className="img-fluid mx-auto d-block"
                              />
                            </div>
                          </Col>
                        </Row>
                        <h4 className="mt-5">
                          No Cash Flow data is available for{" "}
                          <b>{selectedInvLot.investment_lot_name}</b>
                        </h4>
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                Upload cash flow data
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={handleValidOrderSubmit}>
                  <Row form>
                    <Col className="col-12 mb-3">
                      <label className="form-label">Select Investment</label>
                      <ReactSelect
                        users={projects}
                        multiOptionLabel={true}
                        optionLabelKeys={["project_name"]}
                        selectedOption={selectedOption}
                        setSelectedOption={handleSelectedOptionChange}
                      />
                    </Col>
                    <Col className="col-12 mb-3">
                      <label className="form-label">
                        Select Investment Lot
                      </label>
                      <ReactMultiSelect
                        options={postInvLotList}
                        isDisabled={!selectedOption}
                        multiOptionLabel={true}
                        optionLabelKeys={["investment_lot_name"]}
                        selectedOption={selectedPostInvLot}
                        setSelectedOption={setSelectedPostInvLot}
                      />
                    </Col>
                    <Col className="col-12 mb-3">
                      <Label htmlFor="formFile" className="form-label">
                        Upload CSV
                      </Label>
                      <Input
                        className="form-control"
                        type="file"
                        accept=".csv"
                        id="formFile"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={onSelectFile}
                      />
                    </Col>
                    <Col>
                      <label className="form-label">Notes</label>
                      <div className="text-editor mb-3 w-100">
                        <EditorToolbar />
                        <ReactQuill
                          theme="snow"
                          value={notes.value}
                          onChange={handleChange}
                          placeholder={"Write notes here..."}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default ProjectsCashflow
