import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import getBase64 from "../../base64"
import { investmentData } from "constants/common"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "../../common/Loader"
import { sortingByAlphabet } from "../../constants/sort"
import { showToastError, showToastSuccess } from "utils"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"
const InvestmentProjection = () => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [base64, setBase64] = useState("")
  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [investments, setInvestments] = useState([])
  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `investment-projection?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setData(response?.data?.data)
        setLimit(response?.data)
        setLoading(false)
      }
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
    const investments = await investmentData()
    if (investments) {
      setInvestments(investments?.data?.data)
      setLoading(false)
    } else {
      toast.error("something went wrong")
      setLoading(false)
    }
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  const selectRow = {
    mode: "checkbox",
  }

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search

  const ProjectionColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <div className="d-flex gap-3">
          {accessControlData?.permissions?.INVESTMENT_PROJECTIONS_EDIT ? (
            <Link
              to="#"
              className="text-success"
              onClick={() => handleOrderClick(order)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                {accessControlData?.permissions?.INVESTMENT_PROJECTIONS_EDIT}
              </UncontrolledTooltip>
            </Link>
          ) : (
            "NA"
          )}
          {accessControlData?.permissions?.INVESTMENT_PROJECTIONS_DELETE ? (
            <Link
              to="#"
              className="text-danger"
              onClick={() => handleDeleteOrder(order)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                {accessControlData?.permissions?.INVESTMENT_PROJECTIONS_DELETE}
              </UncontrolledTooltip>
            </Link>
          ) : (
            "NA"
          )}
        </div>
      ),
    },

    {
      dataField: "cash_flows",
      text: "CSV Download",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <div className="d-flex gap-3">
          {accessControlData?.permissions
            ?.INVESTMENT_PROJECTIONS_CSV_DOWNLOAD ? (
            <Link
              to="#"
              className="text-primary"
              onClick={() =>
                handleCsvDownload(
                  order.cash_flows,
                  order?.investment?.project_name
                )
              }
            >
              {
                accessControlData?.permissions
                  ?.INVESTMENT_PROJECTIONS_CSV_DOWNLOAD
              }
            </Link>
          ) : (
            "NA"
          )}
        </div>
      ),
    },

    {
      dataField: "investment.project_name",
      text: "Project Name",
      sort: true,
    },

    {
      dataField: "created_at",
      text: "Created On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },

    {
      dataField: "updated_at",
      text: "Updated On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]
  const handleCsvDownload = (json, projectName) => {
    const csv = JSON.parse(json)
    JSONToCSVConvertor(csv, `Investment Projection,${projectName}`, true)
    //   const linkSource = `data:text/csv;json,${csv}`
    //   const downloadLink = document.createElement("a")
    //   const fileName = "InvestmentProjection.csv"
    //   downloadLink.href = linkSource
    //   downloadLink.download = fileName
    //   downloadLink.click()
  }

  const sampleCsv =
    '[{"Date":"1/27/2022","Investment":"-400000","Interest":"50000","Repayment":"0","Total":"-400000"},{"Date":"2/14/2022","Investment":"-225000","Interest":"0","Repayment":"0","Total":"-225000"},{"Date":"4/15/2022","Investment":"0","Interest":"13162.129","Repayment":"0","Total":"13162.129"},{"Date":"7/15/2022","Investment":"-187500","Interest":"16769.53472","Repayment":"0","Total":"-170730.4653"},{"Date":"10/15/2022","Investment":"-187500","Interest":"22173.26606","Repayment":"0","Total":"-165326.7339"},{"Date":"1/15/2023","Investment":"0","Interest":"27399.13267","Repayment":"0","Total":"27399.13267"},{"Date":"4/15/2023","Investment":"0","Interest":"38719.48188","Repayment":"0","Total":"38719.48188"},{"Date":"7/15/2023","Investment":"0","Interest":"39155.51708","Repayment":"0","Total":"39155.51708"},{"Date":"10/15/2023","Investment":"0","Interest":"42114.81415","Repayment":"75000","Total":"117114.8142"},{"Date":"1/15/2024","Investment":"0","Interest":"39986.67946","Repayment":"100000","Total":"139986.6795"},{"Date":"4/15/2024","Investment":"0","Interest":"39032.79728","Repayment":"200000","Total":"239032.7973"},{"Date":"7/15/2024","Investment":"0","Interest":"31201.83978","Repayment":"200000","Total":"231201.8398"},{"Date":"10/15/2024","Investment":"0","Interest":"23976.50359","Repayment":"212500","Total":"236476.5036"},{"Date":"1/15/2025","Investment":"0","Interest":"15563.3739","Repayment":"212500","Total":"228063.3739"},{"Date":"Total","Investment":"-1000000","Interest":"349255.0696","Repayment":"1000000","Total":"349255.0696"}]'

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const handleOrderClick = arg => {
    const order = arg
    setBase64("")
    setOrderList({
      id: order.id,
      project_name: order.investmentId,
      cash_flows: order.cash_flows,
    })
    setIsEdit(true)

    toggle()
  }

  const handleDeleteOrder = async order => {
    var r = confirm(`Are you sure want to delete `)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `investment-projection/${order.id}`
        )
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEdit) {
      const updateOrder = {
        investmentId: values.project_name,
        cash_flows:
          base64.replace("data:text/csv;base64,", "") || orderList.cash_flows,
      }

      // update order

      try {
        const response = await axiosInstance.post(
          `/upload-base-64?type=investment_projection`,
          updateOrder
        )
        if (response) {
          masterData()
          showToastSuccess("Successfully Edited")
        }
      } catch (error) {
        const msg = error?.response?.data?.errors[0]?.message
        showToastError(msg)
      }
    } else {
      const newOrder = {
        investmentId: values.project_name,
        cash_flows: base64.replace("data:text/csv;base64,", ""),
      }
      // save new order
      if (!base64) {
        showToastError("Please upload valid file")
        setLoading(false)
        return false
      }
      try {
        const response = await axiosInstance.post(
          `/upload-base-64?type=investment_projection`,
          newOrder
        )
        if (response) {
          masterData()
          showToastSuccess(" Asset Successfully Added")
        }
      } catch (error) {
        const msg = error?.response?.data?.errors[0]?.message
        showToastError(msg)
      }
    }
    toggle()
    setLoading(false)
  }

  const handleOrderClicks = () => {
    setOrderList("")
    setBase64("")
    setIsEdit(false)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const handleFileUpload = e => {
    setFile(e.target.files[0])
  }

  useEffect(async () => {
    if (file) {
      setLoading(true)
      new Promise((resolve, reject) => {
        getBase64(file, data => resolve(data))
      }).then(result => {
        setBase64(result)
        setLoading(false)
      })
    }
  }, [file])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.INVESTMENT_PROJECTIONS_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={ProjectionColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row justify-content-between">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <div className="col-auto">
                              {accessControlData?.permissions
                                ?.INVESTMENT_PROJECTIONS_ADD && (
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded  mb-2 me-2"
                                  onClick={handleOrderClicks}
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  {
                                    accessControlData?.permissions
                                      ?.INVESTMENT_PROJECTIONS_ADD
                                  }
                                </Button>
                              )}
                              {accessControlData?.permissions
                                ?.INVESTMENT_PROJECTIONS_SAMPLE_CSV && (
                                <Button
                                  type="button"
                                  color="primary"
                                  className="btn-rounded  mb-2 me-2"
                                  onClick={() =>
                                    handleCsvDownload(sampleCsv, "Sample", true)
                                  }
                                  style={{ marginRight: "3px" }}
                                >
                                  <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                  {
                                    accessControlData?.permissions
                                      ?.INVESTMENT_PROJECTIONS_SAMPLE_CSV
                                  }
                                </Button>
                              )}
                            </div>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                columns={ProjectionColumns(toggle)}
                                data={orders}
                                pagination={paginationFactory(pageOptions)}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit
                                    ? "Edit Investment Projection"
                                    : "Add Investment Projection"}
                                </ModalHeader>
                                <ModalBody>
                                  {loading && <Loader />}
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <AvField
                                            name="project_name"
                                            label="Project Name"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                            sort="true"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.project_name || ""}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {sortingByAlphabet(
                                              investments?.map(
                                                (item, index) => (
                                                  <option
                                                    key={item?.project_name}
                                                    value={item?.id}
                                                  >
                                                    {item?.project_name}
                                                  </option>
                                                )
                                              )
                                            )}
                                          </AvField>
                                        </div>

                                        <div className="mt-3">
                                          {loading && <Loader />}
                                          <Label
                                            htmlFor="formFile"
                                            className="form-label"
                                          >
                                            {orderList.cash_flows
                                              ? "Update Cash Flows"
                                              : "Upload Cash Flows"}
                                          </Label>
                                          <Input
                                            className="form-control"
                                            type="file"
                                            accept=".csv"
                                            id="formFile"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={handleFileUpload}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                            style={{ marginTop: "5px" }}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

InvestmentProjection.propTypes = {
  orders: PropTypes.array,
}

export default withRouter(InvestmentProjection)
