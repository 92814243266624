import { axiosInstance } from "ConfigAxioxinstance"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { toast } from "react-toastify"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import nodata from "../../assets/images/nodata/no-data.svg"
import moment from "moment"
import { useHistory } from "react-router-dom"
import { JSONToCSVConvertor } from "common/jsontocsv"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { DealManagementAccess } from "common/AccessManagement"
import { AccessId } from "constants/ConstantFields"
import paginationFactory from "react-bootstrap-table2-paginator"
import getBase64 from "base64"
import Breadcrumbs from "components/Common/Breadcrumb"
import ProjectDetailCard from "components/Common/ProjectDetailCard"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const BondPriceMaster = () => {
  const [loading, setLoading] = useState(false)
  const [projects, setProjects] = useState([])
  const [selectedProject, setSelectedProject] = useState()
  const [bondPriceData, setBondPriceData] = useState([])
  const [base64, setBase64] = useState("")
  const [file, setFile] = useState()
  const [selectedOption, setSelectedOption] = useState()
  const [projectData, setProjectData] = useState({})
  const [expiryDate, setExpiryDate] = useState()
  const [exInterestDate, setExInterestDate] = useState()
  const [recordDate, setRecordDate] = useState()
  const [interestPayoutDate, setInterestPayoutDate] = useState()
  const [prevBondPriceData, setPrevBondPriceData] = useState([])
  const history = useHistory()
  const [modal, setModal] = useState(false)
  const queryParams = new URLSearchParams(location.search)
  const investmentIdFromUrl = queryParams.get("investmentId")

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/investment?$sort[created_at]=-1`
      )
      if (response) {
        if (investmentIdFromUrl) {
          setSelectedProject(
            response.data?.data.find(item => item.id === investmentIdFromUrl)
          )
        }
        setProjects(response.data?.data)
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  const handleInvestmentChange = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/bond-price-master?investmentId=${selectedProject.id}&$sort[date]=-1`
      )
      const projectData = {
        imageUrl: selectedProject?.display_pic_url,

        projectName: selectedProject?.project_name,
        issuerName: selectedProject?.registered_issuer_name,

        cityLabel: "City",
        city: selectedProject?.location,

        isinLabel: "ISIN ",
        isinLinkText: "(NSDL Link)",
        isin: selectedProject?.isin_number,

        // faceValueLabel: "Face Value",
        // faceValue: selectedProject?.face_value,

        bondsLabel: "Total Bonds",
        bonds: selectedProject?.total_bonds,

        dateLabel: "Expiry Date",

        issueSizeLabel: "Issue Size",
        issueSize: selectedProject?.issue_size,

        exInterestDateLabel: "Ex-Interest Date",
        interestPayoutDateLabel: "Interest Payout Date",

        recordDateLabel: "Record Date",
      }
      if (response) {
        setBondPriceData(response.data?.data || response.data)
        projectData["date"] = response.data?.data[0]?.investment?.expiry_date
        projectData["exInterestDate"] =
          response.data?.data[0]?.investment?.ex_interest_date
        projectData["recordDate"] =
          response.data?.data[0]?.investment?.record_date
        projectData["interestPayoutDate"] =
          response.data?.data[0]?.investment?.interest_payout_date
      }
      setProjectData(projectData)
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    const amountInRupees = Math.round(Math.abs(amount))
    return `₹ ${
      amount < 0
        ? "(" + dollarIndianLocale.format(amountInRupees) + ")"
        : dollarIndianLocale.format(amountInRupees)
    } `
  }

  const onSelectFile = e => {
    setFile(e.target.files[0])
  }

  useEffect(() => {
    masterData()
  }, [])

  useEffect(() => {
    if (selectedProject?.id) {
      history.replace({
        search: "?investmentId=" + selectedProject.id,
      })
      handleInvestmentChange()
    }
  }, [selectedProject])

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM YYYY")
    return date1
  }

  const BondPriceDataColumn = () => [
    {
      dataField: "date",
      text: "Date",
      sort: true,
      formatter: (cellContent, row) => handleValidDate(row.date),
    },
    {
      dataField: "face_value",
      text: "Face Value",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.face_value)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "called_face_value",
      text: "Called Face Value",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.called_face_value)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "redeemed_face_value",
      text: "Redeemed Face Value",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.redeemed_face_value)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "benpos_adjustment",
      text: "Benpos Adjustment",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.benpos_adjustment)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "accrued_interest",
      text: "Accrued Interest",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.accrued_interest)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "premium",
      text: "Premium",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.premium)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "annual_return",
      text: "Annual Return",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{row.annual_return}%</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
  ]

  const downloadData = (data, name, csvTableHeaders) => {
    const downloadableArr = data.map(item => ({
      date: item.date,
      face_value: item.face_value,
      called_face_value: item.called_face_value,
      redeemed_face_value: item.redeemed_face_value,
      benpos_adjustment: item.benpos_adjustment,
      accrued_interest: item.accrued_interest,
      premium: item.premium,
      annual_return: item.annual_return,
    }))
    JSONToCSVConvertor(downloadableArr, name, true)
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const toggle = () => {
    setModal(!modal)
  }
  const handleOrderClicks = async () => {
    setSelectedOption()
    setExpiryDate(" ")
    setExInterestDate(" ")
    setRecordDate(" ")
    setInterestPayoutDate(" ")
    setFile("")
    setBase64("")
    toggle()
  }

  const handleValidOrderSubmit = async (e, values) => {
    if (!selectedOption?.id) {
      toast.error("Please select an investment")
      return
    }

    if (
      moment(values.interest_payout_date) <= moment(values.ex_interest_date)
    ) {
      toast.error("Interest Payout should be after Ex-Interest Date")
      return
    }

    if (!file && prevBondPriceData.length) {
      setLoading(true)
      const updateObj = {
        expiry_date: values.bond_price_expiry_date,
        ex_interest_date: values.ex_interest_date,
        record_date: values.record_date,
        interest_payout_date: values.interest_payout_date,
      }
      try {
        const response = await axiosInstance.patch(
          `/investment/${selectedOption.id}`,
          updateObj
        )
        if (response?.status === 200) {
          if (selectedOption?.id == selectedProject?.id) {
            handleInvestmentChange()
          }
          setSelectedProject(selectedOption)
          toast.success("Expiry date updated successfully")
          toast.success("Ex Interest date updated successfully")
          toast.success("Interest Payout date updated successfully")
          toast.success("Record date date updated successfully")
          setLoading(false)
          toggle()
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
      return
    }
    const dataToUpload = {
      bond_price: base64.replace("data:text/csv;base64,", ""),
      investmentId: selectedOption.id,
      expiry_date: values.bond_price_expiry_date,
      ex_interest_date: values.ex_interest_date,
      record_date: values.record_date,
      interest_payout_date: values.interest_payout_date,
    }
    if (base64) {
      try {
        const uploadResponse = await axiosInstance.post(
          "/upload-base-64?type=bond_price_master",
          dataToUpload
        )
        if (uploadResponse) {
          if (selectedOption?.id == selectedProject?.id) {
            handleInvestmentChange()
          }
          setSelectedProject(selectedOption)
          toast.success("Sucessfully Uploaded")
          setBase64("")
        }
      } catch (error) {
        const msg = error?.response?.data.message
        toast.error(msg)
      }
    } else {
      toast.error("Please select valid file")
      setLoading(false)
      return false
    }
    toggle()
    setLoading(false)
  }

  useEffect(async () => {
    if (file) {
      setLoading(true)
      new Promise((resolve, reject) => {
        getBase64(file, data => resolve(data))
      }).then(result => {
        setBase64(result)
        setLoading(false)
      })
    }
  }, [file])

  const sampleCsv = [
    {
      date: "5/23/2023",
      face_value: 10000000,
      called_face_value: 5000000,
      redeemed_face_value: 2000000,
      benpos_adjustment: 2000000,
      accrued_interest: 300000,
      premium: 200000,
      annual_return: 20,
    },
  ]
  const handleCsvDownload = (json, projectName) => {
    JSONToCSVConvertor(json, `Bond-Price-Master,${projectName}`, true)
  }

  const handleSelectedOptionChange = async project => {
    setSelectedOption(project)
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/bond-price-master?investmentId=${project.id}&$sort[date]=-1`
      )
      if (response?.data?.data.length) {
        setExpiryDate(response.data.data[0].investment.expiry_date)
        setExInterestDate(response.data.data[0].investment.ex_interest_date)
        setRecordDate(response.data.data[0].investment.record_date)
        setInterestPayoutDate(
          response.data.data[0].investment.interest_payout_date
        )
        setPrevBondPriceData(response.data.data)
      } else {
        setExpiryDate("")
        setExInterestDate("")
        setRecordDate("")
        setInterestPayoutDate("")
        setPrevBondPriceData([])
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.BOND_PRICE_MASTER_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between align-items-center">
                  <div className="col-md-4">
                    <Row className="mb-4">
                      <label className="card-title">Select Project Name</label>
                      <ReactSelect
                        users={projects}
                        multiOptionLabel={true}
                        optionLabelKeys={["project_name"]}
                        selectedOption={selectedProject}
                        setSelectedOption={setSelectedProject}
                      />
                    </Row>
                  </div>
                  <div className="col-auto">
                    {accessControlData?.permissions?.BOND_PRICE_MASTER_ADD && (
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-2 me-2"
                        onClick={handleOrderClicks}
                      >
                        <i className="mdi mdi-plus me-1" />{" "}
                        {accessControlData?.permissions?.BOND_PRICE_MASTER_ADD}
                      </Button>
                    )}
                    {accessControlData?.permissions
                      ?.BOND_PRICE_MASTER_SAMPLE_CSV ? (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded  mb-2 me-2"
                        onClick={() =>
                          handleCsvDownload(sampleCsv, "Sample", true)
                        }
                        style={{ marginRight: "3px" }}
                      >
                        <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                        {
                          accessControlData?.permissions
                            ?.BOND_PRICE_MASTER_SAMPLE_CSV
                        }
                      </Button>
                    ) : null}
                    {accessControlData?.permissions
                      ?.BOND_PRICE_MASTER_DOWNLOAD && (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded  mb-2 me-2"
                        disabled={!selectedProject?.id}
                        onClick={() => {
                          downloadData(
                            bondPriceData,
                            `${
                              selectedProject.project_name.replaceAll(
                                " ",
                                "-"
                              ) + "-"
                            }Bond-Price-Master`,
                            BondPriceDataColumn
                          )
                        }}
                        style={{ marginRight: "3px" }}
                      >
                        <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                        {
                          accessControlData?.permissions
                            ?.BOND_PRICE_MASTER_DOWNLOAD
                        }
                      </Button>
                    )}
                  </div>
                </Row>
                {selectedProject && bondPriceData.length > 0 ? (
                  <>
                    <Row className="mt-4">
                      <Col lg="12">
                        <ProjectDetailCard
                          imageUrl={projectData?.imageUrl}
                          projectName={projectData?.projectName}
                          issuerName={projectData?.issuerName}
                          // cityLabel={projectData?.cityLabel}
                          // city={projectData?.city}
                          isinLabel={projectData?.isinLabel}
                          isinLinkText={projectData?.isinLinkText}
                          isin={projectData?.isin}
                          faceValueLabel={projectData?.faceValueLabel}
                          faceValue={projectData?.faceValue}
                          bondsLabel={projectData?.bondsLabel}
                          bonds={projectData?.bonds}
                          dateLabel={projectData?.dateLabel}
                          date={projectData?.date}
                          issueSizeLabel={projectData?.issueSizeLabel}
                          issueSize={projectData?.issueSize}
                          exInterestDateLabel={projectData?.exInterestDateLabel}
                          exInterestDate={projectData?.exInterestDate}
                          recordDateLabel={projectData?.recordDateLabel}
                          recordDate={projectData?.recordDate}
                          interestPayoutDateLabel={
                            projectData?.interestPayoutDateLabel
                          }
                          interestPayoutDate={projectData?.interestPayoutDate}
                          isinBelow={true}
                        />
                      </Col>
                    </Row>
                    <Card>
                      <CardBody>
                        <React.Fragment>
                          <Row>
                            <Col xs="12">
                              <ToolkitProvider
                                keyField="id"
                                data={bondPriceData}
                                columns={BondPriceDataColumn()}
                                bootstrap4
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <BootstrapTable
                                          keyField="id"
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          columns={BondPriceDataColumn()}
                                          data={bondPriceData}
                                          pagination={paginationFactory(
                                            pageOptions
                                          )}
                                          wrapperClasses={
                                            "table-responsive mb-4"
                                          }
                                          classes={
                                            "table align-middle table-nowrap table-check"
                                          }
                                          headerWrapperClasses={"table-light"}
                                          {...toolkitProps.baseProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            </Col>
                          </Row>
                        </React.Fragment>
                      </CardBody>
                    </Card>
                  </>
                ) : null}
                {!selectedProject && !loading ? (
                  <Row>
                    <Col lg="12">
                      <div className="text-center">
                        <Row className="justify-content-center">
                          <Col sm="4">
                            <div className="maintenance-img">
                              <img
                                src={nodata}
                                alt=""
                                className="img-fluid mx-auto d-block"
                              />
                            </div>
                          </Col>
                        </Row>
                        <h4 className="mt-5">Please select a Project</h4>
                      </div>
                    </Col>
                  </Row>
                ) : null}
                {selectedProject && !loading && bondPriceData.length == 0 ? (
                  <Row>
                    <Col lg="12">
                      <div className="text-center">
                        <Row className="justify-content-center">
                          <Col sm="4">
                            <div className="maintenance-img">
                              <img
                                src={nodata}
                                alt=""
                                className="img-fluid mx-auto d-block"
                              />
                            </div>
                          </Col>
                        </Row>
                        <h4 className="mt-5">
                          No Bond Price data is available for{" "}
                          <b>{selectedProject.project_name}</b>
                        </h4>
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                Upload Bond Price Data
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={handleValidOrderSubmit}>
                  <Row form>
                    <Col className="col-12 mb-3">
                      <label className="card-title">Select Investment</label>
                      <ReactSelect
                        users={projects}
                        multiOptionLabel={true}
                        optionLabelKeys={["project_name"]}
                        selectedOption={selectedOption}
                        setSelectedOption={handleSelectedOptionChange}
                      />
                    </Col>
                    <Col className="col-12 mb-3">
                      <AvField
                        name="bond_price_expiry_date"
                        label="Bond Price Expiry Date"
                        type="date"
                        errorMessage="Invalid Value"
                        validate={{
                          required: { value: true },
                        }}
                        value={expiryDate}
                        onChange={e => {
                          setExpiryDate(e.target.value)
                        }}
                      ></AvField>
                    </Col>
                    <Col className="col-12 mb-3">
                      <AvField
                        name="ex_interest_date"
                        label="Ex Interest Date"
                        type="date"
                        errorMessage="Invalid Value"
                        validate={{
                          required: { value: true },
                        }}
                        value={exInterestDate}
                        onChange={e => {
                          setExInterestDate(e.target.value)
                        }}
                      ></AvField>
                    </Col>
                    <Col className="col-12 mb-3">
                      <AvField
                        name="record_date"
                        label="Record Date"
                        type="date"
                        errorMessage="Invalid Value"
                        validate={{
                          required: { value: true },
                        }}
                        value={recordDate}
                        onChange={e => {
                          setRecordDate(e.target.value)
                        }}
                      ></AvField>
                    </Col>
                    <Col className="col-12 mb-3">
                      <AvField
                        name="interest_payout_date"
                        label="Interest Payout Date"
                        type="date"
                        errorMessage="Invalid Value"
                        validate={{
                          required: { value: true },
                        }}
                        value={interestPayoutDate}
                        onChange={e => {
                          setInterestPayoutDate(e.target.value)
                        }}
                      ></AvField>
                    </Col>
                    <Col className="col-12 mb-3">
                      <Label htmlFor="formFile" className="form-label">
                        Upload CSV <b>*</b>
                      </Label>
                      <Input
                        className="form-control"
                        type="file"
                        accept=".csv"
                        id="formFile"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={onSelectFile}
                      />
                    </Col>
                    <Col className="col-12 mb-3">
                      <p className="fw-bold">
                        * Please ensure that the Annual Return is updated in
                        Investment and Investment Attributes <br />* The date
                        should be in mm/dd/yyyy format <br />* Benpos adjustment
                        will be added to the outstanding principal in the
                        interest calculation report
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default BondPriceMaster
