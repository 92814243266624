import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  AccessId,
  investmentCountFilter,
  kycStatusType,
} from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import {
  csvDownloadData,
  investmentData,
  maskEmail,
  maskPhoneNumber,
} from "constants/common"
import { JSONToCSVConvertor } from "common/jsontocsv"
import ReactMultiSelect from "constants/ReactMultiSelect"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const InvestorCountSummary = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [userFilter, setUserFilter] = useState("")
  const [downloadErrorModal, setDownloadErrorModal] = useState(false)
  const [distributorList, setDistributorList] = useState([])
  const [selectedDistrinutor, setSelectedDistrinutor] = useState(null)

  const [rmList, setRmList] = useState([])
  const [selectedRm, setSelectedRm] = useState(null)
  const [selectedKycStatus, setSelectedKycStatus] = useState(null)
  const [selectedCountFilter, setSelectedCountFilter] = useState(null)

  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const isRm = localUser?.role?.department == "Relationship_Manager"
  const isDist = localUser?.role?.department === "Distributor"

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    isRm ? `&relManagerId=${localUser.id}` : ""
  }${isDist ? `&distributorId=${localUser.id}` : ""}${
    selectedDistrinutor ? `&distributorId=${selectedDistrinutor.id}` : ""
  }${selectedRm ? `&relManagerId=${selectedRm.id}` : ""}${
    selectedKycStatus && selectedKycStatus.length > 0
      ? `&kyc_status=${selectedKycStatus.map(item => item.id).join(",")}`
      : ""
  }${selectedCountFilter ? `&count=${selectedCountFilter.id}` : ""}`

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const masterData = async () => {
    setLoading(true)

    const getUrl = `investor-count-summary?$limit=${limit}&$skip=${skip}${filterUrl}`
    try {
      const response = await axiosInstance.get(getUrl)

      if (response) {
        setTotal(response.data.total)
        setData(response.data?.data || response.data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const getFiltersDataList = async () => {
    if (isDist) {
      return
    }
    try {
      setLoading(true)
      const opsUsersRes = await axiosInstance.get(
        `/users?$sort[created_at]=-1&permissions=OPS_USER`
      )
      if (opsUsersRes) {
        const opsUsers = opsUsersRes.data.data
        setDistributorList(
          opsUsers?.filter(user => {
            if (user?.role?.department === "Distributor") {
              user.user_name = user.distributor_kyc?.name
              if (isRm && user.relManagerId != localUser.id) {
                return false
              }
              return true
            }
          })
        )
        setRmList(opsUsers?.filter(ele => ele.roleId == 1))
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getFiltersDataList()
  }, [])

  useEffect(() => {
    masterData()
  }, [
    limit,
    skip,
    selectedDistrinutor,
    selectedRm,
    selectedKycStatus,
    selectedCountFilter,
  ])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const Column = () => {
    let col = [
      {
        dataField: "name_on_pan",
        text: "Investor Pan Name",
        sort: true,
      },
      {
        dataField: "created_at",
        text: "Created At",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">
            {moment(row.created_at).format("DD MMM YYYY")}
          </div>
        ),
      },
      {
        dataField: "pan_type",
        text: "PAN Type",
        sort: true,
      },
      {
        dataField: "kyc_status",
        text: "Kyc Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = kycStatusType.find(obj => obj.id == cellContent)
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              }`}
            >
              {status?.statusText}
            </span>
          )
        },
      },
      {
        dataField: "count",
        text: "Total Count",
        sort: true,
        // eslint-disable-next-line react/display-name
        headerFormatter: (column, colIndex) => (
          <div className="text-end">{column.text}</div>
        ),
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },
      {
        dataField: "completed_count",
        text: "Completed",
        sort: true,
        // eslint-disable-next-line react/display-name
        headerFormatter: (column, colIndex) => (
          <div className="text-end">{column.text}</div>
        ),
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },
      {
        dataField: "awaiting_manual_transfer_count",
        text: "Awaiting Manual Transfer",
        sort: true,
        // eslint-disable-next-line react/display-name
        headerFormatter: (column, colIndex) => (
          <div className="text-end">{column.text}</div>
        ),
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },
      {
        dataField: "partially_paid_count",
        text: "Partially Paid",
        sort: true,
        // eslint-disable-next-line react/display-name
        headerFormatter: (column, colIndex) => (
          <div className="text-end">{column.text}</div>
        ),
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },
      {
        dataField: "completed_amount_sum",
        text: "Completed Investment Amount",
        sort: true,
        // eslint-disable-next-line react/display-name
        headerFormatter: (column, colIndex) => (
          <div className="text-end">{column.text}</div>
        ),
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{handleAmount(cellContent)}</div>
        ),
      },
      {
        dataField: "pan_number",
        text: "PAN Number",
        sort: true,
      },
      // {
      //   dataField: "user_name",
      //   text: "User Name",
      //   sort: true,
      // },
      {
        dataField: "email",
        text: "User Email",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => <div>{maskEmail(cellContent)}</div>,
      },

      {
        dataField: "phone",
        text: "User Phone",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div>{maskPhoneNumber(cellContent)}</div>
        ),
      },
    ]
    if (!isDist && !isRm) {
      const rmCol = [
        {
          dataField: "rm_user_name",
          text: "RM Name",
          sort: true,
        },
        {
          dataField: "rm_email",
          text: "RM Email",
          sort: true,
        },
        {
          dataField: "rm_phone",
          text: "RM Phone",
          sort: true,
        },
      ]
      col = [...col, ...rmCol]
    }
    if (!isDist) {
      const distCol = [
        {
          dataField: "distributor_user_name",
          text: "Distributor Name",
          sort: true,
        },
        {
          dataField: "distributor_legal_entity_name",
          text: "Distributor Legal Entity Name",
          sort: true,
        },
        {
          dataField: "distributor_email",
          text: "Distributor Email",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => <div>{maskEmail(cellContent)}</div>,
        },
        {
          dataField: "distributor_phone",
          text: "Distributor Phone",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => (
            <div>{maskPhoneNumber(cellContent)}</div>
          ),
        },
      ]
      col = [...col, ...distCol]
    }

    return col
  }

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  const toggleDownloadErrorModal = () => {
    setDownloadErrorModal(!downloadErrorModal)
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      case "distributor":
        setSelectedDistrinutor(e)
        break
      case "rm":
        setSelectedRm(e)
        break
      case "kycStatus":
        setSelectedKycStatus(e)
        break
      case "count":
        setSelectedCountFilter(e)
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const downloadData = async () => {
    // if (total > 500) {
    //   setDownloadErrorModal(true)
    //   return
    // }

    try {
      setLoading(true)
      const url = `investor-count-summary?$sort[created_at]=-1${filterUrl}`
      const res = await axiosInstance.get(url)
      const fileName = `Investor Count Summary`
      if (res) {
        const data = res.data?.data
        data.forEach(item => {
          item.email = item.email ? maskEmail(item.email) : "-"
          item.phone = item.phone ? maskPhoneNumber(item.phone) : "-"
          item.distributor_email = item.distributor_email
            ? maskEmail(item.distributor_email)
            : "-"
          item.distributor_phone = item.distributor_phone
            ? maskPhoneNumber(item.distributor_phone)
            : "-"
          return item
        })
        const csvTableHeaders = Column()
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.INVESTOR_COUNT_SUMMARY_REPORT_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem="Investor Count Summary" />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between">
                  <Col md={2}>
                    <select
                      className="form-select w-75"
                      value={limit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <div className="col-auto">
                    {accessControlData?.permissions
                      ?.INVESTOR_COUNT_SUMMARY_REPORT_DOWNLOAD && (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded  mb-2 me-2"
                        onClick={downloadData}
                        style={{ marginRight: "3px" }}
                      >
                        <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                        Download
                      </Button>
                    )}
                  </div>
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={data}
                      columns={Column()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row">
                            <Col md={3}>
                              <div className="col-auto">
                                <label>Search Investor (By Pan Name)</label>
                                <input
                                  onChange={e => handleFilterChange(e, "user")}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control rounded custom-input-height"
                                  placeholder={`Search`}
                                  value={userFilter || ""}
                                />
                                {/* <i className="bx bx-search-alt"></i> */}
                              </div>
                            </Col>
                            {!isDist ? (
                              <Col md={3}>
                                <div className="mb-3">
                                  <label>Distributor</label>
                                  <ReactSelect
                                    users={distributorList}
                                    setSelectedOption={e =>
                                      handleFilterChange(e, "distributor")
                                    }
                                    multiOptionLabel={true}
                                    optionLabelKeys={[
                                      "user_name",
                                      "email",
                                      "phone",
                                    ]}
                                    isClearable={true}
                                  />
                                </div>
                              </Col>
                            ) : null}
                            {!isRm && !isDist ? (
                              <Col md={3}>
                                <div className="mb-3">
                                  <label>Relationship Manager</label>
                                  <ReactSelect
                                    users={rmList}
                                    setSelectedOption={e =>
                                      handleFilterChange(e, "rm")
                                    }
                                    multiOptionLabel={true}
                                    optionLabelKeys={[
                                      "user_name",
                                      "email",
                                      "phone",
                                    ]}
                                    isClearable={true}
                                  />
                                </div>
                              </Col>
                            ) : null}
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Kyc status</label>
                                <ReactMultiSelect
                                  options={kycStatusType}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "kycStatus")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["statusText"]}
                                  isClearable={true}
                                  isMulti={true}
                                />
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="mb-3">
                                <label>Total Count</label>
                                <ReactSelect
                                  users={investmentCountFilter}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "count")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["statusText"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                columns={Column()}
                                data={data}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                            </Col>
                          </Row>
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}
                    `}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              isOpen={downloadErrorModal}
              toggle={toggleDownloadErrorModal}
            >
              <ModalHeader toggle={toggleDownloadErrorModal} tag="h4">
                Invalid Request!
              </ModalHeader>
              <ModalBody>
                <Row form>
                  Download requested for more than 500 entries. Please select a
                  filter before proceeding.
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-success save-user"
                        onClick={toggleDownloadErrorModal}
                      >
                        OK
                      </button>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(InvestorCountSummary)
