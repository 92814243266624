import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import "../SponserOtherProjects/styles.css"
import EditorTool, { moduless, formatss } from "../InvestmentRisk/EditorTool"
import { investmentData, picUrl } from "../../constants/common"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { sortingByAlphabet } from "constants/sort"
import { AccessId } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import { Pagination } from "constants/common"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"
const InvestmentPerfHistory = props => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [file, setFile] = useState(null)
  const [dp, setDp] = useState("")
  const [state, setState] = useState({ value: null })
  const [detail, setDetail] = useState({ value: null })
  const [loading, setLoading] = useState(false)
  const [imgAltDescModal, setImgAltDescModal] = useState(false)
  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/investment-perf-history?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setData(response?.data?.data)
        setLimit(response?.data)
        setLoading(false)
      }
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  const selectRow = {
    mode: "checkbox",
  }

  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const PerformanceColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessControlData?.permissions?.PERFORMANCE_EDIT ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {accessControlData?.permissions?.PERFORMANCE_EDIT}
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
            {accessControlData?.permissions?.PERFORMANCE_DELETE ? (
              <Link
                to="#"
                className="text-danger"
                onClick={() => handleDeleteOrder(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {accessControlData?.permissions?.PERFORMANCE_DELETE}
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },

    {
      dataField: "investment.project_name",
      text: "Project Name",
      sort: true,
    },
    {
      dataField: "perf_history_name",
      text: "Perf Name",
      sort: true,
    },

    {
      dataField: "disp_order",
      text: "Disp Order",
      sort: true,
    },

    {
      dataField: "updated_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  const handleSponsorName = overview => {
    const overviewSorted = overview?.project_name
    return overviewSorted
  }

  const handleDocList = id => {
    handleDoc(id)
  }

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const toLowerCase1 = str => {
    return str.toLowerCase()
  }

  const handleOrderClick = arg => {
    const order = arg
    setState({ value: null })
    setDetail({ value: null })
    setOrderList({
      id: order.id,
      project_name: order.investmentId,
      allign: order.allign,
      img: order.img,
      img_col_width: order.img_col_width,
      perf_history_name: order.perf_history_name,
      section_details: order.section_details,
      section_header: order.section_header,
      section_width: order.section_width,
      disp_order: order.disp_order,
      created_at: order.created_at,
      image_alt_desc: order.image_alt_desc,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDeleteOrder = async order => {
    var r = confirm(`Are you sure want to delete`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `investment-perf-history/${order.id}`
        )
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }
  const handleValidOrderSubmit = async (e, values) => {
    if (isEdit) {
      const updateOrder = {
        investmentId: values.project_name,
        allign: values.allign,
        img: dp || orderList.img,
        img_col_width: values.img_col_width || null,
        perf_history_name: values.perf_history_name,
        section_header: values.section_header,
        section_width: values.section_width,
        section_details: detail.value || orderList.section_details,
        disp_order: values.disp_order,
        created_at: values.created_at,
        image_alt_desc: values.image_alt_desc,
      }

      // update order

      try {
        const response = await axiosInstance.patch(
          `investment-perf-history/${orderList.id}`,
          updateOrder
        )
        if (response) {
          setState({ value: null })
          setDetail({ value: null })
          setDp(null)
          masterData()
          toast.success("Successfully Edited")
        }
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      const newOrder = {
        investmentId: values["project_name"],
        allign: values["allign"],
        img: dp || null,
        img_col_width: values["img_col_width"],
        perf_history_name: values["perf_history_name"],
        section_header: values["section_header"],
        section_width: values["section_width"],
        section_details: detail.value,
        disp_order: values["disp_order"],
        created_at: values["created_at"],
        image_alt_desc: values.image_alt_desc,
      }
      // save new order

      try {
        const response = await axiosInstance.post(
          `investment-perf-history`,
          newOrder
        )
        if (response) {
          setState({ value: null })
          setDetail({ value: null })
          setDp(null)

          masterData()
          toast.success(" Item Successfully Added")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    toggle()
  }

  const handleOrderClicks = () => {
    setState({ value: null })
    setDetail({ value: null })
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const [sponser, setSponser] = useState([])

  useEffect(async () => {
    try {
      const response = await investmentData()
      if (response) {
        setSponser(response?.data?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }, [])

  const handleChange = value => {
    setState({ value })
  }

  const handleItemChange = value => {
    setDetail({ value })
  }
  const handleFileUpload = e => {
    setFile(e.target.files[0])
  }

  useEffect(async () => {
    if (file) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=underlying_asset_image`,
          formData
        )
        if (response) {
          setDp(response.data[0].name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [file])

  return (
    <React.Fragment>
      {loading && <Loader />}
      {/*<EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.PERFORMANCE_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={PerformanceColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              {accessControlData?.permissions
                                ?.PERFORMANCE_ADD && (
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleOrderClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    {
                                      accessControlData?.permissions
                                        ?.PERFORMANCE_ADD
                                    }
                                  </Button>
                                </div>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                columns={PerformanceColumns(toggle)}
                                data={orders}
                                pagination={paginationFactory(pageOptions)}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit
                                    ? "Edit Perf History"
                                    : "Add Perf History"}
                                </ModalHeader>
                                <ModalBody>
                                  {loading && <Loader />}

                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <AvField
                                            name="project_name"
                                            label="Project Name"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid Project Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.project_name || ""}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {sortingByAlphabet(sponser).map(
                                              (item, index) => (
                                                <option
                                                  key={item.project_name}
                                                  value={item.id}
                                                >
                                                  {item.project_name}
                                                </option>
                                              )
                                            )}
                                          </AvField>
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="disp_order"
                                            label="Disp Order"
                                            type="number"
                                            errorMessage="Invalid Disp Order"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.disp_order || ""}
                                          />
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="perf_history_name"
                                            label="Perf History Name"
                                            type="text"
                                            errorMessage="Invalid Perf History Name"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList.perf_history_name || ""
                                            }
                                          />
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="allign"
                                            label="Align"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid Align"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.allign || ""}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            <option value="Left">Left</option>
                                            <option value="Right">Right</option>
                                          </AvField>
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="section_header"
                                            label="Section Header"
                                            type="text"
                                            errorMessage="Invalid Section Header"
                                            value={
                                              orderList.section_header || ""
                                            }
                                          />
                                        </div>

                                        <div
                                          className="mb-3"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Section Details
                                        </div>
                                        <div
                                          className="text-editor"
                                          style={{
                                            width: "100%",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          <EditorTool />
                                          <ReactQuill
                                            theme="snow"
                                            value={
                                              detail?.value ||
                                              orderList?.section_details ||
                                              " "
                                            }
                                            onChange={handleItemChange}
                                            placeholder={
                                              "Write section details here..."
                                            }
                                            modules={moduless}
                                            formats={formatss}
                                          />
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="section_width"
                                            label="Section Width"
                                            type="number"
                                            errorMessage="Invalid Section Width"
                                            value={
                                              orderList.section_width || ""
                                            }
                                          />
                                        </div>
                                        {orderList.img ? (
                                          <img
                                            src={`${picUrl}${orderList.img}`}
                                            alt="Image"
                                            style={{
                                              maxWidth: "100px",
                                              maxHeight: "150px",
                                            }}
                                          ></img>
                                        ) : null}
                                        <div
                                          className="mt-3"
                                          style={{ marginBottom: "5px" }}
                                        >
                                          <Label
                                            htmlFor="formFile"
                                            className="form-label"
                                          >
                                            {orderList.img
                                              ? "Update Image (Recommended Image Resolution : 456*200 pixels)"
                                              : "Upload Image (Recommended Image Resolution : 456*200 pixels)"}
                                          </Label>
                                          <Input
                                            className="form-control"
                                            type="file"
                                            id="formFile"
                                            onChange={handleFileUpload}
                                          />
                                        </div>
                                        <div className="col-12">
                                          <div className="my-3">
                                            <Modal
                                              isOpen={imgAltDescModal}
                                              toggle={() => {
                                                setImgAltDescModal(
                                                  !imgAltDescModal
                                                )
                                              }}
                                            >
                                              <ModalHeader
                                                toggle={() => {
                                                  setImgAltDescModal(
                                                    !imgAltDescModal
                                                  )
                                                }}
                                                tag="h3"
                                              >
                                                Image Alt Description
                                              </ModalHeader>
                                              <ModalBody>
                                                <p className="font-size-16">
                                                  This will not appear on the
                                                  website but it is required for
                                                  SEO.
                                                </p>
                                              </ModalBody>
                                            </Modal>
                                            <Label>
                                              Image Alt Description{" "}
                                              <i
                                                className="fas fa-info-circle"
                                                id="TooltipTop"
                                                onClick={() => {
                                                  setImgAltDescModal(true)
                                                }}
                                              ></i>
                                            </Label>
                                            <AvField
                                              name="image_alt_desc"
                                              type="textarea"
                                              errorMessage="Invalid input"
                                              value={
                                                orderList.image_alt_desc || ""
                                              }
                                              validate={{
                                                required: { value: true },
                                              }}
                                            ></AvField>
                                          </div>
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="img_col_width"
                                            label="Img Col Width"
                                            type="number"
                                            errorMessage="Invalid Img Col Width"
                                            value={
                                              orderList.img_col_width || null
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

InvestmentPerfHistory.propTypes = {
  orders: PropTypes.array,
}

export default withRouter(InvestmentPerfHistory)
