import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import getBase64 from "../../base64"
import {
  AccessDenied,
  Pagination,
  investmentData,
} from "../../constants/common"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import "react-quill/dist/quill.snow.css"
import "../SponserOtherProjects/styles.css"
import Loader from "../../common/Loader"
import { sortingByAlphabet } from "../../constants/sort"
import { AccessId } from "constants/ConstantFields"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const InvestmentCashFlows = props => {
  const [orders, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState()
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [base64, setBase64] = useState("")
  const [base64Data, setBase64Data] = useState()
  const [creationRequestId, setCreationRequestId] = useState("")
  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [baseAmount, setBaseAmount] = useState()
  const [profitAmount, setProfitAmount] = useState()
  const [trancheAmount, setTrancheAmount] = useState()
  const [investmentId, setInvestmentId] = useState()
  const [projectname, setProjectname] = useState()
  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `investment-cash-flows?$limit=1111111&$skip=${skip}&$sort[created_at]=-1`
      )

      if (response) {
        setData(response?.data?.data)
        setLimit(response?.data)
        setLoading(false)
      }
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
  }

  useEffect(async () => {
    masterData()
  }, [skip])

  const selectRow = {
    mode: "checkbox",
  }

  const handleProfitAmountChange = e => {
    setProfitAmount(e.target.value)
  }
  const handleInvestment = e => {
    setInvestmentId(e.target.value)
  }

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search

  const CashFlowCOlumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <div className="d-flex gap-3">
          {accessControlData?.permissions?.INVESTMENT_CASH_FLOWS_EDIT ? (
            <Link
              to="#"
              className="text-success"
              onClick={() => handleOrderClick(order)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                {accessControlData?.permissions?.INVESTMENT_CASH_FLOWS_EDIT}
              </UncontrolledTooltip>
            </Link>
          ) : (
            AccessDenied
          )}{" "}
          {accessControlData?.permissions?.INVESTMENT_CASH_FLOWS_DELETE ? (
            <Link
              to="#"
              className="text-danger"
              onClick={() => handleDeleteOrder(order)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                {accessControlData?.permissions?.INVESTMENT_CASH_FLOWS_DELETE}
              </UncontrolledTooltip>
            </Link>
          ) : (
            `,${AccessDenied}`
          )}
        </div>
      ),
    },
    {
      dataField: "cash_flows",
      text: "CSV Download",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <div className="d-flex gap-3">
          {accessControlData?.permissions
            ?.INVESTMENT_CASH_FLOWS_CSV_DOWNLOAD ? (
            <Link
              to="#"
              className="text-primary"
              onClick={() =>
                handleCsvDownload(
                  order.cash_flows,
                  order?.investment?.project_name
                )
              }
            >
              {
                accessControlData?.permissions
                  ?.INVESTMENT_CASH_FLOWS_CSV_DOWNLOAD
              }
            </Link>
          ) : (
            "NA"
          )}
        </div>
      ),
    },
    {
      dataField: "tranche_amount",
      text: " Tranche Amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleAmount(row.tranche_amount),
    },

    {
      dataField: "base_amount",
      text: "Base Amount",
      sort: true,
    },

    {
      dataField: "profit_amount",
      text: "Profit Amount",
      sort: true,
    },
    {
      dataField: "investment.project_name",
      text: "Project Name",
      // formatter: (cellContent, row) => handleSponsorName(row.investmentId),
      sort: true,
    },

    {
      dataField: "updated_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  const handleCsvDownload = (json, projectName) => {
    const csv = JSON.parse(json)
    JSONToCSVConvertor(csv, `Investment Cash Flows,${projectName}`, true)
  }

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }
  const handleOrderClick = arg => {
    setBaseAmount("")
    const order = arg
    setOrderList({
      id: order.id,
      investmentId: order.investmentId,
      base_amount: order.base_amount,
      cash_flows: order.cash_flows,
      profit_amount: order.profit_amount,
      img: order.img,
      created_at: order.created_at,
      tranche_amount: order.tranche_amount,
    })
    setProjectname(order.investment.project_name)
    setIsEdit(true)
    setBase64("")

    toggle()
  }
  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }
  const handleDeleteOrder = async order => {
    var r = confirm(`Are you sure want to delete`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `investment-cash-flows/${order.id}`
        )
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const fileUploadData = async () => {
    var newCashFlowsData = ""
    setLoading(true)
    if (base64.includes("data:application/vnd.ms-excel;base64,")) {
      newCashFlowsData = base64.replace(
        "data:application/vnd.ms-excel;base64,",
        ""
      )
    } else {
      newCashFlowsData = base64.replace("data:text/csv;base64,", "")
    }
    const data = {
      investmentId: creationRequestId || orderList.investmentId,
      base_amount: +baseAmount || +orderList.base_amount,
      cash_flows: newCashFlowsData || orderList.cash_flows,
      profit_amount: +profitAmount || +orderList.profit_amount,
      tranche_amount: +trancheAmount || +orderList.tranche_amount,
    }
    try {
      const response = await axiosInstance.post(
        "/upload-base-64?type=calc",
        data
      )
      if (response) {
        setBase64Data(response)
        masterData()
        getInvestmentData()
        setLoading(false)
        toast.success("Sucessfully Added")
      }
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
  }
  const handleValidOrderSubmit = async (e, values) => {
    if (isEdit) {
      // update order
      if (!base64 && !orderList.cash_flows) {
        toast.error("Cash Flows file upload is mandatory!!!")
        return false
      }

      fileUploadData()
    } else {
      fileUploadData()
    }
    toggle()
  }

  const handleOrderClicks = () => {
    setProjectname()
    setCreationRequestId("")
    setBaseAmount()
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const [sponser, setSponser] = useState([])
  const [selectedRequestData, setSelectedRequestData] = useState()

  useEffect(() => {
    const selectedRequestData = sponser.find(
      item => item?.id === creationRequestId
    )
    setSelectedRequestData(selectedRequestData)
  }, [creationRequestId])

  useEffect(() => {
    if (selectedRequestData) {
      const baseAmountValue = selectedRequestData?.lot_price
      setBaseAmount(baseAmountValue)
    }
  }, [selectedRequestData])

  const getInvestmentData = async () => {
    try {
      const response = await investmentData()
      if (response) {
        setSponser(response?.data?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    getInvestmentData()
  }, [])

  const handleFileUpload = e => {
    setFile(e.target.files[0])
  }

  const handleTranche = e => {
    setTrancheAmount(e.target.value)
  }

  useEffect(async () => {
    if (file) {
      setLoading(true)
      new Promise((resolve, reject) => {
        getBase64(file, data => resolve(data))
      }).then(result => {
        setBase64(result)
        setLoading(false)
      })
    }
  }, [file])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.INVESTMENT_CASH_FLOWS_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={CashFlowCOlumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              {accessControlData?.permissions
                                ?.INVESTMENT_CASH_FLOWS_ADD && (
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleOrderClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    {
                                      accessControlData?.permissions
                                        ?.INVESTMENT_CASH_FLOWS_ADD
                                    }
                                  </Button>
                                </div>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                columns={CashFlowCOlumns(toggle)}
                                data={orders}
                                pagination={paginationFactory(pageOptions)}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit
                                    ? "Edit Investment Cash Flows"
                                    : "Add Investment Cash Flows"}
                                </ModalHeader>
                                <ModalBody>
                                  {loading && <Loader />}
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <AvField
                                            name="id"
                                            readOnly={isEdit}
                                            disabled={isEdit}
                                            label="Project Name"
                                            type="select"
                                            // onChange={handleInvestment}
                                            className="form-select"
                                            errormessage="Invalid Sponsor Master"
                                            onChange={e =>
                                              setCreationRequestId(
                                                e.target.value
                                              )
                                            }
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={orderList.id || ""}
                                            style={{ widht: "100%" }}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {sortingByAlphabet(sponser).map(
                                              (item, index) => (
                                                <option
                                                  key={item.id}
                                                  value={item.id}
                                                >
                                                  {projectname ||
                                                    item.project_name}
                                                </option>
                                              )
                                            )}
                                          </AvField>
                                        </div>
                                        {baseAmount || isEdit ? (
                                          <div className="mb-3">
                                            <AvField
                                              name="base_amount"
                                              label="Base Amount (it should be same as lot price of investment)"
                                              type="number"
                                              errormessage="Invalid Base Amount"
                                              onChange={e =>
                                                setBaseAmount(e?.target?.value)
                                              }
                                              value={
                                                baseAmount ||
                                                orderList.base_amount
                                              }
                                            ></AvField>
                                          </div>
                                        ) : (
                                          ""
                                        )}

                                        <div className="mb-3">
                                          <AvField
                                            name="profit_amount"
                                            label="Profit Amount"
                                            type="number"
                                            onChange={handleProfitAmountChange}
                                            errormessage="Invalid Profit Amount"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList.profit_amount || ""
                                            }
                                          ></AvField>
                                        </div>
                                        <div
                                          className="mb-3"
                                          style={{ marginTop: "10px" }}
                                        >
                                          <AvField
                                            name="tranche_amount"
                                            label="Tranche Amount"
                                            type="number"
                                            errorMessage="Invalid value"
                                            onChange={handleTranche}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList.tranche_amount || null
                                            }
                                          ></AvField>
                                        </div>
                                        <div className="mt-3">
                                          {loading && <Loader />}
                                          <Label
                                            htmlFor="formFile"
                                            className="form-label"
                                          >
                                            {orderList.cash_flows
                                              ? "Update Cash Flows"
                                              : "Upload Cash Flows"}
                                          </Label>
                                          <Input
                                            className="form-control"
                                            type="file"
                                            accept=".csv"
                                            id="formFile"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={handleFileUpload}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                            style={{ marginTop: "5px" }}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

InvestmentCashFlows.propTypes = {
  orders: PropTypes.array,
}

export default withRouter(InvestmentCashFlows)
