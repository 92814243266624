import { axiosInstance } from "ConfigAxioxinstance"
import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"

import { Button, Card, CardBody, Col, Container, Input, Row } from "reactstrap"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import { JSONToCSVConvertor } from "common/jsontocsv"
import Loader from "common/Loader"
import { csvDownloadData, investmentData } from "constants/common"
import ReactSelect from "constants/ReactSelect"
import moment from "moment"

const CampaignLeads = props => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [investmentList, setInvestmentList] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [userFilter, setUserFilter] = useState("")
  const [investmentFilter, setInvestmentFilter] = useState(null)
  const [total, setTotal] = useState(0)

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])
  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
  }`

  const masterData = async () => {
    setLoading(true)

    try {
      const res = await axiosInstance.get(
        `campaign-leads?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`
      )

      if (res) {
        setData(res?.data?.data)
        setTotal(res?.data?.total)
        let pages = Math.ceil(
          (res.data?.total || res.total) / (res.data?.limit || res.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    try {
      try {
        const invResponse = await investmentData("Approved")
        if (invResponse) {
          setInvestmentList(invResponse?.data?.data || invResponse?.data)
        }
      } catch (error) {
        toast.error(error?.message)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "investment":
        setInvestmentFilter(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  useEffect(() => {
    masterData()
  }, [limit, skip, investmentFilter])

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const Columns = (download = false) => {
    const cols = [
      {
        dataField: "investment.project_name",
        text: "Project Name",
        sort: true,
      },
      {
        dataField: "name",
        text: "Name",
        sort: true,
      },
      {
        dataField: "phone",
        text: "Phone",
        sort: true,
      },
      {
        dataField: "url",
        text: "Url",
        sort: true,
      },
      {
        dataField: "created_at",
        text: "Created At",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => handleValidDate(order.created_at),
      },
    ]
    return cols
  }

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const downloadData = async () => {
    try {
      const fileName = `Campaign-Leads`
      setLoading(true)
      const res = await axiosInstance.get(
        `campaign-leads?$sort[created_at]=-1${filterUrl}`
      )
      if (res) {
        const data = res.data?.data
        const csvTableHeaders = Columns(true)
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        arr.forEach(
          item => (item.created_at = handleValidDate(item.created_at))
        )
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions
          ?.SALES_AND_INVESTOR_SERVICE_CAMPAIGN_LEADS_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem="Campaign Leads" />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between">
                  <Col md={2}>
                    <select
                      className="form-select w-75"
                      value={limit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <div className="col-auto">
                    {accessControlData?.permissions
                      ?.SALES_AND_INVESTOR_SERVICE_CAMPAIGN_LEADS_DOWNLOAD && (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded mb-2 me-2"
                        onClick={() => downloadData()}
                        style={{ marginRight: "3px" }}
                        // disabled={!transferorFilter?.id || !isAddedFilter?.id}
                      >
                        <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                        Download
                      </Button>
                    )}
                  </div>
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={data}
                      columns={Columns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row">
                            <Col md={4}>
                              <div className="position-relative">
                                <label>User</label>
                                <input
                                  onChange={e => handleFilterChange(e, "user")}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control rounded custom-input-height"
                                  placeholder={`Search by Name or Phone`}
                                  value={userFilter || ""}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Project Name</label>
                                <ReactSelect
                                  users={investmentList}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "investment")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["project_name"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                columns={Columns()}
                                data={data}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                            </Col>
                          </Row>
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}
                    `}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(CampaignLeads)
